import React from "react";
import { Box } from "@chakra-ui/react";

import MultiColorBar from "./MultiColorBar";
import { theme } from "src/config/theme";
import { SliderWithLine } from "./SliderWithLine";

interface IProps {
  year: number;
  setYear: (year: number) => void;
  minYear: number;
  maxYear: number;
}

export const SliderSingleColor: React.FC<IProps> = (props) => {
  const { year, setYear, minYear, maxYear } = props;
  const gray = theme.colors.gray[100];
  const share = ((year - minYear) / (maxYear - minYear)) * 100;
  return (
    <Box>
      <Box mb="-1rem">
        <MultiColorBar
          categories={[
            {
              share: share,
              color: "red.500",
            },
            { share: 100 - share, color: gray },
          ]}
        />
      </Box>
      <SliderWithLine
        currentValue={year}
        min={minYear}
        max={maxYear}
        onChange={setYear}
        pointColor="red.500"
      />
    </Box>
  );
};

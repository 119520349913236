import { EntityState, createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { countryAdapter } from "./adapter";
import { ICountry } from "src/api/open-api";

export const selectSlice = (state: RootState) => state.countries;

export const selectAllCountries = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<ICountry>) =>
    countryAdapter.getSelectors().selectAll(state),
);

import { ITaxonomy } from "src/api/open-api";
import { IAppTaxonomy } from "src/redux/interfaces/IAppAsset";

export const transformTaxonomyOnLoad = (
  taxonomy?: ITaxonomy,
): IAppTaxonomy | undefined =>
  taxonomy
    ? {
        substantial_contribution_type: taxonomy.substantial_contribution_type,
        substantial_contribution_compliance:
          taxonomy.substantial_contribution_compliance,
        doNoSignificantHarm: {
          cca: [
            taxonomy.climate_change_adaptation_min_class_c,
            taxonomy.climate_change_adaptation_no_fossil,
          ],
          ccm: [
            taxonomy.climate_change_mitigation_assessment,
            taxonomy.climate_change_mitigation_in_five_years,
          ],
          bde: [
            taxonomy.biodiversity_assessment,
            taxonomy.biodiversity_adaptation_five_years,
          ],
        },
      }
    : undefined;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { ScenariosApi, ICustomPathway } from "src/api/open-api";
import { defaultApiConfiguration } from "src/api/configurations";

type TAddProps = {
  assetId: number;
  customPathway: ICustomPathway;
};
const mock = false;
export const upsertCustomPathway = createAsyncThunk(
  "customPathway/upsert",
  async ({ assetId, customPathway }: TAddProps, { rejectWithValue }) => {
    const api = new ScenariosApi(defaultApiConfiguration);
    try {
      const response = mock
        ? { data: { custom_pathway_id: Math.round(Math.random() * 20) } }
        : await api.addCustomPathway(assetId, customPathway);
      return {
        assetId,
        customPathway: {
          ...customPathway,
          custom_pathway_id: response.data.custom_pathway_id,
        },
      };
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

export const deleteCustomPathway = createAsyncThunk(
  "customPathway/delete",
  async (
    {
      assetId,
      customPathwayId,
    }: {
      assetId: number;
      customPathwayId: number;
    },
    { rejectWithValue },
  ) => {
    const api = new ScenariosApi(defaultApiConfiguration);
    try {
      if (!mock) {
        await api.deleteCustomPathway(assetId, customPathwayId);
      }
      return {
        assetId,
        customPathwayId,
      };
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

import { Text } from "@chakra-ui/react";
import React from "react";

type TProps = {
  appVersion: string | undefined;
};

export const VersionBlock: React.FC<TProps> = ({ appVersion }) => {
  return (
    <Text
      color="gray.200"
      margin="10px"
      fontSize="xs"
      display={{ base: "none", md: "block" }}
    >
      App Version: {appVersion}
    </Text>
  );
};

import React from "react";
import { Alert, AlertDescription, AlertIcon, Box } from "@chakra-ui/react";
import { EconomicAssetsList } from "src/common/components/organisms/AssetList/EconomicAssetsList";
import { useAppSelector } from "src/redux/hooks";
import {
  selectEnergyForSelectedAssets,
  selectFilteredAndSelectedAssets,
  selectFilteredAssets,
  selectGhgForSelectedAssets,
  selectIsAssetsDataLoading,
  selectMaxReportingYear,
} from "src/redux/slices/portfolios/assets/selectors";
import { selectOpenedPortfolio } from "src/redux/slices/portfolios/selectors";
import { selectSelectedAssetIds } from "src/redux/slices/uiState/assetsFilter/selectors";
import { PerformanceTable } from "src/common/components/molecules/PerformanceTable";
import { PortfolioDecarbCosts } from "src/pages/PortfolioView/components/PortfolioDecarbCosts";
import { CapexPerformance } from "src/pages/AssetView/tabs/Economic/CapexPerformance";
import CostAnalysisTable from "src/pages/AssetView/tabs/Economic/CostAnalysisTable";
import { DecarbonisationCostSlider } from "src/common/components/molecules/DecarbCostSlider";
import { StandardContent } from "src/common/components/templates/StandardContent";
import { LoadComponentPlaceholder } from "src/common/components/atoms/Spinner";
import { currencyCodeToSymbol } from "src/common/utils/units";
import { Units, getUnits } from "src/constants/measureUnits";

export const EconomicTab: React.FC = () => {
  const selectedAssetIds = useAppSelector(selectSelectedAssetIds);
  const filteredAssets = useAppSelector(selectFilteredAssets);
  const selectedAndFilteredAssets = useAppSelector(
    selectFilteredAndSelectedAssets,
  );
  const ghg = useAppSelector(selectGhgForSelectedAssets);
  const energy = useAppSelector(selectEnergyForSelectedAssets);
  const isLoading = useAppSelector(selectIsAssetsDataLoading);
  const portfolio = useAppSelector(selectOpenedPortfolio);
  const maxReportingYear = useAppSelector(selectMaxReportingYear);

  const showAlert = !filteredAssets?.length || !selectedAssetIds?.length;

  if (isLoading) {
    return <LoadComponentPlaceholder />;
  }

  const moneyPerArea = `${currencyCodeToSymbol(portfolio?.currency)}/${getUnits(
    Units.area,
    portfolio?.measureSystem,
  )}`;

  return (
    <Box>
      <StandardContent
        message={
          selectedAssetIds?.length < 1 ? "No assets were selected" : undefined
        }
        chartAreaContent={
          <>
            {showAlert ? (
              <Alert status="warning">
                <AlertIcon />
                <AlertDescription>
                  {selectedAssetIds.length === 0
                    ? "Please select at least one Asset."
                    : "No results"}
                </AlertDescription>
              </Alert>
            ) : (
              <Box p="1rem" w="100%">
                <CostAnalysisTable
                  assets={selectedAndFilteredAssets}
                  isoCurrencyCode={portfolio?.currency}
                />
                <CapexPerformance
                  assets={selectedAndFilteredAssets}
                  units={moneyPerArea}
                  areaUnits={getUnits(Units.area, portfolio?.measureSystem)}
                />
                <DecarbonisationCostSlider
                  assets={selectedAndFilteredAssets}
                  isPortfolio
                  units={moneyPerArea}
                />
              </Box>
            )}
          </>
        }
      >
        <Box width="100%" p="0.5rem">
          <PortfolioDecarbCosts assets={selectedAndFilteredAssets} />
          <PerformanceTable
            assets={selectedAndFilteredAssets}
            targets={ghg.targets}
            forecastGHG={ghg.forecast}
            forecastEnergy={energy.forecast}
            maxReportingYear={maxReportingYear}
            measureSystem={portfolio?.measureSystem}
          />
        </Box>
      </StandardContent>
      <Box
        pt="10px"
        backgroundColor="white"
        width="100%"
        borderRadius="8px 8px 0 0"
      >
        <EconomicAssetsList
          selectedAssetIds={selectedAssetIds}
          assets={filteredAssets}
          units={moneyPerArea}
        />
      </Box>
    </Box>
  );
};

import { EntityState, createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { archetypesAdapter } from "./adapter";
import { selectSlice as selectAssetSlice } from "../assets/selectors";
import { IAppArchetype } from "src/redux/interfaces/IAppArchetype";

export const selectSlice = (state: RootState) => state.archetypes;

export const selectAllArchetypes = createDraftSafeSelector(
  [selectSlice],
  (state) => archetypesAdapter.getSelectors().selectAll(state),
);

export const selectIsArchetypesDataLoading = createDraftSafeSelector(
  [selectSlice],
  (slice) => {
    return (
      slice.requestStatus === "initial" || slice.requestStatus === "pending"
    );
  },
);

export const findArchetypeForSelectedAsset = (
  archetypesState: EntityState<IAppArchetype>,
  assetId: number | null,
): IAppArchetype | undefined => {
  if (assetId === null) {
    return undefined;
  }
  return archetypesAdapter
    .getSelectors()
    .selectAll(archetypesState)
    .find((a) => a.asset_id === assetId);
};

export const findArchetypeErrorForSelectedAsset = createDraftSafeSelector(
  [selectSlice, selectAssetSlice],
  (archetypesSlice, assetSlice) => {
    if (!assetSlice.selectedAssetId) return null;
    return archetypesSlice.error[assetSlice.selectedAssetId] || null;
  },
);

export const selectIsNoArchetypeAvailable = createDraftSafeSelector(
  [selectSlice, selectAssetSlice],
  (archetypesSlice, assetSlice) => {
    if (!assetSlice.selectedAssetId) return null;
    return !findArchetypeForSelectedAsset(
      archetypesSlice,
      assetSlice.selectedAssetId,
    );
  },
);

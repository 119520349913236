import React from "react";
import { Text, Box } from "@chakra-ui/react";
interface IProps {
  title: string;
  value: string;
}

export const PortfolioCardTerm: React.FC<IProps> = ({ title, value }) => {
  return (
    <Box mb="0.6em">
      <Text color="gray.500" fontSize="sm">
        {title}
      </Text>
      <Text color="gray.800" fontSize="sm" fontWeight="bold">
        {value}
      </Text>
    </Box>
  );
};

import { avg } from "src/common/utils/math";
import { IThresholds, Performance } from "./references";
import { ITimeSeriesPoint } from "src/api/open-api";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";

const _findValueForYear = (
  series: ITimeSeriesPoint[],
  year: number,
): number => {
  const result = series.find((it) => it.year === year);
  if (!result)
    throw new Error(`There is no year ${year} for this TTimeSeries.`);
  return result.value;
};

const _getNetZeroReadiness = (
  assetEnergiesConsumption: IAppAsset["energiesConsumption"],
) => {
  if (
    assetEnergiesConsumption.gas > 0 ||
    assetEnergiesConsumption.oil > 0 ||
    (assetEnergiesConsumption.otherTypeValue || 0) > 0
  ) {
    return "BaU / Fossil Fuel Based";
  } else if (
    assetEnergiesConsumption.cooling > 0 ||
    assetEnergiesConsumption.heating > 0
  ) {
    return "District Heating/Cooling/Trigen Based";
  } else {
    return "Fully Electrified";
  }
};

export const getPortfolioElectrifiedPercentage = (assets: IAppAsset[]) => {
  const fullyElectrified = assets.filter(
    (it) =>
      _getNetZeroReadiness(it.energiesConsumption) === "Fully Electrified",
  );
  const netZeroReadiness = fullyElectrified.length / assets.length;
  return netZeroReadiness;
};

export const getAvgEnergyIntensity = (forecastEnergy: ITimeSeriesPoint[]) => {
  if (!forecastEnergy?.length) return NaN;
  return avg(forecastEnergy.map((p) => p.value));
};

export const getReductionEffortTo2050 = (
  forecast: ITimeSeriesPoint[],
  target?: ITimeSeriesPoint[],
) => {
  if (!target) return 0;
  return (_findValueForYear(forecast, 2050) - _findValueForYear(target, 2050)) /
    _findValueForYear(forecast, 2050) >
    0
    ? (_findValueForYear(forecast, 2050) - _findValueForYear(target, 2050)) /
        _findValueForYear(forecast, 2050)
    : 0;
};

export const getAvgIntensityLevelPerformance = (
  references: IThresholds[],
  energyIntensity: number,
) => {
  const threshold = {
    1: avg(references.map((it) => it.energyIntensity[Performance.Medium])),
    2: avg(references.map((it) => it.energyIntensity[Performance.High])),
  };
  if (energyIntensity < threshold[2]) {
    return Performance.High;
  } else if (energyIntensity < threshold[1]) {
    return Performance.Medium;
  } else {
    return Performance.Low;
  }
};

export const getCo2ReductionEffortLevelPerformance = (
  references: IThresholds[],
  co2ReductionEffort: number,
) => {
  const threshold = {
    1: avg(references.map((it) => it.co2reduction[Performance.Medium])),
    2: avg(references.map((it) => it.co2reduction[Performance.High])),
  };
  if (co2ReductionEffort <= threshold[2]) {
    return Performance.High;
  } else if (co2ReductionEffort <= threshold[1]) {
    return Performance.Medium;
  } else {
    return Performance.Low;
  }
};

export const getNetZeroReadinessLevelPerformance = (
  references: IThresholds[],
  netZeroCo2Readiness: number,
) => {
  const threshold = {
    1: avg(references.map((it) => it.netZeroCo2Readiness[Performance.Medium])),
    2: avg(references.map((it) => it.netZeroCo2Readiness[Performance.High])),
  };
  if (netZeroCo2Readiness < threshold[1]) {
    return Performance.Low;
  } else if (netZeroCo2Readiness < threshold[2]) {
    return Performance.Medium;
  } else {
    return Performance.High;
  }
};

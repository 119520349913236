import React from "react";
import { Text, Box } from "@chakra-ui/react";
interface IProps {
  title: string;
  children: React.ReactNode;
}

export const PortfolioCardGraphics: React.FC<IProps> = ({
  title,
  children,
}) => {
  return (
    <Box mb="0.6em">
      <Text color="gray.500" fontSize="xs">
        {title}
      </Text>
      {children}
    </Box>
  );
};

import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { InfoTooltip } from "src/common/components/atoms/InfoTooltip";

interface IProps {
  title: string;
  description: string;
  color?: string;
  value: number;
  units: string;
}

export const EconomicHighlight: React.FC<IProps> = ({
  title,
  description,
  color,
  value,
  units,
}) => {
  return (
    <Box textAlign="center" data-testid="EconomicHighlight">
      <Text
        fontFamily="body"
        fontSize="small"
        fontWeight="bold"
        mb="10px"
        px="5px"
      >
        {title}
        <InfoTooltip text={description} />
      </Text>
      <Text
        fontFamily="body"
        fontSize="lg"
        fontWeight="bold"
        color={color || "gray.500"}
        marginBottom="0.5rem"
      >
        {`${value.toLocaleString()} ${units}`}
      </Text>
    </Box>
  );
};

const preventCSVExploit = (input: string): string => {
  // https://owasp.org/www-community/attacks/CSV_Injection
  // Examples:
  // preventCSVExploit("<script>alert(1)</script>");
  // helpers.preventCSVExploit("=10+20+cmd|' /C calc'!A0");
  // helpers.preventCSVExploit("=cmd|' /C calc'!A0");
  const riskyChars = ["=", "+", "-", "@", "'"];
  if (!input) return "";

  let isInjected = true;
  let output = input;

  output = output.replace("<script>", "");
  output = output.replace("</script>", "");

  do {
    const firstChar = output.charAt(0);
    isInjected = riskyChars.includes(firstChar);
    if (isInjected) {
      output = output.slice(1);
    }
  } while (isInjected);

  return output;
};

export const cleanText = (input: string): string => {
  return preventCSVExploit(input);
};

export const cleanFlatObject = <T>(obj: T): T | null => {
  if (typeof obj !== "object" || obj === null) return null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: { [key: string]: any } = {};
  for (const key of Object.keys(obj)) {
    // @ts-expect-error we just check it's an object and not null
    if (typeof obj[key] === "string") result[key] = preventCSVExploit(obj[key]);
    else if (
      // @ts-expect-error we just check it's an object and not null
      typeof obj[key] === "number" ||
      // @ts-expect-error we just check it's an object and not null
      typeof obj[key] === "boolean" ||
      // @ts-expect-error we just check it's an object and not null
      typeof obj[key] === "undefined"
    ) {
      // @ts-expect-error we just check it's an object and not null
      result[key] = obj[key];
    }
  }
  return result as T;
};

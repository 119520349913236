import { EntityState, createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { IAssetFilters } from "src/redux/interfaces/IAssetFilter";
import { selectSlice as selectAssetsFilterSlice } from "src/redux/slices/uiState/assetsFilter/selectors";
import { selectSlice as selectModelIdSlice } from "src/redux/slices/uiState/modelSelection/selectors";
import { assetAdapter } from "./adapter";
import {
  IAppAsset,
  IAppAssetChartResults,
} from "src/redux/interfaces/IAppAsset";
import { IModelSelectionState } from "src/redux/slices/uiState/modelSelection/slice";
import { intersectTimeSeries, sumOfPathways } from "src/common/utils/pathways";
import { ITimeSeriesPoint } from "src/api/open-api";
import { TReduxPartialTarget } from "./slice";
import { sum } from "src/common/utils/math";
import { IReduxChartTarget } from "src/redux/interfaces/IReduxChartTarget";

const _applyFilters = (
  asset: IAppAsset,
  filters: IAssetFilters,
  modelVersionId?: number,
) => {
  const nameFilter =
    !filters.selectedPartialName?.length ||
    asset.assetLabel
      .toLowerCase()
      .includes(filters.selectedPartialName.toLowerCase());
  const countryFilter =
    !filters.selectedCountries?.length ||
    filters.selectedCountries.includes(String(asset.location.isoCountryCode));
  const buildingTypeFilter =
    !filters.selectedBuildingTypes?.length ||
    asset.businessBuildingTypes.some((buildingtype) =>
      filters.selectedBuildingTypes.includes(
        String(buildingtype.business_building_type_id),
      ),
    );
  const riskLevelFilter =
    !filters.selectedRiskLevels?.length ||
    filters.selectedRiskLevels.includes(
      String(
        asset.ghgResults.targets.find((data) =>
          // if something gone wrong and model version wasn't selected pick any.
          // Need to review this whole idea of selection base on model version at some point
          modelVersionId === undefined
            ? true
            : data.modelVersionId === modelVersionId,
        )?.strandingRiskWeighted,
      ),
    );
  return nameFilter && countryFilter && buildingTypeFilter && riskLevelFilter;
};

export const selectSlice = (state: RootState) => state.assets;

const _allAssets = (assetState: EntityState<IAppAsset>) =>
  assetAdapter.getSelectors().selectAll(assetState);

export const selectAllAssets = createDraftSafeSelector(
  [selectSlice],
  _allAssets,
);

const _filterAssets = (
  assetState: EntityState<IAppAsset>,
  assetFilters: IAssetFilters,
  modelSelectionState: IModelSelectionState,
): IAppAsset[] =>
  _allAssets(assetState).filter((asset) =>
    _applyFilters(
      asset,
      assetFilters,
      modelSelectionState.selectedModelVersionId,
    ),
  );

const _getSelectedAssets = (
  assetState: EntityState<IAppAsset>,
  assetFilters: IAssetFilters,
  modelSelectionState: IModelSelectionState,
): IAppAsset[] =>
  _filterAssets(assetState, assetFilters, modelSelectionState).filter((asset) =>
    assetFilters.selectedIds.includes(asset.assetId),
  );

export const selectAssetsError = createDraftSafeSelector(
  [selectSlice],
  (slice) => slice.assetsError,
);

export const selectFilteredAssets = createDraftSafeSelector(
  [selectSlice, selectAssetsFilterSlice, selectModelIdSlice],
  _filterAssets,
);

export const selectFilteredAndSelectedAssets = createDraftSafeSelector(
  [selectSlice, selectAssetsFilterSlice, selectModelIdSlice],
  _getSelectedAssets,
);

export const selectIsAssetsDataLoading = createDraftSafeSelector(
  [selectSlice],
  (slice) => {
    return (
      slice.requestStatus === "initial" || slice.requestStatus === "pending"
    );
  },
);

export const selectCustomPathwaysError = createDraftSafeSelector(
  [selectSlice],
  (state) => state.customPathwaysError,
);
export const selectCustomPathwaysEditStatus = createDraftSafeSelector(
  [selectSlice],
  (state) => state.customPathwayEditStatus,
);

export const selectIsAssetsDataReady = createDraftSafeSelector(
  [selectSlice],
  (slice) => {
    return slice.requestStatus === "fulfilled";
  },
);

export const selectIsAssetDataRequested = createDraftSafeSelector(
  [selectSlice],
  (slice) => {
    return slice.requestStatus === "pending";
  },
);

export const selectIsImageRequested = createDraftSafeSelector(
  [selectSlice],
  (slice) => {
    return slice.imgRequestStatus !== "initial";
  },
);

export const selectMaxReportingYear = createDraftSafeSelector(
  [selectSlice, selectAssetsFilterSlice, selectModelIdSlice],
  (
    assetState: EntityState<IAppAsset>,
    assetFilters: IAssetFilters,
    modelSelectionState: IModelSelectionState,
  ) =>
    Math.max(
      ..._getSelectedAssets(assetState, assetFilters, modelSelectionState).map(
        (asset) =>
          isNaN(Number(asset.reportingYear)) ? 0 : asset.reportingYear,
      ),
    ),
);

const multiplyTimeSeriesBy = (timeSeries: ITimeSeriesPoint[], mult: number) => {
  return (timeSeries || []).map((p) => ({ ...p, value: p.value * mult }));
};

const _averageForecast = (
  assetsData: { data: IAppAssetChartResults; area: number | null }[],
) => {
  return multiplyTimeSeriesBy(
    assetsData
      .map((asset) =>
        multiplyTimeSeriesBy(
          asset.data.emissionsForecast || [],
          asset.area || 1,
        ),
      )
      .reduce(sumOfPathways, [] as ITimeSeriesPoint[]) || [],
    1 / sum(assetsData.map((a) => a.area || 1)),
  );
};

const _aggregateTargets = (
  targetDescription: TReduxPartialTarget,
  strandingResults: IReduxChartTarget[],
  totalArea: number,
) => {
  const resultsForThisModelVersion = strandingResults.filter(
    (result) => result.modelVersionId === targetDescription.modelVersionId,
  );

  return {
    ...targetDescription,
    timeSeries: multiplyTimeSeriesBy(
      resultsForThisModelVersion
        .map((result) => result.timeSeries)
        .reduce(sumOfPathways, []),
      1 / totalArea,
    ),
  };
};

export const selectAvailableGHGTargets = createDraftSafeSelector(
  [selectSlice],
  (assetState) => {
    return assetState.availableGhgTargets;
  },
);

const _mapAndAggregateTargets = (
  assetsData: { data: IAppAssetChartResults; area: number | null }[],
  availableTargets: IReduxChartTarget[],
) => {
  const filteredStrandingResults = assetsData
    .map((asset) => {
      return asset.data.targets.map((res) => ({
        ...res,
        timeSeries: multiplyTimeSeriesBy(
          res?.timeSeries || [],
          asset.area || 1,
        ),
      }));
    })
    .flat(1);

  return availableTargets.map((type) =>
    _aggregateTargets(
      type,
      filteredStrandingResults,
      sum(assetsData.map((asset) => asset.area || 1)),
    ),
  );
};

const _addIntersectionToTarget = (
  target: {
    modelVersionId?: number;
    timeSeries?: ITimeSeriesPoint[];
    lineColor: string;
    targetName: string;
  },
  forecast: ITimeSeriesPoint[],
) => {
  if (!target.timeSeries?.length || !forecast?.length) return null;
  const strandingPoint = intersectTimeSeries(forecast, target.timeSeries);
  return {
    ...target,
    ...strandingPoint,
    timeSeries: target.timeSeries as ITimeSeriesPoint[],
  };
};

const _getAggregatedAvgResult = (
  assetsData: { data: IAppAssetChartResults; area: number | null }[],
  availableTargets: IReduxChartTarget[],
) => {
  const averagedTargets = _mapAndAggregateTargets(assetsData, availableTargets);
  const forecast = _averageForecast(assetsData);
  return {
    forecast: forecast || [],
    targets: !forecast
      ? (averagedTargets as IReduxChartTarget[])
      : (averagedTargets
          ?.map((target) => _addIntersectionToTarget(target, forecast))
          .filter((t) => t !== null) as IReduxChartTarget[]),
  };
};

export const selectGhgForSelectedAssets = createDraftSafeSelector(
  [selectSlice, selectAssetsFilterSlice, selectModelIdSlice],
  (
    assetState,
    assetFilters: IAssetFilters,
    modelSelectionState: IModelSelectionState,
  ) => {
    const ghgData = _getSelectedAssets(
      assetState,
      assetFilters,
      modelSelectionState,
    ).map((asset) => ({
      data: asset.ghgResults,
      area: asset.area,
    }));
    return _getAggregatedAvgResult(
      ghgData,
      assetState.availableGhgTargets as IReduxChartTarget[],
    );
  },
);

export const selectEnergyForSelectedAssets = createDraftSafeSelector(
  [selectSlice, selectAssetsFilterSlice, selectModelIdSlice],
  (
    assetState,
    assetFilters: IAssetFilters,
    modelSelectionState: IModelSelectionState,
  ) => {
    const energyData = _getSelectedAssets(
      assetState,
      assetFilters,
      modelSelectionState,
    ).map((asset) => ({
      data: asset.energyResults,
      area: asset.area,
    }));
    return _getAggregatedAvgResult(
      energyData,
      assetState.availableEnergyTargets as IReduxChartTarget[],
    );
  },
);

export const selectAssetsStrandingYears = createDraftSafeSelector(
  [selectSlice, selectAssetsFilterSlice, selectModelIdSlice],
  (
    assetState,
    assetFilters: IAssetFilters,
    modelSelectionState: IModelSelectionState,
  ) => {
    return _getSelectedAssets(assetState, assetFilters, modelSelectionState)
      .map(
        (asset) =>
          (modelSelectionState.selectedModelVersionId === undefined
            ? asset.ghgResults.targets[0]
            : asset.ghgResults.targets.find(
                // if model version is selected, pick the correct oe
                (s) =>
                  s.modelVersionId ===
                  modelSelectionState.selectedModelVersionId,
              )
          )?.strandingX,
      )
      .filter((year) => !!year) as number[];
  },
);

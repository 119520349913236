import React from "react";
import {
  Box,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";

interface IProps {
  currentValue: number;
  min: number;
  max: number;
  onChange: (value: number) => void;
  pointColor: string;
}

export const SliderWithLine: React.FC<IProps> = ({
  currentValue,
  min,
  max,
  onChange,
  pointColor,
}) => {
  return (
    <Slider
      data-testid="SliderWithLine"
      value={currentValue}
      min={min}
      max={max}
      step={1}
      onChange={onChange}
    >
      <SliderTrack bg="transparent">
        <Box position="relative" right="0" />
        <SliderFilledTrack bg="transparent" />
      </SliderTrack>
      <SliderThumb
        data-testid="SliderThumb"
        mt="0.1rem"
        boxSize={5}
        border="1px solid"
        borderColor="gray.800"
        bg={pointColor}
        _before={{
          content: `""`,
          border: "none",
          borderLeft: "1px solid",
          borderColor: "gray.500",
          borderStyle: "dashed",
          height: "100vh",
          position: "absolute",
          bottom: "100%",
          left: "50%",
          marginLeft: "-1px",
        }}
        _after={{
          content: `"${currentValue}"`,
          position: "absolute",
          top: "100%",
          marginTop: "0.3rem",
          fontWeight: "bold",
          fontSize: "small",
        }}
      />
    </Slider>
  );
};

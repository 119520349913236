import axios from "axios";

export const getAssetCoordinates = async (
  address?: string,
  city?: string,
): Promise<{ lon: number; lat: number } | undefined> => {
  if (!address || !city) return undefined;
  try {
    const { data } = await axios.get(
      `https://nominatim.openstreetmap.org/search.php?q=${encodeURI(
        address + ", " + city,
      )}&limit=1&format=jsonv2`,
    );
    if (data.length > 0) {
      return data[0];
    } else return undefined;
  } catch (_error) {
    return undefined;
  }
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompanyApi, ICompany } from "src/api/open-api";
import { defaultApiConfiguration } from "src/api/configurations";
import { USE_LOCAL_MOCKS } from "src/config/mocks";
import { userCompaniesMock } from "./__mocks__/userCompaniesMock";

export const fetchUserCompanies = createAsyncThunk(
  "userCompanies/fetch",
  async (_, { rejectWithValue }) => {
    if (USE_LOCAL_MOCKS) return userCompaniesMock as ICompany[];
    const api = new CompanyApi(defaultApiConfiguration);
    try {
      const response = await api.getNoneAdminCompanies();
      return response.data;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

import { createAction, createSlice } from "@reduxjs/toolkit";

import { assetAdapter } from "./adapter";
import {
  fetchAssets,
  getAssetImage,
  saveAssetImage,
} from "src/redux/thunks/assets";
import {
  IAppAsset,
  IAppAssetStrandingResult,
} from "src/redux/interfaces/IAppAsset";
import { unique } from "src/common/utils/aggregation";
import { apiErrorToI18nError } from "src/redux/interfaces/Ii18nError";
import { Ii18nError } from "src/redux/interfaces/Ii18nError";
import {
  deleteCustomPathway,
  upsertCustomPathway,
} from "src/redux/thunks/customPathways";
import { getCustomLineColor } from "src/common/utils/colors/colors";
import { normalizeTimeSeries } from "src/common/utils/pathways/normalizeTimeSeries";
import { globalMaxYear, globalMinYear } from "src/constants";
import { intersectTimeSeries } from "src/common/utils/pathways";

export type TReduxPartialTarget = {
  modelVersionId: number | undefined;
  targetName: string;
  lineColor: string;
};

const _mapTargets = (
  targetId: number,
  allEnergyTargets: IAppAssetStrandingResult[],
) => {
  const strandingResult = allEnergyTargets.find(
    (target) => target.modelVersionId === targetId,
  );
  return {
    modelVersionId: strandingResult!.modelVersionId,
    targetName: strandingResult!.targetName,
    lineColor: strandingResult!.lineColor,
  };
};

export const setAssetSelected = createAction<number>("setAssetSelectedAction");

const slice = createSlice({
  name: "assets",
  initialState: {
    ...assetAdapter.getInitialState(),
    availableGhgTargets: [] as TReduxPartialTarget[],
    availableEnergyTargets: [] as TReduxPartialTarget[],
    requestStatus: "initial",
    imgRequestStatus: "initial",
    selectedAssetId: null as null | number,
    assetsError: null as null | Ii18nError,
    saveImageError: null as null | Ii18nError,
    getImageError: null as null | Ii18nError,
    customPathwaysError: null as null | Ii18nError,
    customPathwayEditStatus: "initial",
  },
  reducers: {
    clearCustomPathwaysError: (state) => {
      state.customPathwaysError = null;
    },
    setCustomPathwayEditStatusInProgress: (state) => {
      state.customPathwayEditStatus = "userinput";
    },
    resetCustomPathwayEditStatusInProgress: (state) => {
      state.customPathwayEditStatus = "initial";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setAssetSelected, (state, { payload }) => {
      if (state.selectedAssetId !== payload) {
        state.selectedAssetId = payload;
        state.imgRequestStatus = "initial";
      }
    });
    builder.addCase(fetchAssets.pending, (state) => {
      state.requestStatus = "pending";
      state.selectedAssetId = null;
      state.imgRequestStatus = "initial";
      state.assetsError = null;
      assetAdapter.removeAll(state);
    });
    builder.addCase(fetchAssets.rejected, (state, action) => {
      state.assetsError = action.payload
        ? apiErrorToI18nError(action.payload)
        : { errorCode: "GENERIC" };
      state.requestStatus = "rejected";
      state.selectedAssetId = null;
    });
    builder.addCase(fetchAssets.fulfilled, (state, { payload }) => {
      // save assets
      assetAdapter.setMany(state, payload as IAppAsset[]);

      // get a list of Energy target pathways descriptions to display lines selector
      const allEnergyTargets = payload
        .map((asset) => asset.energyResults.targets)
        .flat(1);

      const uniqueEnergyModelVersionIds = unique(
        allEnergyTargets.map((t) => t.modelVersionId),
      );
      state.availableEnergyTargets = uniqueEnergyModelVersionIds.map(
        (targetId) => _mapTargets(targetId, allEnergyTargets),
      );

      // get a list of GHG target pathways descriptions to display lines selector
      const allGhgTargets = payload
        .map((asset) => asset.ghgResults.targets)
        .flat(1);
      const uniqueGhgModelVersionIds = unique(
        allGhgTargets.map((t) => t.modelVersionId),
      );
      state.availableGhgTargets = uniqueGhgModelVersionIds.map((targetId) =>
        _mapTargets(targetId, allGhgTargets),
      );

      state.requestStatus = "fulfilled";
    });

    builder.addCase(getAssetImage.pending, (state) => {
      state.imgRequestStatus = "pending";
      state.getImageError = null;
    });
    builder.addCase(getAssetImage.rejected, (state, action) => {
      state.getImageError = action.payload
        ? apiErrorToI18nError(action.payload)
        : { errorCode: "GENERIC" };
      state.imgRequestStatus = "rejected";
    });
    builder.addCase(getAssetImage.fulfilled, (state, { payload }) => {
      state.imgRequestStatus = "fulfilled";
      state.getImageError = null;
      assetAdapter.updateOne(state, {
        id: payload.assetId,
        changes: {
          assetImageUrl: payload.imageUrl,
        },
      });
    });

    builder.addCase(saveAssetImage.pending, (state) => {
      state.imgRequestStatus = "pending";
      state.saveImageError = null;
    });
    builder.addCase(saveAssetImage.rejected, (state, action) => {
      state.imgRequestStatus = "rejected";
      state.saveImageError = action.payload
        ? apiErrorToI18nError(action.payload)
        : { errorCode: "GENERIC" };
    });
    builder.addCase(saveAssetImage.fulfilled, (state, { payload }) => {
      state.imgRequestStatus = "fulfilled";
      state.saveImageError = null;
      assetAdapter.updateOne(state, {
        id: payload.assetId,
        changes: {
          assetImageUrl: payload.imageUrl,
        },
      });
    });

    builder.addCase(deleteCustomPathway.pending, (state, { payload }) => {
      state.imgRequestStatus = "pending";
    });
    builder.addCase(upsertCustomPathway.pending, (state, { payload }) => {
      state.imgRequestStatus = "pending";
    });
    // Custom pathways runtime edits
    builder.addCase(upsertCustomPathway.fulfilled, (state, { payload }) => {
      const assetEdited = assetAdapter
        .getSelectors()
        .selectById(state, payload.assetId);
      state.customPathwayEditStatus = "initial";
      if (assetEdited) {
        const cleanTimeSeries = normalizeTimeSeries(
          payload.customPathway.time_series_points,
          assetEdited.reportingYear || globalMinYear,
          globalMaxYear,
        );
        const strandingPoint = intersectTimeSeries(
          assetEdited.ghgResults.emissionsForecast || [],
          cleanTimeSeries,
        );
        const allPathways = [...(assetEdited.ghgResults.customPathways || [])];
        const existingPathwayId = allPathways.findIndex(
          (p) => p.lineId === payload.customPathway.custom_pathway_id,
        );
        const transformedPathway: IAppAssetStrandingResult = {
          modelVersionId: 0,
          intensityTypeId: payload.customPathway.intensity_type_id,
          timeSeries: payload.customPathway.time_series_points,
          lineColor:
            existingPathwayId > -1
              ? allPathways[existingPathwayId].lineColor
              : getCustomLineColor(
                  assetEdited.ghgResults.customPathways?.length || 0,
                ),
          lineId: payload.customPathway.custom_pathway_id,
          targetName: payload.customPathway.custom_pathway_name,
          strandingX: strandingPoint.strandingX,
          strandingY: strandingPoint.strandingY,
          performance: 2,
          strandingRisk: 0,
        };

        if (existingPathwayId > -1) {
          allPathways[existingPathwayId] = transformedPathway;
        } else {
          allPathways.push(transformedPathway);
        }
        assetAdapter.upsertOne(state, {
          ...assetEdited,
          ghgResults: {
            ...assetEdited.ghgResults,
            customPathways: allPathways,
          },
        });
      }
    });
    builder.addCase(deleteCustomPathway.fulfilled, (state, { payload }) => {
      const assetEdited = assetAdapter
        .getSelectors()
        .selectById(state, payload.assetId);
      state.customPathwayEditStatus = "initial";
      if (assetEdited) {
        assetAdapter.upsertOne(state, {
          ...assetEdited,
          ghgResults: {
            ...assetEdited.ghgResults,
            customPathways: (
              assetEdited.ghgResults.customPathways || []
            ).filter((p) => p.lineId !== payload.customPathwayId),
          },
        });
      }
    });
    builder.addCase(deleteCustomPathway.rejected, (state, { payload }) => {
      state.customPathwaysError = payload
        ? apiErrorToI18nError(payload)
        : { errorCode: "GENERIC" };
    });
    builder.addCase(upsertCustomPathway.rejected, (state, { payload }) => {
      state.customPathwaysError = payload
        ? apiErrorToI18nError(payload)
        : { errorCode: "GENERIC" };
    });
  },
});

export const {
  clearCustomPathwaysError,
  setCustomPathwayEditStatusInProgress,
  resetCustomPathwayEditStatusInProgress,
} = slice.actions;
export default slice;

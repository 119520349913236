import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchAssets } from "src/redux/thunks/assets";
import { setAssetSelected } from "../../portfolios/assets/slice";
import { assetAdapter } from "../../portfolios/assets/adapter";
import {
  deleteCustomPathway,
  upsertCustomPathway,
} from "src/redux/thunks/customPathways";

const slice = createSlice({
  name: "customPathwaysSelection",
  initialState: {
    ...assetAdapter.getInitialState(),
    selectedIds: [] as number[],
    allIds: [] as number[],
    assetId: null as null | number,
  },
  reducers: {
    toggleId: (state, action: PayloadAction<number>) => {
      const index = state.selectedIds.indexOf(action.payload);
      if (index < 0) {
        state.selectedIds = [...state.selectedIds, action.payload];
      } else {
        state.selectedIds = state.selectedIds.filter(
          (alreadySelected) => alreadySelected !== action.payload,
        );
      }
    },
    toggleAll: (state, action: PayloadAction<boolean>) => {
      if (!action.payload) state.selectedIds = [];
      else state.selectedIds = [...state.allIds];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAssets.pending, (state) => {
      state.selectedIds = [];
      state.allIds = [];
      assetAdapter.removeAll(state);
    });
    builder.addCase(fetchAssets.fulfilled, (state, { payload }) => {
      // save copy of assets for easier access to custom pathways
      assetAdapter.setMany(state, payload);
      if (
        state.assetId !== null &&
        payload.find((a) => a.assetId === state.assetId)
      ) {
        const selectedAsset = assetAdapter
          .getSelectors()
          .selectById(state, state.assetId);
        state.allIds =
          selectedAsset?.ghgResults.customPathways?.map(
            (p) => p.lineId as number,
          ) || [];
        state.selectedIds =
          selectedAsset?.ghgResults.customPathways?.map(
            (p) => p.lineId as number,
          ) || [];
      }
    });
    builder.addCase(setAssetSelected, (state, { payload }) => {
      if (!payload) return;
      state.assetId = payload;
      const selectedAsset = assetAdapter
        .getSelectors()
        .selectById(state, payload);
      state.allIds =
        selectedAsset?.ghgResults.customPathways?.map(
          (p) => p.lineId as number,
        ) || [];
      state.selectedIds =
        selectedAsset?.ghgResults.customPathways?.map(
          (p) => p.lineId as number,
        ) || [];
    });
    builder.addCase(deleteCustomPathway.fulfilled, (state, { payload }) => {
      state.allIds = state.allIds.filter(
        (id) => id !== payload.customPathwayId,
      );
      state.selectedIds = state.selectedIds.filter(
        (id) => id !== payload.customPathwayId,
      );
    });
    builder.addCase(upsertCustomPathway.fulfilled, (state, { payload }) => {
      const didIdExist = state.allIds.findIndex(
        (id) => id === payload.customPathway.custom_pathway_id,
      );
      if (didIdExist < 0) {
        state.allIds = [
          ...state.allIds,
          payload.customPathway.custom_pathway_id,
        ];
        state.selectedIds = [
          ...state.selectedIds,
          payload.customPathway.custom_pathway_id,
        ];
      }
    });
  },
});

export const { toggleId, toggleAll } = slice.actions;
export default slice;

// These values should always match the database
export const ghgIntensityTypeId = 3;
export const energyIntensityTypeId = 4;

export const hardcodedLineIds = {
  crremCritical: {
    model_description: "2.0",
    model_version_name: "2.0",
  },
  crremWarn: {
    model_description: "1.5",
    model_version_name: "2.0",
  },
};

import React, { useState, useEffect } from "react";
import { Autocomplete, Option } from "chakra-ui-simple-autocomplete";
import { Badge, Box } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

export const AccessAutocomplete: React.FC<{
  items: Option[];
  placeholder?: string;
  defaultSelectedItems?: Option[];
  onSelectCallback: (selectedItems: Option[]) => void;
}> = ({ items, onSelectCallback, defaultSelectedItems, placeholder }) => {
  const [selectedItems, setSelectedItems] = useState<Option[]>(
    defaultSelectedItems || [],
  );

  useEffect(() => {
    setSelectedItems(defaultSelectedItems || []);
  }, [defaultSelectedItems]);

  const handleSelectedItems = (selectedItems?: Option[]) => {
    if (selectedItems) {
      setSelectedItems(selectedItems);
      onSelectCallback && onSelectCallback(selectedItems);
    }
  };

  return (
    <Box maxW="md" mt="0.5rem" width="100%">
      <Autocomplete
        options={items}
        style={{
          width: "100%",
          border: "1px solid #BEBEBE",
          padding: "6px",
          fontSize: "0.8rem",
        }}
        result={selectedItems}
        setResult={(options: Option[]) => handleSelectedItems(options)}
        placeholder={placeholder || "Start typing..."}
        allowCreation={false}
        variant="outline"
        size="md"
        colorScheme="gray"
        renderBadge={(option: Option) => (
          <Badge
            borderRadius="full"
            px="2"
            colorScheme="teal"
            my={1}
            mr={1}
            cursor="pointer"
            fontFamily="body"
          >
            {option.label}
            <CloseIcon ml={1} w={2} h={2} mb="4px" />
          </Badge>
        )}
      />
    </Box>
  );
};

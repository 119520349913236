import React from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ICompany } from "src/api/open-api";

interface IProps {
  items?: ICompany[];
}

const CompaniesTable: React.FC<IProps> = (props) => {
  const { items } = props;
  const navigate = useNavigate();

  return (
    <Table variant="simple" width="100%">
      <Thead>
        <Tr>
          <Th>Company</Th>
          <Th>Address</Th>
          <Th>Country Code</Th>
        </Tr>
      </Thead>
      <Tbody>
        {items &&
          items.map((company: ICompany) => (
            <Tr
              key={company.company_id}
              _hover={{ bg: "white.400", cursor: "pointer" }}
              onClick={() =>
                navigate(`/admin/company/${company.company_id}`, {
                  replace: true,
                })
              }
              h="3rem"
            >
              <Td>{company.company_name}</Td>
              <Td>{company.company_address}</Td>
              <Td>{company.country_code}</Td>
            </Tr>
          ))}
      </Tbody>
    </Table>
  );
};

export default CompaniesTable;

import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
  variants: {
    outline: {
      tab: {
        bg: "white.500",
        border: "0.1rem solid",
        borderColor: "black.900",
        borderRadius: "4px",
        color: "black.900",
        fontSize: "small",
        fontWeight: "bold",
        flex: 1,
        maxWidth: "10rem",
        mx: ".8rem",
        minH: "2rem",
        _focus: {
          boxShadow: "0",
        },
        _selected: {
          color: "red.500",
          border: "1px",
          borderColor: "red.500",
        },
      },
      tablist: {
        maxHeight: "none",
      },
    },
  },
};

export default componentOverride;

import { IArchetype, IArchetypeDecarbMeasures } from "src/api/open-api";
import { errorReporter } from "src/common/utils/errorReporter/errorReporter";
import { normalizeTimeSeries } from "src/common/utils/pathways/normalizeTimeSeries";
import { globalMaxYear, globalMinYear } from "src/constants";
import { IAppArchetype } from "src/redux/interfaces/IAppArchetype";
import { IAppIntervention } from "src/redux/interfaces/IAppIntervention";

export const groupMeasuresByImplementationYear = (
  assetId: number,
  archetype: IArchetype,
) => {
  const implementationStages = new Map();
  archetype.archetypes_decarb_measures
    .map((i) => ({
      ...i,
      asset_id: assetId,
      asset_intervention_id: i.archetype_decarb_measure_id,
    }))
    ?.forEach((interv) =>
      implementationStages.set(interv.implementation_year, [
        ...(implementationStages.get(interv.implementation_year) || []),
        interv,
      ]),
    );
  return {
    asset_id: assetId,
    scenario_name: "Archetype",
    packages: Array.from(implementationStages)
      .map((entry) => ({
        package_code: entry[0] ? "By " + entry[0] : "No date specified",
        interventions: (entry[1] as IArchetypeDecarbMeasures[])?.map(
          (intervention) => ({
            impementationYear: intervention.implementation_year,
            interventionCode: intervention.intervention_code,
            interventionDescription: intervention.intervention_description,
            ghgAbsoluteChange: intervention.ghg_change,
            ghgRelativeChange: intervention.ghg_change_relative,
            euiAbsoluteChange: intervention.eui_change,
            euiRelativeChange: intervention.eui_change_relative,
            capexAbsoluteTotal: intervention.capex_cost_change,
            capexAbsoluteEol: intervention.capex_eol_change,
            capexAbsoluteDecarb: intervention.capex_decarb_change,
            opexAbsolute: intervention.opex_change,
            marginalAbatementCost: intervention.marginal_abatement_cost,
            simplePayback: intervention.simple_payback,
            roi: intervention.roi_annualised,
          }),
        ) as IAppIntervention[],
        implementationYear: (entry[0] as number) || globalMinYear + 5,
      }))
      .sort((a, b) => a.implementationYear - b.implementationYear),
    pathways: archetype.archetypes_pathways
      .filter((p) => !!p.time_series_points?.length)
      .map((p) => {
        return {
          ...p,
          time_series_points: normalizeTimeSeries(
            p.time_series_points || [],
            globalMinYear,
            globalMaxYear,
            true,
          ),
        };
      }),
    cost: archetype.cost,
  };
};

export const transformArchetypeToScenario = (
  assetId: number,
  archetype: IArchetype,
): IAppArchetype | null => {
  if (!archetype) return null;
  try {
    return groupMeasuresByImplementationYear(assetId, archetype);
  } catch (_error) {
    errorReporter({
      activeUrl: window?.location?.pathname,
      stack: JSON.stringify(_error),
      context: "thunks/transformArchetypeToScenario",
    });
    throw new Error("UI_DATA_IS_BROCKEN");
  }
};

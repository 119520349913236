import React from "react";
import { ErrorNotFound } from "src/common/components/molecules/ErrorNotFound";

interface IProps {
  errorMessage?: string;
}
export const Page404: React.FC<IProps> = ({ errorMessage }) => {
  return (
    <ErrorNotFound
      isFullPage
      is401={errorMessage === "Request failed with status code 401"}
    />
  );
};

import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Plugin,
} from "chart.js";
import { externalTooltipHandler } from "./tooltipHandler";
import { IChartCategory } from "./interfaces";
import { theme } from "src/config/theme";

ChartJS.register(ArcElement, Tooltip, Legend);

interface IProps {
  width?: string;
  height?: string;
  units?: string;
  epcRating?: boolean;
  customLabel?: string;
  customSubLabel?: string;
  labeIsFocusColor?: boolean;
  categories: IChartCategory[];
}

export const CustomPieChart: React.FC<IProps> = (props) => {
  const {
    categories,
    units,
    epcRating,
    customLabel,
    customSubLabel,
    labeIsFocusColor,
  } = props;

  const data = {
    labels: [...categories.map((c) => c.label), customLabel],
    datasets: [
      {
        data: categories.map((c) => Math.round(c.value * 10) / 10),
        backgroundColor: categories.map((c) => c.color),
        borderWidth: 0,
      },
    ],
  };

  const drawRelativeLabel = (chart: ChartJS<"doughnut">) => {
    let label = "";
    let color = theme.colors.gray[600];
    if (customLabel) {
      label = customLabel;
    } else {
      const values = chart.data.datasets.map((d) => d.data)[0].flat();
      const focused = categories.filter((c) => c.focused);
      const focusedIdxs = focused.map((_, idx) => idx);
      color = focused[0]?.color || color;
      if (focusedIdxs.length) {
        label = `${Math.round(
          (focusedIdxs.map((i) => values[i]).reduce((a, b) => a + b, 0) /
            values.reduce((a, b) => a + b, 0)) *
            100 +
            Number.EPSILON,
        )}%`;
      }
    }
    const width = chart.width,
      height = chart.height,
      ctx = chart.ctx;
    ctx.restore();
    const fontSize = height * 0.22;
    ctx.textBaseline = "middle";
    ctx.font = `bold ${fontSize}px Arial, sans-serif`;
    ctx.fillStyle = color;
    ctx.fillText(
      label,
      Math.round((width - ctx.measureText(label).width) / 2),
      customSubLabel ? 0.45 * height : 0.5 * height,
    );
    if (customSubLabel) {
      ctx.font = `bold ${(fontSize * 0.45).toFixed(2)}px Arial, sans-serif`;
      ctx.fillText(
        customSubLabel,
        Math.round((width - ctx.measureText(customSubLabel).width) / 2),
        0.5 * height + fontSize * 0.45,
      );
    }
    ctx.save();
  };

  const drawAbsoluteLabel = (chart: ChartJS<"doughnut">, units: string) => {
    let label = "";
    let color = theme.colors.gray[500];
    const focused = categories.filter((c) => c.focused);
    if (customLabel && chart.data.labels) {
      label = (chart.data.labels[chart.data.labels.length - 1] as string) || "";
      color = labeIsFocusColor ? focused[0]?.color || color : color;
    } else {
      const values = chart.data.datasets.map((d) => d.data)[0].flat();
      const focusedIdxs = focused.map((_, idx) => idx);
      if (focusedIdxs.length) {
        label = `${focusedIdxs
          .map((i) => values[i])
          .reduce((a, b) => a + b, 0)}`;
      }
    }
    const width = chart.width,
      height = chart.height,
      ctx = chart.ctx;
    ctx.restore();
    const spacing = 4;
    const valueFontSize = height * 0.16;
    const unitFontSize = Math.round(valueFontSize * 0.6);
    ctx.textBaseline = "middle";
    // Value
    ctx.font = `bold ${valueFontSize.toFixed()}px Arial, sans-serif`;
    ctx.fillStyle = color;
    ctx.fillText(
      label,
      Math.round((width - ctx.measureText(label).width) / 2),
      (height - unitFontSize - spacing) / 2,
    );
    // Units
    ctx.font = `${unitFontSize}px Arial, sans-serif`;
    ctx.fillStyle = theme.colors.gray[500];
    ctx.fillText(
      `${units}`,
      Math.round((width - ctx.measureText(`${units}`).width) / 2),
      (height + unitFontSize + spacing) / 2,
    );
    ctx.save();
  };

  const drawEPCRatingLabel = (chart: ChartJS<"doughnut">) => {
    let label = "";
    if (customLabel) {
      label = customLabel;
    } else {
      const values = chart.data.datasets.map((d) => d.data)[0].flat();
      const focused = categories.filter((c) => c.focused);
      const focusedIdxs = focused.map((_, idx) => idx);
      if (focusedIdxs.length) {
        label = `${focusedIdxs
          .map((i) => values[i])
          .reduce((a, b) => a + b, 0)}`;
      }
    }

    const width = chart.width,
      height = chart.height,
      ctx = chart.ctx;
    ctx.restore();
    const spacing = 4;
    const valueFontSize = height * 0.12;
    const unitFontSize = Math.round(valueFontSize * 0.9);
    ctx.textBaseline = "middle";
    // value
    ctx.font = `bold ${valueFontSize.toFixed()}px Arial, sans-serif`;
    ctx.fillStyle = theme.colors.gray[500];
    ctx.fillText(
      label,
      Math.round((width - ctx.measureText(label).width) / 2),
      (height - unitFontSize - spacing) / 2,
    );
    // drawing units
    // preparing text
    const args = [
      { text: "D", fillStyle: theme.colors.energyRating.d },
      { text: "+", fillStyle: theme.colors.gray[500] },
      { text: "E", fillStyle: theme.colors.energyRating.e },
      { text: "+", fillStyle: theme.colors.gray[500] },
      { text: "F", fillStyle: theme.colors.energyRating.f },
      { text: "+", fillStyle: theme.colors.gray[500] },
      { text: "G", fillStyle: theme.colors.energyRating.g },
    ];
    ctx.font = `${unitFontSize}px Arial, sans-serif`;
    let x = Math.round((width - ctx.measureText("D+E+F+G").width) / 2);
    const y = (height + unitFontSize + spacing) / 2;
    //drawing text
    args.forEach(({ text, fillStyle }) => {
      ctx.fillStyle = fillStyle;
      ctx.fillText(text, x, y);
      x += ctx.measureText(text).width;
    });
    ctx.save();
  };

  const centerLabelPlugin: Plugin<"doughnut"> = {
    id: "centerLabel",
    beforeDraw: (chart) => {
      if (units) {
        drawAbsoluteLabel(chart as ChartJS<"doughnut">, units);
      } else if (epcRating) {
        drawEPCRatingLabel(chart as ChartJS<"doughnut">);
      } else {
        drawRelativeLabel(chart as ChartJS<"doughnut">);
      }
    },
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: false,
        external: externalTooltipHandler,
      },
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: true,
    responsive: true,
    animation: {
      duration: 0,
    },
    layout: {
      autoPadding: false,
      padding: 0,
    },
    title: {
      display: false,
    },
    cutout: "68%",
    spacing: 0,
  };

  return (
    <Doughnut
      width={props.width}
      height={props.height}
      data={data}
      options={options}
      plugins={[centerLabelPlugin]}
    />
  );
};

import React from "react";
import { Tooltip, Text } from "@chakra-ui/react";

interface IProps {
  title?: string;
  text: string;
  children: React.ReactElement;
}

//Tooltop component that accepts a react child component and text to display tooltip on hover
const HoverTooltip: React.FC<IProps> = (props) => {
  const { title, text, children } = props;
  return (
    <Tooltip
      arrowSize={8}
      hasArrow
      label={
        <>
          <Text fontSize="small" fontWeight="bold">
            {title}
          </Text>
          <Text fontSize="small">{text}</Text>
        </>
      }
      placement="right"
    >
      {children}
    </Tooltip>
  );
};

export default HoverTooltip;

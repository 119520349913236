import { Flex, Text } from "@chakra-ui/react";
import React from "react";

type TProps = {
  name: string;
};

const getFirstLetterOfLastWord = (str: string) => {
  str = str.trim();
  const words = str.split(" ");
  const lastWord = words[words.length - 1];
  return lastWord.charAt(0);
};

export const UserBlock: React.FC<TProps> = ({ name, ...rest }) => {
  const letter = getFirstLetterOfLastWord(name);
  return (
    <Flex direction="row" alignItems="center" justifyContent="center" {...rest}>
      <Text
        color="gray.500"
        fontSize="xs"
        display={{ base: "none", md: "block" }}
      >
        {name}
      </Text>
      <Flex
        borderRadius="50%"
        backgroundColor="gray.200"
        width="1.6em"
        height="1.6em"
        marginLeft="0.6em"
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize="xs" color="white" fontWeight="bold">
          {letter.toUpperCase()}
        </Text>
      </Flex>
    </Flex>
  );
};

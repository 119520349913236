import { Box, Tabs, TabPanels, TabPanel, Text, Flex } from "@chakra-ui/react";
import React, { useState, useMemo } from "react";
import { StrandingChartWithSlider } from "src/common/components/molecules/ChartsWithSlider/StrandingChartWithSlider";
import { useAppSelector } from "src/redux/hooks";
import {
  selectGHGTargetsForOpenedAsset,
  selectEnergyTargetsForOpenedAsset,
} from "src/redux/slices/portfolios/assets/openedAssetSelectors";
import { StandardContent } from "src/common/components/templates/StandardContent";
import { TabsHeader } from "src/common/components/atoms/TabsHeader";
import { PerformanceTable } from "src/common/components/molecules/PerformanceTable";
import { PerformanceHighlights } from "src/common/components/molecules/PerformanceHighlights";
import { selectOpenedPortfolio } from "src/redux/slices/portfolios/selectors";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { AssetStrandingSummary } from "../components/AssetStrandingSummary";
import { Units, getUnits } from "src/constants/measureUnits";
import { currencyCodeToSymbol } from "src/common/utils/units";
import { getScaledStrandingChartValuesForGHG } from "src/common/utils/units/ghgChartScaling";
import { selectShouldShowCapex } from "src/redux/slices/featureConfig/selectors";

type TProps = {
  asset: IAppAsset;
};
export const AssetStrandingTab: React.FC<TProps> = ({ asset }) => {
  const openedPortfolio = useAppSelector(selectOpenedPortfolio);
  const targetsGHG = useAppSelector(selectGHGTargetsForOpenedAsset);
  const targetsEnergy = useAppSelector(selectEnergyTargetsForOpenedAsset);
  const shouldShowCapex = useAppSelector(selectShouldShowCapex);
  const [year, setYear] = useState(2031);

  const forecastEnergy = asset.energyResults.emissionsForecast;
  const forecastGHG = asset.ghgResults.emissionsForecast;

  const ghgScaledValues = useMemo(
    () =>
      getScaledStrandingChartValuesForGHG(
        forecastGHG || [],
        targetsGHG,
        [],
        openedPortfolio?.measureSystem,
      ),
    [forecastGHG, targetsGHG, openedPortfolio],
  );

  return (
    <Box>
      <StandardContent
        chartAreaContent={
          <>
            <Tabs colorScheme="red" size="sm" variant="unstyled" width="100%">
              <TabsHeader
                ml="15px"
                tabTitles={["GHG Emissions Intensity", "Energy Intensity"]}
              />

              <TabPanels>
                <TabPanel>
                  <StrandingChartWithSlider
                    units={ghgScaledValues.units}
                    year={year}
                    setYear={setYear}
                    forecast={ghgScaledValues.forecast}
                    targets={ghgScaledValues.targets}
                  />
                </TabPanel>
                <TabPanel>
                  <StrandingChartWithSlider
                    units={getUnits(
                      Units.energyIntensityYearly,
                      openedPortfolio?.measureSystem,
                    )}
                    year={year}
                    setYear={setYear}
                    forecast={forecastEnergy || []}
                    targets={targetsEnergy}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        }
      >
        <Box width="100%">
          <Text fontSize="sm" fontWeight="bold">
            Asset Performance Overview
          </Text>
          <AssetStrandingSummary asset={asset} />
        </Box>
      </StandardContent>
      <Flex
        direction="row"
        backgroundColor="white"
        padding="20px"
        borderRadius="8px"
      >
        <Box width="450px">
          <PerformanceTable
            assets={[asset]}
            targets={targetsGHG}
            forecastGHG={forecastGHG || []}
            forecastEnergy={forecastEnergy || []}
            maxReportingYear={asset.reportingYear}
            measureSystem={openedPortfolio?.measureSystem}
          />
        </Box>
        <Box width="calc(100% - 450px)">
          <PerformanceHighlights
            assets={[asset]}
            units={`${currencyCodeToSymbol(
              openedPortfolio?.currency,
            )}/${getUnits(Units.area, openedPortfolio?.measureSystem)}`}
            areaUnits={getUnits(Units.area, openedPortfolio?.measureSystem)}
            shouldShowCapex={shouldShowCapex}
          />
        </Box>
      </Flex>
    </Box>
  );
};

import React from "react";
import { Box, Flex, GridItem, Text } from "@chakra-ui/react";

//components
import { EPCLabel } from "src/common/components/atoms/EPCLabel";

interface IProps {
  rating: "A" | "B" | "C" | "D" | "E" | "F" | "G";
  text: string[];
}

const RatingPerformanceLabel: React.FC<IProps> = (props) => {
  const { rating, text, ...rest } = props;

  return (
    <GridItem
      rowSpan={1}
      colSpan={1}
      alignSelf="center"
      justifySelf="center"
      {...rest}
    >
      <Flex direction="column" alignItems="center">
        {text.map((line, idx) => (
          <Text
            key={`line${idx}`}
            color="gray.500"
            fontSize="md"
            mt={1}
            textAlign="justify"
          >
            {line}
          </Text>
        ))}
        <EPCLabel rating={rating} size={3} />
        <Box
          border="1px"
          borderColor="gray.400"
          bg="gray.400"
          height="50px"
          width="1px"
        />
      </Flex>
    </GridItem>
  );
};
export default RatingPerformanceLabel;

import React from "react";
import { Tbody, Tr, Td, Checkbox, Link } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { toggleAssetId } from "src/redux/slices/uiState/assetsFilter/slice";
import { selectAllCountries } from "src/redux/slices/countries/selectors";
interface IProps {
  assets: IAppAsset[];
  selectedAssetIds: number[];
  renderCustomTds: (assset: IAppAsset) => React.ReactNode;
  tabName: string;
}

const getAssetLink = (portfolioId: number, assetId: number, tab: string) =>
  `/${portfolioId}/asset/${assetId}/${tab}`;
export const AssetsTableBody: React.FC<IProps> = ({
  assets,
  selectedAssetIds,
  renderCustomTds,
  tabName,
}) => {
  const dispatch = useAppDispatch();
  const countries = useAppSelector(selectAllCountries);
  const navigate = useNavigate();
  return (
    <Tbody>
      {assets &&
        assets.map((asset: IAppAsset) => (
          <Tr
            key={asset.assetId}
            _hover={{ bg: "white.400", cursor: "pointer" }}
            onClick={() =>
              navigate(getAssetLink(asset.portfolioId, asset.assetId, tabName))
            }
          >
            <Td
              data-name="checkbox area"
              onClick={
                (e) =>
                  e.stopPropagation() /* to prevent redirect to asset on checkbox click */
              }
            >
              <Checkbox
                colorScheme="gray"
                onChange={() => dispatch(toggleAssetId(asset.assetId))}
                isChecked={selectedAssetIds.includes(asset.assetId)}
              />
            </Td>
            <Td>
              {/* This Link is just to enhance navigation & semantics, but onClick handles it anyway */}
              <Link
                href={getAssetLink(asset.portfolioId, asset.assetId, tabName)}
              >
                {asset.assetLabel}
              </Link>
            </Td>
            <Td>
              {asset.businessBuildingTypes
                .map((t) => t.business_building_type_label)
                .join(", ")}
            </Td>
            <Td>
              {countries?.find(
                (c) => c.country_iso_code === asset.location.isoCountryCode,
              )?.country_label || asset.location.isoCountryCode}
            </Td>
            {renderCustomTds(asset)}
          </Tr>
        ))}
    </Tbody>
  );
};

import React, { useMemo } from "react";
import { Box, Flex, Radio, RadioGroup, Text } from "@chakra-ui/react";
import { ITimeSeriesPoint } from "src/api/open-api";
import { RoundSpinner } from "src/common/components/atoms/Spinner/RoundSpinner";
import { globalMaxYear, globalMinYear } from "src/constants";
import { SliderSingleColor } from "src/common/components/atoms/Slider/SliderSingleColor";
import { BarChart } from "src/common/components/atoms/Chart/BarChart";
import { theme } from "src/config/theme";
import { CustomPieChart } from "../../../common/components/molecules/CustomPieChart";
import { IReduxChartTarget } from "src/redux/interfaces/IReduxChartTarget";

interface IProps {
  title?: string;
  assetsStrandingYears: number[]; // normalized values are expected
  selectedModelVersionId: number;
  setModelVersionId: (id: number) => void;
  year: number;
  setYear: (year: number) => void;
  targets: Partial<IReduxChartTarget>[];
  forecast: ITimeSeriesPoint[];
}

export const StrandedAssetsShareChart: React.FC<IProps> = ({
  title,
  assetsStrandingYears,
  setModelVersionId,
  selectedModelVersionId,
  year,
  setYear,
  targets,
  forecast,
}) => {
  const load = assetsStrandingYears?.length;
  const minYear = Math.max(forecast[0]?.year || globalMinYear, globalMinYear);
  const data = useMemo(
    () =>
      Array(globalMaxYear - minYear)
        .fill(minYear)
        .map((y, i) => ({
          year: y + i,
          value:
            (assetsStrandingYears.filter((a) => a <= y + i).length * 100) /
            assetsStrandingYears.length,
        })),
    [assetsStrandingYears, minYear],
  );
  const currentValue =
    year === globalMaxYear
      ? data.find((d) => d.year === globalMaxYear - 1)?.value
      : data.find((d) => d.year === year)?.value;

  if (load) {
    return (
      <Box>
        {title && (
          <Text
            ml="1rem"
            mb="1rem"
            textColor="gray.800"
            display="inline"
            fontWeight="bold"
          >
            {title} ({globalMinYear}-{globalMaxYear})
          </Text>
        )}

        <Box
          overflowY="hidden"
          w="100%"
          pb="1.1rem"
          pl="1rem"
          paddingRight="17px"
        >
          <BarChart content={data} />
          <Box w="100%" mt="1rem" paddingLeft="34px">
            <SliderSingleColor
              year={year}
              setYear={setYear}
              minYear={minYear}
              maxYear={globalMaxYear}
            />
          </Box>
        </Box>

        <Flex direction="row" justifyContent="space-evenly">
          <RadioGroup
            onChange={(v) => setModelVersionId(Number(v))}
            value={String(selectedModelVersionId)}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            {targets.map((p) => (
              <Radio
                colorScheme="red"
                key={p.modelVersionId}
                value={String(p.modelVersionId)}
              >
                {p.targetName}{" "}
              </Radio>
            ))}
          </RadioGroup>
          <Box marginRight="1rem" w="20%" minW="80px" minH="80px">
            <CustomPieChart
              categories={[
                {
                  color: theme.colors.red["500"],
                  value: Number(currentValue),
                  focused: true,
                  label: "Stranded [%]",
                },
                {
                  color: theme.colors.gray["100"],
                  value: 100 - Number(currentValue) || 0,
                  focused: false,
                  label: "Not Stranded [%]",
                },
              ]}
            />
          </Box>
        </Flex>
      </Box>
    );
  } else {
    return (
      <Box height="200px">
        <RoundSpinner />
      </Box>
    );
  }
};

import React from "react";
import { Table, Text, Tbody, Tr, Td, VStack } from "@chakra-ui/react";
import { IAppIntervention } from "src/redux/interfaces/IAppIntervention";
import { currencyCodeToSymbol } from "src/common/utils/units";
import { MeasureSystem } from "src/redux/interfaces/IAppPortfolio";
import { Units, getUnits } from "src/constants/measureUnits";
import { AccordionItem } from "src/common/components/atoms/AccordionItem";

import {
  genTableDisplayValue,
  getTableMonetaryDisplayValue,
} from "../utils/getTableDisplayValue";
import {
  calcInterventionPackageTotals,
  metricTypes,
} from "../utils/getInterventionValues";
import { sum } from "src/common/utils/math";

type IProps = {
  name: string;
  interventions: IAppIntervention[];
  assetArea?: number;
  isoCurrencyCode?: string;
  measureSystem?: MeasureSystem;
};

const unitsMap = {
  carbon: (measureSystem?: MeasureSystem) =>
    getUnits(Units.ghgYearly, measureSystem).replace(
      "{weight}",
      getUnits(Units.weightx1000, measureSystem),
    ),
  energy: (measureSystem?: MeasureSystem) =>
    getUnits(Units.energyYearlyx1000, measureSystem),
  capex: (isoCurrencyCode?: string) => currencyCodeToSymbol(isoCurrencyCode),
};

export const ArchetypeSummary: React.FC<IProps> = ({
  name,
  interventions,
  assetArea,
  isoCurrencyCode,
  measureSystem,
}) => {
  const { carbonTotal, energyTotal } = calcInterventionPackageTotals(
    interventions,
    metricTypes.Absolute,
    assetArea || 1,
    1,
  );
  const capexTotal = sum(interventions.map((i) => i.capexAbsoluteTotal || 0));
  if (!interventions?.length) return <></>;

  return (
    <>
      <AccordionItem name={name}>
        <Table maxWidth="400px" mb="1em" ml="auto" mr="auto">
          <Tbody>
            <Tr>
              <Td borderRight="1px solid" borderRightColor="gray.100">
                Carbon Reduced
              </Td>
              <Td isNumeric fontWeight="bold">
                {genTableDisplayValue(
                  carbonTotal / 1000,
                  unitsMap.carbon(measureSystem),
                )}
              </Td>
            </Tr>
            <Tr>
              <Td borderRight="1px solid" borderRightColor="gray.100">
                Energy Reduced
              </Td>
              <Td isNumeric fontWeight="bold">
                {genTableDisplayValue(
                  energyTotal / 1000,
                  unitsMap.energy(measureSystem),
                )}
              </Td>
            </Tr>
            <Tr>
              <Td borderRight="1px solid" borderRightColor="gray.100">
                CAPEX
              </Td>
              <Td isNumeric fontWeight="bold">
                {getTableMonetaryDisplayValue(capexTotal)}{" "}
                {unitsMap.capex(isoCurrencyCode)}
              </Td>
            </Tr>
          </Tbody>
        </Table>
        <VStack
          width="90%"
          fontSize="12px"
          align="flex-start"
          ml="auto"
          mr="auto"
        >
          {interventions.map((intervention) => (
            <Text key={intervention.interventionCode}>
              {intervention.interventionCode}:{" "}
              {intervention.interventionDescription}
            </Text>
          ))}
        </VStack>
      </AccordionItem>
    </>
  );
};

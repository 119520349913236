export interface IConfig {
  API: string;
  AWS_REGION: string;
  COGNITO_DOMAIN: string;
  COGNITO_POOL_ID: string;
  COGNITO_CLIENT_ID: string;
  COGNITO_AZURE_PROVIDER_ID: string;
  CYPRESS_TEST_USER_EMAIL: string;
  CYPRESS_TEST_USER_PASSWORD?: string;
  CYPRESS_BASE_URL: string;
  MAPGL_ACCESS_TOCKEN: string;
}

export const baseConfig: IConfig = {
  API: process.env.API || "https://api.dev.crisp.arup.com",
  AWS_REGION: process.env.AWS_REGION || "eu-west-1",
  COGNITO_DOMAIN:
    process.env.COGNITO_DOMAIN || "crispauth.auth.eu-west-1.amazoncognito.com",
  COGNITO_POOL_ID: process.env.COGNITO_POOL_ID || "eu-west-1_4Scy7nWYH",
  COGNITO_CLIENT_ID:
    process.env.COGNITO_CLIENT_ID || "7bbbk00i2vtfnp2aajrm4n4iv2",
  COGNITO_AZURE_PROVIDER_ID: process.env.COGNITO_CLIENT_ID || "AzureAD",

  // This info will be useful later when setting up test/demo/prod
  // Items selection
  // App client name Client ID
  // crisp-pool-client-prod	3c25229k94m0ee89p39cup92a4
  // crisp-pool-client-dev	7bbbk00i2vtfnp2aajrm4n4iv2 // See above
  // crisp-pool-client-demo	gnmen9e0lh2rfj6qc7biio9r0
  // crisp-pool-client-test	i1lomkp3375s3ep8t0qev10tl

  CYPRESS_TEST_USER_EMAIL: process.env.CYPRESS_TEST_USER_EMAIL || "",
  CYPRESS_TEST_USER_PASSWORD: process.env.CYPRESS_TEST_USER_PASSWORD || "",
  CYPRESS_BASE_URL: process.env.CYPRESS_BASE_URL || "http://localhost:3000",
  MAPGL_ACCESS_TOCKEN:
    "pk.eyJ1IjoiZGh1YiIsImEiOiJja3ZxZWNuY2wxazdoMnJxZnJleXdleTE2In0.e9rAaRfJx7Tme-Fff1s2ww",
};

import React from "react";
import { Flex, Text, Image } from "@chakra-ui/react";
import { ProgressBar } from "src/common/components/atoms/ProgressBar";

interface IProps {
  name: string;
  img: string;
  value: number;
}

const CertSummary: React.FC<IProps> = (props) => {
  const { name, img, value } = props;
  return (
    <Flex fontSize="small" pr="1.75rem" alignItems="center">
      <Image src={img} maxW="4rem" mr="0.5rem" />
      <Flex w="50%" ml="0.5rem" flexDir="column" maxW="6rem">
        <Text fontSize="heading2" textTransform="uppercase" fontWeight="bold">
          {name}
        </Text>
        <ProgressBar value={value} color="green" />
        <Text color="green.500" fontSize="heading2" fontWeight="bold">
          {value}&#37;
        </Text>
      </Flex>
    </Flex>
  );
};
export default CertSummary;

import React from "react";
import { TaxonomyCategory } from "src/pages/CRUDModals/utils/portfolioExcelParser/parseAssets";
import CCMInfoModal from "./CCMInfoModal";
import CCAInfoModal from "./CCAInfoModal";
import BDEInfoModal from "./BDEInfoModal";
import PPCInfoModal from "./PPCInfoModal";
import TCEInfoModal from "./TCEInfoModal";
import WRMInfoModal from "./WRMInfoModal";

interface IProps {
  category: TaxonomyCategory;
  isOpen: boolean;
  onClose: () => void;
}
type PropsType = IProps;

const InfoModal: React.FC<PropsType> = (props) => {
  const { category, isOpen, onClose } = props;
  switch (category) {
    case "ccm":
      return <CCMInfoModal isOpen={isOpen} onClose={onClose} />;
    case "cca":
      return <CCAInfoModal isOpen={isOpen} onClose={onClose} />;
    case "tce":
      return <TCEInfoModal isOpen={isOpen} onClose={onClose} />;
    case "wrm":
      return <WRMInfoModal isOpen={isOpen} onClose={onClose} />;
    case "ppc":
      return <PPCInfoModal isOpen={isOpen} onClose={onClose} />;
    case "bde":
      return <BDEInfoModal isOpen={isOpen} onClose={onClose} />;
    default:
      return <CCMInfoModal isOpen={isOpen} onClose={onClose} />;
  }
};

export default InfoModal;

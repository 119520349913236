import React from "react";
import { Table, Text, Tbody, Thead, Th, Tr, Td } from "@chakra-ui/react";
import { currencyCodeToSymbol } from "src/common/utils/units";
import { IAppIntervention } from "src/redux/interfaces/IAppIntervention";
import { getTableMonetaryDisplayValue } from "../../utils/getTableDisplayValue";

type IProps = {
  interventions: IAppIntervention[];
  isoCurrencyCode?: string;
  macConfig: {
    feature_status: boolean;
    feature_config?: string;
  } | null;
};

export const InvestmentTable: React.FC<IProps> = ({
  interventions,
  isoCurrencyCode,
  macConfig,
}) => {
  if (!interventions?.length) return <></>;

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th lineHeight="1.2">Measure Description</Th>
          <Th lineHeight="1.2" textAlign="right">
            Simple Payback,
            <br />
            <Text fontSize="0.8em">Years</Text>
          </Th>
          <Th lineHeight="1.2" textAlign="right">
            ROI Annulised,
            <br />
            <Text fontSize="0.8em">%</Text>
          </Th>
          {macConfig?.feature_status ? (
            <Th lineHeight="1.2" textAlign="right">
              Marginal Abatement Cost
              <br />
              <Text fontSize="0.8em">
                {currencyCodeToSymbol(isoCurrencyCode)}/tCO₂
              </Text>
            </Th>
          ) : null}
        </Tr>
      </Thead>
      <Tbody>
        {interventions.map((intervention) => (
          <Tr key={intervention.interventionCode}>
            <Td>
              {intervention.interventionCode}:{" "}
              {intervention.interventionDescription}
            </Td>
            <Td isNumeric>
              {intervention.simplePayback === 0
                ? 0
                : !intervention.simplePayback
                ? "No payback"
                : Math.abs(intervention.simplePayback) === 9000000000000 // This is a workaround, spr returns this value when opex = 0, and we cannot divide by 0
                ? "-"
                : Math.abs(intervention.simplePayback) > 20
                ? ">20"
                : getTableMonetaryDisplayValue(
                    Math.abs(intervention.simplePayback),
                  )}
            </Td>
            <Td isNumeric>
              {getTableMonetaryDisplayValue((intervention.roi || 0) * 100)}
            </Td>
            {macConfig?.feature_status ? (
              <Td isNumeric>
                {getTableMonetaryDisplayValue(
                  intervention.marginalAbatementCost || 0,
                )}
              </Td>
            ) : null}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

import React from "react";
import { Flex, HStack, Box } from "@chakra-ui/react";

interface IProps {
  value?: 0 | 1 | 2 | 3;
  badges: React.ReactElement[];
  isSquare?: boolean;
}

export const SustainabilityScoreBar: React.FC<IProps> = ({
  value,
  badges,
  isSquare,
}) => {
  return (
    <Flex flexDir="row" alignItems="center" justifyContent="space-between">
      <HStack spacing="0.7rem" w="100%" justifyContent="left">
        {badges.map((_, idx) =>
          value === idx ? (
            badges[idx]
          ) : (
            <Box
              key={`scoreBox${idx}`}
              bg="gray.50"
              borderRadius={isSquare ? "0" : "100%"}
              flex="1"
              maxW={{ base: "1.2rem", lg: "1.7rem" }}
              minW={{ base: "1.2rem", lg: "1.7rem" }}
              style={{ aspectRatio: `1 / 1` }}
              data-testid="scoreBox"
            />
          ),
        )}
      </HStack>
    </Flex>
  );
};

import { Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ErrorNotFound } from "src/common/components/molecules/ErrorNotFound";
import { TabsNavigation } from "src/common/components/molecules/TabsNavigation";
import { StandardLayout } from "src/common/components/templates/StandardLayout";
import { StrandingTab } from "./tabs/StrandingTab";
import { InefficientAssetsTab } from "./tabs/InefficientAssetsTab";
import { TaxonomyCheckTab } from "./tabs/TaxonomyCheckTab";
import { SustainabilityTab } from "./tabs/SustainabilityTab";
import { PortfolioSidebar } from "./components/PortfolioSidebar";
import { fetchAssets } from "src/redux/thunks/assets";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  selectOpenedPortfolio,
  selectPortfoliosNotLoaded,
} from "src/redux/slices/portfolios/selectors";
import { setPortfolioSelected } from "src/redux/slices/portfolios/slice";
import { EconomicTab } from "./tabs/EconomicTab";
import { ScenarioTab } from "./tabs/ScenarioTab";
import { selectAssetsError } from "src/redux/slices/portfolios/assets/selectors";
import { genErrorMessage } from "src/common/utils/errors";
import { Alert } from "src/common/components/atoms/Alert";
import { CustomPathwaysTab } from "./tabs/CustomPathwaysTab";
import { selectTabList } from "src/redux/slices/featureConfig/selectors";
import { useCompany } from "src/common/components/organisms/CompanyProviderWrapper";

export const PortfolioView: React.FC = () => {
  const dispatch = useAppDispatch();
  const company = useCompany();
  const openedPortfolio = useAppSelector(selectOpenedPortfolio);
  const location = useLocation();
  const selectedTab = location.pathname.split("/")[2];
  const portfolioId = Number(location.pathname.split("/")[1]);
  const isLoading = useAppSelector(selectPortfoliosNotLoaded);
  const assetError = useAppSelector(selectAssetsError);
  const tabs = useAppSelector(selectTabList);
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setPortfolioSelected(portfolioId));
    dispatch(fetchAssets(portfolioId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioId, dispatch]);

  useEffect(() => {
    if (
      openedPortfolio &&
      openedPortfolio.summary.companyId !== Number(company.selectedCompanyId)
    ) {
      company.setSelectedCompanyId(String(openedPortfolio.summary.companyId));
    }
  }, [portfolioId, company, openedPortfolio]);

  useEffect(() => {
    if (openedPortfolio === null && !isLoading) {
      navigate(`/`);
    }
  }, [portfolioId, isLoading, navigate, openedPortfolio]);

  if (!openedPortfolio) {
    return null;
  }

  return (
    <StandardLayout
      sideBar={
        <PortfolioSidebar
          portfolioId={openedPortfolio.portfolioId}
          portfolioName={openedPortfolio.summary.portfolioName}
          latestUpdate={
            openedPortfolio.summary.latestUpdateDate
              ? new Date(openedPortfolio.summary.latestUpdateDate)
              : undefined
          }
          isoCurrencyCode={openedPortfolio.currency}
          measureSystem={openedPortfolio.measureSystem}
        />
      }
      backUrl={`/companyView/${openedPortfolio.summary.companyId}`}
      backUrlText="All Portfolios Overview"
    >
      <TabsNavigation tabs={tabs} selectedTab={selectedTab} />
      {assetError ? (
        <Alert
          isError
          message={genErrorMessage(assetError, t, "Cannot display assets. ")}
        />
      ) : (
        <Routes>
          <Route path="/stranding" element={<StrandingTab />} />
          <Route path="/economic" element={<EconomicTab />} />
          <Route path="/inefficientAssets" element={<InefficientAssetsTab />} />
          <Route path="/taxonomy" element={<TaxonomyCheckTab />} />
          <Route path="/sustainability" element={<SustainabilityTab />} />
          <Route path="/scenario" element={<ScenarioTab />} />
          <Route path="/customPathways" element={<CustomPathwaysTab />} />
          <Route
            path="/*"
            element={
              <Flex
                direction="column"
                justifyContent="center"
                minHeight="70vh"
                alignItems="center"
                width="100%"
              >
                <ErrorNotFound isFullPage={false} />
              </Flex>
            }
          />
        </Routes>
      )}
    </StandardLayout>
  );
};

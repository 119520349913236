import axios, { AxiosRequestConfig } from "axios";
import config from "src/api";
import { getCognitoAuthToken } from "src/common/utils/auth/cognitoAuth";

export const getScenariosFile = async (
  portfolioId: number,
  assetId: number,
): Promise<string> => {
  const userAccessToken = await getCognitoAuthToken();

  const requestConfig: AxiosRequestConfig = {
    method: "GET",
    url: `${config.API}/scenario-file/${portfolioId}/${assetId}`,
    headers: {
      Authorization: `Bearer ${userAccessToken}`,
    },
  };
  return axios(requestConfig).then((res) => res.data);
};

import React from "react";
import { Box, Table, Thead, Tr, Th, Td, Tbody } from "@chakra-ui/react";
import { sum } from "src/common/utils/math";
import { formatMoney } from "src/common/utils/formatters";
import { Units, getUnits } from "src/constants/measureUnits";
import { MeasureSystem } from "src/redux/interfaces/IAppPortfolio";

type PartialAsset = {
  area: number;
  value?: number;
};

interface IProps {
  allAssets: PartialAsset[];
  selectedAssets: PartialAsset[];
  isoCurrecyCode: string;
  measureSystem: MeasureSystem;
  shouldHideValue?: boolean;
}

export const PortfolioStatTable: React.FC<IProps> = ({
  allAssets,
  selectedAssets,
  isoCurrecyCode,
  measureSystem,
  shouldHideValue,
}) => {
  const numberOfAssets = allAssets.length;
  const numberOfSelectedAssets = selectedAssets.length;
  const areaAssets = sum(allAssets.map((it) => it.area));
  const areaSelectedAssets = sum(selectedAssets.map((it) => it.area));
  const monetaryValueAssets = sum(allAssets.map((it) => it.value || 0));
  const monetaryValueSelectedAssets = sum(
    selectedAssets.map((it) => it.value || 0),
  );

  return (
    <Box maxW="100%" overflowX="scroll" mt="10px">
      <Table variant="simple" size="xs">
        <Thead>
          <Tr>
            <Th></Th>
            <Th textColor="gray.500" fontWeight="thin" textAlign="right">
              Portfolio
            </Th>
            <Th textColor="gray.500" fontWeight="thin" textAlign="right">
              Selection
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td color="primary.500" fontWeight="bold" paddingLeft="5px">
              Assets
            </Td>
            <Td isNumeric>{numberOfAssets}</Td>
            <Td isNumeric>{numberOfSelectedAssets}</Td>
          </Tr>
          <Tr>
            <Td color="primary.500" fontWeight="bold" paddingLeft="5px">
              <span style={{ whiteSpace: "nowrap" }}>
                Area, {getUnits(Units.area, measureSystem)}
              </span>
            </Td>
            <Td isNumeric>
              {areaAssets.toLocaleString(undefined, {
                maximumFractionDigits: 1,
              })}
            </Td>
            <Td isNumeric>
              {areaSelectedAssets.toLocaleString(undefined, {
                maximumFractionDigits: 1,
              })}
            </Td>
          </Tr>
          {shouldHideValue ? null : (
            <Tr>
              <Td fontWeight="bold" paddingLeft="5px">
                Value
              </Td>
              <Td isNumeric>
                {monetaryValueAssets === numberOfAssets
                  ? "Not provided"
                  : formatMoney(monetaryValueAssets, isoCurrecyCode)}
              </Td>
              <Td isNumeric>
                {monetaryValueSelectedAssets === numberOfSelectedAssets
                  ? "Not provided"
                  : formatMoney(monetaryValueSelectedAssets, isoCurrecyCode)}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

import React from "react";
import { Grid, GridItem, Box } from "@chakra-ui/react";
import { ReactComponent as AdaptionIcon } from "src/assets/icons/Tax_Adaptation.svg";
import { ReactComponent as BiodiversityIcon } from "src/assets/icons/Tax_Biodiversity.svg";
import { ReactComponent as CircularIcon } from "src/assets/icons/Tax_Circular.svg";
import { ReactComponent as MitigationIcon } from "src/assets/icons/Tax_Mitigation.svg";
import { ReactComponent as PollutionIcon } from "src/assets/icons/Tax_Pollution.svg";
import { ReactComponent as WaterIcon } from "src/assets/icons/Tax_Water.svg";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import TaxonomyAssetCard from "./TaxonomyAssetCard";
import TaxonomyLegend from "src/pages/PortfolioView/components/Taxonomy/TaxonomyLegend";

interface IProps {
  asset: IAppAsset;
}

const TaxonomyAssetTab: React.FC<IProps> = (props) => {
  const { asset } = props;

  return (
    <Box backgroundColor="white" p="20px" borderRadius="8px" mb="16px">
      <TaxonomyLegend />
      <Grid h="100%" templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem h="100%" colSpan={{ base: 4, sm: 2 }}>
          <TaxonomyAssetCard
            asset={asset}
            icon={MitigationIcon}
            label={"Climate Change Mitigation [CCM]"}
            category="ccm"
          />
        </GridItem>
        <GridItem h="100%" colSpan={{ base: 4, sm: 2 }}>
          <TaxonomyAssetCard
            asset={asset}
            icon={AdaptionIcon}
            label={"Climate Change Adaptation [CCA]"}
            category="cca"
          />
        </GridItem>
        <GridItem h="100%" colSpan={{ base: 4, sm: 2 }}>
          <TaxonomyAssetCard
            asset={asset}
            icon={CircularIcon}
            label={"Transition to Circular Economy [TCE]"}
            category="tce"
          />
        </GridItem>
        <GridItem h="100%" colSpan={{ base: 4, sm: 2 }}>
          <TaxonomyAssetCard
            asset={asset}
            icon={WaterIcon}
            label={"Water Resource Management [WRM]"}
            category="wrm"
          />
        </GridItem>
        <GridItem h="100%" colSpan={{ base: 4, sm: 2 }}>
          <TaxonomyAssetCard
            asset={asset}
            icon={PollutionIcon}
            label={"Pollution Control and Prevention [PCP]"}
            category="ppc"
          />
        </GridItem>
        <GridItem h="100%" colSpan={{ base: 4, sm: 2 }}>
          <TaxonomyAssetCard
            asset={asset}
            icon={BiodiversityIcon}
            label={"Biodiversity and Ecosystems [BDE]"}
            category="bde"
          />
        </GridItem>
      </Grid>
    </Box>
  );
};
export default TaxonomyAssetTab;

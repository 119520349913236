import React, { useEffect } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { fetchRecalcLogs, startRecalc } from "src/redux/thunks/admin";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  selectRecalcIsLoading,
  selectRecalcLogs,
  sselectRecalcStateError,
} from "src/redux/slices/admin/recalc/selectors";
import { genErrorMessage } from "src/common/utils/errors";
import { useTranslation } from "react-i18next";

const msToTime = (duration: number) => {
  let seconds: string | number = Math.floor((duration / 1000) % 60);
  let minutes: string | number = Math.floor((duration / (1000 * 60)) % 60);
  let hours: string | number = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + "h " + minutes + "m " + seconds + "s";
};

// TODO: teach it to autoupdate table every few minutes when recalc is in progress
export const Recalculate: React.FC = () => {
  const dispatch = useAppDispatch();
  const logs = useAppSelector(selectRecalcLogs);
  const isLoading = useAppSelector(selectRecalcIsLoading);
  const { t } = useTranslation();
  const error = useAppSelector(sselectRecalcStateError);

  useEffect(() => {
    dispatch(fetchRecalcLogs())
      .unwrap()
      .catch((err) => {
        // TODO: nice handling, low-priority
      });
  }, [dispatch]);

  const handleStartRecalc = async () => {
    await dispatch(startRecalc());
    dispatch(fetchRecalcLogs());
  };

  return (
    <Box backgroundColor="white" padding="40px" borderRadius="8px">
      <Text color="red.500" fontWeight="bold" mt="1rem">
        Admin
      </Text>
      <Heading as="h1" fontSize="lg" fontWeight="semi-bold" mb="2rem">
        Recalculate
      </Heading>

      <Button
        colorScheme="red"
        variant="solidPrimary"
        isDisabled={
          isLoading ||
          !!logs.find((log) => log.status.toUpperCase() === "IN PROGRESS")
        }
        onClick={handleStartRecalc}
        mb="2em"
      >
        Run recalc {/*TODO: lock when something is in progress*/}
      </Button>

      {isLoading ? (
        <Flex h="100%" w="100%" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      ) : error ? (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{genErrorMessage(error, t)}</AlertDescription>
        </Alert>
      ) : (
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <td>Start time</td>
              <td>Status</td>
              <td>Time spent</td>
              <td>Errors</td>
            </tr>
          </thead>
          <tbody>
            {logs.map((log) => {
              const start = new Date(log.start_datetime);
              const end = log.finish_datetime
                ? new Date(log.finish_datetime)
                : null;
              return (
                <tr key={log.logs_recalculate_id}>
                  <td>
                    {start.toLocaleDateString()} {start.toLocaleTimeString()}
                  </td>
                  <td>{log.status}</td>
                  <td>
                    {log.finish_datetime
                      ? msToTime((end?.getTime() || 0) - start.getTime())
                      : "-"}
                  </td>
                  <td>{log.error_log || "-"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </Box>
  );
};

import React, { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { ingestPortfolio } from "../../utils/ingestPortfolio";
import { parsePortfolioExcel } from "../../utils/portfolioExcelParser";
import { errorReporter } from "src/common/utils/errorReporter/errorReporter";
import { getLatestPortfolioFile } from "src/redux/thunks/files";
import { FileUploadModaWithReports } from "src/common/components/organisms/FileUploadModal/FileUploadModaWithReports";
import { AssetPayload, PortfolioPayload } from "src/api/open-api";
import { useTranslation } from "react-i18next";
import { validatePortfolioApi } from "src/redux/thunks/portfolios";
import { useCompany } from "src/common/components/organisms/CompanyProviderWrapper";
import { useAppSelector } from "src/redux/hooks";
import { selectAllUserCompanies } from "src/redux/slices/companies/selectors";
interface IProps {
  isOpen: boolean;
  onClose: () => void;
  portfolioId?: number;
}

const PortfolioExcelModal: React.FC<IProps> = (props) => {
  const { isOpen, onClose, portfolioId } = props;
  const toast = useToast();
  const { t } = useTranslation();
  const { selectedCompanyId } = useCompany();
  const companies = useAppSelector(selectAllUserCompanies);
  const companyId = selectedCompanyId ? parseInt(selectedCompanyId) : undefined;

  const [portfolioData, setPortfolioData] = useState<PortfolioPayload | null>(
    null,
  );
  const [assetsData, setAssetsData] = useState<AssetPayload[] | null>(null);
  // save file
  const [file, setfile] = useState<File | null>(null);
  const actionTitle = portfolioId !== undefined ? "Update" : "Add";

  let editFlag = 0;
  if (actionTitle === "Update") {
    editFlag = 1;
  }
  const handleValidate = async (file: File) => {
    let portfolio;
    let assets;
    try {
      const result = await parsePortfolioExcel(file, companyId);
      portfolio = result.portfolio;
      assets = result.assets;
      setPortfolioData(result.portfolio);
      setAssetsData(result.assets);
      setfile(file);
    } catch (error) {
      if (error instanceof Error) {
        return {
          errors: [error.message],
        };
      }
    }

    return await validatePortfolioApi(portfolio!, assets!, editFlag!);
  };

  const handleUpload = async () => {
    if (!portfolioData || !assetsData) return;
    return ingestPortfolio(portfolioData, assetsData, file, t, portfolioId)
      .then((errors) => {
        if (errors.length) {
          return {
            errors: errors.map((e) => e.message),
          };
        }
        toast({
          title: "Portfolio successfully updated",
          description:
            "All portfolio and asset data has been saved. Please refresh.",
          status: "success",
          isClosable: true,
          position: "top",
        });
        window.location.reload();
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 409) {
          return {
            errors: [
              "Portfolio already exists. To make changes please open and edit an existing portfolio.",
            ],
          };
        }
        if (err?.length) {
          errorReporter({
            activeUrl: window?.location?.pathname,
            stack: JSON.stringify(err),
            context: "ingestPortfolio",
          });
          return {
            errors: err as string[],
          };
        }
      });
  };

  return (
    <FileUploadModaWithReports
      title={actionTitle + " Portfolio"}
      isOpen={isOpen}
      onClose={onClose}
      onUpload={handleUpload}
      onValidate={handleValidate}
      getLatestFileUrl={
        portfolioId
          ? () => {
              return getLatestPortfolioFile(portfolioId);
            }
          : undefined
      }
      templateLink="CRISP_Input_Sheet_V1.2.xlsx"
      isUpdate={!!portfolioId}
      currentFileVersion="1.2"
      companyName={
        companies?.find((c) => c.company_id === companyId)?.company_name
      }
    />
  );
};
export default PortfolioExcelModal;

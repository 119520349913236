import { ITimeSeriesPoint } from "src/api/open-api";
import { Units, getUnits } from "src/constants/measureUnits";
import { MeasureSystem } from "src/redux/interfaces/IAppPortfolio";
import { IReduxChartTarget } from "src/redux/interfaces/IReduxChartTarget";
import { formatTimeSeriesWeight } from "../formatters";

export const getScaledStrandingChartValuesForGHG = (
  forecast: ITimeSeriesPoint[],
  targets: IReduxChartTarget[],
  scenarios: IReduxChartTarget[],
  measureSystem?: MeasureSystem,
  customPathways?: IReduxChartTarget[],
) => {
  const units = getUnits(Units.ghgIntensityYearly, measureSystem);
  const refValue = Math.max(...forecast.map((f) => f.value));
  const forecastRecalced = formatTimeSeriesWeight(
    units,
    forecast,
    refValue,
    measureSystem,
  );
  return {
    forecast: forecastRecalced.timeSeries,
    targets: targets.map((t) => ({
      ...t,
      timeSeries: formatTimeSeriesWeight(
        units,
        t.timeSeries,
        refValue,
        measureSystem,
      ).timeSeries,
    })),
    scenarios: scenarios.map((s) => ({
      ...s,
      timeSeries: formatTimeSeriesWeight(
        units,
        s.timeSeries,
        refValue,
        measureSystem,
      ).timeSeries,
    })),
    customPathways:
      customPathways?.map((s) => ({
        ...s,
        timeSeries: formatTimeSeriesWeight(
          units,
          s.timeSeries,
          refValue,
          measureSystem,
        ).timeSeries,
      })) || [],
    units: forecastRecalced.units,
  };
};

import i18next from "i18next";
import {
  Ii18nError,
  apiErrorToI18nError,
} from "src/redux/interfaces/Ii18nError";

export const genErrorMessage = (
  error: Ii18nError,
  t: typeof i18next.t,
  prefix?: string,
) => {
  return (
    (prefix || "") +
    t([`error.${error.errorCode}`, "error.GENERIC"], error.parameters)
  );
};

export const genErrorMessageOutsideRedux = (
  error: unknown,
  t: typeof i18next.t,
  prefix?: string,
) => {
  let message = `${prefix || ""}${
    // @ts-expect-error it doesn't like error.message, but we know it might exist
    error?.message || (typeof error === "string" ? error : "Unknown error")
  }`;
  // @ts-expect-error error type if alway unknown
  if (error?.errorCode) {
    message = genErrorMessage(apiErrorToI18nError(error), t, prefix || "");
  }
  return message;
};

import React from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  VStack,
} from "@chakra-ui/react";
import { FormEvent, useState } from "react";
import { useAppDispatch } from "src/redux/hooks";
import {
  addCompanyUser,
  editUser,
  fetchCompanyPermissions,
  fetchCompanyUsers,
} from "src/redux/thunks/admin";
import { IAppCompanyUser } from "src/redux/interfaces/IAppCompanyUser";
import { FormInput } from "src/common/components/atoms/Input";
import { useErrorState } from "src/redux/hooks/useErrorState";

interface IProps {
  onSubmitCallback?: () => void;
  user?: IAppCompanyUser;
  companyId: number;
}

const AddUserForm: React.FC<IProps> = (props: IProps) => {
  const { onSubmitCallback, user, companyId } = props;
  const dispatch = useAppDispatch();
  const { error, setError, clearError } = useErrorState();
  const [userForm, setUserForm] = useState(
    user
      ? {
          ...user,
        }
      : {
          user_name: "",
          user_email: "",
          is_active: 1,
        },
  );

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (error) clearError();
    if (userForm) {
      if (user) {
        return dispatch(editUser(userForm))
          .then((res) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (res.error) throw res.error;
            clearError();
            if (onSubmitCallback) onSubmitCallback();
          })
          .catch((err) => {
            setError(err);
          });
      }
      dispatch(addCompanyUser({ user: userForm, companyId }))
        .then((res) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (res.error) throw res.error;
          clearError();
          if (onSubmitCallback) onSubmitCallback();
        })
        .then(() => dispatch(fetchCompanyUsers(companyId)))
        .then(() => dispatch(fetchCompanyPermissions(companyId)))
        .catch((err) => {
          setError(err);
        });
    }
  };

  const handleChange = (target: { name: string; value: string }) => {
    if (error) clearError();
    setUserForm({
      ...userForm,
      [target.name]: target.value,
    });
  };

  return (
    <>
      <Box>
        <form
          encType="multipart/form-data"
          onSubmit={(e) => {
            handleSubmit(e);
            e.preventDefault();
          }}
        >
          <VStack spacing={"1.5rem"} textAlign="left">
            <FormInput
              label="Name"
              name="user_name"
              value={userForm.user_name}
              type="name"
              onBlur={handleChange}
              isRequired
            />
            <FormInput
              label="Email"
              name="user_email"
              value={userForm.user_email}
              type="email"
              onBlur={handleChange}
              isRequired
            />
            <Box mt="2rem">
              <Button
                type="submit"
                variant="outlinePrimary"
                placeSelf={{ base: "center" }}
              >
                Save
              </Button>
            </Box>
          </VStack>
        </form>
        {error && (
          <Alert status="error" mt="1rem">
            <AlertIcon />
            <AlertDescription>{String(error)}</AlertDescription>
          </Alert>
        )}
      </Box>
    </>
  );
};

export default AddUserForm;

import { IAppIntervention } from "src/redux/interfaces/IAppIntervention";

export enum metricTypes {
  Absolute = "Absolute",
  Relative = "Relative",
  Normalized = "Normalized",
}

const valuesMap = {
  [metricTypes.Absolute]: {
    carbon: "ghgAbsoluteChange",
    energy: "euiAbsoluteChange",
  },
  [metricTypes.Relative]: {
    carbon: "ghgRelativeChange",
    energy: "euiRelativeChange",
  },
};

export const getInterventionField = (
  intervention: IAppIntervention,
  type: metricTypes,
  column: "carbon" | "energy",
  assetArea: number,
): number => {
  if (type === metricTypes.Normalized) {
    return (
      ((intervention[
        valuesMap[metricTypes.Absolute][column] as keyof IAppIntervention
      ] as number) || 0) / assetArea
    );
  }
  return (
    (intervention[
      valuesMap[type][column] as keyof IAppIntervention
    ] as number) || 0
  );
};

const roundToOneDecimal = (value: number) => Math.round(value * 10) / 10;

export const calcInterventionPackageTotals = (
  interventions: IAppIntervention[],
  type: metricTypes,
  assetArea: number,
  roundTo: number,
): { carbonTotal: number; energyTotal: number } => {
  return interventions.reduce(
    (accumulator, currentValue) => ({
      carbonTotal:
        accumulator.carbonTotal +
        (roundTo === 1
          ? roundToOneDecimal(
              getInterventionField(currentValue, type, "carbon", assetArea),
            )
          : Math.round(
              getInterventionField(currentValue, type, "carbon", assetArea),
            )),
      energyTotal:
        accumulator.energyTotal +
        (roundTo === 1
          ? roundToOneDecimal(
              getInterventionField(currentValue, type, "energy", assetArea),
            )
          : Math.round(
              getInterventionField(currentValue, type, "energy", assetArea),
            )),
    }),
    { carbonTotal: 0, energyTotal: 0 },
  );
};

import React from "react";
import { SimpleGrid } from "@chakra-ui/react";
import { PortfolioCard } from "./PortfolioCard";

interface IProps {
  items?: {
    portfolioId: number;
    portfolioName: string;
    countries: string[];
    uses: string[];
    riskLevel?: number;
    assetsCnt: number;
    area: string;
    value: string;
    taxonomy?: number;
    certifications: number;
    dataMaturity?: number;
    fundType?: string;
  }[];
  cardConfig: { [key: string]: boolean };
  firstTab: string;
}
export const PortfolioCardPage: React.FC<IProps> = ({
  items,
  cardConfig,
  firstTab,
}) => {
  return (
    <SimpleGrid
      gap={3}
      columns={{ xl: 3, lg: 2, md: 1, base: 1 }}
      maxWidth={{ base: "850px", xl: "1200px" }}
    >
      {items?.map((portfolio) => {
        return (
          <PortfolioCard
            cardConfig={cardConfig}
            key={portfolio.portfolioId}
            firstTab={firstTab}
            {...portfolio}
          />
        );
      })}
    </SimpleGrid>
  );
};

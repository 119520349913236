import React, { useEffect } from "react";
import { CompanyAdminView } from "./CompanyAdminView";
import { AppAdminView } from "./AppAdminView";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { selectCanAccessAppAdminLevel } from "src/redux/slices/auth/authInfo/selectors";
import { selectMyEditableCompanyId } from "src/redux/slices/auth/userAccess/selectors";
import { fetchCompanies } from "src/redux/thunks/companies";

export const AdminView: React.FC = () => {
  const dispatch = useAppDispatch();
  const isSystemOrAppAdmin = useAppSelector(selectCanAccessAppAdminLevel);
  const myCompanyId = useAppSelector(selectMyEditableCompanyId);

  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);

  if (isSystemOrAppAdmin) {
    return <AppAdminView />;
  }

  return <CompanyAdminView companyId={myCompanyId} />;
};

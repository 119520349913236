import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Button, Flex } from "@chakra-ui/react";

type TProps = {
  selectedTab: string;
  tabs: {
    name: string;
    to: string;
  }[];
};

export const TabsNavigation: React.FC<TProps> = ({ tabs, selectedTab }) => {
  return (
    <Flex flexWrap="nowrap">
      {tabs.map((tab) => (
        <ReactRouterLink to={tab.to} key={tab.to}>
          <Button
            as="span"
            variant={selectedTab === tab.to ? "assetTabSelected" : "assetTab"}
            marginRight="0.7em"
            marginBottom="0.7rem"
          >
            {tab.name}
          </Button>
        </ReactRouterLink>
      ))}
    </Flex>
  );
};

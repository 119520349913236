import { EntityState, createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { userAccessAdapter } from "./adapter";
import { ICompanyPermission } from "src/api/open-api";
import { hardcodedEntityTypes } from "src/constants/accessEntityTypes";
import { hardcodedPermissionTypes } from "src/constants/accessRolesAndPermissions";

const selectSlice = (state: RootState) => state.userAccess;

export const selectMyPermissions = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<ICompanyPermission>) =>
    userAccessAdapter.getSelectors().selectAll(state),
);

export const selectAmICompanyAdmin = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<ICompanyPermission>) =>
    userAccessAdapter
      .getSelectors()
      .selectAll(state)
      .filter(
        (p) =>
          p.entity_type_id === Number(hardcodedEntityTypes.company) &&
          p.permission_type_id === hardcodedPermissionTypes.full,
      ).length > 0,
);

export const selectMyEditableCompanyId = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<ICompanyPermission>) =>
    userAccessAdapter
      .getSelectors()
      .selectAll(state)
      .find(
        (p) =>
          p.entity_type_id === Number(hardcodedEntityTypes.company) &&
          p.permission_type_id === hardcodedPermissionTypes.full,
      )?.entity_id,
);

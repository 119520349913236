import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Flex, Text, Image, Link, Button } from "@chakra-ui/react";

import ArupLogo from "src/assets/img/arup_red.svg";

interface IProps {
  is401?: boolean;
  to?: string;
  isFullPage?: boolean;
}
export const ErrorNotFound: React.FC<IProps> = ({
  is401,
  to = "/",
  isFullPage,
}) => {
  return (
    <Flex
      flexDir="column"
      width="100%"
      minHeight={isFullPage ? "90vh" : ""}
      justifyContent="center"
      alignItems="center"
    >
      <Image src={ArupLogo} alt="Arup logo" w="7rem" mb="0.5rem" />
      {is401 ? (
        <Text
          mt="1rem"
          fontSize="heading1"
          letterSpacing="0.05rem"
          textColor="gray"
          align="center"
        >
          You are not authorised. Please email{" "}
          <Link href="mailto:crisp.support@arup.com" isExternal>
            crisp.support@arup.com{" "}
          </Link>{" "}
          to request access.
        </Text>
      ) : (
        <>
          <Text
            mt="1rem"
            mb="0.5rem"
            fontSize="heading1"
            letterSpacing="0.05rem"
            textColor="gray"
            align="center"
          >
            Oops, we couldn&apos;t seem to find the page you were looking for.
          </Text>
          <ReactRouterLink to={to}>
            <Button
              data-cy="Page-404-go-back-link"
              variant="solid"
              colorScheme="red"
            >
              &lt; Go back
            </Button>
          </ReactRouterLink>
        </>
      )}
    </Flex>
  );
};

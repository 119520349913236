import React from "react";
import { Box } from "@chakra-ui/react";

interface IProps {
  compliance?: boolean;
  substantialContribution: boolean;
}

export const ComplianceIndicator: React.FC<IProps> = (props) => {
  const { compliance, substantialContribution } = props;

  return (
    <Box
      border="2px solid transparent"
      borderRadius="100%"
      borderColor={substantialContribution ? "red.500" : ""}
      padding="2px"
      style={{ aspectRatio: "1 / 1" }}
      height="1.5rem"
      data-testid="ComplianceIndicator"
    >
      {compliance !== undefined && (
        <Box
          bg={compliance ? "gray.700" : "gray.300"}
          borderRadius={"100%"}
          height={"100%"}
        />
      )}
    </Box>
  );
};

import React from "react";
import { Box, Table, Th, Td, Text, Flex } from "@chakra-ui/react";
import {
  IAppAsset,
  IAppAssetStrandingResult,
} from "src/redux/interfaces/IAppAsset";
import { decimalToPercent } from "src/common/utils/math";
import { riskToColor } from "src/common/utils/colors/colors";
import { defaultRiskLevels } from "src/constants/riskLevel";
import { InfoTooltip } from "../../atoms/InfoTooltip";
import { AssetsTableHeader } from "./components/AssetsTableHeader";
import { AssetsTableBody } from "./components/AssetsTableBody";
import { EPCLabel } from "../../atoms/EPCLabel";
import HoverTooltip from "../../atoms/HoverTooltip";
import { globalMaxYear, globalMinYear } from "src/constants";
import { NabersRating } from "../../atoms/NabersRating";
interface IProps {
  assets: IAppAsset[];
  selectedAssetIds: number[];
  selectedModelVersionId: number;
  toggleAssetId: (id: number) => void;
  tabName?: string;
}

const getWeightedRisk = (
  strandingResults: IAppAssetStrandingResult[],
  selectedModelVersionId: number,
) => {
  const result = strandingResults.find(
    (r) => r.modelVersionId === selectedModelVersionId,
  );
  return result?.strandingRiskWeighted;
};
const WeightedRisk: React.FC<{ weightedRiskValue?: number }> = ({
  weightedRiskValue,
}) => {
  return (
    <HoverTooltip
      title="Weighted Stranding Risk"
      text={
        defaultRiskLevels.find((r) => r.value === weightedRiskValue)?.label ||
        "Not provided"
      }
    >
      <Box
        backgroundColor={riskToColor(weightedRiskValue)}
        boxSize="1rem"
        borderRadius="50%"
      />
    </HoverTooltip>
  );
};

export const StrandingAssetList: React.FC<IProps> = ({
  assets,
  selectedAssetIds,
  selectedModelVersionId,
  tabName,
}) => {
  const haveNabers = !!assets.find((a) => !!a.nabers_rating);
  const haveEPC = !!assets.find((a) => !!a.epc.rating);
  return (
    <Box overflowY="auto" maxHeight="500px">
      <Table variant="simple" width="100%">
        <AssetsTableHeader
          selectedAssetIds={selectedAssetIds}
          filteredAssetsCnt={assets.length}
        >
          {haveNabers ? (
            <Th>
              <Flex alignItems="center">
                NABERS Rating
                <InfoTooltip text="National australian built environment rating system" />
              </Flex>
            </Th>
          ) : null}
          {haveEPC ? (
            <Th>
              <Flex alignItems="center">
                EPC Rating
                <InfoTooltip text="Energy performance certificate rating" />
              </Flex>
            </Th>
          ) : null}
          <Th>Stranding Year</Th>
          <Th>
            <Text whiteSpace="nowrap">Stranding Risk</Text>{" "}
            <Text>(Weighted)</Text>
          </Th>
          <Th>
            <Text>GHG</Text>
            <Text>
              Emissions
              <InfoTooltip text="Greenhouse gases of portfolio" />
            </Text>
          </Th>
          <Th>Data Maturity</Th>
        </AssetsTableHeader>
        <AssetsTableBody
          assets={assets}
          selectedAssetIds={selectedAssetIds}
          tabName={tabName || "stranding"}
          renderCustomTds={(asset) => {
            const strandingYear = Math.floor(
              asset.ghgResults.targets.find(
                (r) => r.modelVersionId === selectedModelVersionId,
              )?.strandingX || 0,
            );
            return (
              <>
                {haveNabers ? (
                  <Td>
                    {asset.nabers_rating ? (
                      <NabersRating rating={asset.nabers_rating} />
                    ) : (
                      "Not provided"
                    )}
                  </Td>
                ) : null}
                {haveEPC ? (
                  <Td>
                    <EPCLabel rating={asset.epc.rating} />
                  </Td>
                ) : null}
                <Td>
                  {strandingYear === globalMaxYear + 1
                    ? "-"
                    : strandingYear < globalMinYear
                    ? "Stranded"
                    : strandingYear || "-"}
                </Td>
                <Td width="12%">
                  <WeightedRisk
                    weightedRiskValue={getWeightedRisk(
                      asset.ghgResults.targets,
                      selectedModelVersionId,
                    )}
                  />
                </Td>

                <Td>
                  <Text fontWeight="bold">
                    {`${decimalToPercent(asset?.shareOfPortfolioGHG)}%`}
                  </Text>
                </Td>
                <Td>{asset.dataQuality ? asset.dataQuality + " %" : "-"}</Td>
              </>
            );
          }}
        />
      </Table>
    </Box>
  );
};

import { hardcodedEntityTypes } from "src/constants/accessEntityTypes";
import { hardcodedPermissionTypes } from "src/constants/accessRolesAndPermissions";

export const generateCompanyLevelPermissions = (
  userRoleId: number,
  companyId: number,
) => {
  return [
    {
      permission_type_id:
        userRoleId === 2 // if admin that full access, if user - read access
          ? hardcodedPermissionTypes.full
          : hardcodedPermissionTypes.read,
      entity_type_id: Number(hardcodedEntityTypes.company),
      entity_id: companyId,
    },
  ];
};

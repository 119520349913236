import { createSlice } from "@reduxjs/toolkit";
import { fetchAllUsers, addUser } from "../../../thunks/admin";
import { allUsersAdapter } from "./adapter";
import {
  Ii18nError,
  apiErrorToI18nError,
} from "src/redux/interfaces/Ii18nError";

const slice = createSlice({
  name: "allUsers",
  initialState: {
    ...allUsersAdapter.getInitialState(),
    requestStatus: "initial",
    isLoading: false,
    error: null as null | Ii18nError,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllUsers.pending, (state) => {
      allUsersAdapter.removeAll(state);
      state.requestStatus = "pending";
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchAllUsers.rejected, (state, action) => {
      state.requestStatus = "rejected";
      state.isLoading = false;
      state.error = action.payload
        ? apiErrorToI18nError(action.payload)
        : { errorCode: "GENERIC" };
    });
    builder.addCase(fetchAllUsers.fulfilled, (state, { payload }) => {
      allUsersAdapter.setMany(state, payload);
      state.requestStatus = "fulfilled";
      state.isLoading = false;
    });
    builder.addCase(addUser.fulfilled, (state, { payload }) => {
      allUsersAdapter.upsertOne(state, payload);
    });
  },
});

export default slice;

import React from "react";
import { Box } from "@chakra-ui/react";
import MultiColorBar from "./MultiColorBar";
import { globalMinYear, globalMaxYear } from "src/constants";
import { SliderWithLine } from "./SliderWithLine";
import {
  getColorForGivenYear,
  getMultiColorBarCategories,
} from "./sliderHelpers";

interface IProps {
  year: number;
  setYear: (year: number) => void;
  minYear: number;
  maxYear?: number;
  normalizedStrandingYear: number;
  holdingYears?: number;
}

export const SliderWithHoldingYears: React.FC<IProps> = ({
  year,
  setYear,
  minYear,
  maxYear = globalMaxYear,
  normalizedStrandingYear,
  holdingYears = 0,
}) => {
  const holdingYearPoint = Math.max(
    minYear || globalMinYear,
    normalizedStrandingYear - holdingYears,
  );

  const color =
    getColorForGivenYear(year, normalizedStrandingYear, holdingYears) ||
    "gray.500";

  return (
    <Box data-testid="SliderWithHoldingYears">
      <Box mb="-1rem">
        <MultiColorBar
          categories={getMultiColorBarCategories(
            holdingYearPoint,
            normalizedStrandingYear,
            minYear,
          )}
        />
      </Box>
      <SliderWithLine
        currentValue={year}
        min={minYear}
        max={maxYear}
        onChange={setYear}
        pointColor={color}
      />
    </Box>
  );
};

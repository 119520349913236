import { parseScenariosExcel } from "./parseScenariosExcel";
import {
  upsertInterventions,
  saveScenariosFileRef,
  submitScenariosFile,
} from "src/redux/thunks/scenarios";
import { formatExcelFile } from "src/common/utils/files/formatExcelFile";
import { genErrorMessageOutsideRedux } from "src/common/utils/errors";
import i18next from "i18next";

export const uploadScenario = async (
  file: File,
  assetId: number,
  portfolioId: number,
  t: typeof i18next.t,
) => {
  // Parse excel data
  const filedata = await file.arrayBuffer();
  const { scenarios, errors: parsingErrors } = parseScenariosExcel(filedata);
  if (parsingErrors.length)
    return parsingErrors.map(
      (error) =>
        `Error in scenario ${error.scenarioName}: ${error.errors.join(", ")}`,
    );

  try {
    // Submit scenarios data
    await upsertInterventions({ assetId: assetId, scenarios });
  } catch (error) {
    const message = genErrorMessageOutsideRedux(
      // @ts-expect-error error type unknown
      error?.response?.data?.errorCode ? error?.response?.data : error,
      t,
      "Cannot save scenario data: ",
    );

    return [message];
  }

  try {
    //update file name with last modified date for asset file ref
    const uploadFile = formatExcelFile(file);

    // Save File
    await submitScenariosFile(uploadFile.file, assetId.toString());
    await saveScenariosFileRef(
      assetId.toString(),
      portfolioId.toString(),
      `${assetId}/${uploadFile.file.name}`,
      uploadFile.file,
      uploadFile.lastMod,
    );
  } catch (error) {
    const message = genErrorMessageOutsideRedux(
      // @ts-expect-error error type unknown
      error?.response?.data?.errorCode ? error?.response?.data : error,
      t,
      "Cannot save scenario file: ",
    );

    return [message];
  }
};

import { EntityState, createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { IAppAuthData } from "src/redux/interfaces/IAppAuthData";
import { authInfoAdapter } from "./adapter";
import { selectSlice as selectAssetsSlice } from "../../portfolios/assets/selectors";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { assetAdapter } from "../../portfolios/assets/adapter";

const selectSlice = (state: RootState) => state.authUserData;

const _checkIsAppOrSystemAdmin = (data: IAppAuthData) =>
  Boolean(data.isAppAdmin || data.isSystemAdmin);

const _getMyRecord = (state: EntityState<IAppAuthData>) =>
  authInfoAdapter.getSelectors().selectAll(state)[0] || {};

export const selectAuthData = createDraftSafeSelector(
  [selectSlice],
  _getMyRecord,
);

export const selectCanEditAppUsers = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<IAppAuthData>) =>
    _checkIsAppOrSystemAdmin(_getMyRecord(state)),
);

export const selectCanAddCompany = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<IAppAuthData>) =>
    _checkIsAppOrSystemAdmin(_getMyRecord(state)),
);

export const selectCanAccessAppAdminLevel = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<IAppAuthData>) =>
    _checkIsAppOrSystemAdmin(_getMyRecord(state)),
);

export const selectAmISystemAdmin = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<IAppAuthData>) =>
    Boolean(_getMyRecord(state).isSystemAdmin),
);

export const selectCompanyEditPermission = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<IAppAuthData>) => {
    const me = _getMyRecord(state);
    // check if we're admin of any company
    return me.permissions?.filter(
      (p) =>
        p.entity_type_name === "company" &&
        (p.permission_type_name === "full" ||
          p.permission_type_name === "write"),
    );
  },
);

export const selectPortfolioEditPermission = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<IAppAuthData>) => {
    const me = _getMyRecord(state);
    return me.permissions?.filter(
      (p) =>
        (p.entity_type_name === "company" &&
          (p.permission_type_name === "full" ||
            p.permission_type_name === "write")) ||
        (p.entity_type_name === "portfolio" &&
          (p.permission_type_name === "full" ||
            p.permission_type_name === "write")),
    );
  },
);

export const selectCanIEditOpenedPortfolio = createDraftSafeSelector(
  [selectSlice, selectAssetsSlice],
  (state: EntityState<IAppAuthData>, assetsSlice: EntityState<IAppAsset>) => {
    const isAppAdmin = _checkIsAppOrSystemAdmin(_getMyRecord(state));
    if (isAppAdmin) return true;

    const anyAsset = assetAdapter.getSelectors().selectAll(assetsSlice)[0];
    if (!anyAsset) return false;

    const me = _getMyRecord(state);
    // check if we're admin of this company
    const amICompanyAdmin = !!me.permissions?.find(
      (p) =>
        p.entity_type_name === "company" &&
        p.entity_id === anyAsset.companyId &&
        (p.permission_type_name === "full" ||
          p.permission_type_name === "write"),
    );
    if (amICompanyAdmin) return true;

    return !!me.permissions?.find(
      (p) =>
        p.entity_type_name === "portfolio" &&
        p.entity_id === anyAsset.portfolioId &&
        (p.permission_type_name === "full" ||
          p.permission_type_name === "write"),
    );
  },
);

export const selectCanIGenerateReport = createDraftSafeSelector(
  [selectSlice, selectAssetsSlice],
  (state: EntityState<IAppAuthData>, assetsSlice: EntityState<IAppAsset>) => {
    const isAppAdmin = _checkIsAppOrSystemAdmin(_getMyRecord(state));
    if (isAppAdmin) return true;

    const anyAsset = assetAdapter.getSelectors().selectAll(assetsSlice)[0];
    if (!anyAsset) return false;

    const me = _getMyRecord(state);

    return !!me.permissions?.find(
      (p) =>
        p.entity_type_name === "company" &&
        p.entity_id === anyAsset.companyId &&
        p.permission_type_name === "full",
    );
  },
);

import React from "react";
import { Table, Th, Td, Flex, Box } from "@chakra-ui/react";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { AssetsTableHeader } from "./components/AssetsTableHeader";
import { AssetsTableBody } from "./components/AssetsTableBody";
import { closest50 } from "src/common/utils/math";
import { getCapexReferences } from "../../molecules/PerformanceTable/getReference";
import { riskToColor } from "src/common/utils/colors/colors";
import { getCapexPerformance } from "src/common/utils/getCapexPerformance";
interface IProps {
  assets: IAppAsset[];
  selectedAssetIds: number[];
  units: string;
}

const CustomTds: React.FC<{
  asset: IAppAsset;
  units: string;
}> = ({ asset, units }) => {
  const { capex } = asset;
  const decarbCapex = closest50(capex?.capex_cost_decarb_per_meter || 0);
  const endOfLifeCapex = closest50(capex?.capex_cost_oel_per_meter || 0);
  const totalCapex = closest50(decarbCapex + endOfLifeCapex);

  return (
    <>
      <Td>
        {totalCapex.toLocaleString()} {units}
      </Td>
      <Td>
        {endOfLifeCapex.toLocaleString()} {units}
      </Td>
      <Td>
        {decarbCapex.toLocaleString()} {units}
      </Td>
      <Td>
        <Flex
          justifyContent="start"
          align="center"
          w="100%"
          margin="auto"
          py="5%"
        >
          <Box
            backgroundColor={riskToColor(
              2 -
                getCapexPerformance(
                  capex?.capex_cost_decarb_per_meter || 0,
                  getCapexReferences({
                    buildingType: asset.primaryBusinessBuildingType,
                    locationName: asset.location.businessLocationName,
                  }),
                ),
            )}
            boxSize="1.2rem"
            borderRadius="50%"
          />
        </Flex>
      </Td>
    </>
  );
};

export const EconomicAssetsList: React.FC<IProps> = ({
  assets,
  units,
  selectedAssetIds,
}) => {
  return (
    <Table variant="simple" width="100%" backgroundColor="white">
      <AssetsTableHeader
        selectedAssetIds={selectedAssetIds}
        filteredAssetsCnt={assets.length}
      >
        <Th>Total CAPEX</Th>
        <Th>Business as Usual CAPEX</Th>
        <Th>Decarb. CAPEX</Th>
        <Th>Cost</Th>
      </AssetsTableHeader>
      <AssetsTableBody
        assets={assets}
        selectedAssetIds={selectedAssetIds}
        tabName="economic"
        renderCustomTds={(asset) => <CustomTds asset={asset} units={units} />}
      />
    </Table>
  );
};

export default EconomicAssetsList;

import { Button, Flex } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import React, { useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  Link as RouterLink,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ErrorNotFound } from "src/common/components/molecules/ErrorNotFound";
import { TabsNavigation } from "src/common/components/molecules/TabsNavigation";
import { StandardLayout } from "src/common/components/templates/StandardLayout";
import { AssetSidebar } from "./components/AssetSidebar";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  selectIsAssetDataRequested,
  selectIsAssetsDataLoading,
  selectIsAssetsDataReady,
} from "src/redux/slices/portfolios/assets/selectors";
import { selectOpenedAsset } from "src/redux/slices/portfolios/assets/openedAssetSelectors";
import { setAssetSelected } from "src/redux/slices/portfolios/assets/slice";
import { fetchAssets } from "src/redux/thunks/assets";
import { AssetStrandingTab } from "./tabs/StrandingTab";
import { EconomicAssetTab } from "./tabs/Economic/EconomicAssetTab";
import { InefficientAssetsTab } from "./tabs/InefficientAssetTab";
import TaxonomyAssetTab from "./tabs/Taxonomy/TaxonomyAssetTab";
import SustainabilityAssetTab from "./tabs/SustainabilityAssetTab";
import { AssetScenarioTab } from "./tabs/Scenario/ScenarioPlanningTab";
import { LoadComponentPlaceholder } from "src/common/components/atoms/Spinner";
import { selectAssetsError } from "src/redux/slices/portfolios/assets/selectors";
import { genErrorMessage } from "src/common/utils/errors";
import { Alert } from "src/common/components/atoms/Alert";
import { setPortfolioSelected } from "src/redux/slices/portfolios/slice";
import { selectOpenedPortfolio } from "src/redux/slices/portfolios/selectors";
import { Units, getUnits } from "src/constants/measureUnits";
import { CustomPathwaysTab } from "./tabs/CustomPathways/CustomPathwaysTab";
import { selectTabList } from "src/redux/slices/featureConfig/selectors";
import { useCompany } from "src/common/components/organisms/CompanyProviderWrapper";

export const AssetView: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const company = useCompany();
  const asset = useAppSelector(selectOpenedAsset);
  const isLoading = useAppSelector(selectIsAssetsDataLoading);
  const isAssetDataReady = useAppSelector(selectIsAssetsDataReady);
  const isAssetDataRequested = useAppSelector(selectIsAssetDataRequested);
  const assetError = useAppSelector(selectAssetsError);
  const { t } = useTranslation();
  const portfolioId = Number(location.pathname.split("/")[1]);
  const assetId = location.pathname.split("/")[3];
  const selectedTab = location.pathname.split("/")[4];
  const openedPortfolio = useAppSelector(selectOpenedPortfolio);
  const tabs = useAppSelector(selectTabList);

  useEffect(() => {
    if (!isAssetDataReady && !isAssetDataRequested && !assetError) {
      dispatch(fetchAssets(portfolioId));
    }
  }, [
    dispatch,
    portfolioId,
    isAssetDataReady,
    isAssetDataRequested,
    assetError,
  ]);

  useEffect(() => {
    dispatch(setPortfolioSelected(portfolioId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioId, dispatch, isLoading]);

  useEffect(() => {
    if (
      openedPortfolio &&
      openedPortfolio.summary.companyId !== Number(company.setSelectedCompanyId)
    ) {
      company.setSelectedCompanyId(String(openedPortfolio.summary.companyId));
    }
  }, [portfolioId, company, openedPortfolio]);

  useEffect(() => {
    if (!asset || asset?.assetId !== Number(assetId))
      dispatch(setAssetSelected(Number(assetId)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId, dispatch, isLoading]);

  if (isLoading) {
    return <LoadComponentPlaceholder />;
  }

  if (!asset) {
    if (assetError) {
      return (
        <Flex justifyContent="center" alignItems="center" direction="column">
          <Alert
            isError
            message={genErrorMessage(assetError, t, "Cannot display asset. ")}
          />
          <RouterLink to={`/${portfolioId}/${selectedTab}`}>
            <Button
              as="span"
              colorScheme="red"
              variant="outlinePrimary"
              marginBottom="0.7rem"
              marginLeft={{ base: "30px", xl: "0" }}
              backgroundColor="white"
              mt="1rem"
            >
              <ArrowBackIcon fill="red.500" />
              Back to Portfolio page
            </Button>
          </RouterLink>
        </Flex>
      );
    }
    console.warn(
      `No asset with id=${assetId} is found, or it's loading. Ignore this message if the page isn't blank`,
    );
    return null;
  }

  return (
    <StandardLayout
      sideBar={
        <AssetSidebar
          asset={asset}
          isoCurrencyCode={openedPortfolio?.currency}
          measureSystem={openedPortfolio?.measureSystem}
        />
      }
      backUrl={`/${portfolioId}/${selectedTab}`}
      backUrlText="Portfolio Overview"
    >
      <TabsNavigation tabs={tabs} selectedTab={selectedTab} />
      <Routes>
        <Route
          path="/stranding"
          element={<AssetStrandingTab asset={asset} />}
        />
        <Route path="/economic" element={<EconomicAssetTab asset={asset} />} />
        <Route
          path="/inefficientAssets"
          element={
            <InefficientAssetsTab
              asset={asset}
              units={getUnits(
                Units.energyIntensity,
                openedPortfolio?.measureSystem,
              )}
            />
          }
        />
        <Route path="/taxonomy" element={<TaxonomyAssetTab asset={asset} />} />
        <Route
          path="/sustainability"
          element={<SustainabilityAssetTab asset={asset} />}
        />
        <Route path="/scenario" element={<AssetScenarioTab asset={asset} />} />
        <Route
          path="/customPathways"
          element={<CustomPathwaysTab asset={asset} />}
        />

        <Route
          path="/*"
          element={
            <Flex
              direction="column"
              justifyContent="center"
              minHeight="70vh"
              alignItems="center"
              width="100%"
            >
              <ErrorNotFound isFullPage={false} />
            </Flex>
          }
        />
      </Routes>
    </StandardLayout>
  );
};

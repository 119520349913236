import { Input as UIInput, FormControl, FormLabel } from "@chakra-ui/react";
import React from "react";
import { cleanText } from "src/common/utils/cleaners";

type TProps = {
  onBlur: (event: { name: string; value: string }) => void;
  placeholder?: string;
  label?: string;
  hasBorder?: boolean;
  backgroundColor?: string;
  variant?: "outline" | "solid" | "outlineMuted";
  type?: "name" | "text" | "address" | "email";
  isRequired?: boolean;
  value: string;
  name: string;
  maxLength?: number;
  fontSize?: string;
};

// This input is not controlled, so the value parameter is only read once when the input gets rendered.
export const FormInput: React.FC<TProps> = ({
  placeholder,
  onBlur,
  label,
  isRequired,
  variant,
  value,
  maxLength,
  name,
  type,
  fontSize,
}) => {
  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = cleanText(event.target.value);
    onBlur({ name: event.target.name, value });
  };
  return (
    <FormControl
      isRequired={isRequired}
      pb="0.2rem"
      fontSize="xs"
      color="gray.700"
      data-testid="FormInput"
    >
      {label ? <FormLabel>{label}</FormLabel> : null}
      <UIInput
        data-testid="Input"
        name={name}
        variant={variant || "outline"}
        defaultValue={value}
        type={type || "text"}
        maxLength={maxLength || 100}
        placeholder={placeholder}
        _placeholder={{
          textColor: "gray.400",
        }}
        fontSize={fontSize || "small"}
        onBlur={handleBlur}
      />
    </FormControl>
  );
};

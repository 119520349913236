import { createSlice } from "@reduxjs/toolkit";
import {
  addCompany,
  fetchCompanies,
  updateCompany,
} from "../../../thunks/companies";
import { companiesAdapter } from "./adapter";
import {
  Ii18nError,
  apiErrorToI18nError,
} from "src/redux/interfaces/Ii18nError";

const slice = createSlice({
  name: "companies",
  initialState: {
    ...companiesAdapter.getInitialState(),
    requestStatus: "initial",
    isLoading: false,
    error: null as null | Ii18nError,
    updateError: null as null | Ii18nError,
    addError: null as null | Ii18nError,
    selectedCompanyId: null as null | number,
  },
  reducers: {
    setCompanySelected: (state, { payload }) => {
      if (state.selectedCompanyId !== payload) {
        state.selectedCompanyId = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanies.pending, (state) => {
      companiesAdapter.removeAll(state);
      state.requestStatus = "pending";
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchCompanies.rejected, (state, action) => {
      state.requestStatus = "rejected";
      state.isLoading = false;
      state.error = action.payload
        ? apiErrorToI18nError(action.payload)
        : { errorCode: "GENERIC" };
    });
    builder.addCase(fetchCompanies.fulfilled, (state, { payload }) => {
      companiesAdapter.setMany(state, payload);
      state.requestStatus = "fulfilled";
      state.isLoading = false;
    });
    builder.addCase(updateCompany.pending, (state) => {
      state.updateError = null;
    });
    builder.addCase(updateCompany.fulfilled, (state, { payload }) => {
      companiesAdapter.upsertOne(state, payload);
    });
    builder.addCase(updateCompany.rejected, (state, { payload }) => {
      state.updateError = payload
        ? apiErrorToI18nError(payload)
        : { errorCode: "GENERIC" };
    });
    builder.addCase(addCompany.pending, (state) => {
      state.addError = null;
    });
    builder.addCase(addCompany.fulfilled, (state, { payload }) => {
      companiesAdapter.upsertOne(state, payload);
    });
    builder.addCase(addCompany.rejected, (state, { payload }) => {
      state.addError = payload
        ? apiErrorToI18nError(payload)
        : { errorCode: "GENERIC" };
    });
  },
});

export default slice;

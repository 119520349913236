import React from "react";
import { Table, Text, Tbody, Thead, Tfoot, Th, Tr, Td } from "@chakra-ui/react";
import { currencyCodeToSymbol } from "src/common/utils/units";
import { roundMonetary, sum } from "src/common/utils/math";
import { MeasureSystem } from "src/redux/interfaces/IAppPortfolio";
import { Units, getUnits } from "src/constants/measureUnits";
import { IAppIntervention } from "src/redux/interfaces/IAppIntervention";
import { formatMonetaryValue } from "../../utils/getTableDisplayValue";

type IProps = {
  interventions: IAppIntervention[];
  assetArea: number;
  isoCurrencyCode?: string;
  measureSystem?: MeasureSystem;
};

export const OpexTable: React.FC<IProps> = ({
  interventions,
  assetArea,
  isoCurrencyCode,
  measureSystem,
}) => {
  if (!interventions?.length) return <></>;
  const roundedOpex = interventions.map((intervention) => ({
    norm: Math.round((intervention.opexAbsolute || 0) / assetArea) + 0,
    abs: roundMonetary(
      Math.round((intervention.opexAbsolute || 0) / 100) * 100,
    ),
    interventionCode: intervention.interventionCode,
    interventionDescription: intervention.interventionDescription,
  }));
  const opexTotal = sum(roundedOpex.map((i) => i.abs));
  const opexNormalised = sum(roundedOpex.map((i) => i.norm));

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th lineHeight="1.2">Measure Description</Th>
          <Th lineHeight="1.2" textAlign="right">
            Yearly OPEX
            <br />
            <Text fontSize="0.8em">
              Variation&nbsp;Normalised,
              {`${currencyCodeToSymbol(isoCurrencyCode)}/${getUnits(
                Units.area,
                measureSystem,
              )}`}
            </Text>
          </Th>
          <Th lineHeight="1.2" textAlign="right">
            Yearly OPEX
            <br />
            <Text fontSize="0.8em">
              Variation&nbsp;Total,
              {currencyCodeToSymbol(isoCurrencyCode)}
            </Text>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {roundedOpex.map((intervention) => (
          <Tr key={intervention.interventionCode}>
            <Td>
              {intervention.interventionCode}:{" "}
              {intervention.interventionDescription}
            </Td>
            <Td isNumeric>
              {!intervention.norm
                ? "-"
                : formatMonetaryValue(intervention.norm, 2, 2)}
            </Td>
            <Td isNumeric>{formatMonetaryValue(intervention.abs)}</Td>
          </Tr>
        ))}
      </Tbody>
      <Tfoot>
        <Tr>
          <Th color="black">Total</Th>
          <Th color="black" isNumeric>
            {formatMonetaryValue(opexNormalised, 2, 2)}
          </Th>
          <Th color="black" isNumeric>
            {formatMonetaryValue(opexTotal)}
          </Th>
        </Tr>
      </Tfoot>
    </Table>
  );
};

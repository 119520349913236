import React from "react";
import { Box, Text, VStack, Grid, GridItem } from "@chakra-ui/react";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { Performance } from "src/common/components/molecules/PerformanceTable/references";
import { riskToColor } from "src/common/utils/colors/colors";
import { MiniDoughnutChart } from "src/common/components/atoms/Chart/DoughnutChart/MiniDoughnut";
import { theme } from "src/config/theme";
import { minEmissionsTargetlabel } from "src/constants/targetLabels";
import { Risk as RiskLevels } from "../../../common/components/molecules/PerformanceTable/references";

interface IProps {
  asset: IAppAsset;
}

const Risk = ["Low", "Medium", "High"];

const getRiskText = (strandingRisk: Performance) => {
  if (Math.round(strandingRisk) === Performance.Low) {
    return "Within the next 10 years this asset is expected to emit less CO₂ per year, in comparison with the 1.5°C target pathway.";
  } else if (Math.round(strandingRisk) === Performance.Medium) {
    return "Within the next 5-10 years this asset is expected to exceed the yearly CO₂ emission allowance, in comparison with the 1.5°C target pathway.";
  } else {
    return "Within the next 5 years this asset is expected to exceed the yearly CO₂ emission allowance, in comparison with the 1.5°C target pathway.";
  }
};

const getWeightedRiskText = (weightedRisk?: 0 | 1 | 2) => {
  if (weightedRisk === undefined) {
    return {
      title: "No data.",
      description:
        " Weigthed risk value cannot be calculated for this asset. Please check input data.",
    };
  } else if (Math.round(weightedRisk) === RiskLevels.Low) {
    return {
      title: "There is no immediate action necessary.",
      description:
        " With consideration of the selected weighting factor the asset risk is comparably low considering to the overall portfolio risk.",
    };
  } else if (Math.round(weightedRisk) === RiskLevels.Medium) {
    return {
      title: "This asset should be further investigated on.",
      description:
        "With consideration of the selected weighting factor the asset risk is comparably medium considering to the overall portfolio risk.",
    };
  } else {
    return {
      title: "This asset should be prioritized.",
      description:
        " With consideration of the selected weighting factor the asset risk is comparably high considering to the overall portfolio risk.",
    };
  }
};

export const AssetStrandingSummary: React.FC<IProps> = ({ asset }) => {
  const shareOfGhg = Math.floor((asset.shareOfPortfolioGHG || 0) * 100);
  const shareOfTotal = asset.shareOfPortfolioValue
    ? Math.floor(asset.shareOfPortfolioValue * 100)
    : null;
  const stranding15 = asset.ghgResults.targets.find(
    (target) => target.targetName === minEmissionsTargetlabel,
  );
  const strandingRisk = stranding15?.strandingRisk;
  const weightedRisk = stranding15?.strandingRiskWeighted;

  const weightedStrandingRiskContent = getWeightedRiskText(weightedRisk);

  return (
    <VStack fontSize="small">
      <Box w="100%">
        <Grid templateColumns="repeat(12, 1fr)" pt="5%">
          <GridItem colSpan={{ base: 4, sm: 7 }}>
            <Text fontWeight="bold">Stranding Risk</Text>
          </GridItem>
          <GridItem
            h="100%"
            colSpan={{ base: 4, sm: 4 }}
            display="flex"
            alignItems="center"
          >
            <Box
              backgroundColor={riskToColor(strandingRisk)}
              boxSize="1rem"
              borderRadius="50%"
              style={{ aspectRatio: "1 / 1" }}
            />
            <Text
              fontSize={"14px"}
              ml="10px"
              color={riskToColor(strandingRisk)}
            >
              {Risk[strandingRisk as number]}
            </Text>
          </GridItem>
        </Grid>
        <Text color={theme.colors.gray["500"]} fontSize="0.9em" pt="2%" pb="3%">
          {getRiskText(strandingRisk as 0 | 1 | 2)}
        </Text>

        <Text
          fontWeight="bold"
          textAlign="left"
          mb={2.3}
          borderTop="1px solid"
          pt="5%"
        >
          Weighting Factors
        </Text>
        <Grid templateColumns="repeat(12, 1fr)" pt="2%">
          <GridItem
            h="100%"
            colSpan={{ base: 3, sm: 3 }}
            display="flex"
            alignItems="center"
          >
            <MiniDoughnutChart value={shareOfGhg} />
          </GridItem>
          <GridItem h="100%" colSpan={{ base: 5, sm: 9 }}>
            <Text>Share of Portfolio GHG Emissions</Text>
          </GridItem>
        </Grid>
        <Grid
          templateColumns="repeat(12, 1fr)"
          borderBottom="1px solid"
          pt="2%"
          pb="5%"
        >
          <GridItem h="100%" colSpan={{ base: 3, sm: 3 }} display="flex">
            {shareOfTotal ? (
              <MiniDoughnutChart value={shareOfTotal} />
            ) : (
              <Text color={theme.colors.gray["500"]}>Not Provided</Text>
            )}
          </GridItem>
          <GridItem h="100%" colSpan={{ base: 5, sm: 9 }}>
            <Text>Share of Total Portfolio Value</Text>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(12, 1fr)" pt="5%">
          <GridItem colSpan={{ base: 4, sm: 7 }}>
            <Text fontWeight="bold" textAlign="left">
              Weighted Stranding Risk
            </Text>
          </GridItem>
          <GridItem
            h="100%"
            colSpan={{ base: 4, sm: 4 }}
            display="flex"
            alignItems="center"
          >
            <Box
              backgroundColor={riskToColor(weightedRisk)}
              boxSize="1rem"
              borderRadius="50%"
              style={{ aspectRatio: "1 / 1" }}
            />
            <Text fontSize={"14px"} ml="10px" color={riskToColor(weightedRisk)}>
              {(weightedRisk !== undefined && Risk[weightedRisk]) ||
                "Not provided"}
            </Text>
          </GridItem>
        </Grid>
        <Text color={riskToColor(weightedRisk)} fontSize="0.9em" pt="3%" pb="0">
          {weightedStrandingRiskContent.title}
        </Text>
        <Text color={theme.colors.gray["500"]} fontSize="0.9em" pt="0" pb="6%">
          {weightedStrandingRiskContent.description}
        </Text>
      </Box>
    </VStack>
  );
};

import { Box, GridItem, Grid } from "@chakra-ui/react";
import React from "react";
import { TaxonomyCheckList } from "src/common/components/organisms/AssetList/TaxanomyCheckList";
import { useAppSelector } from "src/redux/hooks";
import {
  selectFilteredAndSelectedAssets,
  selectFilteredAssets,
  selectIsAssetsDataLoading,
} from "src/redux/slices/portfolios/assets/selectors";
import { selectSelectedAssetIds } from "src/redux/slices/uiState/assetsFilter/selectors";
import TaxonomyLegend from "src/pages/PortfolioView/components/Taxonomy/TaxonomyLegend";
import TaxonomyCard from "../components/Taxonomy/TaxonomyCard";

import { ReactComponent as AdaptionIcon } from "src/assets/icons/Tax_Adaptation.svg";
import { ReactComponent as BiodiversityIcon } from "src/assets/icons/Tax_Biodiversity.svg";
import { ReactComponent as CircularIcon } from "src/assets/icons/Tax_Circular.svg";
import { ReactComponent as MitigationIcon } from "src/assets/icons/Tax_Mitigation.svg";
import { ReactComponent as PollutionIcon } from "src/assets/icons/Tax_Pollution.svg";
import { ReactComponent as WaterIcon } from "src/assets/icons/Tax_Water.svg";
import { LoadComponentPlaceholder } from "src/common/components/atoms/Spinner";

export const TaxonomyCheckTab: React.FC = () => {
  const selectedAssetIds = useAppSelector(selectSelectedAssetIds);
  const filteredAssets = useAppSelector(selectFilteredAssets);
  const selectedAssets = useAppSelector(selectFilteredAndSelectedAssets);
  const isLoading = useAppSelector(selectIsAssetsDataLoading);

  if (isLoading) {
    return <LoadComponentPlaceholder />;
  }

  return (
    <>
      <Box backgroundColor="white" p="20px" borderRadius="8px" mb="16px">
        <TaxonomyLegend />
        <Grid h="100%" templateColumns="repeat(12, 1fr)" gap={4}>
          <GridItem h="100%" colSpan={{ base: 4, sm: 2 }}>
            <TaxonomyCard
              assets={selectedAssets}
              icon={MitigationIcon}
              label={"Climate Change Mitigation [CCM]"}
              category="ccm"
            />
          </GridItem>
          <GridItem h="100%" colSpan={{ base: 4, sm: 2 }}>
            <TaxonomyCard
              assets={selectedAssets}
              icon={AdaptionIcon}
              label={"Climate Change Adaptation [CCA]"}
              category="cca"
            />
          </GridItem>
          <GridItem h="100%" colSpan={{ base: 4, sm: 2 }}>
            <TaxonomyCard
              assets={selectedAssets}
              icon={CircularIcon}
              label={"Transition to Circular Economy [TCE]"}
              category="tce"
            />
          </GridItem>
          <GridItem h="100%" colSpan={{ base: 4, sm: 2 }}>
            <TaxonomyCard
              assets={selectedAssets}
              icon={WaterIcon}
              label={"Water Resource Management [WRM]"}
              category="wrm"
            />
          </GridItem>
          <GridItem h="100%" colSpan={{ base: 4, sm: 2 }}>
            <TaxonomyCard
              assets={selectedAssets}
              icon={PollutionIcon}
              label={"Pollution Control and Prevention [PCP]"}
              category="ppc"
            />
          </GridItem>
          <GridItem h="100%" colSpan={{ base: 4, sm: 2 }}>
            <TaxonomyCard
              assets={selectedAssets}
              icon={BiodiversityIcon}
              label={"Biodiversity and Ecosystems [BDE]"}
              category="bde"
            />
          </GridItem>
        </Grid>
      </Box>

      <Box
        pt="15px"
        backgroundColor="white"
        width="100%"
        borderRadius="8px 8px 0 0"
      >
        <TaxonomyCheckList
          selectedAssetIds={selectedAssetIds}
          assets={filteredAssets}
        />
      </Box>
    </>
  );
};

import { IBenchmarks, ILibrary, IThreshold, IThresholds } from "./references";

const Performance = {
  Low: 0,
  Medium: 1,
  High: 2,
};

export const benchmarkReferenceLibrary: ILibrary<IBenchmarks> = {
  default: {
    default: {
      capex: {
        [Performance.Low]: 1000,
        [Performance.Medium]: 600,
        [Performance.High]: 200,
      },
    },
  },
  Albania: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 400,
        [Performance.Medium]: 240,
        [Performance.High]: 80,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 370,
        [Performance.Medium]: 220,
        [Performance.High]: 80,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 290,
        [Performance.Medium]: 180,
        [Performance.High]: 60,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 230,
        [Performance.Medium]: 140,
        [Performance.High]: 50,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 210,
        [Performance.Medium]: 130,
        [Performance.High]: 40,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 220,
        [Performance.Medium]: 130,
        [Performance.High]: 50,
      },
    },
  },
  Austria: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 990,
        [Performance.Medium]: 600,
        [Performance.High]: 190,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 940,
        [Performance.Medium]: 550,
        [Performance.High]: 190,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 730,
        [Performance.Medium]: 440,
        [Performance.High]: 150,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 580,
        [Performance.Medium]: 350,
        [Performance.High]: 110,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 520,
        [Performance.Medium]: 320,
        [Performance.High]: 100,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 540,
        [Performance.Medium]: 320,
        [Performance.High]: 110,
      },
    },
  },
  Australia: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 700,
        [Performance.Medium]: 420,
        [Performance.High]: 130,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 660,
        [Performance.Medium]: 390,
        [Performance.High]: 130,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 510,
        [Performance.Medium]: 310,
        [Performance.High]: 100,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 400,
        [Performance.Medium]: 240,
        [Performance.High]: 80,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 360,
        [Performance.Medium]: 220,
        [Performance.High]: 70,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 380,
        [Performance.Medium]: 220,
        [Performance.High]: 80,
      },
    },
  },
  Belgium: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 840,
        [Performance.Medium]: 510,
        [Performance.High]: 160,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 800,
        [Performance.Medium]: 470,
        [Performance.High]: 160,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 620,
        [Performance.Medium]: 370,
        [Performance.High]: 120,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 490,
        [Performance.Medium]: 300,
        [Performance.High]: 100,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 440,
        [Performance.Medium]: 270,
        [Performance.High]: 90,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 460,
        [Performance.Medium]: 270,
        [Performance.High]: 100,
      },
    },
  },
  "Bosnia and Herzegovia": {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 360,
        [Performance.Medium]: 220,
        [Performance.High]: 70,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 340,
        [Performance.Medium]: 200,
        [Performance.High]: 70,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 270,
        [Performance.Medium]: 160,
        [Performance.High]: 50,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 210,
        [Performance.Medium]: 130,
        [Performance.High]: 40,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 190,
        [Performance.Medium]: 110,
        [Performance.High]: 40,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 200,
        [Performance.Medium]: 110,
        [Performance.High]: 40,
      },
    },
  },
  Bulgaria: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 460,
        [Performance.Medium]: 280,
        [Performance.High]: 90,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 430,
        [Performance.Medium]: 250,
        [Performance.High]: 90,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 340,
        [Performance.Medium]: 200,
        [Performance.High]: 70,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 270,
        [Performance.Medium]: 160,
        [Performance.High]: 50,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 240,
        [Performance.Medium]: 150,
        [Performance.High]: 50,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 250,
        [Performance.Medium]: 150,
        [Performance.High]: 50,
      },
    },
  },
  Croatia: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 430,
        [Performance.Medium]: 260,
        [Performance.High]: 80,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 410,
        [Performance.Medium]: 240,
        [Performance.High]: 80,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 320,
        [Performance.Medium]: 190,
        [Performance.High]: 60,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 250,
        [Performance.Medium]: 150,
        [Performance.High]: 50,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 230,
        [Performance.Medium]: 140,
        [Performance.High]: 40,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 240,
        [Performance.Medium]: 140,
        [Performance.High]: 50,
      },
    },
  },
  Cyprus: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 590,
        [Performance.Medium]: 360,
        [Performance.High]: 110,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 560,
        [Performance.Medium]: 330,
        [Performance.High]: 110,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 440,
        [Performance.Medium]: 260,
        [Performance.High]: 90,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 340,
        [Performance.Medium]: 210,
        [Performance.High]: 70,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 310,
        [Performance.Medium]: 190,
        [Performance.High]: 60,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 320,
        [Performance.Medium]: 190,
        [Performance.High]: 70,
      },
    },
  },
  Czechia: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 600,
        [Performance.Medium]: 360,
        [Performance.High]: 120,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 560,
        [Performance.Medium]: 330,
        [Performance.High]: 120,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 440,
        [Performance.Medium]: 270,
        [Performance.High]: 90,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 350,
        [Performance.Medium]: 210,
        [Performance.High]: 70,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 310,
        [Performance.Medium]: 190,
        [Performance.High]: 60,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 330,
        [Performance.Medium]: 190,
        [Performance.High]: 70,
      },
    },
  },
  Denmark: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 1180,
        [Performance.Medium]: 710,
        [Performance.High]: 230,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 1110,
        [Performance.Medium]: 660,
        [Performance.High]: 230,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 870,
        [Performance.Medium]: 520,
        [Performance.High]: 170,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 680,
        [Performance.Medium]: 420,
        [Performance.High]: 130,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 620,
        [Performance.Medium]: 380,
        [Performance.High]: 120,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 640,
        [Performance.Medium]: 380,
        [Performance.High]: 130,
      },
    },
  },
  Estonia: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 650,
        [Performance.Medium]: 390,
        [Performance.High]: 130,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 610,
        [Performance.Medium]: 360,
        [Performance.High]: 130,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 480,
        [Performance.Medium]: 290,
        [Performance.High]: 100,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 380,
        [Performance.Medium]: 230,
        [Performance.High]: 70,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 340,
        [Performance.Medium]: 210,
        [Performance.High]: 70,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 360,
        [Performance.Medium]: 210,
        [Performance.High]: 70,
      },
    },
  },
  Finland: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 1290,
        [Performance.Medium]: 780,
        [Performance.High]: 250,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 1220,
        [Performance.Medium]: 720,
        [Performance.High]: 250,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 960,
        [Performance.Medium]: 570,
        [Performance.High]: 190,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 750,
        [Performance.Medium]: 460,
        [Performance.High]: 150,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 680,
        [Performance.Medium]: 410,
        [Performance.High]: 130,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 710,
        [Performance.Medium]: 410,
        [Performance.High]: 150,
      },
    },
  },
  France: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 890,
        [Performance.Medium]: 540,
        [Performance.High]: 170,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 840,
        [Performance.Medium]: 490,
        [Performance.High]: 170,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 660,
        [Performance.Medium]: 390,
        [Performance.High]: 130,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 520,
        [Performance.Medium]: 310,
        [Performance.High]: 100,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 460,
        [Performance.Medium]: 280,
        [Performance.High]: 90,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 480,
        [Performance.Medium]: 280,
        [Performance.High]: 100,
      },
    },
  },
  Germany: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 1260,
        [Performance.Medium]: 760,
        [Performance.High]: 250,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 1190,
        [Performance.Medium]: 710,
        [Performance.High]: 240,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 930,
        [Performance.Medium]: 560,
        [Performance.High]: 190,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 740,
        [Performance.Medium]: 440,
        [Performance.High]: 150,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 660,
        [Performance.Medium]: 400,
        [Performance.High]: 130,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 690,
        [Performance.Medium]: 410,
        [Performance.High]: 140,
      },
    },
  },
  Greece: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 570,
        [Performance.Medium]: 340,
        [Performance.High]: 110,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 540,
        [Performance.Medium]: 320,
        [Performance.High]: 110,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 420,
        [Performance.Medium]: 250,
        [Performance.High]: 80,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 330,
        [Performance.Medium]: 200,
        [Performance.High]: 70,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 300,
        [Performance.Medium]: 180,
        [Performance.High]: 60,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 310,
        [Performance.Medium]: 180,
        [Performance.High]: 70,
      },
    },
  },
  Hungary: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 530,
        [Performance.Medium]: 320,
        [Performance.High]: 100,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 500,
        [Performance.Medium]: 290,
        [Performance.High]: 100,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 390,
        [Performance.Medium]: 230,
        [Performance.High]: 80,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 310,
        [Performance.Medium]: 190,
        [Performance.High]: 60,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 280,
        [Performance.Medium]: 170,
        [Performance.High]: 50,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 290,
        [Performance.Medium]: 170,
        [Performance.High]: 60,
      },
    },
  },
  Iceland: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 1210,
        [Performance.Medium]: 730,
        [Performance.High]: 230,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 1150,
        [Performance.Medium]: 680,
        [Performance.High]: 230,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 900,
        [Performance.Medium]: 540,
        [Performance.High]: 180,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 700,
        [Performance.Medium]: 430,
        [Performance.High]: 140,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 630,
        [Performance.Medium]: 390,
        [Performance.High]: 120,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 660,
        [Performance.Medium]: 390,
        [Performance.High]: 140,
      },
    },
  },
  Ireland: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 950,
        [Performance.Medium]: 570,
        [Performance.High]: 180,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 900,
        [Performance.Medium]: 530,
        [Performance.High]: 180,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 700,
        [Performance.Medium]: 420,
        [Performance.High]: 140,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 550,
        [Performance.Medium]: 330,
        [Performance.High]: 110,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 500,
        [Performance.Medium]: 300,
        [Performance.High]: 100,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 520,
        [Performance.Medium]: 300,
        [Performance.High]: 110,
      },
    },
  },
  Italy: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 720,
        [Performance.Medium]: 430,
        [Performance.High]: 140,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 680,
        [Performance.Medium]: 400,
        [Performance.High]: 140,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 900,
        [Performance.Medium]: 500,
        [Performance.High]: 300,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 420,
        [Performance.Medium]: 250,
        [Performance.High]: 80,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 380,
        [Performance.Medium]: 230,
        [Performance.High]: 70,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 390,
        [Performance.Medium]: 230,
        [Performance.High]: 80,
      },
    },
  },
  Latvia: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 610,
        [Performance.Medium]: 370,
        [Performance.High]: 120,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 570,
        [Performance.Medium]: 340,
        [Performance.High]: 120,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 450,
        [Performance.Medium]: 270,
        [Performance.High]: 90,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 350,
        [Performance.Medium]: 210,
        [Performance.High]: 70,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 320,
        [Performance.Medium]: 190,
        [Performance.High]: 60,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 330,
        [Performance.Medium]: 190,
        [Performance.High]: 70,
      },
    },
  },
  Lithuania: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 590,
        [Performance.Medium]: 360,
        [Performance.High]: 110,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 560,
        [Performance.Medium]: 330,
        [Performance.High]: 110,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 440,
        [Performance.Medium]: 260,
        [Performance.High]: 90,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 340,
        [Performance.Medium]: 210,
        [Performance.High]: 70,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 310,
        [Performance.Medium]: 190,
        [Performance.High]: 60,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 320,
        [Performance.Medium]: 190,
        [Performance.High]: 70,
      },
    },
  },
  Luxembourg: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 970,
        [Performance.Medium]: 580,
        [Performance.High]: 190,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 910,
        [Performance.Medium]: 540,
        [Performance.High]: 190,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 720,
        [Performance.Medium]: 430,
        [Performance.High]: 140,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 560,
        [Performance.Medium]: 340,
        [Performance.High]: 110,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 510,
        [Performance.Medium]: 310,
        [Performance.High]: 100,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 530,
        [Performance.Medium]: 310,
        [Performance.High]: 110,
      },
    },
  },
  Malta: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 680,
        [Performance.Medium]: 410,
        [Performance.High]: 130,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 640,
        [Performance.Medium]: 380,
        [Performance.High]: 130,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 500,
        [Performance.Medium]: 300,
        [Performance.High]: 100,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 390,
        [Performance.Medium]: 240,
        [Performance.High]: 80,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 350,
        [Performance.Medium]: 220,
        [Performance.High]: 70,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 370,
        [Performance.Medium]: 220,
        [Performance.High]: 80,
      },
    },
  },
  Montenegro: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 420,
        [Performance.Medium]: 250,
        [Performance.High]: 80,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 400,
        [Performance.Medium]: 240,
        [Performance.High]: 80,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 310,
        [Performance.Medium]: 190,
        [Performance.High]: 60,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 240,
        [Performance.Medium]: 150,
        [Performance.High]: 50,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 220,
        [Performance.Medium]: 130,
        [Performance.High]: 40,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 230,
        [Performance.Medium]: 130,
        [Performance.High]: 50,
      },
    },
  },
  Netherlands: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 1060,
        [Performance.Medium]: 640,
        [Performance.High]: 210,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 1000,
        [Performance.Medium]: 590,
        [Performance.High]: 210,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 790,
        [Performance.Medium]: 470,
        [Performance.High]: 160,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 620,
        [Performance.Medium]: 380,
        [Performance.High]: 120,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 560,
        [Performance.Medium]: 340,
        [Performance.High]: 110,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 580,
        [Performance.Medium]: 340,
        [Performance.High]: 120,
      },
    },
  },
  "North Macedonia": {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 330,
        [Performance.Medium]: 200,
        [Performance.High]: 60,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 310,
        [Performance.Medium]: 180,
        [Performance.High]: 60,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 240,
        [Performance.Medium]: 140,
        [Performance.High]: 50,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 190,
        [Performance.Medium]: 110,
        [Performance.High]: 40,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 170,
        [Performance.Medium]: 100,
        [Performance.High]: 30,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 180,
        [Performance.Medium]: 100,
        [Performance.High]: 40,
      },
    },
  },
  Norway: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 1320,
        [Performance.Medium]: 800,
        [Performance.High]: 260,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 1250,
        [Performance.Medium]: 740,
        [Performance.High]: 260,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 980,
        [Performance.Medium]: 590,
        [Performance.High]: 200,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 770,
        [Performance.Medium]: 470,
        [Performance.High]: 150,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 690,
        [Performance.Medium]: 420,
        [Performance.High]: 140,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 720,
        [Performance.Medium]: 420,
        [Performance.High]: 150,
      },
    },
  },
  Poland: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 570,
        [Performance.Medium]: 340,
        [Performance.High]: 110,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 540,
        [Performance.Medium]: 320,
        [Performance.High]: 110,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 420,
        [Performance.Medium]: 250,
        [Performance.High]: 80,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 330,
        [Performance.Medium]: 200,
        [Performance.High]: 70,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 300,
        [Performance.Medium]: 180,
        [Performance.High]: 60,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 310,
        [Performance.Medium]: 180,
        [Performance.High]: 70,
      },
    },
  },
  Portugal: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 550,
        [Performance.Medium]: 330,
        [Performance.High]: 110,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 520,
        [Performance.Medium]: 310,
        [Performance.High]: 110,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 410,
        [Performance.Medium]: 250,
        [Performance.High]: 80,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 320,
        [Performance.Medium]: 200,
        [Performance.High]: 60,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 290,
        [Performance.Medium]: 180,
        [Performance.High]: 60,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 300,
        [Performance.Medium]: 180,
        [Performance.High]: 60,
      },
    },
  },
  Romania: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 410,
        [Performance.Medium]: 250,
        [Performance.High]: 80,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 390,
        [Performance.Medium]: 230,
        [Performance.High]: 80,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 310,
        [Performance.Medium]: 180,
        [Performance.High]: 60,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 240,
        [Performance.Medium]: 150,
        [Performance.High]: 50,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 220,
        [Performance.Medium]: 130,
        [Performance.High]: 40,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 230,
        [Performance.Medium]: 130,
        [Performance.High]: 50,
      },
    },
  },
  Serbia: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 430,
        [Performance.Medium]: 260,
        [Performance.High]: 80,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 410,
        [Performance.Medium]: 240,
        [Performance.High]: 80,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 320,
        [Performance.Medium]: 190,
        [Performance.High]: 60,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 250,
        [Performance.Medium]: 150,
        [Performance.High]: 50,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 230,
        [Performance.Medium]: 140,
        [Performance.High]: 40,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 240,
        [Performance.Medium]: 140,
        [Performance.High]: 50,
      },
    },
  },
  Slovakia: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 640,
        [Performance.Medium]: 390,
        [Performance.High]: 120,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 610,
        [Performance.Medium]: 360,
        [Performance.High]: 120,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 470,
        [Performance.Medium]: 280,
        [Performance.High]: 90,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 370,
        [Performance.Medium]: 230,
        [Performance.High]: 70,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 340,
        [Performance.Medium]: 200,
        [Performance.High]: 70,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 350,
        [Performance.Medium]: 200,
        [Performance.High]: 70,
      },
    },
  },
  Slovenia: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 560,
        [Performance.Medium]: 340,
        [Performance.High]: 110,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 530,
        [Performance.Medium]: 310,
        [Performance.High]: 110,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 420,
        [Performance.Medium]: 250,
        [Performance.High]: 80,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 330,
        [Performance.Medium]: 200,
        [Performance.High]: 60,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 290,
        [Performance.Medium]: 180,
        [Performance.High]: 60,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 310,
        [Performance.Medium]: 180,
        [Performance.High]: 60,
      },
    },
  },
  Spain: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 700,
        [Performance.Medium]: 420,
        [Performance.High]: 130,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 660,
        [Performance.Medium]: 390,
        [Performance.High]: 130,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 510,
        [Performance.Medium]: 310,
        [Performance.High]: 100,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 400,
        [Performance.Medium]: 240,
        [Performance.High]: 80,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 360,
        [Performance.Medium]: 220,
        [Performance.High]: 70,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 380,
        [Performance.Medium]: 220,
        [Performance.High]: 80,
      },
    },
  },
  Sweden: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 1270,
        [Performance.Medium]: 760,
        [Performance.High]: 240,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 1200,
        [Performance.Medium]: 710,
        [Performance.High]: 240,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 940,
        [Performance.Medium]: 560,
        [Performance.High]: 190,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 730,
        [Performance.Medium]: 450,
        [Performance.High]: 140,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 660,
        [Performance.Medium]: 400,
        [Performance.High]: 130,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 690,
        [Performance.Medium]: 400,
        [Performance.High]: 140,
      },
    },
  },
  Switzerland: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 1540,
        [Performance.Medium]: 930,
        [Performance.High]: 300,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 1450,
        [Performance.Medium]: 860,
        [Performance.High]: 300,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 1140,
        [Performance.Medium]: 680,
        [Performance.High]: 230,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 890,
        [Performance.Medium]: 540,
        [Performance.High]: 180,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 810,
        [Performance.Medium]: 490,
        [Performance.High]: 160,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 840,
        [Performance.Medium]: 490,
        [Performance.High]: 180,
      },
    },
  },
  Turkey: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 330,
        [Performance.Medium]: 200,
        [Performance.High]: 60,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 310,
        [Performance.Medium]: 180,
        [Performance.High]: 60,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 240,
        [Performance.Medium]: 140,
        [Performance.High]: 50,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 190,
        [Performance.Medium]: 110,
        [Performance.High]: 40,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 170,
        [Performance.Medium]: 100,
        [Performance.High]: 30,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 180,
        [Performance.Medium]: 100,
        [Performance.High]: 40,
      },
    },
  },
  "United Kingdom": {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 910,
        [Performance.Medium]: 550,
        [Performance.High]: 180,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 850,
        [Performance.Medium]: 500,
        [Performance.High]: 180,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 670,
        [Performance.Medium]: 400,
        [Performance.High]: 130,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 530,
        [Performance.Medium]: 320,
        [Performance.High]: 100,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 470,
        [Performance.Medium]: 290,
        [Performance.High]: 90,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 490,
        [Performance.Medium]: 290,
        [Performance.High]: 100,
      },
    },
  },

  Canada: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 990,
        [Performance.Medium]: 600,
        [Performance.High]: 190,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 940,
        [Performance.Medium]: 550,
        [Performance.High]: 190,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 730,
        [Performance.Medium]: 440,
        [Performance.High]: 150,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 580,
        [Performance.Medium]: 350,
        [Performance.High]: 110,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 520,
        [Performance.Medium]: 320,
        [Performance.High]: 100,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 540,
        [Performance.Medium]: 320,
        [Performance.High]: 110,
      },
    },
  },
  "United States": {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 840,
        [Performance.Medium]: 510,
        [Performance.High]: 160,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 800,
        [Performance.Medium]: 470,
        [Performance.High]: 160,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 620,
        [Performance.Medium]: 370,
        [Performance.High]: 120,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 490,
        [Performance.Medium]: 300,
        [Performance.High]: 100,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 440,
        [Performance.Medium]: 270,
        [Performance.High]: 90,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 460,
        [Performance.Medium]: 270,
        [Performance.High]: 100,
      },
    },
  },
  Japan: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 1260,
        [Performance.Medium]: 760,
        [Performance.High]: 250,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 1190,
        [Performance.Medium]: 710,
        [Performance.High]: 240,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 930,
        [Performance.Medium]: 560,
        [Performance.High]: 190,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 740,
        [Performance.Medium]: 440,
        [Performance.High]: 150,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 660,
        [Performance.Medium]: 400,
        [Performance.High]: 130,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 690,
        [Performance.Medium]: 410,
        [Performance.High]: 140,
      },
    },
  },
  "Hong Kong": {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 570,
        [Performance.Medium]: 340,
        [Performance.High]: 110,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 540,
        [Performance.Medium]: 320,
        [Performance.High]: 110,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 420,
        [Performance.Medium]: 250,
        [Performance.High]: 80,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 330,
        [Performance.Medium]: 200,
        [Performance.High]: 70,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 300,
        [Performance.Medium]: 180,
        [Performance.High]: 60,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 310,
        [Performance.Medium]: 180,
        [Performance.High]: 70,
      },
    },
  },
  China: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 530,
        [Performance.Medium]: 320,
        [Performance.High]: 100,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 500,
        [Performance.Medium]: 290,
        [Performance.High]: 100,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 390,
        [Performance.Medium]: 230,
        [Performance.High]: 80,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 310,
        [Performance.Medium]: 190,
        [Performance.High]: 60,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 280,
        [Performance.Medium]: 170,
        [Performance.High]: 50,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 290,
        [Performance.Medium]: 170,
        [Performance.High]: 60,
      },
    },
  },
  Singapore: {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 950,
        [Performance.Medium]: 570,
        [Performance.High]: 180,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 900,
        [Performance.Medium]: 530,
        [Performance.High]: 180,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 700,
        [Performance.Medium]: 420,
        [Performance.High]: 140,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 550,
        [Performance.Medium]: 330,
        [Performance.High]: 110,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 500,
        [Performance.Medium]: 300,
        [Performance.High]: 100,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 520,
        [Performance.Medium]: 300,
        [Performance.High]: 110,
      },
    },
  },
  "South Korea": {
    "Arts&Culture": {
      capex: {
        [Performance.Low]: 720,
        [Performance.Medium]: 430,
        [Performance.High]: 140,
      },
    },
    Laboratory: {
      capex: {
        [Performance.Low]: 680,
        [Performance.Medium]: 400,
        [Performance.High]: 140,
      },
    },
    Office: {
      capex: {
        [Performance.Low]: 900,
        [Performance.Medium]: 500,
        [Performance.High]: 300,
      },
    },
    Production: {
      capex: {
        [Performance.Low]: 420,
        [Performance.Medium]: 250,
        [Performance.High]: 80,
      },
    },
    Residential: {
      capex: {
        [Performance.Low]: 380,
        [Performance.Medium]: 230,
        [Performance.High]: 70,
      },
    },
    Warehouse: {
      capex: {
        [Performance.Low]: 390,
        [Performance.Medium]: 230,
        [Performance.High]: 80,
      },
    },
  },
};

export const thresholdsReferenceLibrary: ILibrary<IThresholds> = {
  default: {
    default: {
      strandingYear: {
        [Performance.Medium]: 5,
        [Performance.High]: 10,
      } as unknown as IThreshold,
      co2reduction: {
        [Performance.Medium]: 70,
        [Performance.High]: 50,
      } as unknown as IThreshold,
      energyIntensity: {
        [Performance.Medium]: 79.04815504167073,
        [Performance.High]: 158.09631008334145,
      } as unknown as IThreshold,
      netZeroCo2Readiness: {
        [Performance.Medium]: 25,
        [Performance.High]: 50,
      } as unknown as IThreshold,
      shareOfRenewables: {
        [Performance.Medium]: 20,
        [Performance.High]: 50,
      } as unknown as IThreshold,
    },
    Office: {
      energyIntensity: {
        [Performance.Medium]: 86.66307902530663,
        [Performance.High]: 173.32615805061326,
      } as unknown as IThreshold,
    },
    "Residential, Multi-family": {
      energyIntensity: {
        [Performance.Medium]: 42.59279377458719,
        [Performance.High]: 85.18558754917439,
      } as unknown as IThreshold,
    },
    "Residential, Single-family": {
      energyIntensity: {
        [Performance.Medium]: 44.42569862224425,
        [Performance.High]: 88.8513972444885,
      } as unknown as IThreshold,
    },
    "Retail, High Street": {
      energyIntensity: {
        [Performance.Medium]: 92.33224235400515,
        [Performance.High]: 184.6644847080103,
      } as unknown as IThreshold,
    },
    "Retail, Shopping Center": {
      energyIntensity: {
        [Performance.Medium]: 73.80521901364297,
        [Performance.High]: 147.61043802728594,
      } as unknown as IThreshold,
    },
    "Retail, Warehouse": {
      energyIntensity: {
        [Performance.Medium]: 84.67992888675721,
        [Performance.High]: 169.35985777351442,
      } as unknown as IThreshold,
    },
    Hotel: {
      energyIntensity: {
        [Performance.Medium]: 92.2429411167276,
        [Performance.High]: 184.4858822334552,
      } as unknown as IThreshold,
    },
    "Industrial, Distribution Warehouse": {
      energyIntensity: {
        [Performance.Medium]: 31.62712381046465,
        [Performance.High]: 63.2542476209293,
      } as unknown as IThreshold,
    },
    Healthcare: {
      energyIntensity: {
        [Performance.Medium]: 137.79953363768288,
        [Performance.High]: 275.59906727536577,
      } as unknown as IThreshold,
    },
    "Lodging, Leisure & Recreation": {
      energyIntensity: {
        [Performance.Medium]: 104.31299017528859,
        [Performance.High]: 208.62598035057718,
      } as unknown as IThreshold,
    },
  },
  Germany: {
    default: {
      energyIntensity: {
        [Performance.Medium]: 87.68660015702929,
        [Performance.High]: 175.37320031405858,
      } as unknown as IThreshold,
    },
    Office: {
      energyIntensity: {
        [Performance.Medium]: 89.26642966492344,
        [Performance.High]: 178.53285932984687,
      } as unknown as IThreshold,
    },
    "Residential, Multi-family": {
      energyIntensity: {
        [Performance.Medium]: 60.25107609280051,
        [Performance.High]: 120.50215218560102,
      } as unknown as IThreshold,
    },
    "Residential, Single-family": {
      energyIntensity: {
        [Performance.Medium]: 76.0081135336333,
        [Performance.High]: 152.0162270672666,
      } as unknown as IThreshold,
    },
    "Retail, High Street": {
      energyIntensity: {
        [Performance.Medium]: 95.10346306055419,
        [Performance.High]: 190.20692612110838,
      } as unknown as IThreshold,
    },
    "Retail, Shopping Center": {
      energyIntensity: {
        [Performance.Medium]: 75.98809822005306,
        [Performance.High]: 151.97619644010612,
      } as unknown as IThreshold,
    },
    "Retail, Warehouse": {
      energyIntensity: {
        [Performance.Medium]: 87.23902675853516,
        [Performance.High]: 174.47805351707032,
      } as unknown as IThreshold,
    },
    Hotel: {
      energyIntensity: {
        [Performance.Medium]: 97.4569977877016,
        [Performance.High]: 194.9139955754032,
      } as unknown as IThreshold,
    },
    "Industrial, Distribution Warehouse": {
      energyIntensity: {
        [Performance.Medium]: 46.210298603625795,
        [Performance.High]: 92.42059720725159,
      } as unknown as IThreshold,
    },
    Healthcare: {
      energyIntensity: {
        [Performance.Medium]: 141.87695076169925,
        [Performance.High]: 283.7539015233985,
      } as unknown as IThreshold,
    },
    "Lodging, Leisure & Recreation": {
      energyIntensity: {
        [Performance.Medium]: 107.46554708676659,
        [Performance.High]: 214.93109417353318,
      } as unknown as IThreshold,
    },
  },
  Italy: {
    default: {
      energyIntensity: {
        [Performance.Medium]: 98.02101469495322,
        [Performance.High]: 196.04202938990645,
      } as unknown as IThreshold,
    },
    Office: {
      energyIntensity: {
        [Performance.Medium]: 99.0981425905525,
        [Performance.High]: 198.196285181105,
      } as unknown as IThreshold,
    },
    "Residential, Multi-family": {
      energyIntensity: {
        [Performance.Medium]: 62.82348311535747,
        [Performance.High]: 125.64696623071494,
      } as unknown as IThreshold,
    },
    "Residential, Single-family": {
      energyIntensity: {
        [Performance.Medium]: 63.598648463716835,
        [Performance.High]: 127.19729692743367,
      } as unknown as IThreshold,
    },
    "Retail, High Street": {
      energyIntensity: {
        [Performance.Medium]: 105.57343734258177,
        [Performance.High]: 211.14687468516354,
      } as unknown as IThreshold,
    },
    "Retail, Shopping Center": {
      energyIntensity: {
        [Performance.Medium]: 75.98809822005306,
        [Performance.High]: 151.97619644010612,
      } as unknown as IThreshold,
    },
    "Retail, Warehouse": {
      energyIntensity: {
        [Performance.Medium]: 87.23902675853516,
        [Performance.High]: 174.47805351707032,
      } as unknown as IThreshold,
    },
    Hotel: {
      energyIntensity: {
        [Performance.Medium]: 170.22281583200729,
        [Performance.High]: 340.44563166401457,
      } as unknown as IThreshold,
    },
    "Industrial, Distribution Warehouse": {
      energyIntensity: {
        [Performance.Medium]: 38.912504323557506,
        [Performance.High]: 77.82500864711501,
      } as unknown as IThreshold,
    },
    Healthcare: {
      energyIntensity: {
        [Performance.Medium]: 157.45358558285199,
        [Performance.High]: 314.90717116570397,
      } as unknown as IThreshold,
    },
    "Lodging, Leisure & Recreation": {
      energyIntensity: {
        [Performance.Medium]: 119.30040472031867,
        [Performance.High]: 238.60080944063733,
      } as unknown as IThreshold,
    },
  },
};

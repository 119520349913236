import React from "react";
import { Button, Text, VStack } from "@chakra-ui/react";
import { ICrispUser } from "src/api/open-api";

interface IProps {
  user?: ICrispUser;
  deleteCallback: (user: ICrispUser) => void;
  isCompanyLevel?: boolean;
  actionLabel?: string;
}

export const UserDeleteAlert = (props: IProps) => {
  const { user, deleteCallback, isCompanyLevel } = props;

  if (!user) return <></>;

  return (
    <VStack spacing="2rem" justify="center">
      <Text display="inline">
        Are you sure you want to {isCompanyLevel ? "delete" : "deactivate"}{" "}
        <b>{user?.user_name}</b>
        {isCompanyLevel
          ? " from this company?"
          : " account in CRISP system? The user's company-level permissions will also be revoked."}
      </Text>
      <Button
        justifyContent="space-around"
        w="50%"
        variant="outlinePrimary"
        onClick={() => deleteCallback(user)}
      >
        {isCompanyLevel ? "Delete" : "Deactivate"}
      </Button>
    </VStack>
  );
};

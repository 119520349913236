import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import AddEntityModal from "./AddEntityModal";
import { UserDeleteAlert } from "./UserDeleteAlert";
import { ICrispUser } from "src/api/open-api";
import { ActivateUserAlert } from "./AppAdmin/ActivateUserAlert";
import { EditUserForm } from "./AppAdmin/EditUserForm";
import UserPermissionsTable from "./AppAdmin/UserPermissionsTable";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { selectAllCompanies } from "src/redux/slices/admin/companies/selectors";
import { fetchCompanies } from "src/redux/thunks/companies";
import { BooleanIcon } from "src/common/components/atoms/BooleanIcon";
interface IProps {
  items?: (ICrispUser & { isCompanyAdmin?: boolean })[];
  editActionCallback: () => void;
  deleteActionCallback: (userId: number) => Promise<void>;
  activateActionCallback?: (userId: number) => Promise<void>;
  isCompanyLevel?: boolean;
}

const UsersTable: React.FC<IProps> = (props) => {
  const {
    items,
    editActionCallback,
    deleteActionCallback,
    activateActionCallback,
    isCompanyLevel,
  } = props;
  const [selectedUser, setSelectedUser] = useState<ICrispUser>();
  const companies = useAppSelector(selectAllCompanies);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCompanies);
  }, [dispatch]);

  const {
    isOpen: editUserModalOpen,
    onClose: editUserModalClose,
    onOpen: editUserModalOnOpen,
  } = useDisclosure();

  const {
    isOpen: deleteModalOpen,
    onClose: deleteOnClose,
    onOpen: deleteOnOpen,
  } = useDisclosure();

  const {
    isOpen: isPermissionsModalOpen,
    onClose: onPermissionModalClose,
    onOpen: onPermissionModalOpen,
  } = useDisclosure();

  const {
    isOpen: isActivateUserModalOpen,
    onClose: onActivateModalClose,
    onOpen: onActivateModalOpen,
  } = useDisclosure();

  const closeHandler = () => {
    editUserModalClose();
    editActionCallback();
  };

  const editSelectedUser = (user: ICrispUser) => {
    setSelectedUser(user);
    editUserModalOnOpen();
  };

  const editPermissions = (user: ICrispUser) => {
    setSelectedUser(user);
    onPermissionModalOpen();
  };

  const deleteHandler = (user: ICrispUser) => {
    deleteActionCallback(user.user_id).then(() => {
      deleteOnClose();
    });
  };

  const activateSelectedUserHandler = (user: ICrispUser) => {
    setSelectedUser(user);
    onActivateModalOpen();

    if (activateActionCallback) {
      activateActionCallback(user.user_id).then(() => {
        onActivateModalClose();
      });
    }
  };

  //TODO: Create form to search for users
  return (
    <Box overflow="auto">
      <Table variant="simple" width="100%">
        <Thead>
          <Tr>
            <Th>User name</Th>
            <Th>Email</Th>
            {!isCompanyLevel && <Th textAlign="center">Active Status</Th>}
            {isCompanyLevel ? (
              <Th textAlign="center">Company Admin</Th>
            ) : (
              <Th></Th>
            )}
            <Th></Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {items &&
            items.map((user) => (
              <Tr key={user.user_id} _hover={{ bg: "white.400" }}>
                <Td>{user.user_name}</Td>
                <Td>{user.user_email}</Td>
                {!isCompanyLevel && (
                  <Td textAlign="center">
                    <BooleanIcon isTrue={!!user.is_active} />
                  </Td>
                )}
                {isCompanyLevel && (
                  <Td textAlign="center">
                    <BooleanIcon isTrue={!!user.isCompanyAdmin} />
                  </Td>
                )}

                <Td>
                  <Button
                    size="sm"
                    variant="outlinePrimary"
                    placeSelf={{ base: "center", xs: "inherit" }}
                    onClick={() => {
                      editSelectedUser(user);
                    }}
                  >
                    Edit
                  </Button>
                </Td>
                {!isCompanyLevel && !user.is_active && (
                  <>
                    <Td></Td>
                    <Td>
                      <Button
                        size="sm"
                        variant="outlinePrimary"
                        placeSelf={{ base: "center", xs: "inherit" }}
                        onClick={() => {
                          setSelectedUser(user);
                          onActivateModalOpen();
                        }}
                      >
                        Activate User
                      </Button>
                    </Td>
                  </>
                )}
                {!isCompanyLevel && user.is_active && (
                  <>
                    <Td w="2rem">
                      <Button
                        size="sm"
                        variant="outlinePrimary"
                        placeSelf="center"
                        onClick={() => {
                          editPermissions(user);
                        }}
                      >
                        Roles
                      </Button>
                    </Td>
                    <Td w="2rem" textAlign="center">
                      <Button
                        size="sm"
                        variant="outlineGray"
                        placeSelf="center"
                        onClick={() => {
                          setSelectedUser(user);
                          deleteOnOpen();
                        }}
                      >
                        Deactivate
                      </Button>
                    </Td>
                  </>
                )}

                {isCompanyLevel && (
                  <Td w="2rem">
                    <Button
                      variant="outlineGrey"
                      onClick={() => {
                        setSelectedUser(user);
                        deleteOnOpen();
                      }}
                    >
                      Delete
                    </Button>
                  </Td>
                )}
              </Tr>
            ))}
        </Tbody>
      </Table>

      {selectedUser && (
        <AddEntityModal
          isOpen={editUserModalOpen}
          onClose={editUserModalClose}
          title={"Edit user"}
        >
          <EditUserForm onSubmitCallback={closeHandler} user={selectedUser} />
        </AddEntityModal>
      )}
      <AddEntityModal
        isOpen={isPermissionsModalOpen}
        onClose={onPermissionModalClose}
        title={"Edit app permissions"}
      >
        <UserPermissionsTable user={selectedUser} companies={companies} />
      </AddEntityModal>

      <AddEntityModal
        isOpen={isActivateUserModalOpen}
        onClose={onActivateModalClose}
        title={"Activate user"}
        size="lg"
      >
        <ActivateUserAlert
          activateCallback={activateSelectedUserHandler}
          user={selectedUser}
        />
      </AddEntityModal>
      <AddEntityModal
        isOpen={deleteModalOpen}
        onClose={deleteOnClose}
        title={
          isCompanyLevel ? "Delete user from company." : "Deactivate user."
        }
        size="lg"
      >
        <UserDeleteAlert
          deleteCallback={deleteHandler}
          user={selectedUser}
          isCompanyLevel={isCompanyLevel}
        />
      </AddEntityModal>
    </Box>
  );
};

export default UsersTable;

import { EntityState, createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { IPortfolioFilters } from "src/redux/interfaces/IPortfolioFilter";
import { selectSlice as selectPortfoliofilterSlice } from "src/redux/slices/uiState/portfoliosFilter/selectors";
import { portfolioAdapter } from "./adapter";
import { IAppPortfolio } from "src/redux/interfaces/IAppPortfolio";
import { formatMoney } from "src/common/utils/formatters";
import { getUnits } from "src/constants/measureUnits";
import { Units } from "../../../constants/measureUnits";

export const selectSlice = (state: RootState) => state.portfolios;

export const selectAllPortfolios = createDraftSafeSelector(
  [selectSlice],
  (assetState: EntityState<IAppPortfolio>) =>
    portfolioAdapter.getSelectors().selectAll(assetState),
);

const _getOpenedPortfolio = (
  state: EntityState<IAppPortfolio> & { selectedPortfolioId: number | null },
) =>
  !state.selectedPortfolioId
    ? undefined
    : portfolioAdapter
        .getSelectors()
        .selectById(state, state.selectedPortfolioId) || null;

export const selectOpenedPortfolio = createDraftSafeSelector(
  [selectSlice],
  _getOpenedPortfolio,
);

export const selectCurrencyCode = createDraftSafeSelector(
  [selectSlice],
  (state) => {
    return _getOpenedPortfolio(state)?.currency;
  },
);

export const selectRecentlyDeletedIds = createDraftSafeSelector(
  [selectSlice],
  (assetState) => assetState.deletedIds,
);

export const selectDeletePortfolioError = createDraftSafeSelector(
  [selectSlice],
  (state) => state.deletionError,
);

export const selectFetchPortfolioError = createDraftSafeSelector(
  [selectSlice],
  (state) => state.error,
);

export const selectDeletePortfolioPending = createDraftSafeSelector(
  [selectSlice],
  (state) => state.deletionStatus === "pending",
);

export const selectPortfoliosNotLoaded = createDraftSafeSelector(
  [selectSlice],
  (state) =>
    state.requestStatus === "pending" || state.requestStatus === "initial",
);

export const selectFilteredPortfolios = createDraftSafeSelector(
  [selectSlice, selectPortfoliofilterSlice],
  (
    portfolioState: EntityState<IAppPortfolio>,
    portfolioFilters: IPortfolioFilters,
  ) =>
    portfolioAdapter
      .getSelectors()
      .selectAll(portfolioState)
      .filter((portfolio) => {
        const countryFilter =
          !portfolioFilters.selectedCountries?.length ||
          !portfolio.summary.countries?.length ||
          portfolio.summary.countries.some((country) =>
            portfolioFilters.selectedCountries.includes(country),
          );
        const usesFilter =
          !portfolio.summary.uses?.length ||
          portfolio.summary.uses.some((buildingtype) =>
            portfolioFilters.selectedBuildingTypes.includes(buildingtype),
          );
        const riskLevelFilter =
          portfolio.summary.riskLevelId === undefined ||
          portfolioFilters.selectedRiskLevels.includes(
            String(portfolio.summary.riskLevelId),
          );
        const fundTypeFilter =
          !portfolioFilters.selectedFundTypes?.length ||
          !portfolio.summary.fundTypeName ||
          (portfolio.summary.fundTypeName &&
            portfolioFilters.selectedFundTypes.includes(
              portfolio.summary.fundTypeName,
            ));
        return countryFilter && usesFilter && riskLevelFilter && fundTypeFilter;
      })
      .map((portfolio) => ({
        portfolioId: portfolio.portfolioId,
        portfolioName: portfolio.summary.portfolioName,
        companyName: portfolio.summary.companyName,
        companyId: portfolio.summary.companyId,
        countries: portfolio.summary.countries,
        uses: portfolio.summary.uses,
        riskLevel: portfolio.summary.riskLevelId,
        assetsCnt: portfolio.summary.assetCnt,
        area:
          (portfolio.summary.area?.toLocaleString(undefined, {
            maximumFractionDigits: 1,
          }) || 0) +
          " " +
          getUnits(Units.area, portfolio.measureSystem),
        value:
          !portfolio.summary.value ||
          portfolio.summary.value === portfolio.summary.assetCnt
            ? "Not Provided"
            : formatMoney(portfolio.summary.value, portfolio.currency),
        taxonomy: portfolio.summary.taxonomy,
        certifications: portfolio.summary.certifications,
        dataMaturity: portfolio.summary.dataMaturity,
        fundType: portfolio.summary.fundTypeName,
      })),
);

import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { ScenariosApi, AddAssetScenariosRequestInner } from "src/api/open-api";
import { defaultApiConfiguration } from "src/api/configurations";
import { USE_LOCAL_MOCKS } from "src/config/mocks";
import { mockedScenarios } from "./__mocks__/mockScenarios";
import { globalMaxYear, globalMinYear } from "src/constants";
import { normalizeTimeSeries } from "src/common/utils/pathways/normalizeTimeSeries";
import { errorReporter } from "src/common/utils/errorReporter/errorReporter";
import { groupInterventionsByPackage } from "./utils";
import { IAppScenario } from "src/redux/interfaces/IAppScenario";

export const fetchScenarios = createAsyncThunk(
  "scenarios/fetch",
  async (assetId: number, { rejectWithValue }) => {
    if (USE_LOCAL_MOCKS) {
      return mockedScenarios
        .map((s) => {
          return {
            ...s,
            asset_id: assetId,
            pathways: s?.pathways.map((p) => ({
              ...p,
              time_series_points: normalizeTimeSeries(
                p.time_series_points || [],
                globalMinYear,
                globalMaxYear,
              ),
            })),
          };
        })
        .map(groupInterventionsByPackage) as IAppScenario[];
    }
    const api = new ScenariosApi(defaultApiConfiguration);
    let response;
    try {
      response = await api.getScenariosByAssetId(assetId);
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }

    const data = response?.data;

    try {
      return data
        .map((s) => {
          return {
            ...s,
            asset_id: assetId,
            pathways: s?.pathways.map((p) => ({
              ...p,
              time_series_points: normalizeTimeSeries(
                p.time_series_points || [],
                globalMinYear,
                globalMaxYear,
                true,
              ),
            })),
          };
        })
        .map(groupInterventionsByPackage) as IAppScenario[];
    } catch (error) {
      errorReporter({
        activeUrl: window?.location?.pathname,
        stack: JSON.stringify(error),
        context: "thunks/fetchScenarios data transform part",
      });
      return rejectWithValue({ errorCode: "UI_DATA_IS_BROCKEN" });
    }
  },
);

export const getScenarioFile = async (assetId: number, portfolioId: number) => {
  const api = new ScenariosApi(defaultApiConfiguration);
  const response = await api.getScenarioFile(portfolioId, assetId);
  return response.data;
};

export const saveScenariosFileRef = async (
  assetId: string,
  portfolioId: string,
  filePath: string,
  file: File,
  lastMod: number,
) => {
  const scenarioFileInfo = {
    asset_id: assetId,
    portfolio_id: portfolioId,
    file_path: filePath,
    file_name: file.name,
    file_last_modified: lastMod,
  };
  const api = new ScenariosApi(defaultApiConfiguration);
  const response = await api.uploadScenarioFile(
    parseInt(portfolioId),
    parseInt(assetId),
    scenarioFileInfo,
  );
  return response.data;
};

export const submitScenariosFile = async (file: File, assetId: string) => {
  const form = new FormData();
  form.append("excel", file);
  const api = new ScenariosApi(defaultApiConfiguration);
  const response = await api.putExcel(assetId, file);
  return response.data;
};

type TAddInterventionsProps = {
  assetId: number;
  scenarios: AddAssetScenariosRequestInner[];
};
export const upsertInterventions = async ({
  assetId,
  scenarios,
}: TAddInterventionsProps) => {
  const api = new ScenariosApi(defaultApiConfiguration);
  const response = await api.addAssetScenarios(assetId, scenarios);
  return response.data;
};

export const setSelectedScenarios = createAction<string[]>("scenarios/select");

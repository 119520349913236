import { theme } from "src/config/theme";
import { Performance } from "src/common/components/molecules/PerformanceTable/references";
import {
  maxEmissionsTargetLabel,
  minEmissionsTargetlabel,
} from "src/constants/targetLabels";

const safeColor = theme.colors.green["500"];
const warningColor = theme.colors.yellow["400"];
const dangerColor = theme.colors.red["400"];
const defaultColor = theme.colors.gray["400"];

export const riskToColor = (weightedRiskValue: number | undefined) => {
  switch (weightedRiskValue) {
    case 0:
      return safeColor;

    case 1:
      return warningColor;

    case 2:
      return dangerColor;
    default:
      return defaultColor;
  }
};

const colorsCustomPathways = [
  theme.colors.purple[500],
  theme.colors.purple[200],
  theme.colors.blue[500],
  theme.colors.blue[300],
  theme.colors.teal[800],
  theme.colors.teal[200],
  theme.colors.orange[500],
  theme.colors.orange[300],
  theme.colors.pink[500],
  theme.colors.pink[100],
  theme.colors.blue[700],
  theme.colors.blue[100],
];
export const getCustomLineColor = (index: number): string => {
  const i = index % colorsCustomPathways.length;
  return colorsCustomPathways[i];
};
export const getLineColor = (description: string): string => {
  switch (description) {
    case "SBTi":
      return theme.colors.blue[500];
    case minEmissionsTargetlabel:
      return warningColor;
    case maxEmissionsTargetLabel:
      return dangerColor;
    case "Custom":
      return theme.colors.purple[500];
    case "Forecast":
      return defaultColor;
    case "Scenario":
      return "#BB00AA";
    default:
      return defaultColor;
  }
};

export const getColorNameByHex = (hex: string): string => {
  switch (hex) {
    case theme.colors.blue[500]:
      return "blue";
    case warningColor:
      return "yellow";
    case dangerColor:
      return "red";
    case theme.colors.purple[500]:
      return "purple";
    case "#BB00AA":
      return "purple";
    default:
      return "gray";
  }
};

export const getFundColor = (fundType: string) => {
  switch (fundType) {
    case "Art 9":
      return "art9";
    case "Art 8":
      return "art8";
    default:
      return "art6";
  }
};

export const getPerformanceColor = (rating: Performance) => {
  switch (rating) {
    case Performance.Low:
      return dangerColor;

    case Performance.Medium:
      return warningColor;

    case Performance.High:
      return safeColor;
  }
};

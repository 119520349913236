import ValidationError from "src/common/utils/ValidationError";
import { WorkSheet, utils, read } from "xlsx";

type IRawScenario = {
  "CAPEX Cost": number;
  "CAPEX DECARB": number;
  "CAPEX EOL": number;
  "DC Usage Change": number;
  "DH Usage Change": number;
  "Electricity Usage Change": number;
  "GHG Emission Change": number;
  "Gas Usage Change": number;
  "Intervention Category": string;
  "Intervention Description": string;
  "Intervention ID": string;
  MAC: number;
  "OPEX Change": number;
  "Oil Usage Change": number;
  "Other Usage Change": number;
  "Other Usage Type ": number;
  Package: string;
  "Renewable Other Increase": number;
  "Renewable Solar Increase": number;
  "Year Of Implementation": number;
};

export interface IInterventionFromExcel {
  capex_cost: number;
  capex_decarb: number;
  capex_eol: number;
  dc_usage_change: number;
  dh_usage_change: number;
  electricity_usage_change: number;
  gas_usage_change: number;
  ghg_emission_change: number;
  implementation_year: number;
  intervention_id: string;
  intervention_category: string;
  intervention_description: string;
  mac: number;
  oil_usage_change: number;
  opex_revenue: number;
  other_usage_change: number;
  other_usage_type: number;
  package_id: string;
  renewable_other_increase: number;
  renewable_solar_increase: number;
}

const parseScenario = (
  sheet: WorkSheet,
): { interventions: IInterventionFromExcel[]; errors: ValidationError[] } => {
  const errors: ValidationError[] = [];
  const dataJson: IRawScenario[] = utils.sheet_to_json(sheet);
  if (!dataJson?.length) errors.push(new ValidationError("Sheet is empty"));
  return {
    interventions: dataJson.map((raw: IRawScenario) => ({
      package_id: raw.Package,
      intervention_id: raw["Intervention ID"],
      intervention_category: raw["Intervention Category"],
      intervention_description: raw["Intervention Description"],
      implementation_year: raw["Year Of Implementation"],
      electricity_usage_change: raw["Electricity Usage Change"],
      gas_usage_change: raw["Gas Usage Change"],
      oil_usage_change: raw["Oil Usage Change"],
      dh_usage_change: raw["DH Usage Change"],
      dc_usage_change: raw["DC Usage Change"],
      other_usage_type: raw["Other Usage Type "],
      other_usage_change: raw["Other Usage Change"],
      renewable_solar_increase: raw["Renewable Solar Increase"],
      renewable_other_increase: raw["Renewable Other Increase"],
      ghg_emission_change: raw["GHG Emission Change"],
      capex_eol: raw["CAPEX EOL"],
      capex_decarb: raw["CAPEX DECARB"],
      capex_cost: raw["CAPEX Cost"],
      opex_revenue: raw["OPEX Change"],
      mac: raw.MAC,
    })),
    errors,
  };
};

type IParseScenarioError = {
  scenarioName: string;
  errors: ValidationError[];
};
export const parseScenariosExcel = (filedata: ArrayBuffer) => {
  const workbook = read(filedata, { type: "array" });
  const allErrors: IParseScenarioError[] = [];
  const scenarios = workbook.SheetNames.map((name) => {
    const { interventions, errors } = parseScenario(workbook.Sheets[name]);
    if (errors?.length) {
      allErrors.push({ scenarioName: name, errors });
    }
    return { scenario_name: name, packages: interventions };
  });
  return {
    scenarios: scenarios,
    errors: allErrors,
  };
};

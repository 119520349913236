import React, { useEffect, useLayoutEffect } from "react";
import { Route, Routes } from "react-router";
import { Auth } from "aws-amplify";
import { fetchAuthInfo } from "src/redux/thunks/auth";
import { signOut } from "src/common/utils/auth/cognitoAuth";
import { errorReporter } from "src/common/utils/errorReporter/errorReporter";
import { useAppDispatch } from "./redux/hooks";
import { SpinnerPageWrapper } from "./common/components/atoms/Spinner";
import { CompanyView } from "./pages/CompanyView";
import { CompanySelection } from "./pages/CompanySelectView";
import { PortfolioView } from "./pages/PortfolioView";
import { AssetView } from "./pages/AssetView";
import { Page404 } from "./pages/Page404";
import { AdminView } from "./pages/AdminView";
import { CompanyAdminView } from "./pages/AdminView/CompanyAdminView";
import { fetchPortfolios } from "./redux/thunks/portfolios";
import { fetchCountries } from "./redux/thunks/countries";
import { Stylebook } from "./pages/Stylebook";
import { CompanyProvider } from "./common/components/organisms/CompanyProviderWrapper";
import { fetchUserCompanies } from "./redux/thunks/userCompanies";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const [initialAppLoadingState, setInitialAppLoadingState] =
    React.useState(true);
  //intervention to capture auth state when switching between tabs
  const onVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      /*
        if the user has signed out in another tab,
        we want to make sure we have a failsafe to rerender the page on this instance to check the auth state
        if the user is unauthenticated, trigger a signout
      */
      Auth.currentAuthenticatedUser().catch((error) => {
        errorReporter({
          stack: JSON.stringify(error),
          message: "Currenct aut user error",
          activeUrl: window?.location?.pathname,
          context: "App Auth.currentAuthenticatedUser",
        });
        signOut();
      });
    }
  };

  //add screen visibility change listener to capture switching between tabs
  useLayoutEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () =>
      document.removeEventListener("visibilitychange", onVisibilityChange);
  }, []);

  useEffect(() => {
    dispatch(fetchAuthInfo())
      .then(() => dispatch(fetchCountries()))
      .then(() => dispatch(fetchUserCompanies()))
      .then(() => dispatch(fetchPortfolios()))
      .catch(() => {
        console.log("Dev error to be fixed in auth process");
      })
      .finally(() => {
        setInitialAppLoadingState(false);
      });
  }, [dispatch]);

  return (
    <SpinnerPageWrapper isLoading={initialAppLoadingState}>
      <CompanyProvider>
        <Routes>
          <Route
            path="/:portfolioId/asset/:assetId/*"
            element={<AssetView />}
          />
          <Route path="/:portfolioId/*" element={<PortfolioView />} />
          <Route
            path="/admin/company/:companyId"
            element={<CompanyAdminView />}
          />
          <Route path="/admin/*" element={<AdminView />} />
          {window?.location?.hostname === "localhost" ? (
            <Route path="/stylebook" element={<Stylebook />} />
          ) : null}
          <Route path="/" element={<CompanySelection />} />
          <Route path="/companyView/:companyId" element={<CompanyView />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </CompanyProvider>
    </SpinnerPageWrapper>
  );
};

export default App;

import { utils, WorkSheet } from "xlsx";
import ValidationError from "src/common/utils/ValidationError";
import { validateExcelPortfolio } from "src/api/typeguards/generated/check";
import { IExcelPortfolio } from "src/api/typeguards/templates/excelTypes/IExcelPortfolio";
import { cleanFlatObject } from "src/common/utils/cleaners";
import { PortfolioPayload } from "src/api/open-api";

const portfolioError = new ValidationError(
  "There is an issue with the file format you have uploaded, it may be outdated or corrupt. Please download the template file again and populate with the Portfolio data.",
);

export const parsePortfolio = (
  sheet: WorkSheet,
  companyId: number,
): PortfolioPayload => {
  // Find line in excel
  const dataJson: IExcelPortfolio[] = utils.sheet_to_json(sheet, {
    range: 6,
  });
  const excelPortoflioLine = dataJson.filter((p) => "NAME" in p)[0];
  if (!excelPortoflioLine) {
    throw portfolioError;
  }

  // prevent csv exploit
  const cleanExcelPortfolioObject =
    cleanFlatObject<typeof excelPortoflioLine>(excelPortoflioLine);

  // validation (do the value match the structure of current excel)
  const initialValidationResult = validateExcelPortfolio(
    cleanExcelPortfolioObject,
  );
  // TODO: add file version validation here
  if (!initialValidationResult.success || !cleanExcelPortfolioObject) {
    throw portfolioError;
  }

  const portfolio: PortfolioPayload = {
    portfolio_name: cleanExcelPortfolioObject.NAME,
    fund_type: cleanExcelPortfolioObject.FU_TY ?? "",
    analysis_level: cleanExcelPortfolioObject.ANALYSIS_LEV,
    iso_currency_code: cleanExcelPortfolioObject.ISO_CUR ?? "",
    system_of_measurement: cleanExcelPortfolioObject.SYS_MEAS ?? "",
    file_version: cleanExcelPortfolioObject.FILE_VERS,
    crisp_version: cleanExcelPortfolioObject.CRISP_VERS,
    company_id: companyId,
    stranding_year_l_to_m: cleanExcelPortfolioObject.RT_SY_LM,
    stranding_year_m_to_h: cleanExcelPortfolioObject.RT_SY_MH,
  };

  return portfolio;
};

import {
  AssetPayloadEpcLabelEnum,
  IAsset,
  IAssetEmissionsForecast,
  IAssetStrandingResult,
  ITargetTimeSeries,
  ITimeSeriesPoint,
} from "src/api/open-api";
import {
  getCustomLineColor,
  getLineColor,
} from "src/common/utils/colors/colors";
import { intersectTimeSeries } from "src/common/utils/pathways";
import { normalizeTimeSeries } from "src/common/utils/pathways/normalizeTimeSeries";
import { globalMaxYear, globalMinYear } from "src/constants";
import {
  maxEmissionsTargetLabel,
  minEmissionsTargetlabel,
} from "src/constants/targetLabels";
import { energyIntensityTypeId, hardcodedLineIds } from "src/constants/targets";
import {
  IAppAsset,
  IAppAssetStrandingResult,
} from "src/redux/interfaces/IAppAsset";
import { getPrimaryBuildingType } from "./getPrimaryBuildingType";
import { ghgIntensityTypeId } from "src/constants/targets";
import {
  transformTaxonomyOnLoad,
  transformRenewableOnLoad,
  transformCertificationsOnLoad,
  parseGPSCoordinates,
} from "./transformUtils";

const _findStrandingForTarget = (
  assetStranding: IAssetStrandingResult[],
  target: ITargetTimeSeries,
): IAssetStrandingResult | undefined => {
  return assetStranding?.find(
    (s) =>
      s.intensity_type_id === target.intensity_type_id &&
      s.model_version_id === target.model_version_id,
  );
};

const _getTargetDisplayName = (
  modelVersionDescription: string,
  model_version_name: string,
): string => {
  if (
    modelVersionDescription === hardcodedLineIds.crremWarn.model_description &&
    model_version_name === hardcodedLineIds.crremWarn.model_version_name
  ) {
    return minEmissionsTargetlabel;
  }
  if (
    modelVersionDescription ===
      hardcodedLineIds.crremCritical.model_description &&
    model_version_name === hardcodedLineIds.crremCritical.model_version_name
  ) {
    return maxEmissionsTargetLabel;
  }
  return modelVersionDescription;
};
const _targetConsoleErr = (asset: IAsset, message: string) =>
  console.warn(
    `Broken stranding result found for asset id=${asset.asset_id} "${asset.asset_label}": ${message}`,
  );
const _findEmissionsForecastByType = (
  forecasts: IAssetEmissionsForecast[],
  intensityTypeId: number,
) => {
  return (
    forecasts.find((f) => f.intensity_type_id === intensityTypeId)
      ?.time_series_points || null
  );
};

const _getStrandingResultsForType = (
  asset: IAsset,
  cleanForecast: ITimeSeriesPoint[],
  intensityTypeId: number,
) => {
  if (!asset?.target_time_series?.length) return [];
  const targets = asset.target_time_series
    .filter((t) => t.intensity_type_id === intensityTypeId)
    .map((target) => {
      const stranding = _findStrandingForTarget(asset.stranding, target);

      if (!target.time_series_points?.length) {
        _targetConsoleErr(
          asset,
          `time series point is empty for model version ${target.model_version_name} intensity type "${target.intensity_type_name}"`,
        );
        return null;
      }

      if (!cleanForecast?.length) {
        _targetConsoleErr(
          asset,
          `cannot find emissions forecast for intensity type "${target.intensity_type_name}" to calculate stranding year`,
        );
        return null;
      }

      const targetCleanTimeSeries = normalizeTimeSeries(
        target.time_series_points,
        asset.reporting_year || globalMinYear,
        globalMaxYear,
      );

      // normalize stranding year
      const strandingPoint = intersectTimeSeries(
        cleanForecast,
        targetCleanTimeSeries,
      );
      const targetName = _getTargetDisplayName(
        target.model_description || `target ${target.model_version_name}`,
        target.model_version_name,
      );
      return {
        modelVersionId: target.model_version_id,
        intensityTypeId: target.intensity_type_id,
        timeSeries: targetCleanTimeSeries || [],
        lineColor: getLineColor(targetName),
        targetName: targetName,
        ...strandingPoint,
        strandingRisk:
          strandingPoint?.strandingX < globalMinYear // if asset is stranded initially, stranding risk is high.
            ? 2
            : 2 - (stranding?.performance || 0),
        performance: stranding?.performance || 0,
        strandingRiskWeighted: stranding?.risk_weighted,
      };
    })
    .filter((t) => !!t) as IAppAssetStrandingResult[];
  return targets;
};

// This function exists for the following reasons:
// 1) to make us confident which fields are never undefined
// 2) to group data only once on asset load
// 3) to make it extremely easy to notice that api has changed + make it easy to fix it in one place
export const appAssetTransform = (
  apiAsset: IAsset,
  allAssetsHaveValue?: boolean,
): IAppAsset => {
  const cleanForecasts =
    apiAsset.emission_forecasts
      ?.filter((f) => !!f.time_series_points?.length)
      .map((forecast) => ({
        ...forecast,
        time_series_points: normalizeTimeSeries(
          forecast.time_series_points || [],
          apiAsset.reporting_year || globalMinYear,
          globalMaxYear,
        ),
      })) || [];
  const ghgForecast = _findEmissionsForecastByType(
    cleanForecasts,
    ghgIntensityTypeId,
  );
  const energyForecast = _findEmissionsForecastByType(
    cleanForecasts,
    energyIntensityTypeId,
  );
  const opexForecast =
    apiAsset.emission_forecasts?.find((l) => l.intensity_type_name === "OpEx")
      ?.time_series_points || [];
  return {
    assetId: apiAsset.asset_id,
    portfolioId: apiAsset.portfolio_id,
    portfolioName: apiAsset.portfolio_name,
    companyId: apiAsset.company_id,
    companyName: apiAsset.company_name,
    assetLabel: apiAsset.asset_label,
    location: {
      businessLocationId: apiAsset.business_location_id as number,
      businessLocationName: apiAsset.model_location_description,
      isoCountryCode: apiAsset.iso_country_code,
      gpsCoordinates: parseGPSCoordinates(apiAsset.gps_coordinates),
      city: apiAsset.city || "",
    },
    businessBuildingTypes: apiAsset.business_building_types || [],
    primaryBusinessBuildingType: getPrimaryBuildingType(
      apiAsset.business_building_types || [],
    ),
    listedBuilding: apiAsset.listed_building,
    taxonomy: transformTaxonomyOnLoad(apiAsset.taxonomy),
    epc: {
      rating: apiAsset.epc_rating as AssetPayloadEpcLabelEnum | undefined,
      intensity: apiAsset.epc_intensity,
      actualPerformance: apiAsset.epc_performance as
        | AssetPayloadEpcLabelEnum
        | undefined,
    },
    nabers_rating: apiAsset.nabers_rating,
    buildYear: apiAsset.build_year,
    reportingYear: apiAsset.reporting_year,
    lastMajorRenovationYear: apiAsset.last_major_renovation_year,
    area: apiAsset.area,
    value: apiAsset.value,
    dataQuality: apiAsset.data_quality,
    assetImageUrl: apiAsset.asset_image_url,
    capex: {
      capex_cost_oel: apiAsset.capex_cost_oel,
      capex_cost_decarb: apiAsset.capex_cost_decarb,
      capex_cost_total: apiAsset.capex_cost_total,
      capex_cost_oel_per_meter: apiAsset.capex_cost_oel_per_meter,
      capex_cost_decarb_per_meter: apiAsset.capex_cost_decarb_per_meter,
    },
    opex: {
      opex_status_quo_energy: apiAsset.opex_status_quo_energy,
      opex_pathway_energy: apiAsset.opex_pathway_energy,
      // we use zeros here because we do not have cals for this
      // opex_pathway_carbon and opex_status_quo_carbon can be used later if these values are added to the calcs
      opex_status_quo_carbon: 0,
      opex_pathway_carbon: 0,
      opexForecast: [...opexForecast].sort((p1, p2) => p1.year - p2.year),
    },

    energiesConsumption: {
      electricity: apiAsset.consumption_electricity,
      oil: apiAsset.consumption_oil,
      gas: apiAsset.consumption_gas,
      heating: apiAsset.consumption_heating,
      cooling: apiAsset.consumption_cooling,
      otherTypeName: apiAsset.consumption_other_type_name,
      otherTypeValue: apiAsset.consumption_other_type_value,
      onSiteRenewable: transformRenewableOnLoad(apiAsset),
    },
    certifications: transformCertificationsOnLoad(apiAsset),
    shareOfPortfolioGHG: apiAsset.share_of_portfolio_ghg,
    shareOfPortfolioValue: allAssetsHaveValue
      ? apiAsset.share_of_portfolio_value
      : null,
    ghgResults: {
      emissionsForecast: ghgForecast,
      targets: _getStrandingResultsForType(
        apiAsset,
        ghgForecast || [],
        ghgIntensityTypeId,
      ),
      customPathways: apiAsset.custom_pathways
        ?.filter(
          (f) =>
            !!f.time_series_points?.length &&
            f.intensity_type_id === ghgIntensityTypeId,
        )
        .map((pathway, i) => {
          const cleanTimeSeries = normalizeTimeSeries(
            pathway.time_series_points,
            apiAsset.reporting_year || globalMinYear,
            globalMaxYear,
          );
          const strandingPoint = intersectTimeSeries(
            ghgForecast || [],
            cleanTimeSeries,
          );
          return {
            modelVersionId: 0,
            intensityTypeId: pathway.intensity_type_id,
            timeSeries: pathway.time_series_points,
            lineColor: getCustomLineColor(i),
            lineId: pathway.custom_pathway_id,
            targetName: pathway.custom_pathway_name,
            strandingX: strandingPoint.strandingX,
            strandingY: strandingPoint.strandingY,
            performance: 2,
            strandingRisk: 0,
          };
        }),
    },
    energyResults: {
      emissionsForecast: energyForecast,
      targets: _getStrandingResultsForType(
        apiAsset,
        energyForecast || [],
        energyIntensityTypeId,
      ),
    },
  };
};

import { IBenchmarks, ILibrary, IThresholds } from "./references";
import {
  benchmarkReferenceLibrary,
  thresholdsReferenceLibrary,
} from "./referenceLibrary";
import { merge } from "lodash";
import { IThreshold } from "./references";

interface IAssetDataRequiredForReference {
  buildingType: string; // business building type
  locationName: string; // business location name
}

const getCustomReference = <T>(
  library: ILibrary<T>,
  country: string,
  type: string,
) => {
  let countryKey: string;
  if (Object.keys(library).includes(country)) {
    countryKey = country;
  } else {
    countryKey = "default";
  }
  if (Object.keys(library[countryKey]!).includes(type)) {
    return library[countryKey]![type];
  } else {
    return library[countryKey]!.default;
  }
};

export const getCapexReferences = (
  assetData: IAssetDataRequiredForReference,
) => {
  const defaultReference: IBenchmarks =
    benchmarkReferenceLibrary.default.default;

  const customReference = getCustomReference(
    benchmarkReferenceLibrary,
    assetData.locationName,
    assetData.buildingType,
  );

  return merge(defaultReference, customReference).capex;
};

export const getStrandingReferences = (
  assetData: IAssetDataRequiredForReference,
  portfoliStrandingThreshold?: IThreshold,
) => {
  return merge(
    merge(
      thresholdsReferenceLibrary.default.default.strandingYear,
      getCustomReference(
        thresholdsReferenceLibrary,
        assetData.locationName,
        assetData.buildingType,
      )?.strandingYear,
    ),
    portfoliStrandingThreshold,
  );
};

export const getAssetPermormanceThresholds = (
  assetData: IAssetDataRequiredForReference,
) => {
  const defaulThresholds = thresholdsReferenceLibrary.default.default;

  const customThresholds = getCustomReference(
    thresholdsReferenceLibrary,
    assetData.locationName,
    assetData.buildingType,
  ) as IThresholds;

  return merge(defaulThresholds, customThresholds);
};

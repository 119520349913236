import { Box } from "@chakra-ui/react";
import React from "react";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import DeviateFromExpectedPerformance from "../components/DeviateFromExpectedPerformance";
import RatingAgainstActualPerformance from "../components/RatingAgainstActualPerformance";
import { StandardContent } from "src/common/components/templates/StandardContent";
import { Alert } from "src/common/components/atoms/Alert";

type TProps = {
  asset: IAppAsset;
  units: string;
};
export const InefficientAssetsTab: React.FC<TProps> = ({ asset, units }) => {
  const available =
    asset.epc.rating && asset.epc.actualPerformance ? true : false;

  if (!available)
    return (
      <Box mt=".5em">
        <Alert message="No data provided for analysis" />
      </Box>
    );

  return (
    <StandardContent
      chartAreaContent={
        <Box p="0.5rem">
          {available && (
            <RatingAgainstActualPerformance
              epcRating={asset.epc.rating!}
              epcPerformance={asset.epc.actualPerformance!}
              expectedIntensity={asset.epc.intensity}
              actualIntensity={asset.epc.intensity || 0}
              units={units}
            />
          )}
        </Box>
      }
    >
      <Box width="100%" p="0.5rem">
        {available && (
          <DeviateFromExpectedPerformance
            epcRating={asset.epc.rating!}
            epcPerformance={asset.epc.actualPerformance!}
          />
        )}
      </Box>
    </StandardContent>
  );
};

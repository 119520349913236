import React from "react";
import { Table, Th, Td, Box } from "@chakra-ui/react";
import { IAppAsset, IAppTaxonomy } from "src/redux/interfaces/IAppAsset";
import { AssetsTableHeader } from "./components/AssetsTableHeader";
import { AssetsTableBody } from "./components/AssetsTableBody";
import { ComplianceIndicator } from "../../atoms/ComplianceIndicator";
interface IProps {
  assets: IAppAsset[];
  selectedAssetIds: number[];
}

const taxonomyCategories: string[] = ["ccm", "cca", "tce", "wrm", "ppc", "bde"];
type THarmCategory = "ccm" | "cca" | "bde";
const isTrueOfUndefined = (value?: boolean) => value || value === undefined;

const evaluateTaxonomyCategoryCompliance = (
  taxonomy: IAppTaxonomy,
  category: string,
): boolean => {
  if (taxonomy.substantial_contribution_type === category) {
    return taxonomy.substantial_contribution_compliance || false;
  }

  const noHarm: (undefined | boolean)[] | undefined =
    taxonomy.doNoSignificantHarm[category as unknown as THarmCategory];
  if (noHarm) {
    return isTrueOfUndefined(noHarm[0]) && isTrueOfUndefined(noHarm[1]);
  }
  return true;
};

const _evaluateTaxonomyCompliance = (taxonomy?: IAppTaxonomy): boolean => {
  if (!taxonomy) return false;
  return taxonomyCategories
    .map((c) => evaluateTaxonomyCategoryCompliance(taxonomy, c))
    .reduce((a, b) => a && b, true);
};

const _shouldDisplayCircle = (
  taxonomy: IAppTaxonomy,
  category: string,
): boolean => {
  if (taxonomy.substantial_contribution_type === category) return true;
  return (
    taxonomy.doNoSignificantHarm[category as unknown as THarmCategory] &&
    (taxonomy.doNoSignificantHarm[category as unknown as THarmCategory][0] !==
      undefined ||
      taxonomy.doNoSignificantHarm[category as unknown as THarmCategory][1] !==
        undefined)
  );
};

export const TaxonomyCheckList: React.FC<IProps> = ({
  assets,
  selectedAssetIds,
}) => {
  return (
    <Box overflowY="auto" maxHeight="500px">
      <Table variant="simple" width="100%" backgroundColor="white">
        <AssetsTableHeader
          selectedAssetIds={selectedAssetIds}
          filteredAssetsCnt={assets.length}
        >
          <Th>CCM</Th>
          <Th>CCA</Th>
          <Th>TCE</Th>
          <Th>WRM</Th>
          <Th>PCP</Th>
          <Th>B&E</Th>
          <Th>Compliance</Th>
        </AssetsTableHeader>
        <AssetsTableBody
          assets={assets}
          selectedAssetIds={selectedAssetIds}
          tabName="taxonomy"
          renderCustomTds={(asset) => (
            <>
              {taxonomyCategories.map((c) => (
                <Td key={c}>
                  {asset.taxonomy && _shouldDisplayCircle(asset.taxonomy, c) ? (
                    <ComplianceIndicator
                      compliance={evaluateTaxonomyCategoryCompliance(
                        asset.taxonomy,
                        c,
                      )}
                      substantialContribution={
                        asset.taxonomy.substantial_contribution_type === c
                      }
                    />
                  ) : null}
                </Td>
              ))}

              <Td>
                {_evaluateTaxonomyCompliance(asset.taxonomy) ? "Yes" : "No"}
              </Td>
            </>
          )}
        />
      </Table>
    </Box>
  );
};

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchAssets } from "src/redux/thunks/assets";

export interface IModelSelectionState {
  selectedModelVersionId?: number;
}

const slice = createSlice({
  name: "modelSelection",
  initialState: {} as IModelSelectionState,
  reducers: {
    setSelectedModelId: (state, action: PayloadAction<number>) => {
      state.selectedModelVersionId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAssets.pending, (state) => {
      state.selectedModelVersionId = undefined;
    });
    builder.addCase(fetchAssets.fulfilled, (state, { payload }) => {
      // on assets update find any model version id that exists in assets
      const anyModelVersionId = payload
        .map((asset) => asset.ghgResults.targets)
        .flat(1)[0]?.modelVersionId;

      state.selectedModelVersionId = anyModelVersionId || undefined;
    });
  },
});

export const { setSelectedModelId } = slice.actions;
export default slice;

// Checks if all points in range exist and if not fills with average (linear approximation)

import { ITimeSeriesPoint } from "src/api/open-api";
import { sortTimeSeries } from "./sortTimeSeries";

// this function expects array to be sorted by year
export const findMaxYearIndex = (
  array: ITimeSeriesPoint[],
  maxYear: number,
): number => {
  const lastIndex = array.findIndex((ts) => ts.year > maxYear);
  return lastIndex === -1 ? array.length - 1 : lastIndex - 1;
};

// this function expects array to be sorted by year
export const findMinYearIndex = (
  array: ITimeSeriesPoint[],
  minYear: number,
): number => {
  const lastIndex = array.findIndex((ts) => ts.year >= minYear);
  return lastIndex === -1 ? array.length - 1 : lastIndex;
};

// This algorithm is NOT a part of methodology and just uses common sense for showing graphs and avoiding frontend errors if pathway data is not complete.
export const normalizeTimeSeries = (
  pathway: ITimeSeriesPoint[],
  minYear: number,
  maxYear: number,
  fillWithZeros?: boolean,
) => {
  const sortedPathway = sortTimeSeries(pathway);
  const minIndex = findMinYearIndex(sortedPathway, minYear);
  const maxIndex = findMaxYearIndex(sortedPathway, maxYear);

  const result = [];
  let latestIndexUsed = minIndex;
  for (let year = minYear; year <= maxYear; year++) {
    const ind = sortedPathway.findIndex((ts) => ts.year === year);
    if (ind > -1) {
      // if we have actual data: add it to array
      result.push({ ...sortedPathway[ind] });
      latestIndexUsed = ind;
    } else if (latestIndexUsed !== maxIndex) {
      if (fillWithZeros) {
        // if we want values to be zero
        result.push({ year: year, value: 0 });
      } else {
        // otherwise fill with average values
        const base = sortedPathway[latestIndexUsed].value;
        const biggestValue = sortedPathway[latestIndexUsed + 1].value;
        const yearGap =
          sortedPathway[latestIndexUsed + 1].year -
          sortedPathway[latestIndexUsed].year;
        const diffPerYear = (biggestValue - base) / yearGap;
        const yearCnt = year - sortedPathway[latestIndexUsed].year;
        const valueToAdd = yearCnt * diffPerYear;
        result.push({ year: year, value: base + valueToAdd });
      }
    }
  }

  return result;
};

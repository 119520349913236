import React from "react";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { closest50 } from "src/common/utils/math";
import { Performance } from "src/common/components/molecules/PerformanceTable/references";
import { DottedSlider } from "./DottedSlider";
import {
  getDecarbCostBreakpointsForAssets,
  getDecarbCostBreakpointsForAsset,
} from "./utils";

interface IProps {
  assets: IAppAsset[];
  asset?: IAppAsset;
  isPortfolio?: boolean;
  units: string;
}

export const DecarbonisationCostSlider: React.FC<IProps> = ({
  assets,
  asset,
  isPortfolio,
  units,
}) => {
  const totalCosts = assets
    .map((a) => a.capex?.capex_cost_decarb ?? 0)
    .reduce((a, b) => a + b, 0);
  const totalArea = assets.map((a) => a.area || 0).reduce((a, b) => a + b, 0);
  const averageDecarbCost = closest50(Math.round(totalCosts / totalArea));
  const assetDecarbCost =
    closest50(asset?.capex?.capex_cost_decarb_per_meter || 0) || null;

  const breakpoints = isPortfolio
    ? getDecarbCostBreakpointsForAssets(assets)
    : getDecarbCostBreakpointsForAsset(asset);

  const assetPoint = assetDecarbCost
    ? {
        value: assetDecarbCost,
        label: "Asset:",
      }
    : undefined;

  const averagePoint = {
    value: averageDecarbCost,
    label: isPortfolio ? "" : "Portfolio:",
  };

  return (
    <DottedSlider
      valueBelow={averagePoint}
      valueAbove={isPortfolio ? undefined : assetPoint}
      breakpoints={{
        low: breakpoints[Performance.Low],
        medium: breakpoints[Performance.Medium],
        high: breakpoints[Performance.High],
      }}
      isPortfolio={isPortfolio}
      units={units}
    />
  );
};

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { unique } from "src/common/utils/aggregation";
import { defaultRiskLevels } from "src/constants/riskLevel";
import {
  IAssetFilters,
  TUpdateFilterPayload,
} from "src/redux/interfaces/IAssetFilter";
import { fetchAssets } from "src/redux/thunks/assets";

const initialState: IAssetFilters = {
  selectedPartialName: "",
  buildingTypes: [],
  countryCodes: [],
  riskLevels: [],
  selectedBuildingTypes: [],
  selectedCountries: [],
  selectedRiskLevels: [],
  selectedIds: [],
  allIds: [], // literally all asset ids, not only assets after applied filters
};
const slice = createSlice({
  name: "assetsFilter",
  initialState: initialState,
  reducers: {
    toggleAllAssetsIds: (
      state: IAssetFilters,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.selectedIds = payload ? state.allIds : [];
    },
    toggleAssetId: (
      state: IAssetFilters,
      { payload }: PayloadAction<number>,
    ) => {
      const index = state.selectedIds.indexOf(payload);
      if (index < 0) {
        state.selectedIds = [...state.selectedIds, payload];
      } else {
        state.selectedIds = state.selectedIds.filter((id) => id !== payload);
      }
    },
    updateFilter: (
      state: IAssetFilters,
      { payload }: PayloadAction<TUpdateFilterPayload>,
    ) => {
      state.selectedIds = state.allIds;
      if (payload.filterType === "selectedPartialName") {
        state.selectedPartialName = payload.filterValues;
      } else {
        state[payload.filterType] = payload.filterValues;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAssets.pending, (state) => {
      state = initialState;
    });
    builder.addCase(fetchAssets.fulfilled, (state, { payload }) => {
      // refresh ids
      const allIds = payload.map((a) => a.assetId);
      state.allIds = allIds;
      state.selectedIds = allIds;

      // update filter options
      state.countryCodes = unique(
        payload.map((asset) => asset.location.isoCountryCode),
      );
      const allBuildingTypes = payload
        .map((asset) => asset.businessBuildingTypes)
        .flat(1);
      state.buildingTypes = unique(
        allBuildingTypes.map((t) => t.business_building_type_id),
      ).map((typeId) => ({
        label: allBuildingTypes.find(
          (t) => t.business_building_type_id === typeId,
        )?.business_building_type_label as string,
        value: String(
          allBuildingTypes.find((t) => t.business_building_type_id === typeId)
            ?.business_building_type_id || "",
        ),
      }));
      state.riskLevels = defaultRiskLevels.map((level) => ({
        ...level,
        value: String(level.value),
      }));

      // reset all selected values
      state.selectedCountries = [];
      state.selectedBuildingTypes = [];
      state.selectedPartialName = "";
      state.selectedRiskLevels = [];
    });
  },
});

export const { updateFilter, toggleAllAssetsIds, toggleAssetId } =
  slice.actions;
export default slice;

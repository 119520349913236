import React from "react";
import { Box, Text, Flex, VStack } from "@chakra-ui/react";
import { Performance } from "src/common/components/molecules/PerformanceTable/references";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { getCapexReferences } from "src/common/components/molecules/PerformanceTable/getReference";
import { riskToColor } from "src/common/utils/colors/colors";
import { getCapexPerformance } from "src/common/utils/getCapexPerformance";

interface IProps {
  asset: IAppAsset;
}

const AssetDecarbonisationCosts: React.FC<IProps> = ({ asset }) => {
  const breakpoints = getCapexReferences({
    buildingType: asset.primaryBusinessBuildingType,
    locationName: asset.location.businessLocationName,
  });

  const performance = getCapexPerformance(
    asset.capex?.capex_cost_decarb_per_meter || 0,
    breakpoints,
  );

  const getLabel = () => {
    if (performance === undefined) return "";
    else if (performance > Performance.Medium) return "Low";
    else if (performance > Performance.Low) return "Med.";
    else return "High";
  };

  const getText = () => {
    if (performance === undefined) {
      return "";
    } else if (performance > Performance.Medium) {
      return <Text>Only smaller interventions required.</Text>;
    } else if (performance > Performance.Low) {
      return (
        <Text>
          Smaller interventions at the beginning and adjustment of central
          systems in a second step.
        </Text>
      );
    } else {
      return <Text>Full refurbishment required.</Text>;
    }
  };

  const renderPerformanceInfo = () => {
    return (
      <Flex
        justifyContent="start"
        align="center"
        w="100%"
        margin="auto"
        py="5%"
        borderY="1px solid"
      >
        <Box
          backgroundColor={riskToColor(2 - performance)}
          boxSize="2rem"
          borderRadius="50%"
        />
        <Text fontSize="20px" ml={3} color={riskToColor(2 - performance)}>
          {getLabel()}
        </Text>
      </Flex>
    );
  };

  return (
    <VStack fontSize="small" align="left" py="0.5rem" w="100%" pb="1.5rem">
      <Text fontWeight="bold" textAlign="left" mb={2}>
        Asset Decarbonisation Costs
      </Text>
      {renderPerformanceInfo()}
      <Box mt={1}>{getText()}</Box>
    </VStack>
  );
};

export default AssetDecarbonisationCosts;

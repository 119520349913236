import React from "react";
import { Box, Text, Image, Flex } from "@chakra-ui/react";

interface IProps {
  name: string;
  projects: number;
  img?: string;
  children: React.ReactElement;
}

export const BadgeItem: React.FC<IProps> = ({
  name,
  projects,
  img,
  children,
}) => {
  const projText = projects === 1 ? "project" : "projects";
  const certification = name.split(" ");

  return (
    <Flex
      fontSize={{ base: "9px", lg: "11px" }}
      alignItems="center"
      data-testid="BadgeItem"
    >
      <Box mr="1rem">
        {children}
        <Image src={img} maxW={{ base: "3rem", lg: "4rem" }} />
      </Box>
      <Flex flexDir="column">
        {certification.map((word, i) => (
          <Text
            data-testid="names"
            key={i}
            textTransform="uppercase"
            fontWeight="bold"
            as="span"
          >
            {word}
          </Text>
        ))}
        <Flex flexDir="row" alignItems="baseline">
          <Text fontWeight="bold" mr="0.25rem" fontSize="heading2">
            {projects}
          </Text>
          <Text as="span">{projText}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

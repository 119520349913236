import { EntityState, createDraftSafeSelector } from "@reduxjs/toolkit";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { assetAdapter } from "./adapter";
import { selectSlice } from "./selectors";

const _selectOpenedAsset = (
  assetState: EntityState<IAppAsset> & { selectedAssetId: number | null },
) =>
  assetAdapter
    .getSelectors()
    .selectById(assetState, assetState.selectedAssetId || -1);
export const selectOpenedAsset = createDraftSafeSelector(
  [selectSlice],
  (assetState) => _selectOpenedAsset(assetState),
);

export const selectGHGTargetsForOpenedAsset = createDraftSafeSelector(
  [selectSlice],
  (assetState) => _selectOpenedAsset(assetState)?.ghgResults.targets || [],
);

export const selectEnergyTargetsForOpenedAsset = createDraftSafeSelector(
  [selectSlice],
  (assetState) => _selectOpenedAsset(assetState)?.energyResults.targets || [],
);

export const selectOpexLineForOpenedAsset = createDraftSafeSelector(
  [selectSlice],
  (assetState) => {
    return _selectOpenedAsset(assetState)?.opex?.opexForecast || null;
  },
);

import React, { useState } from "react";
import { IReduxChartTarget } from "src/redux/interfaces/IReduxChartTarget";
import { ITimeSeriesPoint } from "src/api/open-api";
import {
  Flex,
  Box,
  RadioGroup,
  Stack,
  Radio,
  Text,
  Icon,
} from "@chakra-ui/react";
import { EmissionsChart } from "../../atoms/Chart/EmissionsChart";
import { getColorNameByHex } from "src/common/utils/colors/colors";
import { ReactComponent as InfoIcon } from "src/assets/icons/info-icon.svg";

interface IProps {
  forecast: ITimeSeriesPoint[];
  isSelectable?: boolean;
  selectedTargetIndex?: number;
  scenarios: IReduxChartTarget[];
  selectableTargets: IReduxChartTarget[];
  staticTargets: IReduxChartTarget[];
  errorMessage?: string;
  warningMessages?: string[];
  units: string;
}

export const SelectableChart: React.FC<IProps> = ({
  forecast,
  scenarios,
  selectableTargets,
  errorMessage,
  warningMessages,
  units,
  staticTargets,
}) => {
  const [selectedPathway, setSelectedPathway] = useState(0);
  const targets = [
    ...selectableTargets.filter((_, i) => i === selectedPathway),
    ...staticTargets,
  ];
  return (
    <Flex overflowY="hidden" w="100%" pb="1.1rem" mt="0rem">
      <Box width="calc(100% - 132px)">
        {units ? (
          <Text
            color="gray.500"
            fontSize="small"
            display="inline-block"
            pl="5px"
          >
            ({units}, 2020-2050)
          </Text>
        ) : null}
        <EmissionsChart
          targets={targets}
          forecast={forecast}
          scenarios={scenarios}
          year={0}
          aspectRatio={3 / 2}
        />
      </Box>
      <Flex
        minWidth="130px"
        width="26%"
        direction="column"
        justifyContent="space-between"
        pl="0.5rem"
        pb="1.5rem"
      >
        <Box>
          {errorMessage ? (
            <Flex
              direction="row"
              alignItems="center"
              backgroundColor="red.50"
              mb="10px"
              padding="2px 5px"
            >
              <Icon as={InfoIcon} fill="red.500" />
              <Text
                color="red.400"
                fontSize="xs"
                display="inline-block"
                pl="5px"
              >
                {errorMessage}
              </Text>
            </Flex>
          ) : null}
          {warningMessages?.length
            ? warningMessages.map((message) => (
                <Flex
                  key={message}
                  direction="row"
                  alignItems="center"
                  backgroundColor="yellow.50"
                  mb="10px"
                  padding="2px 5px"
                >
                  <Icon as={InfoIcon} fill="yellow.500" />
                  <Text
                    color="yellow.500"
                    fontSize="xs"
                    display="inline-block"
                    pl="5px"
                  >
                    {message}
                  </Text>
                </Flex>
              ))
            : null}
        </Box>
        <Box>
          {scenarios.map((line) => (
            <Flex mb="0.4rem" key={line.targetName}>
              <Box alignSelf="center" w="20px" h="0.2rem" bg={line.lineColor} />
              <Text marginLeft="0.5rem" fontSize="12px">
                {line.targetName}
              </Text>
            </Flex>
          ))}
          {staticTargets.map((line) => (
            <Flex mb="0.4rem" key={line.targetName}>
              <Box alignSelf="center" w="20px" h="0.2rem" bg={line.lineColor} />
              <Text marginLeft="0.5rem" fontSize="12px">
                {line.targetName}
              </Text>
            </Flex>
          ))}
          <Flex mb="0.4rem">
            <Box alignSelf="center" w="20px" h="0.2rem" bg="gray.500" />
            <Text marginLeft="0.5rem" fontSize="12px">
              Asset Forecast
            </Text>
          </Flex>
          <RadioGroup
            onChange={(v) => setSelectedPathway(Number(v))}
            value={String(selectedPathway)}
            size="md"
          >
            <Stack direction="column">
              {selectableTargets.map((p, index) => (
                <Radio
                  colorScheme={getColorNameByHex(p.lineColor)}
                  key={p.targetName}
                  value={String(index)}
                >
                  <Text
                    as="span"
                    fontSize="12px"
                    lineHeight={1.3}
                    display="block"
                    ml="0.3rem"
                  >
                    {p.targetName}
                  </Text>
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </Box>
      </Flex>
    </Flex>
  );
};

import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import MixedChartTwoAxis from "../../atoms/Chart/MixedChartTwoAxis";
import { ITimeSeriesPoint } from "src/api/open-api";
import { IReduxChartTarget } from "src/redux/interfaces/IReduxChartTarget";
import { theme } from "src/config/theme";

interface IProps {
  forecastOpEx: ITimeSeriesPoint[];
  scenarioOpEx: Omit<IReduxChartTarget, "modelVersionId" | "strandingX">;
  scenarioCapexEol?: ITimeSeriesPoint[];
  scenarioDecarbCapex?: ITimeSeriesPoint[];
  isoCurrecyCode?: string;
}

const forecastColor = theme.colors.gray["500"];
export const scenarioColor = "#BB00AA";

const CostChart: React.FC<IProps> = ({
  forecastOpEx,
  scenarioOpEx,
  scenarioCapexEol,
  scenarioDecarbCapex,
  isoCurrecyCode,
}) => {
  const isLoaded = scenarioOpEx?.timeSeries?.length || forecastOpEx?.length;
  if (!isLoaded) {
    return <Box h="100%" w="100%" />;
  }

  return (
    <Flex
      fontSize="small"
      flexDir="column"
      marginRight="0.5rem"
      justifyContent="space-between"
      align="left"
    >
      <Box>
        <Flex ml="1rem" textColor="black.200" justifyContent="space-between">
          <Text display="inline-block">CAPEX</Text>
          <Text display="inline-block">OPEX</Text>
        </Flex>
        <Box overflowY="hidden" w="100%" pb="1.1rem">
          <MixedChartTwoAxis
            isoCurrecyCode={isoCurrecyCode}
            lines={[
              {
                ...scenarioOpEx,
                targetName: scenarioOpEx.targetName + " OPEX",
              },
              {
                timeSeries: forecastOpEx,
                targetName: "Forecast OPEX",
                lineColor: forecastColor,
              },
            ]}
            bars={[
              {
                timeSeries: scenarioCapexEol || [],
                label: scenarioOpEx.targetName + " CAPEX BAU",
                color: forecastColor,
              },
              {
                timeSeries: scenarioDecarbCapex || [],
                label: scenarioOpEx.targetName + " CAPEX Decarb",
                color: scenarioColor,
              },
            ]}
          />
        </Box>
      </Box>

      <Flex justifyContent="space-between" w="100%" alignItems="center">
        <Box alignItems="baseline">
          <Flex alignItems="baseline">
            <Box alignSelf="center" w="1.5rem" h="2px" bg={forecastColor} />
            <Text color="gray.500" marginLeft="1rem" mb="0.3rem">
              {scenarioOpEx.targetName} CAPEX BAU
            </Text>
          </Flex>
          <Flex alignItems="baseline">
            <Box alignSelf="center" w="1.5rem" h="2px" bg={scenarioColor} />
            <Text color="gray.500" marginLeft="1rem" mb="0.3rem">
              {scenarioOpEx.targetName} CAPEX Decarb
            </Text>
          </Flex>
        </Box>
        <Box>
          <Flex alignItems="baseline">
            <Box
              alignSelf="center"
              w="1.5rem"
              h="0"
              borderBottom="2px dashed #6F7776"
            />
            <Text color="gray.500" marginLeft="1rem" mb="0.3rem">
              Forecast OPEX
            </Text>
          </Flex>
          <Flex alignItems="baseline">
            <Box
              alignSelf="center"
              w="1.5rem"
              h="0"
              borderBottom="2px dashed #BB00AA"
            />
            <Text color="gray.500" marginLeft="1rem" mb="0.3rem">
              {scenarioOpEx.targetName} OPEX
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};
export default CostChart;

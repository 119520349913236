import React from "react";
import {
  Grid,
  GridItem,
  Text,
  Icon,
  Flex,
  useDisclosure,
  Box,
  Button,
} from "@chakra-ui/react";
import { IAppAsset, IAppTaxonomy } from "src/redux/interfaces/IAppAsset";
import TaxonomyInfoModal from "src/pages/PortfolioView/components/Taxonomy/TaxonomyInfoModal";

import { ReactComponent as Square } from "src/assets/icons/square.svg";
import { ReactComponent as CheckSquare } from "src/assets/icons/check-square.svg";
import { ReactComponent as CheckX } from "src/assets/icons/x-square.svg";
import { InfoOutlineIcon } from "@chakra-ui/icons";

type TaxonomyCategory = "ccm" | "cca" | "tce" | "wrm" | "ppc" | "bde";

interface IProps {
  asset: IAppAsset;
  icon: React.FC;
  label: string;
  category: TaxonomyCategory;
}

const data: {
  [key: string]: {
    substantialContribution: string;
    doNotSignificantlyHarm?: string[];
  };
} = {
  ccm: {
    substantialContribution: "EPC Class A or top 15% of the market",
    doNotSignificantlyHarm: [
      "Risk assessment",
      "Implement adaptation measures in 5 years",
    ],
  },
  cca: {
    substantialContribution:
      "Risk assessment & Implemented physical and non-physical solutions",
    doNotSignificantlyHarm: [
      "Min EPC class C",
      "Building not dedicated to fossil fuels (extraction, storage, transport or manufacture)",
    ],
  },
  tce: {
    substantialContribution: "TBC",
  },
  wrm: {
    substantialContribution: "TBC",
  },
  ppc: {
    substantialContribution: "TBC",
  },
  bde: {
    substantialContribution:
      "Biodiversity strategy or management plan for the site",
    doNotSignificantlyHarm: [
      "Risk assessment",
      "Implement adaptation measures in 5 years",
    ],
  },
};

const substantialIcon = (taxonomy?: IAppTaxonomy) =>
  taxonomy?.substantial_contribution_compliance ? CheckSquare : CheckX;

const dnshIcon = (
  taxonomy: IAppTaxonomy,
  category: TaxonomyCategory,
  actionIdx: number,
) => {
  const dnshc =
    taxonomy.doNoSignificantHarm[category as unknown as "ccm" | "cca" | "bde"];

  if (dnshc) {
    return dnshc[actionIdx] ? CheckSquare : CheckX;
  } else {
    return Square;
  }
};

const TaxonomyAssetCard: React.FC<IProps> = (props) => {
  const { asset, icon, label, category } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isSubstantial =
    asset.taxonomy?.substantial_contribution_type === category;

  const dnshRequirements = data[category].doNotSignificantlyHarm;

  const isRelevant = isSubstantial || dnshRequirements;

  return (
    <Box p={{ base: 1, xl: 4 }} h="100%">
      <Grid w="100%" gap={3}>
        {/* Header */}
        <GridItem>
          <Flex
            w="100%"
            direction={{ base: "column", sm: "row" }}
            justify="space-between"
            align="center"
          >
            <Box
              border="2px solid transparent"
              borderRadius="100%"
              borderColor={isSubstantial ? "primary.500" : ""}
              padding="2px"
              w="48px"
              h="48px"
            >
              <Icon
                as={icon}
                fill={isRelevant ? "gray.600" : "gray.200"}
                boxSize="40px"
              />
            </Box>
            {isRelevant && (
              <Button
                leftIcon={<InfoOutlineIcon color="gray.500" />}
                color="gray.500"
                size="sm"
                variant="ghost"
                alignSelf="center"
                _hover={{ bg: "white", cursor: "pointer" }}
                fontSize="small"
                onClick={onOpen}
              >
                Info
              </Button>
            )}
          </Flex>
        </GridItem>
        {/* Label */}
        <GridItem>
          <Text
            color={isRelevant ? "gray.700" : "gray.200"}
            fontSize={{ base: ".85rem", xl: "1rem" }}
            fontWeight="bold"
          >
            {label}
          </Text>
        </GridItem>
        {isSubstantial && (
          // Substantial Contribution
          <GridItem color="red.500">
            <Grid>
              <GridItem mb={1}>
                <Text fontSize="xs" fontWeight="bold">
                  Substantial Contribution
                </Text>
              </GridItem>
              <GridItem>
                <Grid templateColumns="repeat(10, 1fr)">
                  <GridItem colSpan={2} display="flex" alignItems="start">
                    <Icon
                      as={substantialIcon(asset.taxonomy)}
                      fill="transparent"
                      boxSize="20px"
                    />
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Text fontSize="xs">
                      {data[category].substantialContribution}
                    </Text>
                  </GridItem>
                </Grid>
              </GridItem>
            </Grid>
          </GridItem>
        )}
        <GridItem color="gray.800">
          <Grid gap={1}>
            <GridItem>
              <Text fontSize="xs" fontWeight="bold">
                {dnshRequirements && dnshRequirements.length > 0
                  ? "Do no significant harm"
                  : "No requirements"}
              </Text>
            </GridItem>
            {dnshRequirements &&
              dnshRequirements.map(
                (item, index) =>
                  asset.taxonomy && (
                    <GridItem key={`doNotSignificantlyHarm${index}`}>
                      <Grid templateColumns="repeat(10, 1fr)">
                        <GridItem colSpan={2} display="flex" alignItems="start">
                          <Icon
                            as={dnshIcon(asset.taxonomy, category, index)}
                            color="gray.700"
                            fill="transparent"
                            boxSize="20px"
                          />
                        </GridItem>
                        <GridItem colSpan={8}>
                          <Text fontSize="xs">{item}</Text>
                        </GridItem>
                      </Grid>
                    </GridItem>
                  ),
              )}
          </Grid>
        </GridItem>
      </Grid>

      <TaxonomyInfoModal
        isOpen={isOpen}
        onClose={onClose}
        category={category}
      />
    </Box>
  );
};

export default TaxonomyAssetCard;

import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";

interface IProps {
  epcRating: string;
  epcPerformance: string;
}

const DeviateFromExpectedPerformance: React.FC<IProps> = (props) => {
  const { epcRating, epcPerformance } = props;

  const deviate = epcRating !== epcPerformance;

  return (
    <Box width="100%" mb={4}>
      <Text fontSize="small" fontWeight="700" mb={3}>
        Significantly Deviate from Expected Performance
      </Text>
      <Flex flexDirection="row" alignItems="center" width="100%">
        <Box
          backgroundColor={deviate ? "#E61E28" : "#5DB758"}
          boxSize="2rem"
          borderRadius="50%"
        />
        <Text ml={2} color={deviate ? "#E61E28" : "#5DB758"} fontWeight="700">
          {deviate ? "Yes" : "No"}
        </Text>
      </Flex>
    </Box>
  );
};

export default DeviateFromExpectedPerformance;

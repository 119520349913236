import React from "react";
import {
  Alert as ChakraUiAlert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";

type TProps = {
  isError?: boolean;
  message: string;
  index?: number;
  mt?: string;
};

export const Alert: React.FC<TProps> = ({ isError, message, index, mt }) => {
  return (
    <ChakraUiAlert
      status={isError ? "error" : "warning"}
      key={index}
      mb="2px"
      mt={mt || "0"}
      borderRadius="8px"
      backgroundColor={isError ? "red.50" : "orange.50"}
      data-testid="Alert"
    >
      <AlertIcon fill="currentcolor" />
      <AlertDescription fontSize="0.9em" lineHeight="1.5">
        {message}
      </AlertDescription>
    </ChakraUiAlert>
  );
};

import React from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  HStack,
  Spacer,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import UserSearchForm from "./UserSearchForm";
import AddUserForm from "./AddCrispUserForm";
import { ICompany } from "src/api/open-api";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { IAppCompanyUser } from "src/redux/interfaces/IAppCompanyUser";
import AddEntityModal from "../AddEntityModal";
import UsersTable from "../UsersTable";
import {
  deleteCompanyPermissions,
  fetchCompanyUsers,
} from "src/redux/thunks/admin";
import { selectAllCompanyPermissions } from "src/redux/slices/admin/permissions/selectors";
import { hardcodedEntityTypes } from "src/constants/accessEntityTypes";

interface IProps {
  company: ICompany;
  users: IAppCompanyUser[];
}

const CompanyUserList: React.FC<IProps> = ({ company, users }) => {
  const toast = useToast();
  const {
    isOpen: addUserModalOpen,
    onClose: addUserOnClose,
    onOpen: addUserOnOpen,
  } = useDisclosure();
  const dispatch = useAppDispatch();
  const companyPermissions = useAppSelector(selectAllCompanyPermissions);

  const {
    isOpen: createUserModalOpen,
    onClose: createUserOnClose,
    onOpen: createUserOnOpen,
  } = useDisclosure();

  const toastMessage = (title: string, description: string) => {
    toast({
      title: title,
      description: description,
      status: "error",
      isClosable: true,
      position: "top",
    });
  };

  const createUser = () => {
    addUserOnClose(); //if open, close the search user modal
    createUserOnOpen(); //trigger new/edit user form
  };

  const editUser = () => {
    addUserOnClose();
    createUserOnClose();
    dispatch(fetchCompanyUsers(company.company_id));
  };

  const deleteUser = async (userId: number) => {
    if (company) {
      const permissionIds = companyPermissions
        .filter(
          (p) =>
            p.user_id === userId &&
            p.entity_type_id === Number(hardcodedEntityTypes.company) &&
            p.entity_id === company.company_id,
        )
        .map((p) => p.company_level_permission_id);
      return dispatch(deleteCompanyPermissions(permissionIds))
        .then(() => {
          dispatch(fetchCompanyUsers(company.company_id));
        })
        .catch(() => {
          toastMessage(
            "Delete Error",
            `Error deleting user, please try again.`,
          );
        });
    }
    return Promise.reject();
  };

  const addUserCallback = () => {
    createUserOnClose();
    addUserOnClose();
  };

  return (
    <>
      <HStack>
        <Spacer />
        <Button
          justifySelf="right"
          variant="outlinePrimary"
          placeSelf={{ base: "center" }}
          onClick={addUserOnOpen}
        >
          Add User
        </Button>
      </HStack>
      <AddEntityModal
        isOpen={addUserModalOpen}
        onClose={addUserOnClose}
        title={"Add user to company"}
      >
        <UserSearchForm
          onSubmitCallback={addUserCallback}
          createUserCallback={createUser}
          companyId={company.company_id}
        />
      </AddEntityModal>
      <AddEntityModal
        isOpen={createUserModalOpen}
        onClose={createUserOnClose}
        title={"Create new company user"}
      >
        <AddUserForm
          onSubmitCallback={addUserCallback}
          companyId={company.company_id}
        />
      </AddEntityModal>

      {users ? (
        <UsersTable
          items={users}
          editActionCallback={editUser}
          deleteActionCallback={deleteUser}
          isCompanyLevel
        />
      ) : (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>Error loading users.</AlertDescription>
        </Alert>
      )}
    </>
  );
};

export default CompanyUserList;

import React from "react";
import { Box, Link, Heading, Text } from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";

const AdminSideBarLink: React.FC<{
  path: string;
  locationPathname: string;
  label: string;
  description: string;
}> = (item) => {
  const isSelected = item.path === item.locationPathname;
  return (
    <Link
      as={RouterLink}
      to={item.path}
      key={item.path}
      _hover={{ textDecoration: "none" }}
    >
      <Box
        backgroundColor={isSelected ? "#f5f6f7" : "transparent"}
        padding="0.5rem"
        _hover={{ backgroundColor: "#f9f9f9" }}
      >
        <Text
          color={isSelected ? "red.500" : ""}
          fontWeight={isSelected ? "bold" : "normal"}
        >
          {item.label}
        </Text>
        <Text fontSize="xs">{item.description}</Text>
      </Box>
    </Link>
  );
};

export const AppAdminSidebar: React.FC<{ isSystemAdmin?: boolean }> = ({
  isSystemAdmin,
}) => {
  const location = useLocation();

  return (
    <>
      <Text mt="16px" as="h2" color="red.500" fontSize="xs">
        Admin
      </Text>
      <Heading as="h3" fontSize="xl" fontWeight="400" mb="1rem">
        Settings
      </Heading>
      <AdminSideBarLink
        path="/admin/users"
        locationPathname={location.pathname}
        label="Users"
        description="Edit list of users in the CRISP, modify their admin status"
      />
      <AdminSideBarLink
        path="/admin/companies"
        locationPathname={location.pathname}
        label="Companies"
        description="View and edit company data, modify users' permissions inside of a company"
      />
      {isSystemAdmin ? (
        <AdminSideBarLink
          path="/admin/recalc"
          locationPathname={location.pathname}
          label="Recalculate"
          description="Run full data update"
        />
      ) : null}
    </>
  );
};

import { savePortfolioFile } from "src/redux/thunks/files";
import { savePortfolio } from "src/redux/thunks/portfolios";
import { saveAssets } from "src/redux/thunks/assets";
import i18next from "i18next";
import { genErrorMessageOutsideRedux } from "src/common/utils/errors";
import { AssetPayload, PortfolioPayload } from "src/api/open-api";

export const ingestPortfolio = async (
  portfolio: PortfolioPayload,
  assets: AssetPayload[],
  file: File | null,
  t: typeof i18next.t,
  portfolioId?: number,
): Promise<Error[]> => {
  let id;
  if (!file) return [new Error("File does not exist")];
  try {
    // Submit portfolio
    const newPortfolioId = await savePortfolio(portfolio!, portfolioId);
    id = newPortfolioId || (portfolioId as number);
    await saveAssets({ portfolioId: id, assets: assets || [] });
  } catch (error) {
    const message = genErrorMessageOutsideRedux(
      // @ts-expect-error error type unknown
      error?.response?.data?.errorCode ? error?.response?.data : error,
      t,
      "Cannot save portfolio data: ",
    );

    return [new Error(message)];
  }

  try {
    // Save Portfolio File
    await savePortfolioFile(id, file);
  } catch (error) {
    const message = genErrorMessageOutsideRedux(
      // @ts-expect-error error type unknown
      error?.response?.data?.errorCode ? error?.response?.data : error,
      t,
      "Cannot save portfolio file: ",
    );
    return [new Error(message)];
  }

  return [];
};

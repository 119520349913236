import { EntityState, createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { IAssetFilters } from "src/redux/interfaces/IAssetFilter";
import { selectSlice as selectCountriesSlice } from "src/redux/slices/countries/selectors";
import { ICountry } from "src/api/open-api";
import { countryAdapter } from "../../countries/adapter";

export const selectSlice = (state: RootState) => state.assetsFilter;

export const selectAllCountries = createDraftSafeSelector(
  [selectSlice, selectCountriesSlice],
  (filtersState: IAssetFilters, countriesState: EntityState<ICountry>) => {
    const allCountries = countryAdapter
      .getSelectors()
      .selectAll(countriesState);
    if (!allCountries?.length) {
      return [];
    }
    return filtersState.countryCodes.map((code) => {
      const country = allCountries.find((c) => c.country_iso_code === code);
      return {
        value: country?.country_iso_code || code,
        label: country?.country_label || code,
      };
    });
  },
);

export const selectSelectedCountry = createDraftSafeSelector(
  [selectSlice],
  (state: IAssetFilters) => state.selectedCountries[0],
);

export const selectSelectedAssetIds = createDraftSafeSelector(
  [selectSlice],
  (state: IAssetFilters) => state.selectedIds,
);

export const selectAllRiskLevels = createDraftSafeSelector(
  [selectSlice],
  (state: IAssetFilters) => state.riskLevels,
);

export const selectSelectedRiskLevel = createDraftSafeSelector(
  [selectSlice],
  (state: IAssetFilters) => state.selectedRiskLevels[0],
);

export const selectAllBuildingTypes = createDraftSafeSelector(
  [selectSlice],
  (state: IAssetFilters) => state.buildingTypes,
);

export const selectSelectedBuildingType = createDraftSafeSelector(
  [selectSlice],
  (state: IAssetFilters) => state.selectedBuildingTypes[0],
);

export const selectPartialAssetName = createDraftSafeSelector(
  [selectSlice],
  (state: IAssetFilters) => state.selectedPartialName,
);

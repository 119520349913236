import React, { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  selectAllBuildingTypes,
  selectAllCountries,
  selectAllRiskLevels,
  selectSelectedBuildingType,
  selectSelectedCountry,
  selectSelectedRiskLevel,
} from "src/redux/slices/uiState/assetsFilter/selectors";
import { updateFilter } from "src/redux/slices/uiState/assetsFilter/slice";
import { Select } from "src/common/components/atoms/Select";
import { Input } from "src/common/components/atoms/Input";
import { debounce } from "src/common/utils/debounce";
import { Box } from "@chakra-ui/react";

export const AssetFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const allCountries = useAppSelector(selectAllCountries);
  const selectedCountry = useAppSelector(selectSelectedCountry);
  const allBuildingTypes = useAppSelector(selectAllBuildingTypes);
  const selectedBuildingType = useAppSelector(selectSelectedBuildingType);
  const riskLevels = useAppSelector(selectAllRiskLevels);
  const selectedRisklevel = useAppSelector(selectSelectedRiskLevel);
  const debouncedEventHandler = debounce(
    useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) =>
        dispatch(
          updateFilter({
            filterType: "selectedPartialName",
            filterValues: event.target.value,
          }),
        ),
      [dispatch],
    ),
    200,
  );

  return (
    <>
      <Input
        title="Search by Asset Name"
        placeholder="Asset Name"
        backgroundColor="white"
        onChange={debouncedEventHandler}
        hasBorder={true}
      />
      <Box mt={3} />
      <Select
        variant="flushed"
        title="CRREM Property Type"
        placeholder="All Property Types"
        options={allBuildingTypes}
        onSelect={(value) =>
          dispatch(
            updateFilter({
              filterType: "selectedBuildingTypes",
              filterValues: value ? [value] : [],
            }),
          )
        }
        selected={selectedBuildingType}
      />
      <Select
        variant="flushed"
        title="Countries"
        placeholder="All Countries"
        options={allCountries}
        onSelect={(value) =>
          dispatch(
            updateFilter({
              filterType: "selectedCountries",
              filterValues: value ? [value] : [],
            }),
          )
        }
        selected={selectedCountry}
      />
      <Select
        variant="flushed"
        title="Weighted Stranding Risk Level"
        placeholder="All Risk Levels"
        options={riskLevels}
        onSelect={(value) =>
          dispatch(
            updateFilter({
              filterType: "selectedRiskLevels",
              filterValues: value ? [value] : [],
            }),
          )
        }
        selected={selectedRisklevel}
      />
    </>
  );
};

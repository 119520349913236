// TODO: keep in syncs with the DB

export const hardcodedAppRoles = {
  system: 1,
  app: 2,
};

export const hardcodedPermissionTypes = {
  full: 1,
  write: 2,
  read: 3,
};

import React from "react";
import { Table, Th, Td, Flex, Box } from "@chakra-ui/react";
import { EPCLabel } from "src/common/components/atoms/EPCLabel";
import { Units, getUnits } from "src/constants/measureUnits";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { MeasureSystem } from "src/redux/interfaces/IAppPortfolio";
import { InfoTooltip } from "../../atoms/InfoTooltip";
import { AssetsTableHeader } from "./components/AssetsTableHeader";
import { AssetsTableBody } from "./components/AssetsTableBody";
import { NabersRating } from "../../atoms/NabersRating";

interface IProps {
  assets: IAppAsset[];
  selectedAssetIds: number[];
  measureSystem?: MeasureSystem;
}
export const InefficientAssetsList: React.FC<IProps> = ({
  assets,
  selectedAssetIds,
  measureSystem,
}) => {
  const haveNabers = !!assets.find((a) => !!a.nabers_rating);
  const haveEPC = !!assets.find((a) => !!a.epc.rating);
  return (
    <Box overflowY="auto" maxHeight="500px">
      <Table variant="simple" width="100%" backgroundColor="white">
        <AssetsTableHeader
          selectedAssetIds={selectedAssetIds}
          filteredAssetsCnt={assets.length}
        >
          {haveNabers ? (
            <Th>
              <Flex alignItems="center">
                NABERS Rating
                <InfoTooltip text="National australian built environment rating system" />
              </Flex>
            </Th>
          ) : null}
          {haveEPC ? (
            <>
              <Th>
                <Flex alignItems="center">
                  EPC Rating
                  <InfoTooltip text="Energy performance certificate rating" />
                </Flex>
              </Th>
              <Th>
                Energy Intensity (
                {getUnits(Units.energyIntensityYearly, measureSystem)})
              </Th>
              <Th>Real EPC Performance</Th>
            </>
          ) : null}

          <Th>
            <Flex alignItems="center">
              Area {getUnits(Units.area, measureSystem)}
            </Flex>
          </Th>
        </AssetsTableHeader>
        <AssetsTableBody
          assets={assets}
          selectedAssetIds={selectedAssetIds}
          tabName="inefficientAssets"
          renderCustomTds={(asset) => (
            <>
              {haveNabers ? (
                <Td>
                  {asset.nabers_rating ? (
                    <NabersRating rating={asset.nabers_rating} />
                  ) : (
                    "Not provided"
                  )}
                </Td>
              ) : null}
              {haveEPC ? (
                <>
                  <Td>
                    <EPCLabel rating={asset.epc.rating} />
                  </Td>
                  <Td>{Math.round(asset?.epc.intensity || 0) || "-"}</Td>
                  <Td>
                    <EPCLabel rating={asset.epc.actualPerformance} />
                  </Td>
                </>
              ) : null}
              <Td>{asset?.area?.toLocaleString("en")}</Td>
            </>
          )}
        />
      </Table>
    </Box>
  );
};

export default InefficientAssetsList;

import { Box, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { StrandingAssetList } from "src/common/components/organisms/AssetList/StrandingAssetList";
import { ReactComponent as InfoIcon } from "src/assets/icons/info-icon.svg";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  selectFilteredAssets,
  selectIsAssetsDataLoading,
} from "src/redux/slices/portfolios/assets/selectors";
import { selectSelectedAssetIds } from "src/redux/slices/uiState/assetsFilter/selectors";
import { toggleAssetId } from "src/redux/slices/uiState/assetsFilter/slice";
import { selectSelectedModelId } from "src/redux/slices/uiState/modelSelection/selectors";
import { LoadComponentPlaceholder } from "src/common/components/atoms/Spinner";

export const CustomPathwaysTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedAssetIds = useAppSelector(selectSelectedAssetIds);
  const filteredAssets = useAppSelector(selectFilteredAssets);
  const modelVerisonId = useAppSelector(selectSelectedModelId);
  const isLoading = useAppSelector(selectIsAssetsDataLoading);

  if (isLoading) {
    return <LoadComponentPlaceholder />;
  }

  return (
    <Box>
      <Box
        backgroundColor="green.50"
        width="100%"
        borderRadius="8px"
        mb="16px"
        p="1.5rem"
      >
        <Text display="flex" alignItems="center">
          <Icon as={InfoIcon} boxSize="18px" mr="7px" />
          This is an asset level module. Please open any asset to access
          scenarios data.
        </Text>
      </Box>

      <Box
        pt="10px"
        backgroundColor="white"
        width="100%"
        borderRadius="8px 8px 0 0"
      >
        <StrandingAssetList
          selectedAssetIds={selectedAssetIds}
          assets={filteredAssets}
          selectedModelVersionId={modelVerisonId}
          toggleAssetId={(id) => dispatch(toggleAssetId(id))}
          tabName="customPathways"
        />
      </Box>
    </Box>
  );
};

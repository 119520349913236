import { ComponentStyleConfig } from "@chakra-ui/react";

const buttonBase = {
  fontFamily: "Arial",
  fontWeight: "400",
  lineHeight: "24px",
  borderRadius: "20px",
  gap: "10px",
  _hover: {
    filter: "brightness(115%)",
  },
};

const smButtonSize = {
  fontSize: "12px",
  minWidth: "3rem",
  height: "30px",
  padding: "4px 12px",
};

const disabledButtonStyles = {
  color: "gray.700",
  backgroundColor: "red.50",
  borderColor: "transparent",
  _hover: {
    color: "gray.700",
    backgroundColor: "red.50",
    borderColor: "transparent",
  },
};

const componentOverride: ComponentStyleConfig = {
  sizes: {
    sm: smButtonSize,
    md: {
      fontSize: "14px",
      letterSpacing: "0.4px",
      padding: "8px 24px",
      height: "40px",
    },
  },
  variants: {
    solidPrimary: {
      ...buttonBase,
      backgroundColor: "red.400",
      color: "white",
      _hover: {
        color: "gray.0",
        backgroundColor: "red.500",
      },
      _disabled: disabledButtonStyles,
    },
    outlinePrimary: {
      ...buttonBase,
      color: "red.500",
      border: "1px solid",
      borderColor: "red.500",
      _hover: {
        backgroundColor: "red.50",
      },
      _disabled: disabledButtonStyles,
    },
    outlineGray: {
      ...buttonBase,
      color: "gray.700",
      border: "1px solid",
      borderColor: "gray.700",
      _hover: {
        color: "gray.600",
        backgroundColor: "gray.50",
      },
      _disabled: {
        color: "gray.900",
        backgroundColor: "gray.100",
        borderColor: "transparent",
        _hover: "none",
      },
    },
    noBackground: {
      ...buttonBase,
      backgroundColor: "transparent",
      color: "red.400",
      borderRadius: "0",
      _hover: {
        color: "red.400",
        backgroundColor: "red.100",
      },
      _disabled: disabledButtonStyles,
    },
    assetTab: {
      ...buttonBase,
      backgroundColor: "transparent",
      color: "gray.500",
      border: "1px solid",
      borderColor: "gray.500",
      padding: "10px 24px",
      minWidth: "8rem",
      _hover: {
        backgroundColor: "gray.0",
      },
      _disabled: disabledButtonStyles,
    },
    assetTabSelected: {
      ...buttonBase,
      padding: "10px 24px",
      backgroundColor: "red.400",
      color: "white",
      minWidth: "8rem",
      _hover: {
        color: "gray.0",
        backgroundColor: "red.500",
      },
      _disabled: disabledButtonStyles,
    },
    collapseButton: {
      ...buttonBase,
      borderRadius: "0 8px 8px 0 !important",
      padding: "5px 0px 5px 0",
      backgroundColor: "white",
      _hover: {
        fill: "gray.200 !important",
      },
    },
  },
  defaultProps: {
    size: "md",
  },
};

export default componentOverride;

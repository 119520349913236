import { formatExcelFile } from "src/common/utils/files/formatExcelFile";
import { PortfolioApi } from "src/api/open-api";
import { defaultApiConfiguration } from "src/api/configurations";

export const getLatestPortfolioFile = async (portfolioId: number) => {
  const api = new PortfolioApi(defaultApiConfiguration);
  const response = await api.getPortfolioFile(portfolioId);
  return response.data;
};

export const savePortfolioFile = async (portfolioId: number, file: File) => {
  //update file name with last modified date for asset file ref
  const uploadFile = formatExcelFile(file);
  await putPortfolioFile(uploadFile.file, portfolioId.toString());

  // Save file ref
  await postPortfolioFileRef(
    portfolioId.toString(),
    `${portfolioId}/${uploadFile.file.name}`,
    uploadFile.file,
    uploadFile.lastMod,
  );
};

export const postPortfolioFileRef = async (
  portfolioId: string,
  filePath: string,
  file: File,
  lastMod: number,
) => {
  const scenarioFileInfo = {
    portfolio_id: portfolioId,
    file_path: filePath,
    file_name: file.name,
    file_last_modified: lastMod,
  };
  const api = new PortfolioApi(defaultApiConfiguration);
  const response = await api.uploadPortfolioFile(
    parseInt(portfolioId),
    scenarioFileInfo,
  );
  return response.data;
};

export const putPortfolioFile = async (file: File, portfolioId: string) => {
  const form = new FormData();
  form.append("excel", file);
  const api = new PortfolioApi(defaultApiConfiguration);
  const response = await api.putPortfolioExcel(parseInt(portfolioId), file);
  return response.data;
};

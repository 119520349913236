import {
  NumberInput as UIInput,
  Text,
  NumberInputField,
} from "@chakra-ui/react";
import React from "react";

type TProps = {
  onChange: (value: number) => void;
  placeholder?: string;
  title?: string;
  backgroundColor?: string;
  defaultValue?: number;
  variant?: "outline" | "solid" | "outlineMuted";
};
export const NumberInput: React.FC<TProps> = ({
  placeholder,
  onChange,
  title,
  variant,
  defaultValue,
}) => {
  return (
    <>
      {title ? (
        <Text pb="0.25rem" fontSize="xs" color="gray.400">
          {title}
        </Text>
      ) : null}
      <UIInput
        data-testid="NumberInput"
        size="sm"
        variant={variant || "outline"}
        placeholder={placeholder}
        data-cy={`Input-${title?.replace(" ", "-")}`}
        _placeholder={{
          textColor: "gray.400",
        }}
        fontSize="small"
        onChange={(_, valueAsNumber) => onChange(valueAsNumber)}
        defaultValue={defaultValue}
        min={0}
      >
        <NumberInputField
          _hover={{
            borderColor: "red.500",
          }}
          _focus={{
            borderColor: "red.500",
          }}
          _focusVisible={{
            borderColor: "red.500",
          }}
        />
      </UIInput>
    </>
  );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IAppPortfolio,
  MeasureSystem,
} from "src/redux/interfaces/IAppPortfolio";
import { mockedApiPortfolios } from "./__mocks__/porftolios";
import {
  AssetPayload,
  IPortfolio,
  PortfolioApi,
  PortfolioPayload,
} from "src/api/open-api";
import { defaultApiConfiguration } from "src/api/configurations";
import { USE_LOCAL_MOCKS } from "src/config/mocks";

const _mapApiToAppPortfolio = (p: IPortfolio): IAppPortfolio => {
  return {
    portfolioId: p.portfolio_id,
    currency: p.iso_currency_code || "EUR",
    measureSystem:
      p.unit_system_type_name === "Imperial"
        ? MeasureSystem.us
        : MeasureSystem.international,
    summary: {
      companyName: p.company_name,
      companyId: p.company_id,
      portfolioName: p.portfolio_name,
      countries: p.countries,
      assetCnt: p.num_of_assets,
      riskLevelId: p.stranding_risk_level,
      fundTypeName: p.fund_type,
      area: p.total_area,
      value: p.total_value,
      uses: p.building_types,
      taxonomy: p.total_taxonomy,
      certifications: p.certifications,
      dataMaturity: p.data_quality,
      latestUpdateDate: p.updated_datetime,
      strandingLtoM: p.stranding_year_l_to_m,
      strandingMtoH: p.stranding_year_m_to_h,
    },
  };
};

export const fetchPortfolios = createAsyncThunk(
  "portfolios/fetch",
  async (_, { rejectWithValue }) => {
    if (USE_LOCAL_MOCKS) {
      return mockedApiPortfolios.map(_mapApiToAppPortfolio);
    }
    const api = new PortfolioApi(defaultApiConfiguration);
    let response;
    try {
      response = await api.getPortfolios();
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
    try {
      return response.data.map(_mapApiToAppPortfolio);
    } catch (error) {
      return rejectWithValue({ errorCode: "UI_DATA_IS_BROCKEN" });
    }
  },
);

export const savePortfolio = async (
  payload: PortfolioPayload,
  portfolioId?: number,
) => {
  const api = new PortfolioApi(defaultApiConfiguration);
  const response = portfolioId
    ? await api.putPortfolio(portfolioId, payload)
    : await api.postPortfolio(payload);
  return Number(response.data);
};

export const deletePortfolio = createAsyncThunk(
  "portfolios/delete",
  async (portfolioId: number, { rejectWithValue }) => {
    if (USE_LOCAL_MOCKS) {
      return portfolioId;
    }
    const api = new PortfolioApi(defaultApiConfiguration);
    try {
      await api.deletePortfolio(portfolioId);
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
    return portfolioId;
  },
);

// TODO: finish when API and report generation is ready
export const validatePortfolioApi = async (
  portfolio: PortfolioPayload,
  assets: AssetPayload[],
  editFlag: number,
) => {
  try {
    const api = new PortfolioApi(defaultApiConfiguration);
    return await api.validatePortfolio(editFlag!, { portfolio, assets });
  } catch (_error) {
    // @ts-expect-error error type is always unknown
    const error: AxiosError = _error;
    return {
      error: {
        errorCode: error?.response?.status,
        errorData: error?.response?.data,
      },
    };
  }
};

export const getPortfolioReport = createAsyncThunk(
  "portfolios/getReportURL",
  async (portfolioId: number, { rejectWithValue }) => {
    const api = new PortfolioApi(defaultApiConfiguration);
    try {
      const response = await api.getPortfolioReportURL(portfolioId);
      return { report_URL: response.data };
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

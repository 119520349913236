export enum MeasureSystem {
  international = "international",
  us = "us",
}

export interface IPortfolioSummary {
  companyName: string;
  companyId: number;
  portfolioName: string;
  countries: string[];
  assetCnt: number;
  riskLevelId?: number;
  fundTypeName?: string;
  area: number;
  value: number;
  uses: string[];
  taxonomy?: number;
  certifications: number;
  dataMaturity?: number;
  latestUpdateDate?: string; // DateTime
  strandingLtoM?: number;
  strandingMtoH?: number;
}

export interface IAppPortfolio {
  portfolioId: number;
  currency: string;
  measureSystem: MeasureSystem;
  summary: IPortfolioSummary;
}

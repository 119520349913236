import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { ArcChartsColorsSequence } from "../config";

ChartJS.register(ArcElement, Tooltip, Legend);

type TOptions = {
  plugins?: {
    legend?: {
      display?: boolean;
      position?: "bottom" | "top" | "left" | "right" | "center";
      align?: "start" | "center" | "end";
    };
  };
};
type TProps = {
  content: {
    value: number;
    label: string;
  }[];
  hoverLabel?: string;
  options?: TOptions;
};

const defaultOptions = {
  plugins: {
    legend: { display: false, position: "bottom", align: "start" },
  },
};

export const DoughnutChart: React.FC<TProps> = ({
  content,
  hoverLabel,
  options,
}) => {
  const dataLabels = content?.map((c) => c.label);
  const dataValues = content?.map((c) => c.value);

  if (!content.length) return null;
  const data = {
    labels: dataLabels,
    datasets: [
      {
        label: hoverLabel ? hoverLabel : " ",
        data: dataValues,
        backgroundColor: ArcChartsColorsSequence,
        borderWidth: 0,
      },
    ],
  };
  return (
    <Doughnut data={data} options={options || (defaultOptions as TOptions)} />
  );
};

import { defaultApiConfiguration } from "src/api/configurations";
import { ErrorApi } from "src/api/open-api";

export const errorReporter = async ({
  activeUrl,
  deviceUserAgent,
  stack,
  context,
  message,
  userId,
}: {
  activeUrl: string;
  deviceUserAgent?: string;
  stack: string;
  context: string;
  message?: string;
  userId?: number;
}) => {
  const api = new ErrorApi(defaultApiConfiguration);
  try {
    const deviceInfo = deviceUserAgent || navigator?.userAgent;
    await api.logError({
      user_id: userId,
      path: activeUrl,
      stack,
      context: context + " Device: " + deviceInfo + " Message: " + message,
    });
  } catch (_error) {
    return Promise.reject("Failed to log error: " + stack);
  }
};

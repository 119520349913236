import { IAssetStrandingResult } from "src/api/open-api";
import {
  energyIntensityTypeId,
  ghgIntensityTypeId,
  hardcodedLineIds,
} from "src/constants/targets";

export const bacisStrandingResults: IAssetStrandingResult[] = [
  {
    model_version_id: 1, // never used, can be removed from api spec
    intensity_type_name: "GHG",
    performance: 3,
    model_name: "model_name",
    risk_weighted: 1,
    model_description: hardcodedLineIds.crremWarn.model_description,
    intensity_type_id: ghgIntensityTypeId,
    model_version_name: hardcodedLineIds.crremWarn.model_version_name,
    stranding_year: 2025,
  },
  {
    model_version_id: 1, // never used, can be removed from api spec
    intensity_type_name: "GHG",
    performance: 3,
    model_name: "model_name",
    risk_weighted: 0,
    model_description: hardcodedLineIds.crremCritical.model_description,
    intensity_type_id: ghgIntensityTypeId,
    model_version_name: hardcodedLineIds.crremCritical.model_version_name,
    stranding_year: 2036,
  },
  {
    model_version_id: 1, // never used, can be removed from api spec
    intensity_type_name: "Energy",
    performance: 3,
    model_name: "model_name",
    risk_weighted: 2,
    model_description: hardcodedLineIds.crremCritical.model_description,
    intensity_type_id: energyIntensityTypeId,
    model_version_name: hardcodedLineIds.crremCritical.model_version_name,
    stranding_year: 2031,
  },
];

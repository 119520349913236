import { Input as UIInput, Text, Box } from "@chakra-ui/react";
import React from "react";

type TProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  title?: string;
  hasBorder?: boolean;
  backgroundColor?: string;
  defaultValue?: string;
  variant?: "outline" | "solid" | "outlineMuted";
};
export const Input: React.FC<TProps> = ({
  placeholder,
  onChange,
  title,
  hasBorder,
  variant,
  defaultValue,
}) => {
  return (
    <>
      {title ? (
        <Text pb="0.25rem" fontSize="xs" color="gray.400">
          {title}
        </Text>
      ) : null}
      <Box
        borderColor={hasBorder ? "gray.100" : "transparent"}
        borderWidth="0.063rem"
        borderRadius="0.25rem"
      >
        <UIInput
          type="text"
          size="sm"
          variant={variant || "outline"}
          placeholder={placeholder}
          data-cy={`Input-${title?.replace(" ", "-")}`}
          _placeholder={{
            textColor: "gray.400",
          }}
          fontSize="small"
          _hover={{
            borderColor: "red.500",
          }}
          _focus={{
            borderColor: "red.500",
          }}
          onChange={onChange}
          defaultValue={defaultValue}
        />
      </Box>
    </>
  );
};

import { createSlice } from "@reduxjs/toolkit";

import { archetypesAdapter } from "./adapter";
import { fetchArchetypeByAssetId } from "src/redux/thunks/archetypes";
import {
  Ii18nError,
  apiErrorToI18nError,
} from "src/redux/interfaces/Ii18nError";

const slice = createSlice({
  name: "archetypes",
  initialState: {
    ...archetypesAdapter.getInitialState(),
    requestStatus: "initial",
    error: {} as {
      [key: number]: null | Ii18nError;
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchArchetypeByAssetId.pending, (state, action) => {
      state.requestStatus = "pending";
      archetypesAdapter.removeAll(state);
      state.error[action.meta.arg] = null;
    });
    builder.addCase(fetchArchetypeByAssetId.rejected, (state, action) => {
      state.requestStatus = "rejected";
      state.error[action.meta.arg] = action.payload
        ? apiErrorToI18nError(action.payload)
        : { errorCode: "GENERIC" };
    });
    builder.addCase(
      fetchArchetypeByAssetId.fulfilled,
      (state, { payload, meta }) => {
        if (payload && payload?.packages?.length) {
          archetypesAdapter.setOne(state, payload);
        }
        state.requestStatus = "fulfilled";
        state.error[meta.arg] = null;
      },
    );
  },
});

export default slice;

import React from "react";
import { Chart } from "react-chartjs-2";
import type {
  ChartData,
  ChartDataset,
  ChartOptions,
  ScatterDataPoint,
} from "chart.js";
import { ITimeSeriesPoint } from "src/api/open-api";
import { IReduxChartTarget } from "src/redux/interfaces/IReduxChartTarget";
import { currencyCodeToSymbol } from "src/common/utils/units";
import { getCapexCharBars, getCapexChartLine } from "./mixChartUtils";

interface IProps {
  lines: Omit<IReduxChartTarget, "modelVersionId" | "strandingX">[];
  bars: { timeSeries: ITimeSeriesPoint[]; label: string; color: string }[];
  isoCurrecyCode?: string;
}

const TwoAxisMixedChart: React.FC<IProps> = ({
  lines,
  bars,
  isoCurrecyCode,
}) => {
  const allYears = lines
    .flat()
    .map((e) => e.timeSeries)
    .flat()
    .map((e) => e.year);
  const minYear = Math.min(...allYears);
  const maxYear = Math.max(...allYears);

  const barDatasets: ChartDataset<"bar", ScatterDataPoint[]>[] = bars.map(
    (bar) => getCapexCharBars(bar),
  );

  const lineDatasets: ChartDataset<"line", ScatterDataPoint[]>[] = [
    ...lines.map((target) =>
      getCapexChartLine({
        name: target.targetName,
        timeSeries: target.timeSeries,
        color: target.lineColor,
      }),
    ),
  ];

  const data: ChartData = {
    labels: new Array(maxYear - minYear)
      .fill(minYear)
      .map((year, index) => year + index),
    datasets: [...lineDatasets, ...barDatasets],
  };

  const options: ChartOptions = {
    scales: {
      CAPEX: {
        beginAtZero: true,
        position: "left",
        type: "linear",
        axis: "y",
        ticks: {
          callback: (value) =>
            `${currencyCodeToSymbol(isoCurrecyCode)} ${value.toLocaleString(
              "us-en",
            )}`,
        },
      },
      OPEX: {
        beginAtZero: false,
        position: "right",
        axis: "y",
        type: "linear",
        ticks: {
          callback: (value) =>
            `${currencyCodeToSymbol(isoCurrecyCode)} ${value.toLocaleString(
              "us-en",
            )}`,
        },
        grid: {
          drawOnChartArea: false,
        },
      },
      years: {
        beginAtZero: false,
        position: "bottom",
        axis: "x",
        type: "linear",
        ticks: {
          maxTicksLimit: 6,
          padding: 0,
        },
        offset: false,
        grid: {
          display: false, // hide verticle lines from chart
        },
        max: maxYear,
        min: minYear,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        intersect: true,
        callbacks: {
          label: (ctx) => {
            return (
              ctx.dataset.label +
              ": " +
              currencyCodeToSymbol(isoCurrecyCode) +
              " " +
              Math.round(ctx.parsed.y).toLocaleString()
            );
          },
        },
      },
    },
    maintainAspectRatio: true,
    resizeDelay: 100,
    responsive: true,
    locale: "es",
  };
  return <Chart type="line" data={data} options={options} />;
};

export default TwoAxisMixedChart;

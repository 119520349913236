import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  size?: string;
}

const AddEntityModal: React.FC<IProps> = (props) => {
  const { isOpen, onClose, title, children, size } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={size ? size : "2xl"}
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          fontWeight="normal"
          fontFamily="Times"
          textColor="gray.800"
        >
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter justifyContent="space-around"></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddEntityModal;

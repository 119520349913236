import React, { useState } from "react";
import { Box, Button } from "@chakra-ui/react";
import ReactPaginate from "react-paginate";
import "./pagination.css";

type IProps = {
  allItems: unknown[];
  children: React.ReactElement;
  itemPerPage?: number;
};

// Wrapped component table should accept 'items' prop
export const Pagination: React.FC<IProps> = ({
  children,
  allItems,
  itemPerPage = 12,
}) => {
  // const itemPerPage = itemPerPage ? itemPerPage : 12;
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemPerPage;
  const currentItems = allItems.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(allItems.length / itemPerPage);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return React.cloneElement(child, { items: currentItems });
    }
    return child;
  });

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = event.selected * itemPerPage;
    setItemOffset(newOffset);
  };
  const isFirstPage = itemOffset === 0;
  const isLastPage = itemOffset + itemPerPage >= allItems.length;

  return (
    <Box>
      {childrenWithProps}
      {pageCount > 1 ? (
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          initialPage={0}
          containerClassName="pagination"
          activeClassName="active"
          pageLinkClassName="pageNum"
          breakLabel={"..."}
          renderOnZeroPageCount={null}
          nextLabel={
            <Button size="sm" variant="solidPrimary" isDisabled={isLastPage}>
              Next
            </Button>
          }
          previousLabel={
            <Button size="sm" variant="solidPrimary" isDisabled={isFirstPage}>
              Prev
            </Button>
          }
        />
      ) : null}
    </Box>
  );
};

import { EntityState, createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { permissionsAdapter } from "./adapter";
import { ICompanyPermission } from "src/api/open-api";
import { hardcodedPermissionTypes } from "src/constants/accessRolesAndPermissions";
import { hardcodedEntityTypes } from "src/constants/accessEntityTypes";

export const selectSlice = (state: RootState) => state.permisisons;

export const selectAllCompanyPermissions = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<ICompanyPermission>) =>
    permissionsAdapter.getSelectors().selectAll(state),
);

export const selectAllCompanyAdminPermissions = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<ICompanyPermission>) =>
    permissionsAdapter
      .getSelectors()
      .selectAll(state)
      .filter(
        (p) =>
          p.entity_type_id === Number(hardcodedEntityTypes.company) &&
          p.permission_type_id === hardcodedPermissionTypes.full,
      ),
);

import { globalMaxYear, globalMinYear } from "src/constants";
import {
  maxEmissionsTargetLabel,
  minEmissionsTargetlabel,
} from "src/constants/targetLabels";
import { IReduxChartTarget } from "src/redux/interfaces/IReduxChartTarget";
import { IThreshold, Performance } from "./references";

export const find15Target = (targets: IReduxChartTarget[]) =>
  targets.find((target) => target.targetName === minEmissionsTargetlabel);

const evaluateStrandingPerformance = (
  strandingYear: number | undefined,
  threshold: IThreshold,
) => {
  const referenceYear = new Date().getFullYear();
  if (!strandingYear) {
    return Performance.High;
  } else if (strandingYear - referenceYear < threshold[Performance.Medium]) {
    return Performance.Low;
  } else if (strandingYear - referenceYear < threshold[Performance.High]) {
    return Performance.Medium;
  } else {
    return Performance.High;
  }
};

const getDisplayStrandingValue = (
  strandingYear: number | typeof NaN,
  strandingStatus: number | string,
) => {
  //if stranding value is NaN, then the portfolio is either already stranded, or has no stranding event
  if (isNaN(strandingYear)) {
    //determine stranding status for selected assets if value NaN
    const isStranded = strandingStatus === "Stranded";
    return !isStranded ? "None" : "Stranded";
  } else {
    return strandingYear;
  }
};

export const calcAllStranding = (
  assetTargets: IReduxChartTarget[],
  maxReportingYear: number,
  threshold: IThreshold,
) => {
  return assetTargets.map((target) => {
    const normStranding = target.strandingX as number;

    /**
     * Determine asset stranding status
     *
     * Logic:
     * - if normalised standing year less than minYear, the asset is stranded
     * - if normalised stranding year is greater than or equal to min reporting year, and less than or equal to max reporting year 2050, return stranding year
     * - if normalised stranding year is greater than max year, we know the asset does not strand within the reporting period
     */
    const strandingStatus = isNaN(normStranding)
      ? "Error"
      : normStranding < maxReportingYear
      ? "Stranded"
      : normStranding >= maxReportingYear && normStranding <= 2050
      ? normStranding
      : "None";

    const strandingFinalValue = getDisplayStrandingValue(
      Math.trunc(target.strandingX as number),
      strandingStatus,
    );
    return {
      targetName: target.targetName,
      strandingFinalValue,
      performance: evaluateStrandingPerformance(target?.strandingX, threshold),
    };
  });
};

const targetLabelToDisplay = [minEmissionsTargetlabel, maxEmissionsTargetLabel];
export const mapStradingValueToTableItem = (
  item: {
    targetName: string;
    strandingFinalValue: number | string;
    performance: number;
  },
  isPotfolio: boolean,
) => {
  if (!targetLabelToDisplay.includes(item.targetName)) return;
  const name =
    item.targetName === minEmissionsTargetlabel
      ? "Stranding Event 1.5°C (SBTi)"
      : "Stranding Event 2.0°C";
  return {
    name,
    value:
      item.strandingFinalValue === globalMaxYear + 1
        ? "-"
        : item.strandingFinalValue === globalMinYear - 1
        ? "Stranded"
        : item.strandingFinalValue,
    rating:
      item.strandingFinalValue === "Stranded" ||
      item.strandingFinalValue === globalMinYear - 1
        ? 0
        : item.performance,
    tooltipInfo: `Year in which the ${
      isPotfolio ? "portfolio" : "asset"
    } is expected to exceed the GHG emissions intensity targets set by ${
      item.targetName === minEmissionsTargetlabel
        ? "CRREM + SBTi for a 1.5°C global warming scenario (Paris-aligned)"
        : "CRREM for a 2.0°C global warming scenario (NOT Paris-aligned)​"
    }`,
  };
};

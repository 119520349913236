import { Flex } from "@chakra-ui/react";
import React from "react";
import { LogoProgress } from "./LogoProgress";

type IProps = {
  isLoading: boolean;
  children: React.ReactNode[] | React.ReactNode;
};
export const SpinnerPageWrapper: React.FunctionComponent<IProps> = ({
  isLoading,
  children,
}) => {
  return (
    <Flex
      data-testid="SpinnerPageWrapper"
      flexDir="row"
      w="100%"
      minHeight="100vh"
      px={{ base: "2rem", xs: "6rem" }}
      justifyContent="center"
      alignItems="center"
      backgroundColor="gray.50"
    >
      {isLoading ? <LogoProgress /> : <>{children}</>}
    </Flex>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * REST API for CRISP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivateCrispUserRequest
 */
export interface ActivateCrispUserRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ActivateCrispUserRequest
     */
    'is_active': boolean;
}
/**
 * 
 * @export
 * @interface AddAdminUserPermissionsRequestInner
 */
export interface AddAdminUserPermissionsRequestInner {
    /**
     * 
     * @type {number}
     * @memberof AddAdminUserPermissionsRequestInner
     */
    'user_id': number;
    /**
     * 
     * @type {number}
     * @memberof AddAdminUserPermissionsRequestInner
     */
    'admin_type_id': number;
}
/**
 * 
 * @export
 * @interface AddAssetScenariosRequestInner
 */
export interface AddAssetScenariosRequestInner {
    /**
     * 
     * @type {string}
     * @memberof AddAssetScenariosRequestInner
     */
    'scenario_name'?: string;
    /**
     * 
     * @type {Array<AddAssetScenariosRequestInnerInterventionsInner>}
     * @memberof AddAssetScenariosRequestInner
     */
    'interventions'?: Array<AddAssetScenariosRequestInnerInterventionsInner>;
}
/**
 * 
 * @export
 * @interface AddAssetScenariosRequestInnerInterventionsInner
 */
export interface AddAssetScenariosRequestInnerInterventionsInner {
    /**
     * 
     * @type {number}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'capex_cost': number;
    /**
     * 
     * @type {number}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'capex_decarb': number;
    /**
     * 
     * @type {number}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'capex_eol': number;
    /**
     * 
     * @type {number}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'dc_usage_change': number;
    /**
     * 
     * @type {number}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'dh_usage_change': number;
    /**
     * 
     * @type {number}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'electricity_usage_change': number;
    /**
     * 
     * @type {number}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'gas_usage_change': number;
    /**
     * 
     * @type {number}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'ghg_emission_change'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'implementation_year': number;
    /**
     * 
     * @type {string}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'intervention_id': string;
    /**
     * 
     * @type {string}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'intervention_category': string;
    /**
     * 
     * @type {string}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'intervention_description': string;
    /**
     * 
     * @type {number}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'mac': number;
    /**
     * 
     * @type {number}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'oil_usage_change': number;
    /**
     * 
     * @type {number}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'opex_revenue': number;
    /**
     * 
     * @type {number}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'other_usage_change': number;
    /**
     * 
     * @type {number}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'other_usage_type': number;
    /**
     * 
     * @type {string}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'package_id': string;
    /**
     * 
     * @type {number}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'renewable_other_increase': number;
    /**
     * 
     * @type {number}
     * @memberof AddAssetScenariosRequestInnerInterventionsInner
     */
    'renewable_solar_increase': number;
}
/**
 * 
 * @export
 * @interface AddCustomPathway200Response
 */
export interface AddCustomPathway200Response {
    /**
     * 
     * @type {number}
     * @memberof AddCustomPathway200Response
     */
    'custom_pathway_id': number;
}
/**
 * 
 * @export
 * @interface AddFeatureConfigByCompanyId200Response
 */
export interface AddFeatureConfigByCompanyId200Response {
    /**
     * 
     * @type {number}
     * @memberof AddFeatureConfigByCompanyId200Response
     */
    'feature_config_id'?: number;
}
/**
 * 
 * @export
 * @interface AddUserPermissionsRequestInner
 */
export interface AddUserPermissionsRequestInner {
    /**
     * 
     * @type {number}
     * @memberof AddUserPermissionsRequestInner
     */
    'user_id': number;
    /**
     * 
     * @type {number}
     * @memberof AddUserPermissionsRequestInner
     */
    'permission_type_id': number;
    /**
     * 
     * @type {number}
     * @memberof AddUserPermissionsRequestInner
     */
    'entity_type_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddUserPermissionsRequestInner
     */
    'entity_id'?: number | null;
}
/**
 * 
 * @export
 * @interface AssetPayload
 */
export interface AssetPayload {
    /**
     * Name of the asset
     * @type {string}
     * @memberof AssetPayload
     */
    'name': string;
    /**
     * Reporting year
     * @type {number}
     * @memberof AssetPayload
     */
    'as_yr': number;
    /**
     * Build year
     * @type {number}
     * @memberof AssetPayload
     */
    'build_yr'?: number;
    /**
     * Gross Asset Value [euro]
     * @type {number}
     * @memberof AssetPayload
     */
    'gav'?: number;
    /**
     * Country
     * @type {string}
     * @memberof AssetPayload
     */
    'country': string;
    /**
     * City
     * @type {string}
     * @memberof AssetPayload
     */
    'city'?: string;
    /**
     * Zip code
     * @type {string}
     * @memberof AssetPayload
     */
    'zip'?: string;
    /**
     * Address
     * @type {string}
     * @memberof AssetPayload
     */
    'address'?: string;
    /**
     * Listed building
     * @type {boolean}
     * @memberof AssetPayload
     */
    'lb'?: boolean;
    /**
     * Asset Type
     * @type {string}
     * @memberof AssetPayload
     */
    'as_ty': AssetPayloadAsTyEnum;
    /**
     * Building type: Residential, Single-family [area percentage]
     * @type {number}
     * @memberof AssetPayload
     */
    'ty_res'?: number;
    /**
     * Building type: Residential, Multi-family [area percentage]
     * @type {number}
     * @memberof AssetPayload
     */
    'ty_rem'?: number;
    /**
     * Building type: Office [area percentage]
     * @type {number}
     * @memberof AssetPayload
     */
    'ty_off'?: number;
    /**
     * Building type: Retail, High Street [area percentage]
     * @type {number}
     * @memberof AssetPayload
     */
    'ty_rhs'?: number;
    /**
     * Building type: Retail, Shopping Center [area percentage]
     * @type {number}
     * @memberof AssetPayload
     */
    'ty_rsm'?: number;
    /**
     * Building type: Retail, Warehouse [area percentage]
     * @type {number}
     * @memberof AssetPayload
     */
    'ty_rwb'?: number;
    /**
     * Building type: Industrial, Distribution Warehouse [area percentage]
     * @type {number}
     * @memberof AssetPayload
     */
    'ty_dww'?: number;
    /**
     * Building type: Industrial, Distribution Warehouse Cooled [area percentage]
     * @type {number}
     * @memberof AssetPayload
     */
    'ty_dwc'?: number;
    /**
     * Building type: Hotel [area percentage]
     * @type {number}
     * @memberof AssetPayload
     */
    'ty_hot'?: number;
    /**
     * Building type: Healthcare [area percentage]
     * @type {number}
     * @memberof AssetPayload
     */
    'ty_hec'?: number;
    /**
     * Building type: Lodging, Leisure & Recreation [area percentage]
     * @type {number}
     * @memberof AssetPayload
     */
    'ty_lei'?: number;
    /**
     * Building type: Data Centers [area percentage]
     * @type {number}
     * @memberof AssetPayload
     */
    'ty_dat'?: number;
    /**
     * Building type: Production or Manufacturing facility [area percentage]
     * @type {number}
     * @memberof AssetPayload
     */
    'ty_pdn'?: number;
    /**
     * If it\'s got air conditioning or not
     * @type {boolean}
     * @memberof AssetPayload
     */
    'ac'?: boolean;
    /**
     * Climate of building location
     * @type {string}
     * @memberof AssetPayload
     */
    'climate'?: string;
    /**
     * Shape of the building
     * @type {string}
     * @memberof AssetPayload
     */
    'gresb'?: string;
    /**
     * Age of the facade [years]
     * @type {number}
     * @memberof AssetPayload
     */
    'fc_age'?: number;
    /**
     * Major renovation [years]
     * @type {number}
     * @memberof AssetPayload
     */
    'mj_renov'?: number;
    /**
     * Total gross internal area [square meters]
     * @type {number}
     * @memberof AssetPayload
     */
    'to_fl': number;
    /**
     * Grid Electricity Usage [kWh]
     * @type {number}
     * @memberof AssetPayload
     */
    'el_grid'?: number;
    /**
     * Natural gas Usage [kWh]
     * @type {number}
     * @memberof AssetPayload
     */
    'ng_con'?: number;
    /**
     * Fuel oil Usage [kWh]
     * @type {number}
     * @memberof AssetPayload
     */
    'ol_con'?: number;
    /**
     * District heating (steam) Usage [kWh]
     * @type {number}
     * @memberof AssetPayload
     */
    'dh_con'?: number;
    /**
     * District cooling (steam) Usage [kWh]
     * @type {number}
     * @memberof AssetPayload
     */
    'dc_con'?: number;
    /**
     * Other energy consumption type
     * @type {string}
     * @memberof AssetPayload
     */
    'ot1_ty'?: string;
    /**
     * Other energy consumption Usage [kWh]
     * @type {number}
     * @memberof AssetPayload
     */
    'ot1_con'?: number;
    /**
     * Fugitive emisions: Type of gas 1
     * @type {string}
     * @memberof AssetPayload
     */
    'ghg_leak1_type'?: string;
    /**
     * Fugitive emisions: Amount of leakage 1
     * @type {number}
     * @memberof AssetPayload
     */
    'ghg_leak1_amount'?: number;
    /**
     * Fugitive emisions: Type of gas 2
     * @type {string}
     * @memberof AssetPayload
     */
    'ghg_leak2_type'?: string;
    /**
     * Fugitive emisions: Amount of leakage 2
     * @type {number}
     * @memberof AssetPayload
     */
    'ghg_leak2_amount'?: number;
    /**
     * On-site renewable electricity (PV, wind): Generated  and consumed on-site [kWh]
     * @type {number}
     * @memberof AssetPayload
     */
    'rnw_el_con'?: number;
    /**
     * On-site renewable electricity (PV, wind): Generated on-site and exported [kWh]
     * @type {number}
     * @memberof AssetPayload
     */
    'rnw_el_exp'?: number;
    /**
     * Other on-site renewable energy source (heatpump, solar thermal): Generated  and consumed on-site [kWh]
     * @type {number}
     * @memberof AssetPayload
     */
    'rnw_ot_con'?: number;
    /**
     * Other on-site renewable energy source (heatpump, solar thermal): Generated on-site and exported [kWh]
     * @type {number}
     * @memberof AssetPayload
     */
    'rnw_ot_exp'?: number;
    /**
     * Taxonomy: Substantial Contribution
     * @type {string}
     * @memberof AssetPayload
     */
    'tax_sc'?: string;
    /**
     * Taxonomy: Substantial Contribution Compliance
     * @type {boolean}
     * @memberof AssetPayload
     */
    'tax_sc_compl'?: boolean;
    /**
     * Taxonomy: Climate Change Mitigation: Risk Assessment
     * @type {boolean}
     * @memberof AssetPayload
     */
    'tax_dsh_ccm_cca1'?: boolean;
    /**
     * Taxonomy: Climate Change Mitigation: Implement adaption measures in 5 years
     * @type {boolean}
     * @memberof AssetPayload
     */
    'tax_dsh_ccm_cca2'?: boolean;
    /**
     * Taxonomy: Climate Change Adaptation: Min. EPC Class C
     * @type {boolean}
     * @memberof AssetPayload
     */
    'tax_dsh_cca_ccm1'?: boolean;
    /**
     * Taxonomy: Climate Change Adaptation: Building not dedicated to fossil fuels
     * @type {boolean}
     * @memberof AssetPayload
     */
    'tax_dsh_cca_ccm2'?: boolean;
    /**
     * Taxonomy: Biodiversity and Ecosystems: Risk Assessment
     * @type {boolean}
     * @memberof AssetPayload
     */
    'tax_dsh_bde_cca1'?: boolean;
    /**
     * Taxonomy: Biodiversity and Ecosystems: Implement adaption measures in 5 years
     * @type {boolean}
     * @memberof AssetPayload
     */
    'tax_dsh_bde_cca2'?: boolean;
    /**
     * Energy Efficiency: EPC Label
     * @type {string}
     * @memberof AssetPayload
     */
    'epc_label'?: AssetPayloadEpcLabelEnum;
    /**
     * Energy Efficiency: EPC Intensity [kWh/m2]
     * @type {number}
     * @memberof AssetPayload
     */
    'epc_intensity'?: number;
    /**
     * Energy Efficiency: EPC Performance
     * @type {string}
     * @memberof AssetPayload
     */
    'epc_performance'?: AssetPayloadEpcPerformanceEnum;
    /**
     * Energy Efficiency: NABERS Rating
     * @type {number}
     * @memberof AssetPayload
     */
    'nabers_rating'?: number;
    /**
     * Certifications: LEED
     * @type {string}
     * @memberof AssetPayload
     */
    'leed'?: string;
    /**
     * Certifications: WELL
     * @type {string}
     * @memberof AssetPayload
     */
    'well'?: string;
    /**
     * Certifications: DGNB
     * @type {string}
     * @memberof AssetPayload
     */
    'dgnb'?: string;
    /**
     * Data Quality, 1 to 100
     * @type {number}
     * @memberof AssetPayload
     */
    'dqua'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetPayload
     */
    'gps_coordinates'?: string;
}

export const AssetPayloadAsTyEnum = {
    MixedUse: 'Mixed Use',
    Production: 'Production',
    Office: 'Office',
    IndustrialDistributionWarehouse: 'Industrial, Distribution Warehouse',
    ResidentialSingleFamily: 'Residential, Single-family',
    ResidentialMultiFamily: 'Residential Multi-Family',
    RetailHighStreet: 'Retail, High Street',
    RetailShoppingCenter: 'Retail, Shopping Center',
    RetailWarehouse: 'Retail, Warehouse',
    Hotel: 'Hotel',
    IndustrialDistributionWarehouseCooled: 'Industrial, Distribution Warehouse Cooled',
    Healthcare: 'Healthcare',
    LodgingLeisureRecreation: 'Lodging, Leisure & Recreation',
    Unknown: 'Unknown'
} as const;

export type AssetPayloadAsTyEnum = typeof AssetPayloadAsTyEnum[keyof typeof AssetPayloadAsTyEnum];
export const AssetPayloadEpcLabelEnum = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    F: 'F',
    G: 'G'
} as const;

export type AssetPayloadEpcLabelEnum = typeof AssetPayloadEpcLabelEnum[keyof typeof AssetPayloadEpcLabelEnum];
export const AssetPayloadEpcPerformanceEnum = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    F: 'F',
    G: 'G'
} as const;

export type AssetPayloadEpcPerformanceEnum = typeof AssetPayloadEpcPerformanceEnum[keyof typeof AssetPayloadEpcPerformanceEnum];

/**
 * 
 * @export
 * @interface CompanyPayload
 */
export interface CompanyPayload {
    /**
     * 
     * @type {string}
     * @memberof CompanyPayload
     */
    'country_code': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPayload
     */
    'company_name': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPayload
     */
    'company_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPayload
     */
    'business_owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPayload
     */
    'project_director'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPayload
     */
    'project_manager'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPayload
     */
    'client_relationship_manager'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPayload
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface ErrorLogPayload
 */
export interface ErrorLogPayload {
    /**
     * 
     * @type {number}
     * @memberof ErrorLogPayload
     */
    'user_id'?: number;
    /**
     * Current location
     * @type {string}
     * @memberof ErrorLogPayload
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogPayload
     */
    'stack': string;
    /**
     * Where this error was caught
     * @type {string}
     * @memberof ErrorLogPayload
     */
    'context': string;
}
/**
 * 
 * @export
 * @interface IAdminPermission
 */
export interface IAdminPermission {
    /**
     * 
     * @type {number}
     * @memberof IAdminPermission
     */
    'admin_permission_id': number;
    /**
     * 
     * @type {number}
     * @memberof IAdminPermission
     */
    'user_id': number;
    /**
     * 
     * @type {number}
     * @memberof IAdminPermission
     */
    'admin_type_id': number;
}
/**
 * 
 * @export
 * @interface IArchetype
 */
export interface IArchetype {
    /**
     * 
     * @type {string}
     * @memberof IArchetype
     */
    'building_type_code': string;
    /**
     * 
     * @type {string}
     * @memberof IArchetype
     */
    'fuel_use_type_code': string;
    /**
     * 
     * @type {string}
     * @memberof IArchetype
     */
    'morphology_type_code': string;
    /**
     * 
     * @type {string}
     * @memberof IArchetype
     */
    'building_age_type_code': string;
    /**
     * 
     * @type {string}
     * @memberof IArchetype
     */
    'archetype_performance_type_code': string;
    /**
     * 
     * @type {boolean}
     * @memberof IArchetype
     */
    'on_site_renewable': boolean;
    /**
     * 
     * @type {string}
     * @memberof IArchetype
     */
    'archetype_climate_type_code': string;
    /**
     * 
     * @type {IScenarioCost}
     * @memberof IArchetype
     */
    'cost': IScenarioCost;
    /**
     * 
     * @type {Array<IScenarioPathwaysInner>}
     * @memberof IArchetype
     */
    'archetypes_pathways': Array<IScenarioPathwaysInner>;
    /**
     * 
     * @type {Array<IArchetypeDecarbMeasures>}
     * @memberof IArchetype
     */
    'archetypes_decarb_measures': Array<IArchetypeDecarbMeasures>;
}
/**
 * 
 * @export
 * @interface IArchetypeDecarbMeasures
 */
export interface IArchetypeDecarbMeasures {
    /**
     * 
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'archetype_decarb_measure_id': number;
    /**
     * 
     * @type {string}
     * @memberof IArchetypeDecarbMeasures
     */
    'package_code': string;
    /**
     * Total GHG change for asset, absolute
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'ghg_change'?: number;
    /**
     * GHG change relative (%)
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'ghg_change_relative'?: number;
    /**
     * Total energy change for asset, absolute
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'eui_change'?: number;
    /**
     * Energy change relative (%)
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'eui_change_relative'?: number;
    /**
     * Total CAPEX cost, in currency
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'capex_cost_change'?: number;
    /**
     * Decarb pathway CAPEX (=Additional CAPEX), (currency)
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'capex_decarb_change'?: number;
    /**
     * EOL, End-of-Life CAPEX (=BAU, Busines-as-Usual CAPEX), (currency)
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'capex_eol_change'?: number;
    /**
     * OPEX change (currency)
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'opex_change'?: number;
    /**
     * 
     * @type {string}
     * @memberof IArchetypeDecarbMeasures
     */
    'intervention_code': string;
    /**
     * 
     * @type {string}
     * @memberof IArchetypeDecarbMeasures
     */
    'intervention_category_name': string;
    /**
     * 
     * @type {string}
     * @memberof IArchetypeDecarbMeasures
     */
    'intervention_description': string;
    /**
     * 
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'implementation_year': number;
    /**
     * 
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'electricity_usage_change': number;
    /**
     * 
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'gas_usage_change': number;
    /**
     * 
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'oil_usage_change': number;
    /**
     * 
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'dh_usage_change': number;
    /**
     * 
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'dc_usage_change': number;
    /**
     * 
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'other_usage_type': number;
    /**
     * 
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'other_usage_change': number;
    /**
     * currency/tCO2
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'marginal_abatement_cost': number;
    /**
     * Measured in years payback
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'simple_payback'?: number;
    /**
     * %
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'roi_annualised'?: number;
    /**
     * 
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'relative_capex_value': number;
    /**
     * 
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'total_capex_opex': number;
    /**
     * 
     * @type {number}
     * @memberof IArchetypeDecarbMeasures
     */
    'energy_emission_change': number;
}
/**
 * 
 * @export
 * @interface IAsset
 */
export interface IAsset {
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'asset_id': number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'portfolio_id': number;
    /**
     * 
     * @type {string}
     * @memberof IAsset
     */
    'portfolio_name': string;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'company_id': number;
    /**
     * 
     * @type {string}
     * @memberof IAsset
     */
    'company_name': string;
    /**
     * Whatever text is used as asset name
     * @type {string}
     * @memberof IAsset
     */
    'asset_label': string;
    /**
     * Location short code
     * @type {string}
     * @memberof IAsset
     */
    'model_location_code'?: string;
    /**
     * Human-readable description of location
     * @type {string}
     * @memberof IAsset
     */
    'model_location_description': string;
    /**
     * Building short code
     * @type {string}
     * @memberof IAsset
     */
    'model_building_type_code'?: string;
    /**
     * Human-readable description of building
     * @type {string}
     * @memberof IAsset
     */
    'model_building_type_description'?: string;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'business_location_id'?: number;
    /**
     * Human-readable description of location
     * @type {string}
     * @memberof IAsset
     */
    'business_building_type_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAsset
     */
    'iso_country_code': string;
    /**
     * 
     * @type {string}
     * @memberof IAsset
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof IAsset
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAsset
     */
    'zip_code': string;
    /**
     * 
     * @type {boolean}
     * @memberof IAsset
     */
    'listed_building': boolean;
    /**
     * Energy performance certificates rating for building is a letter in range A-G
     * @type {string}
     * @memberof IAsset
     */
    'epc_rating'?: string;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'epc_intensity'?: number;
    /**
     * Also a letter in range A-G, but shows actual performance
     * @type {string}
     * @memberof IAsset
     */
    'epc_performance'?: string;
    /**
     * Energy Efficiency: NABERS Rating
     * @type {number}
     * @memberof IAsset
     */
    'nabers_rating'?: number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'build_year': number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'reporting_year': number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'last_major_renovation_year': number;
    /**
     * 
     * @type {string}
     * @memberof IAsset
     */
    'gps_coordinates'?: string;
    /**
     * Total area of building, presumably km^2
     * @type {number}
     * @memberof IAsset
     */
    'area': number;
    /**
     * Asset value in currency specified
     * @type {number}
     * @memberof IAsset
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'consumption_electricity': number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'consumption_oil': number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'consumption_gas': number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'consumption_heating': number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'consumption_cooling': number;
    /**
     * 
     * @type {string}
     * @memberof IAsset
     */
    'consumption_other_type_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'consumption_other_type_value'?: number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'renewable_electricity_consumed'?: number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'renewable_electricity_exported'?: number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'renewable_other_consumed'?: number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'renewable_other_exported'?: number;
    /**
     * percentage
     * @type {number}
     * @memberof IAsset
     */
    'data_quality'?: number;
    /**
     * 
     * @type {string}
     * @memberof IAsset
     */
    'asset_image_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'share_of_portfolio_ghg'?: number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'share_of_portfolio_value'?: number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'capex_cost_decarb'?: number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'capex_cost_decarb_per_meter'?: number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'capex_cost_oel'?: number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'capex_cost_oel_per_meter'?: number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'capex_cost_total'?: number;
    /**
     * This field is not used yet, but reserved in case we\'ll return carbon open calcs
     * @type {number}
     * @memberof IAsset
     */
    'opex_status_quo_carbon'?: number;
    /**
     * This field is not used yet, but reserved in case we\'ll return carbon open calcs
     * @type {number}
     * @memberof IAsset
     */
    'opex_pathway_carbon'?: number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'opex_status_quo_energy'?: number;
    /**
     * 
     * @type {number}
     * @memberof IAsset
     */
    'opex_pathway_energy'?: number;
    /**
     * 
     * @type {Array<IBusinessBuildingType>}
     * @memberof IAsset
     */
    'business_building_types'?: Array<IBusinessBuildingType>;
    /**
     * 
     * @type {ITaxonomy}
     * @memberof IAsset
     */
    'taxonomy'?: ITaxonomy;
    /**
     * 
     * @type {Array<IAssetCertification>}
     * @memberof IAsset
     */
    'certifications'?: Array<IAssetCertification>;
    /**
     * 
     * @type {Array<ICustomPathway>}
     * @memberof IAsset
     */
    'custom_pathways'?: Array<ICustomPathway>;
    /**
     * Asset emissions forecasts grouped by intensity type. \'Baselines\' for all charts in the app
     * @type {Array<IAssetEmissionsForecast>}
     * @memberof IAsset
     */
    'emission_forecasts': Array<IAssetEmissionsForecast>;
    /**
     * Asset stranding information, group per intensity type and model version. E.g. stranding results for GHG SBTi (pathway, stranding year, performance, etc)
     * @type {Array<IAssetStrandingResult>}
     * @memberof IAsset
     */
    'stranding': Array<IAssetStrandingResult>;
    /**
     * Target time series for building_type, model_location, intensity_type, model_version
     * @type {Array<ITargetTimeSeries>}
     * @memberof IAsset
     */
    'target_time_series'?: Array<ITargetTimeSeries>;
}
/**
 * 
 * @export
 * @interface IAssetCertification
 */
export interface IAssetCertification {
    /**
     * 
     * @type {number}
     * @memberof IAssetCertification
     */
    'asset_certification_type_id': number;
    /**
     * 
     * @type {string}
     * @memberof IAssetCertification
     */
    'asset_certification_type_name': string;
    /**
     * 
     * @type {string}
     * @memberof IAssetCertification
     */
    'certification_result': string;
}
/**
 * 
 * @export
 * @interface IAssetEmissionsForecast
 */
export interface IAssetEmissionsForecast {
    /**
     * 
     * @type {number}
     * @memberof IAssetEmissionsForecast
     */
    'intensity_type_id': number;
    /**
     * 
     * @type {string}
     * @memberof IAssetEmissionsForecast
     */
    'intensity_type_name': string;
    /**
     * 
     * @type {Array<ITimeSeriesPoint>}
     * @memberof IAssetEmissionsForecast
     */
    'time_series_points'?: Array<ITimeSeriesPoint>;
}
/**
 * All stranding information grouped by intensity type+model version
 * @export
 * @interface IAssetStrandingResult
 */
export interface IAssetStrandingResult {
    /**
     * 
     * @type {number}
     * @memberof IAssetStrandingResult
     */
    'model_version_id': number;
    /**
     * 
     * @type {string}
     * @memberof IAssetStrandingResult
     */
    'model_version_name': string;
    /**
     * 
     * @type {string}
     * @memberof IAssetStrandingResult
     */
    'model_description': string;
    /**
     * 
     * @type {string}
     * @memberof IAssetStrandingResult
     */
    'model_name': string;
    /**
     * 
     * @type {number}
     * @memberof IAssetStrandingResult
     */
    'intensity_type_id': number;
    /**
     * 
     * @type {string}
     * @memberof IAssetStrandingResult
     */
    'intensity_type_name': string;
    /**
     * 
     * @type {number}
     * @memberof IAssetStrandingResult
     */
    'stranding_year': number;
    /**
     * 
     * @type {number}
     * @memberof IAssetStrandingResult
     */
    'performance': number;
    /**
     * Values are: 0 - low risk, 1 - medium risk, 2 - high risk
     * @type {number}
     * @memberof IAssetStrandingResult
     */
    'risk_weighted'?: number;
}
/**
 * 
 * @export
 * @interface IAuthData
 */
export interface IAuthData {
    /**
     * 
     * @type {number}
     * @memberof IAuthData
     */
    'user_id': number;
    /**
     * 
     * @type {string}
     * @memberof IAuthData
     */
    'user_name': string;
    /**
     * 
     * @type {string}
     * @memberof IAuthData
     */
    'user_email': string;
    /**
     * 
     * @type {boolean}
     * @memberof IAuthData
     */
    'is_sys_admin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IAuthData
     */
    'is_app_admin'?: boolean;
    /**
     * 
     * @type {Array<ICompanyPermission>}
     * @memberof IAuthData
     */
    'permissions'?: Array<ICompanyPermission>;
}
/**
 * Building types including Arup building type and specific types defined for client to whom said asset belong
 * @export
 * @interface IBusinessBuildingType
 */
export interface IBusinessBuildingType {
    /**
     * 
     * @type {number}
     * @memberof IBusinessBuildingType
     */
    'business_building_type_id': number;
    /**
     * 
     * @type {number}
     * @memberof IBusinessBuildingType
     */
    'business_building_type_percentage'?: number;
    /**
     * Name of building type as client will see it
     * @type {string}
     * @memberof IBusinessBuildingType
     */
    'business_building_type_label': string;
}
/**
 * 
 * @export
 * @interface ICompany
 */
export interface ICompany {
    /**
     * 
     * @type {number}
     * @memberof ICompany
     */
    'company_id': number;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'country_code': string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'company_name': string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'company_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'business_owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'project_director'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'project_manager'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'client_relationship_manager'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'updated_datetime'?: string;
}
/**
 * 
 * @export
 * @interface ICompanyPermission
 */
export interface ICompanyPermission {
    /**
     * 
     * @type {number}
     * @memberof ICompanyPermission
     */
    'company_level_permission_id': number;
    /**
     * 
     * @type {number}
     * @memberof ICompanyPermission
     */
    'user_id': number;
    /**
     * 
     * @type {string}
     * @memberof ICompanyPermission
     */
    'user_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICompanyPermission
     */
    'user_email'?: string;
    /**
     * 
     * @type {number}
     * @memberof ICompanyPermission
     */
    'permission_type_id': number;
    /**
     * 
     * @type {string}
     * @memberof ICompanyPermission
     */
    'permission_type_name': string;
    /**
     * 
     * @type {number}
     * @memberof ICompanyPermission
     */
    'entity_type_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ICompanyPermission
     */
    'entity_type_name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ICompanyPermission
     */
    'entity_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ICompanyPermission
     */
    'entity_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICompanyPermission
     */
    'company_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICompanyPermission
     */
    'portfolio_name'?: string | null;
}
/**
 * 
 * @export
 * @interface ICountry
 */
export interface ICountry {
    /**
     * Country ISO code
     * @type {string}
     * @memberof ICountry
     */
    'country_iso_code': string;
    /**
     * Name of the country
     * @type {string}
     * @memberof ICountry
     */
    'country_label': string;
    /**
     * Reference to a region in the region table
     * @type {number}
     * @memberof ICountry
     */
    'region_id'?: number;
}
/**
 * 
 * @export
 * @interface ICrispUser
 */
export interface ICrispUser {
    /**
     * 
     * @type {number}
     * @memberof ICrispUser
     */
    'user_id': number;
    /**
     * 
     * @type {string}
     * @memberof ICrispUser
     */
    'user_name': string;
    /**
     * 
     * @type {string}
     * @memberof ICrispUser
     */
    'user_email': string;
    /**
     * 
     * @type {boolean}
     * @memberof ICrispUser
     */
    'is_active'?: boolean;
}
/**
 * 
 * @export
 * @interface ICustomPathway
 */
export interface ICustomPathway {
    /**
     * 
     * @type {number}
     * @memberof ICustomPathway
     */
    'custom_pathway_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ICustomPathway
     */
    'intensity_type_id': number;
    /**
     * 
     * @type {string}
     * @memberof ICustomPathway
     */
    'intensity_type_name': string;
    /**
     * 
     * @type {string}
     * @memberof ICustomPathway
     */
    'custom_pathway_name': string;
    /**
     * 
     * @type {Array<ITimeSeriesPoint>}
     * @memberof ICustomPathway
     */
    'time_series_points': Array<ITimeSeriesPoint>;
}
/**
 * 
 * @export
 * @interface IError
 */
export interface IError {
    /**
     * 
     * @type {number}
     * @memberof IError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof IError
     */
    'message': string;
    /**
     * Custom error code
     * @type {string}
     * @memberof IError
     */
    'errorCode'?: string;
}
/**
 * 
 * @export
 * @interface IFeatureConfig
 */
export interface IFeatureConfig {
    /**
     * Unique id
     * @type {number}
     * @memberof IFeatureConfig
     */
    'feature_config_id': number;
    /**
     * Feature key, should be unique per for company, but who knows
     * @type {string}
     * @memberof IFeatureConfig
     */
    'feature_key': string;
    /**
     * ON/OFF
     * @type {boolean}
     * @memberof IFeatureConfig
     */
    'feature_status': boolean;
    /**
     * Might have or not have some feature-specific content
     * @type {string}
     * @memberof IFeatureConfig
     */
    'feature_config'?: string;
}
/**
 * 
 * @export
 * @interface IIntervention
 */
export interface IIntervention {
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'asset_intervention_id': number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'asset_id': number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'capex_cost': number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'capex_decarb_cost': number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'capex_eol_cost': number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'dc_usage_change': number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'dh_usage_change': number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'electricity_usage_change': number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'gas_usage_change': number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'ghg_emission_change'?: number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'implementation_year': number;
    /**
     * 
     * @type {string}
     * @memberof IIntervention
     */
    'intervention_code': string;
    /**
     * 
     * @type {string}
     * @memberof IIntervention
     */
    'intervention_category_name': string;
    /**
     * 
     * @type {string}
     * @memberof IIntervention
     */
    'intervention_description': string;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'mac'?: number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'oil_usage_change': number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'other_usage_change': number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'other_usage_type': number;
    /**
     * 
     * @type {string}
     * @memberof IIntervention
     */
    'package_code': string;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'renewable_other_increase'?: number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'marginal_abatement_cost': number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'relative_ghg_value': number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'relative_energy_value'?: number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'relative_capex_value': number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'total_capex_opex': number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'opex_revenue_cost'?: number;
    /**
     * 
     * @type {number}
     * @memberof IIntervention
     */
    'energy_emission_change': number;
}
/**
 * Portfolio data
 * @export
 * @interface IPortfolio
 */
export interface IPortfolio {
    /**
     * 
     * @type {number}
     * @memberof IPortfolio
     */
    'portfolio_id': number;
    /**
     * 
     * @type {number}
     * @memberof IPortfolio
     */
    'company_id': number;
    /**
     * 
     * @type {string}
     * @memberof IPortfolio
     */
    'company_address': string;
    /**
     * 
     * @type {string}
     * @memberof IPortfolio
     */
    'company_name': string;
    /**
     * 
     * @type {string}
     * @memberof IPortfolio
     */
    'portfolio_name': string;
    /**
     * 
     * @type {string}
     * @memberof IPortfolio
     */
    'fund_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof IPortfolio
     */
    'iso_currency_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof IPortfolio
     */
    'unit_system_type_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof IPortfolio
     */
    'num_of_assets': number;
    /**
     * 
     * @type {number}
     * @memberof IPortfolio
     */
    'stranding_year_l_to_m'?: number;
    /**
     * 
     * @type {number}
     * @memberof IPortfolio
     */
    'stranding_year_m_to_h'?: number;
    /**
     * 
     * @type {number}
     * @memberof IPortfolio
     */
    'total_area': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IPortfolio
     */
    'building_types': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof IPortfolio
     */
    'total_value': number;
    /**
     * 
     * @type {number}
     * @memberof IPortfolio
     */
    'total_taxonomy'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IPortfolio
     */
    'countries': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof IPortfolio
     */
    'stranding_risk_level'?: number;
    /**
     * 
     * @type {number}
     * @memberof IPortfolio
     */
    'data_quality'?: number;
    /**
     * 
     * @type {number}
     * @memberof IPortfolio
     */
    'certifications': number;
    /**
     * 
     * @type {string}
     * @memberof IPortfolio
     */
    'updated_datetime'?: string;
}
/**
 * 
 * @export
 * @interface IPortfolioFile
 */
export interface IPortfolioFile {
    /**
     * 
     * @type {string}
     * @memberof IPortfolioFile
     */
    'portfolio_id': string;
    /**
     * 
     * @type {string}
     * @memberof IPortfolioFile
     */
    'file_name': string;
    /**
     * 
     * @type {string}
     * @memberof IPortfolioFile
     */
    'file_path': string;
}
/**
 * 
 * @export
 * @interface IScenario
 */
export interface IScenario {
    /**
     * 
     * @type {string}
     * @memberof IScenario
     */
    'scenario_name': string;
    /**
     * 
     * @type {IScenarioCost}
     * @memberof IScenario
     */
    'cost': IScenarioCost;
    /**
     * 
     * @type {Array<IScenarioPathwaysInner>}
     * @memberof IScenario
     */
    'pathways': Array<IScenarioPathwaysInner>;
    /**
     * 
     * @type {Array<IIntervention>}
     * @memberof IScenario
     */
    'interventions': Array<IIntervention>;
}
/**
 * 
 * @export
 * @interface IScenarioCost
 */
export interface IScenarioCost {
    /**
     * 
     * @type {number}
     * @memberof IScenarioCost
     */
    'capex_eol_total': number;
    /**
     * 
     * @type {number}
     * @memberof IScenarioCost
     */
    'capex_decarb_total': number;
    /**
     * 
     * @type {number}
     * @memberof IScenarioCost
     */
    'capex_cost_total': number;
    /**
     * 
     * @type {number}
     * @memberof IScenarioCost
     */
    'opex_total': number;
}
/**
 * 
 * @export
 * @interface IScenarioFile
 */
export interface IScenarioFile {
    /**
     * 
     * @type {string}
     * @memberof IScenarioFile
     */
    'portfolio_id': string;
    /**
     * 
     * @type {string}
     * @memberof IScenarioFile
     */
    'asset_id': string;
    /**
     * 
     * @type {string}
     * @memberof IScenarioFile
     */
    'file_name': string;
    /**
     * 
     * @type {string}
     * @memberof IScenarioFile
     */
    'file_path': string;
}
/**
 * 
 * @export
 * @interface IScenarioPathwaysInner
 */
export interface IScenarioPathwaysInner {
    /**
     * 
     * @type {number}
     * @memberof IScenarioPathwaysInner
     */
    'scenario_chart_type_id': number;
    /**
     * 
     * @type {string}
     * @memberof IScenarioPathwaysInner
     */
    'intensity_type_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof IScenarioPathwaysInner
     */
    'scenario_chart_type_name': string;
    /**
     * 
     * @type {Array<ITimeSeriesPoint>}
     * @memberof IScenarioPathwaysInner
     */
    'time_series_points'?: Array<ITimeSeriesPoint>;
}
/**
 * All target time series grouped by intensity type+model version+model building type+model location
 * @export
 * @interface ITargetTimeSeries
 */
export interface ITargetTimeSeries {
    /**
     * 
     * @type {number}
     * @memberof ITargetTimeSeries
     */
    'intensity_type_id': number;
    /**
     * 
     * @type {string}
     * @memberof ITargetTimeSeries
     */
    'intensity_type_name': string;
    /**
     * 
     * @type {number}
     * @memberof ITargetTimeSeries
     */
    'model_version_id': number;
    /**
     * 
     * @type {string}
     * @memberof ITargetTimeSeries
     */
    'model_version_name': string;
    /**
     * 
     * @type {string}
     * @memberof ITargetTimeSeries
     */
    'model_description': string;
    /**
     * 
     * @type {string}
     * @memberof ITargetTimeSeries
     */
    'model_building_type_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITargetTimeSeries
     */
    'model_location_code'?: string;
    /**
     * 
     * @type {Array<ITimeSeriesPoint>}
     * @memberof ITargetTimeSeries
     */
    'time_series_points'?: Array<ITimeSeriesPoint>;
}
/**
 * 
 * @export
 * @interface ITaxonomy
 */
export interface ITaxonomy {
    /**
     * 
     * @type {string}
     * @memberof ITaxonomy
     */
    'substantial_contribution_type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ITaxonomy
     */
    'substantial_contribution_compliance'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITaxonomy
     */
    'climate_change_mitigation_assessment'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITaxonomy
     */
    'climate_change_mitigation_in_five_years'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITaxonomy
     */
    'climate_change_adaptation_min_class_c'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITaxonomy
     */
    'climate_change_adaptation_no_fossil'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITaxonomy
     */
    'biodiversity_assessment'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITaxonomy
     */
    'biodiversity_adaptation_five_years'?: boolean;
}
/**
 * 
 * @export
 * @interface ITimeSeriesPoint
 */
export interface ITimeSeriesPoint {
    /**
     * 
     * @type {number}
     * @memberof ITimeSeriesPoint
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof ITimeSeriesPoint
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface PortfolioPayload
 */
export interface PortfolioPayload {
    /**
     * Name of the portfolio
     * @type {string}
     * @memberof PortfolioPayload
     */
    'portfolio_name': string;
    /**
     * Reference to a company in the company table
     * @type {number}
     * @memberof PortfolioPayload
     */
    'company_id': number;
    /**
     * Level of analysis done on portfolio
     * @type {string}
     * @memberof PortfolioPayload
     */
    'analysis_level'?: string;
    /**
     * Name of fund type
     * @type {string}
     * @memberof PortfolioPayload
     */
    'fund_type': string;
    /**
     * Currency of portfolio
     * @type {string}
     * @memberof PortfolioPayload
     */
    'iso_currency_code'?: string;
    /**
     * System of measurement of portfolio
     * @type {string}
     * @memberof PortfolioPayload
     */
    'system_of_measurement'?: string;
    /**
     * Version of Input Spreadsheet
     * @type {string}
     * @memberof PortfolioPayload
     */
    'file_version'?: string;
    /**
     * Version of CRISP used
     * @type {string}
     * @memberof PortfolioPayload
     */
    'crisp_version'?: string;
    /**
     * Stranding year for low to medium risk
     * @type {number}
     * @memberof PortfolioPayload
     */
    'stranding_year_l_to_m'?: number;
    /**
     * Stranding year for medium to high risk
     * @type {number}
     * @memberof PortfolioPayload
     */
    'stranding_year_m_to_h'?: number;
}
/**
 * 
 * @export
 * @interface RecalculateLogs200ResponseInner
 */
export interface RecalculateLogs200ResponseInner {
    /**
     * 
     * @type {number}
     * @memberof RecalculateLogs200ResponseInner
     */
    'logs_recalculate_id': number;
    /**
     * 
     * @type {string}
     * @memberof RecalculateLogs200ResponseInner
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof RecalculateLogs200ResponseInner
     */
    'error_log'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecalculateLogs200ResponseInner
     */
    'start_datetime': string;
    /**
     * 
     * @type {string}
     * @memberof RecalculateLogs200ResponseInner
     */
    'finish_datetime'?: string;
}
/**
 * 
 * @export
 * @interface UpsertCrispUserRequest
 */
export interface UpsertCrispUserRequest {
    /**
     * 
     * @type {number}
     * @memberof UpsertCrispUserRequest
     */
    'user_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpsertCrispUserRequest
     */
    'user_name': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertCrispUserRequest
     */
    'user_email': string;
}
/**
 * 
 * @export
 * @interface ValidatePortfolioRequest
 */
export interface ValidatePortfolioRequest {
    /**
     * 
     * @type {PortfolioPayload}
     * @memberof ValidatePortfolioRequest
     */
    'portfolio'?: PortfolioPayload;
    /**
     * 
     * @type {Array<AssetPayload>}
     * @memberof ValidatePortfolioRequest
     */
    'assets'?: Array<AssetPayload>;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Activate user
         * @param {number} userId User id
         * @param {ActivateCrispUserRequest} activateCrispUserRequest User new status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCrispUser: async (userId: number, activateCrispUserRequest: ActivateCrispUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('activateCrispUser', 'userId', userId)
            // verify required parameter 'activateCrispUserRequest' is not null or undefined
            assertParamExists('activateCrispUser', 'activateCrispUserRequest', activateCrispUserRequest)
            const localVarPath = `/users/user/{userId}/activate`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activateCrispUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add admin user permissions
         * @param {Array<AddAdminUserPermissionsRequestInner>} addAdminUserPermissionsRequestInner Permissions array
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminUserPermissions: async (addAdminUserPermissionsRequestInner: Array<AddAdminUserPermissionsRequestInner>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addAdminUserPermissionsRequestInner' is not null or undefined
            assertParamExists('addAdminUserPermissions', 'addAdminUserPermissionsRequestInner', addAdminUserPermissionsRequestInner)
            const localVarPath = `/users/adminPermissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addAdminUserPermissionsRequestInner, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add user permissions
         * @param {Array<AddUserPermissionsRequestInner>} addUserPermissionsRequestInner Permissions array
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserPermissions: async (addUserPermissionsRequestInner: Array<AddUserPermissionsRequestInner>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addUserPermissionsRequestInner' is not null or undefined
            assertParamExists('addUserPermissions', 'addUserPermissionsRequestInner', addUserPermissionsRequestInner)
            const localVarPath = `/users/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUserPermissionsRequestInner, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete admin user permissions
         * @param {Array<number>} requestBody Permissions ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminPermissions: async (requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteAdminPermissions', 'requestBody', requestBody)
            const localVarPath = `/users/adminPermissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete crisp user
         * @param {number} userId User id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/users/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete user permissions
         * @param {Array<number>} requestBody Permissions ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPermissions: async (requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteUserPermissions', 'requestBody', requestBody)
            const localVarPath = `/users/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get permissions by user id
         * @param {number} userId User id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminPermissionsByUserId: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAdminPermissionsByUserId', 'userId', userId)
            const localVarPath = `/users/adminPermissions/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCrispUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a user by email
         * @param {string} userEmail User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrispUserByEmail: async (userEmail: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userEmail' is not null or undefined
            assertParamExists('getCrispUserByEmail', 'userEmail', userEmail)
            const localVarPath = `/users/user/by-email/{userEmail}`
                .replace(`{${"userEmail"}}`, encodeURIComponent(String(userEmail)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get users filtered by company id
         * @param {number} companyId Company id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrispUsersByCompanyId: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCrispUsersByCompanyId', 'companyId', companyId)
            const localVarPath = `/users/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get permissions by company id
         * @param {number} companyId Company id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionsByCompanyId: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPermissionsByCompanyId', 'companyId', companyId)
            const localVarPath = `/users/permissions/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get permissions by user id
         * @param {number} userId User id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionsByUserId: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getPermissionsByUserId', 'userId', userId)
            const localVarPath = `/users/permissions/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Run recalc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recalculate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get recalc logs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculateLogs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recalculate/logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add/update user
         * @param {UpsertCrispUserRequest} upsertCrispUserRequest User data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertCrispUser: async (upsertCrispUserRequest: UpsertCrispUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsertCrispUserRequest' is not null or undefined
            assertParamExists('upsertCrispUser', 'upsertCrispUserRequest', upsertCrispUserRequest)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertCrispUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * Activate user
         * @param {number} userId User id
         * @param {ActivateCrispUserRequest} activateCrispUserRequest User new status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateCrispUser(userId: number, activateCrispUserRequest: ActivateCrispUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICrispUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateCrispUser(userId, activateCrispUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add admin user permissions
         * @param {Array<AddAdminUserPermissionsRequestInner>} addAdminUserPermissionsRequestInner Permissions array
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAdminUserPermissions(addAdminUserPermissionsRequestInner: Array<AddAdminUserPermissionsRequestInner>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IAdminPermission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAdminUserPermissions(addAdminUserPermissionsRequestInner, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add user permissions
         * @param {Array<AddUserPermissionsRequestInner>} addUserPermissionsRequestInner Permissions array
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserPermissions(addUserPermissionsRequestInner: Array<AddUserPermissionsRequestInner>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ICompanyPermission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserPermissions(addUserPermissionsRequestInner, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete admin user permissions
         * @param {Array<number>} requestBody Permissions ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminPermissions(requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminPermissions(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete crisp user
         * @param {number} userId User id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete user permissions
         * @param {Array<number>} requestBody Permissions ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserPermissions(requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserPermissions(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get permissions by user id
         * @param {number} userId User id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminPermissionsByUserId(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IAdminPermission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminPermissionsByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCrispUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ICrispUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCrispUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a user by email
         * @param {string} userEmail User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCrispUserByEmail(userEmail: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICrispUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCrispUserByEmail(userEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get users filtered by company id
         * @param {number} companyId Company id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCrispUsersByCompanyId(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ICrispUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCrispUsersByCompanyId(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get permissions by company id
         * @param {number} companyId Company id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissionsByCompanyId(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ICompanyPermission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermissionsByCompanyId(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get permissions by user id
         * @param {number} userId User id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissionsByUserId(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ICompanyPermission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermissionsByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Run recalc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recalculate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recalculate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get recalc logs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recalculateLogs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecalculateLogs200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recalculateLogs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add/update user
         * @param {UpsertCrispUserRequest} upsertCrispUserRequest User data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertCrispUser(upsertCrispUserRequest: UpsertCrispUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICrispUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertCrispUser(upsertCrispUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * Activate user
         * @param {number} userId User id
         * @param {ActivateCrispUserRequest} activateCrispUserRequest User new status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCrispUser(userId: number, activateCrispUserRequest: ActivateCrispUserRequest, options?: any): AxiosPromise<ICrispUser> {
            return localVarFp.activateCrispUser(userId, activateCrispUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Add admin user permissions
         * @param {Array<AddAdminUserPermissionsRequestInner>} addAdminUserPermissionsRequestInner Permissions array
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminUserPermissions(addAdminUserPermissionsRequestInner: Array<AddAdminUserPermissionsRequestInner>, options?: any): AxiosPromise<Array<IAdminPermission>> {
            return localVarFp.addAdminUserPermissions(addAdminUserPermissionsRequestInner, options).then((request) => request(axios, basePath));
        },
        /**
         * Add user permissions
         * @param {Array<AddUserPermissionsRequestInner>} addUserPermissionsRequestInner Permissions array
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserPermissions(addUserPermissionsRequestInner: Array<AddUserPermissionsRequestInner>, options?: any): AxiosPromise<Array<ICompanyPermission>> {
            return localVarFp.addUserPermissions(addUserPermissionsRequestInner, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete admin user permissions
         * @param {Array<number>} requestBody Permissions ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminPermissions(requestBody: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminPermissions(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete crisp user
         * @param {number} userId User id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete user permissions
         * @param {Array<number>} requestBody Permissions ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPermissions(requestBody: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserPermissions(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Get permissions by user id
         * @param {number} userId User id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminPermissionsByUserId(userId: number, options?: any): AxiosPromise<Array<IAdminPermission>> {
            return localVarFp.getAdminPermissionsByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCrispUsers(options?: any): AxiosPromise<Array<ICrispUser>> {
            return localVarFp.getAllCrispUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * Get a user by email
         * @param {string} userEmail User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrispUserByEmail(userEmail: string, options?: any): AxiosPromise<ICrispUser> {
            return localVarFp.getCrispUserByEmail(userEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * Get users filtered by company id
         * @param {number} companyId Company id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrispUsersByCompanyId(companyId: number, options?: any): AxiosPromise<Array<ICrispUser>> {
            return localVarFp.getCrispUsersByCompanyId(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get permissions by company id
         * @param {number} companyId Company id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionsByCompanyId(companyId: number, options?: any): AxiosPromise<Array<ICompanyPermission>> {
            return localVarFp.getPermissionsByCompanyId(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get permissions by user id
         * @param {number} userId User id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionsByUserId(userId: number, options?: any): AxiosPromise<Array<ICompanyPermission>> {
            return localVarFp.getPermissionsByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Run recalc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculate(options?: any): AxiosPromise<string> {
            return localVarFp.recalculate(options).then((request) => request(axios, basePath));
        },
        /**
         * Get recalc logs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculateLogs(options?: any): AxiosPromise<Array<RecalculateLogs200ResponseInner>> {
            return localVarFp.recalculateLogs(options).then((request) => request(axios, basePath));
        },
        /**
         * Add/update user
         * @param {UpsertCrispUserRequest} upsertCrispUserRequest User data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertCrispUser(upsertCrispUserRequest: UpsertCrispUserRequest, options?: any): AxiosPromise<ICrispUser> {
            return localVarFp.upsertCrispUser(upsertCrispUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - interface
 * @export
 * @interface AdminApi
 */
export interface AdminApiInterface {
    /**
     * Activate user
     * @param {number} userId User id
     * @param {ActivateCrispUserRequest} activateCrispUserRequest User new status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    activateCrispUser(userId: number, activateCrispUserRequest: ActivateCrispUserRequest, options?: AxiosRequestConfig): AxiosPromise<ICrispUser>;

    /**
     * Add admin user permissions
     * @param {Array<AddAdminUserPermissionsRequestInner>} addAdminUserPermissionsRequestInner Permissions array
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    addAdminUserPermissions(addAdminUserPermissionsRequestInner: Array<AddAdminUserPermissionsRequestInner>, options?: AxiosRequestConfig): AxiosPromise<Array<IAdminPermission>>;

    /**
     * Add user permissions
     * @param {Array<AddUserPermissionsRequestInner>} addUserPermissionsRequestInner Permissions array
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    addUserPermissions(addUserPermissionsRequestInner: Array<AddUserPermissionsRequestInner>, options?: AxiosRequestConfig): AxiosPromise<Array<ICompanyPermission>>;

    /**
     * Delete admin user permissions
     * @param {Array<number>} requestBody Permissions ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    deleteAdminPermissions(requestBody: Array<number>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete crisp user
     * @param {number} userId User id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    deleteUser(userId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete user permissions
     * @param {Array<number>} requestBody Permissions ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    deleteUserPermissions(requestBody: Array<number>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get permissions by user id
     * @param {number} userId User id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    getAdminPermissionsByUserId(userId: number, options?: AxiosRequestConfig): AxiosPromise<Array<IAdminPermission>>;

    /**
     * Get all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    getAllCrispUsers(options?: AxiosRequestConfig): AxiosPromise<Array<ICrispUser>>;

    /**
     * Get a user by email
     * @param {string} userEmail User email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    getCrispUserByEmail(userEmail: string, options?: AxiosRequestConfig): AxiosPromise<ICrispUser>;

    /**
     * Get users filtered by company id
     * @param {number} companyId Company id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    getCrispUsersByCompanyId(companyId: number, options?: AxiosRequestConfig): AxiosPromise<Array<ICrispUser>>;

    /**
     * Get permissions by company id
     * @param {number} companyId Company id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    getPermissionsByCompanyId(companyId: number, options?: AxiosRequestConfig): AxiosPromise<Array<ICompanyPermission>>;

    /**
     * Get permissions by user id
     * @param {number} userId User id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    getPermissionsByUserId(userId: number, options?: AxiosRequestConfig): AxiosPromise<Array<ICompanyPermission>>;

    /**
     * Run recalc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    recalculate(options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Get recalc logs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    recalculateLogs(options?: AxiosRequestConfig): AxiosPromise<Array<RecalculateLogs200ResponseInner>>;

    /**
     * Add/update user
     * @param {UpsertCrispUserRequest} upsertCrispUserRequest User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    upsertCrispUser(upsertCrispUserRequest: UpsertCrispUserRequest, options?: AxiosRequestConfig): AxiosPromise<ICrispUser>;

}

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI implements AdminApiInterface {
    /**
     * Activate user
     * @param {number} userId User id
     * @param {ActivateCrispUserRequest} activateCrispUserRequest User new status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public activateCrispUser(userId: number, activateCrispUserRequest: ActivateCrispUserRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).activateCrispUser(userId, activateCrispUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add admin user permissions
     * @param {Array<AddAdminUserPermissionsRequestInner>} addAdminUserPermissionsRequestInner Permissions array
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public addAdminUserPermissions(addAdminUserPermissionsRequestInner: Array<AddAdminUserPermissionsRequestInner>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).addAdminUserPermissions(addAdminUserPermissionsRequestInner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add user permissions
     * @param {Array<AddUserPermissionsRequestInner>} addUserPermissionsRequestInner Permissions array
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public addUserPermissions(addUserPermissionsRequestInner: Array<AddUserPermissionsRequestInner>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).addUserPermissions(addUserPermissionsRequestInner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete admin user permissions
     * @param {Array<number>} requestBody Permissions ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminPermissions(requestBody: Array<number>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminPermissions(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete crisp user
     * @param {number} userId User id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteUser(userId: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete user permissions
     * @param {Array<number>} requestBody Permissions ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteUserPermissions(requestBody: Array<number>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteUserPermissions(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get permissions by user id
     * @param {number} userId User id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminPermissionsByUserId(userId: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminPermissionsByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAllCrispUsers(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAllCrispUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a user by email
     * @param {string} userEmail User email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getCrispUserByEmail(userEmail: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getCrispUserByEmail(userEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get users filtered by company id
     * @param {number} companyId Company id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getCrispUsersByCompanyId(companyId: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getCrispUsersByCompanyId(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get permissions by company id
     * @param {number} companyId Company id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getPermissionsByCompanyId(companyId: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getPermissionsByCompanyId(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get permissions by user id
     * @param {number} userId User id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getPermissionsByUserId(userId: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getPermissionsByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Run recalc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public recalculate(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).recalculate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get recalc logs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public recalculateLogs(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).recalculateLogs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add/update user
     * @param {UpsertCrispUserRequest} upsertCrispUserRequest User data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public upsertCrispUser(upsertCrispUserRequest: UpsertCrispUserRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).upsertCrispUser(upsertCrispUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ArchetypesApi - axios parameter creator
 * @export
 */
export const ArchetypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get archetypes by asset id
         * @param {number} assetId Asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArchetypesByAssetId: async (assetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getArchetypesByAssetId', 'assetId', assetId)
            const localVarPath = `/archetypes/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArchetypesApi - functional programming interface
 * @export
 */
export const ArchetypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArchetypesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get archetypes by asset id
         * @param {number} assetId Asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArchetypesByAssetId(assetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IArchetype>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArchetypesByAssetId(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArchetypesApi - factory interface
 * @export
 */
export const ArchetypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArchetypesApiFp(configuration)
    return {
        /**
         * Get archetypes by asset id
         * @param {number} assetId Asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArchetypesByAssetId(assetId: number, options?: any): AxiosPromise<IArchetype> {
            return localVarFp.getArchetypesByAssetId(assetId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArchetypesApi - interface
 * @export
 * @interface ArchetypesApi
 */
export interface ArchetypesApiInterface {
    /**
     * Get archetypes by asset id
     * @param {number} assetId Asset id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArchetypesApiInterface
     */
    getArchetypesByAssetId(assetId: number, options?: AxiosRequestConfig): AxiosPromise<IArchetype>;

}

/**
 * ArchetypesApi - object-oriented interface
 * @export
 * @class ArchetypesApi
 * @extends {BaseAPI}
 */
export class ArchetypesApi extends BaseAPI implements ArchetypesApiInterface {
    /**
     * Get archetypes by asset id
     * @param {number} assetId Asset id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArchetypesApi
     */
    public getArchetypesByAssetId(assetId: number, options?: AxiosRequestConfig) {
        return ArchetypesApiFp(this.configuration).getArchetypesByAssetId(assetId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssetApi - axios parameter creator
 * @export
 */
export const AssetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets signed image url for an asset
         * @param {number} assetId Asset ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetImage: async (assetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getAssetImage', 'assetId', assetId)
            const localVarPath = `/assets/images/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets report url for an asset
         * @param {number} assetId Asset ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetReport: async (assetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getAssetReport', 'assetId', assetId)
            const localVarPath = `/report/asset/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates image url for an asset
         * @param {number} portfolioId Portfolio ID
         * @param {number} assetId Asset ID
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAssetImage: async (portfolioId: number, assetId: number, image?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('putAssetImage', 'portfolioId', portfolioId)
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('putAssetImage', 'assetId', assetId)
            const localVarPath = `/assets/images/{portfolioId}/{assetId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetApi - functional programming interface
 * @export
 */
export const AssetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets signed image url for an asset
         * @param {number} assetId Asset ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetImage(assetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetImage(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets report url for an asset
         * @param {number} assetId Asset ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetReport(assetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetReport(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates image url for an asset
         * @param {number} portfolioId Portfolio ID
         * @param {number} assetId Asset ID
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAssetImage(portfolioId: number, assetId: number, image?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAssetImage(portfolioId, assetId, image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetApi - factory interface
 * @export
 */
export const AssetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetApiFp(configuration)
    return {
        /**
         * Gets signed image url for an asset
         * @param {number} assetId Asset ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetImage(assetId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getAssetImage(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets report url for an asset
         * @param {number} assetId Asset ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetReport(assetId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getAssetReport(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates image url for an asset
         * @param {number} portfolioId Portfolio ID
         * @param {number} assetId Asset ID
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAssetImage(portfolioId: number, assetId: number, image?: File, options?: any): AxiosPromise<string> {
            return localVarFp.putAssetImage(portfolioId, assetId, image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetApi - interface
 * @export
 * @interface AssetApi
 */
export interface AssetApiInterface {
    /**
     * Gets signed image url for an asset
     * @param {number} assetId Asset ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApiInterface
     */
    getAssetImage(assetId: number, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Gets report url for an asset
     * @param {number} assetId Asset ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApiInterface
     */
    getAssetReport(assetId: number, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Updates image url for an asset
     * @param {number} portfolioId Portfolio ID
     * @param {number} assetId Asset ID
     * @param {File} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApiInterface
     */
    putAssetImage(portfolioId: number, assetId: number, image?: File, options?: AxiosRequestConfig): AxiosPromise<string>;

}

/**
 * AssetApi - object-oriented interface
 * @export
 * @class AssetApi
 * @extends {BaseAPI}
 */
export class AssetApi extends BaseAPI implements AssetApiInterface {
    /**
     * Gets signed image url for an asset
     * @param {number} assetId Asset ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public getAssetImage(assetId: number, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).getAssetImage(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets report url for an asset
     * @param {number} assetId Asset ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public getAssetReport(assetId: number, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).getAssetReport(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates image url for an asset
     * @param {number} portfolioId Portfolio ID
     * @param {number} assetId Asset ID
     * @param {File} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public putAssetImage(portfolioId: number, assetId: number, image?: File, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).putAssetImage(portfolioId, assetId, image, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get current user auth and access data
         * @summary Get current user auth and access data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyAuthData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * Get current user auth and access data
         * @summary Get current user auth and access data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyAuthData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAuthData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyAuthData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * Get current user auth and access data
         * @summary Get current user auth and access data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyAuthData(options?: any): AxiosPromise<IAuthData> {
            return localVarFp.getMyAuthData(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - interface
 * @export
 * @interface AuthApi
 */
export interface AuthApiInterface {
    /**
     * Get current user auth and access data
     * @summary Get current user auth and access data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    getMyAuthData(options?: AxiosRequestConfig): AxiosPromise<IAuthData>;

}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI implements AuthApiInterface {
    /**
     * Get current user auth and access data
     * @summary Get current user auth and access data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getMyAuthData(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).getMyAuthData(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list of companies. Admin functionality
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of companies. Non-Admin functionality
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoneAdminCompanies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies/non_admin/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates company data
         * @param {number} companyId Company id
         * @param {ICompany} iCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany: async (companyId: number, iCompany: ICompany, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCompany', 'companyId', companyId)
            // verify required parameter 'iCompany' is not null or undefined
            assertParamExists('updateCompany', 'iCompany', iCompany)
            const localVarPath = `/companies/{companyId}/`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iCompany, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a new company. Admin functionality
         * @param {CompanyPayload} companyPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertCompany: async (companyPayload: CompanyPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyPayload' is not null or undefined
            assertParamExists('upsertCompany', 'companyPayload', companyPayload)
            const localVarPath = `/companies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get list of companies. Admin functionality
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ICompany>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of companies. Non-Admin functionality
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoneAdminCompanies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ICompany>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoneAdminCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates company data
         * @param {number} companyId Company id
         * @param {ICompany} iCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompany(companyId: number, iCompany: ICompany, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompany(companyId, iCompany, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a new company. Admin functionality
         * @param {CompanyPayload} companyPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertCompany(companyPayload: CompanyPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertCompany(companyPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @summary Get list of companies. Admin functionality
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanies(options?: any): AxiosPromise<Array<ICompany>> {
            return localVarFp.getCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of companies. Non-Admin functionality
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoneAdminCompanies(options?: any): AxiosPromise<Array<ICompany>> {
            return localVarFp.getNoneAdminCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates company data
         * @param {number} companyId Company id
         * @param {ICompany} iCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(companyId: number, iCompany: ICompany, options?: any): AxiosPromise<void> {
            return localVarFp.updateCompany(companyId, iCompany, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a new company. Admin functionality
         * @param {CompanyPayload} companyPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertCompany(companyPayload: CompanyPayload, options?: any): AxiosPromise<ICompany> {
            return localVarFp.upsertCompany(companyPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - interface
 * @export
 * @interface CompanyApi
 */
export interface CompanyApiInterface {
    /**
     * 
     * @summary Get list of companies. Admin functionality
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    getCompanies(options?: AxiosRequestConfig): AxiosPromise<Array<ICompany>>;

    /**
     * 
     * @summary Get list of companies. Non-Admin functionality
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    getNoneAdminCompanies(options?: AxiosRequestConfig): AxiosPromise<Array<ICompany>>;

    /**
     * Updates company data
     * @param {number} companyId Company id
     * @param {ICompany} iCompany 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    updateCompany(companyId: number, iCompany: ICompany, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Add a new company. Admin functionality
     * @param {CompanyPayload} companyPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    upsertCompany(companyPayload: CompanyPayload, options?: AxiosRequestConfig): AxiosPromise<ICompany>;

}

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI implements CompanyApiInterface {
    /**
     * 
     * @summary Get list of companies. Admin functionality
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompanies(options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of companies. Non-Admin functionality
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getNoneAdminCompanies(options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getNoneAdminCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates company data
     * @param {number} companyId Company id
     * @param {ICompany} iCompany 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateCompany(companyId: number, iCompany: ICompany, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).updateCompany(companyId, iCompany, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a new company. Admin functionality
     * @param {CompanyPayload} companyPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public upsertCompany(companyPayload: CompanyPayload, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).upsertCompany(companyPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigApi - axios parameter creator
 * @export
 */
export const ConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Success
         * @param {number} companyId Company Id
         * @param {IFeatureConfig} iFeatureConfig Feature config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFeatureConfigByCompanyId: async (companyId: number, iFeatureConfig: IFeatureConfig, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addFeatureConfigByCompanyId', 'companyId', companyId)
            // verify required parameter 'iFeatureConfig' is not null or undefined
            assertParamExists('addFeatureConfigByCompanyId', 'iFeatureConfig', iFeatureConfig)
            const localVarPath = `/feature-config/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iFeatureConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId Company Id
         * @param {number} featureConfigId feature config Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureConfig: async (companyId: number, featureConfigId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteFeatureConfig', 'companyId', companyId)
            // verify required parameter 'featureConfigId' is not null or undefined
            assertParamExists('deleteFeatureConfig', 'featureConfigId', featureConfigId)
            const localVarPath = `/feature-config/{companyId}/{featureConfigId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"featureConfigId"}}`, encodeURIComponent(String(featureConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get feature config for company
         * @param {number} companyId Company Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureConfigByCompanyId: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getFeatureConfigByCompanyId', 'companyId', companyId)
            const localVarPath = `/feature-config/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * Success
         * @param {number} companyId Company Id
         * @param {IFeatureConfig} iFeatureConfig Feature config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFeatureConfigByCompanyId(companyId: number, iFeatureConfig: IFeatureConfig, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddFeatureConfigByCompanyId200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFeatureConfigByCompanyId(companyId, iFeatureConfig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId Company Id
         * @param {number} featureConfigId feature config Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeatureConfig(companyId: number, featureConfigId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeatureConfig(companyId, featureConfigId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get feature config for company
         * @param {number} companyId Company Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureConfigByCompanyId(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFeatureConfig>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureConfigByCompanyId(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigApiFp(configuration)
    return {
        /**
         * Success
         * @param {number} companyId Company Id
         * @param {IFeatureConfig} iFeatureConfig Feature config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFeatureConfigByCompanyId(companyId: number, iFeatureConfig: IFeatureConfig, options?: any): AxiosPromise<AddFeatureConfigByCompanyId200Response> {
            return localVarFp.addFeatureConfigByCompanyId(companyId, iFeatureConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId Company Id
         * @param {number} featureConfigId feature config Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureConfig(companyId: number, featureConfigId: number, options?: any): AxiosPromise<number> {
            return localVarFp.deleteFeatureConfig(companyId, featureConfigId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get feature config for company
         * @param {number} companyId Company Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureConfigByCompanyId(companyId: number, options?: any): AxiosPromise<Array<IFeatureConfig>> {
            return localVarFp.getFeatureConfigByCompanyId(companyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigApi - interface
 * @export
 * @interface ConfigApi
 */
export interface ConfigApiInterface {
    /**
     * Success
     * @param {number} companyId Company Id
     * @param {IFeatureConfig} iFeatureConfig Feature config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApiInterface
     */
    addFeatureConfigByCompanyId(companyId: number, iFeatureConfig: IFeatureConfig, options?: AxiosRequestConfig): AxiosPromise<AddFeatureConfigByCompanyId200Response>;

    /**
     * 
     * @param {number} companyId Company Id
     * @param {number} featureConfigId feature config Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApiInterface
     */
    deleteFeatureConfig(companyId: number, featureConfigId: number, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * Get feature config for company
     * @param {number} companyId Company Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApiInterface
     */
    getFeatureConfigByCompanyId(companyId: number, options?: AxiosRequestConfig): AxiosPromise<Array<IFeatureConfig>>;

}

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI implements ConfigApiInterface {
    /**
     * Success
     * @param {number} companyId Company Id
     * @param {IFeatureConfig} iFeatureConfig Feature config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public addFeatureConfigByCompanyId(companyId: number, iFeatureConfig: IFeatureConfig, options?: AxiosRequestConfig) {
        return ConfigApiFp(this.configuration).addFeatureConfigByCompanyId(companyId, iFeatureConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId Company Id
     * @param {number} featureConfigId feature config Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public deleteFeatureConfig(companyId: number, featureConfigId: number, options?: AxiosRequestConfig) {
        return ConfigApiFp(this.configuration).deleteFeatureConfig(companyId, featureConfigId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get feature config for company
     * @param {number} companyId Company Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public getFeatureConfigByCompanyId(companyId: number, options?: AxiosRequestConfig) {
        return ConfigApiFp(this.configuration).getFeatureConfigByCompanyId(companyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ErrorApi - axios parameter creator
 * @export
 */
export const ErrorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Log an issue
         * @param {ErrorLogPayload} errorLogPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logError: async (errorLogPayload: ErrorLogPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'errorLogPayload' is not null or undefined
            assertParamExists('logError', 'errorLogPayload', errorLogPayload)
            const localVarPath = `/errors/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(errorLogPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ErrorApi - functional programming interface
 * @export
 */
export const ErrorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ErrorApiAxiosParamCreator(configuration)
    return {
        /**
         * Log an issue
         * @param {ErrorLogPayload} errorLogPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logError(errorLogPayload: ErrorLogPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logError(errorLogPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ErrorApi - factory interface
 * @export
 */
export const ErrorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ErrorApiFp(configuration)
    return {
        /**
         * Log an issue
         * @param {ErrorLogPayload} errorLogPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logError(errorLogPayload: ErrorLogPayload, options?: any): AxiosPromise<void> {
            return localVarFp.logError(errorLogPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ErrorApi - interface
 * @export
 * @interface ErrorApi
 */
export interface ErrorApiInterface {
    /**
     * Log an issue
     * @param {ErrorLogPayload} errorLogPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorApiInterface
     */
    logError(errorLogPayload: ErrorLogPayload, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ErrorApi - object-oriented interface
 * @export
 * @class ErrorApi
 * @extends {BaseAPI}
 */
export class ErrorApi extends BaseAPI implements ErrorApiInterface {
    /**
     * Log an issue
     * @param {ErrorLogPayload} errorLogPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorApi
     */
    public logError(errorLogPayload: ErrorLogPayload, options?: AxiosRequestConfig) {
        return ErrorApiFp(this.configuration).logError(errorLogPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get list of countries, used when user is saving a new company or portfolio
         * @summary Get list of countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/countries/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationApiAxiosParamCreator(configuration)
    return {
        /**
         * Get list of countries, used when user is saving a new company or portfolio
         * @summary Get list of countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ICountry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationApiFp(configuration)
    return {
        /**
         * Get list of countries, used when user is saving a new company or portfolio
         * @summary Get list of countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesGet(options?: any): AxiosPromise<Array<ICountry>> {
            return localVarFp.countriesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationApi - interface
 * @export
 * @interface LocationApi
 */
export interface LocationApiInterface {
    /**
     * Get list of countries, used when user is saving a new company or portfolio
     * @summary Get list of countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApiInterface
     */
    countriesGet(options?: AxiosRequestConfig): AxiosPromise<Array<ICountry>>;

}

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI implements LocationApiInterface {
    /**
     * Get list of countries, used when user is saving a new company or portfolio
     * @summary Get list of countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public countriesGet(options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).countriesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PortfolioApi - axios parameter creator
 * @export
 */
export const PortfolioApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes a portfolio
         * @param {number} portfolioId Portfolio id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortfolio: async (portfolioId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('deletePortfolio', 'portfolioId', portfolioId)
            const localVarPath = `/portfolios/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get assets by portfolio ID
         * @param {number} portfolioId Portfolio id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssets: async (portfolioId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getAssets', 'portfolioId', portfolioId)
            const localVarPath = `/assets/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get portfolio file by Portfolio and Asset ID
         * @param {number} portfolioId Portfolio id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioFile: async (portfolioId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolioFile', 'portfolioId', portfolioId)
            const localVarPath = `/portfolio-file/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate report url for portfolio
         * @param {number} portfolioId Portfolio id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioReportURL: async (portfolioId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolioReportURL', 'portfolioId', portfolioId)
            const localVarPath = `/report/portfolios/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all portfolios
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolios: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portfolios/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Saves a new portfolio
         * @param {PortfolioPayload} portfolioPayload Portfolio object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPortfolio: async (portfolioPayload: PortfolioPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioPayload' is not null or undefined
            assertParamExists('postPortfolio', 'portfolioPayload', portfolioPayload)
            const localVarPath = `/portfolios/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portfolioPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates assets for a portfolio
         * @param {number} portfolioId Portfolio id
         * @param {Array<AssetPayload>} assetPayload Asset array
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAssets: async (portfolioId: number, assetPayload: Array<AssetPayload>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('putAssets', 'portfolioId', portfolioId)
            // verify required parameter 'assetPayload' is not null or undefined
            assertParamExists('putAssets', 'assetPayload', assetPayload)
            const localVarPath = `/assets/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a portfolio
         * @param {number} portfolioId Portfolio id
         * @param {PortfolioPayload} portfolioPayload Portfolio object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPortfolio: async (portfolioId: number, portfolioPayload: PortfolioPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('putPortfolio', 'portfolioId', portfolioId)
            // verify required parameter 'portfolioPayload' is not null or undefined
            assertParamExists('putPortfolio', 'portfolioPayload', portfolioPayload)
            const localVarPath = `/portfolios/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portfolioPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload Excel file
         * @param {number} portfolioId Portfolio id
         * @param {File} [excel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPortfolioExcel: async (portfolioId: number, excel?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('putPortfolioExcel', 'portfolioId', portfolioId)
            const localVarPath = `/portfolio-file/putExcel/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (excel !== undefined) { 
                localVarFormParams.append('excel', excel as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload portfolio file
         * @param {number} portfolioId Portfolio id
         * @param {IPortfolioFile} iPortfolioFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPortfolioFile: async (portfolioId: number, iPortfolioFile: IPortfolioFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('uploadPortfolioFile', 'portfolioId', portfolioId)
            // verify required parameter 'iPortfolioFile' is not null or undefined
            assertParamExists('uploadPortfolioFile', 'iPortfolioFile', iPortfolioFile)
            const localVarPath = `/portfolio-file/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iPortfolioFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validates a portfolio
         * @param {number} editFlag Edit Flag
         * @param {ValidatePortfolioRequest} validatePortfolioRequest Validate portfolio before saving it. Endpoint is not finished. should return report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePortfolio: async (editFlag: number, validatePortfolioRequest: ValidatePortfolioRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editFlag' is not null or undefined
            assertParamExists('validatePortfolio', 'editFlag', editFlag)
            // verify required parameter 'validatePortfolioRequest' is not null or undefined
            assertParamExists('validatePortfolio', 'validatePortfolioRequest', validatePortfolioRequest)
            const localVarPath = `/portfolios/validate/{editFlag}`
                .replace(`{${"editFlag"}}`, encodeURIComponent(String(editFlag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validatePortfolioRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioApi - functional programming interface
 * @export
 */
export const PortfolioApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfolioApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes a portfolio
         * @param {number} portfolioId Portfolio id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePortfolio(portfolioId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePortfolio(portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get assets by portfolio ID
         * @param {number} portfolioId Portfolio id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssets(portfolioId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IAsset>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssets(portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get portfolio file by Portfolio and Asset ID
         * @param {number} portfolioId Portfolio id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioFile(portfolioId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioFile(portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate report url for portfolio
         * @param {number} portfolioId Portfolio id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioReportURL(portfolioId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioReportURL(portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all portfolios
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolios(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IPortfolio>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolios(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Saves a new portfolio
         * @param {PortfolioPayload} portfolioPayload Portfolio object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPortfolio(portfolioPayload: PortfolioPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPortfolio(portfolioPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates assets for a portfolio
         * @param {number} portfolioId Portfolio id
         * @param {Array<AssetPayload>} assetPayload Asset array
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAssets(portfolioId: number, assetPayload: Array<AssetPayload>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAssets(portfolioId, assetPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a portfolio
         * @param {number} portfolioId Portfolio id
         * @param {PortfolioPayload} portfolioPayload Portfolio object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPortfolio(portfolioId: number, portfolioPayload: PortfolioPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPortfolio(portfolioId, portfolioPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload Excel file
         * @param {number} portfolioId Portfolio id
         * @param {File} [excel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPortfolioExcel(portfolioId: number, excel?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPortfolioExcel(portfolioId, excel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload portfolio file
         * @param {number} portfolioId Portfolio id
         * @param {IPortfolioFile} iPortfolioFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPortfolioFile(portfolioId: number, iPortfolioFile: IPortfolioFile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPortfolioFile(portfolioId, iPortfolioFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validates a portfolio
         * @param {number} editFlag Edit Flag
         * @param {ValidatePortfolioRequest} validatePortfolioRequest Validate portfolio before saving it. Endpoint is not finished. should return report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validatePortfolio(editFlag: number, validatePortfolioRequest: ValidatePortfolioRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validatePortfolio(editFlag, validatePortfolioRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortfolioApi - factory interface
 * @export
 */
export const PortfolioApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortfolioApiFp(configuration)
    return {
        /**
         * Deletes a portfolio
         * @param {number} portfolioId Portfolio id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortfolio(portfolioId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePortfolio(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get assets by portfolio ID
         * @param {number} portfolioId Portfolio id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssets(portfolioId: number, options?: any): AxiosPromise<Array<IAsset>> {
            return localVarFp.getAssets(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get portfolio file by Portfolio and Asset ID
         * @param {number} portfolioId Portfolio id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioFile(portfolioId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getPortfolioFile(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate report url for portfolio
         * @param {number} portfolioId Portfolio id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioReportURL(portfolioId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getPortfolioReportURL(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all portfolios
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolios(options?: any): AxiosPromise<Array<IPortfolio>> {
            return localVarFp.getPortfolios(options).then((request) => request(axios, basePath));
        },
        /**
         * Saves a new portfolio
         * @param {PortfolioPayload} portfolioPayload Portfolio object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPortfolio(portfolioPayload: PortfolioPayload, options?: any): AxiosPromise<string> {
            return localVarFp.postPortfolio(portfolioPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates assets for a portfolio
         * @param {number} portfolioId Portfolio id
         * @param {Array<AssetPayload>} assetPayload Asset array
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAssets(portfolioId: number, assetPayload: Array<AssetPayload>, options?: any): AxiosPromise<void> {
            return localVarFp.putAssets(portfolioId, assetPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a portfolio
         * @param {number} portfolioId Portfolio id
         * @param {PortfolioPayload} portfolioPayload Portfolio object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPortfolio(portfolioId: number, portfolioPayload: PortfolioPayload, options?: any): AxiosPromise<void> {
            return localVarFp.putPortfolio(portfolioId, portfolioPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload Excel file
         * @param {number} portfolioId Portfolio id
         * @param {File} [excel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPortfolioExcel(portfolioId: number, excel?: File, options?: any): AxiosPromise<void> {
            return localVarFp.putPortfolioExcel(portfolioId, excel, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload portfolio file
         * @param {number} portfolioId Portfolio id
         * @param {IPortfolioFile} iPortfolioFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPortfolioFile(portfolioId: number, iPortfolioFile: IPortfolioFile, options?: any): AxiosPromise<void> {
            return localVarFp.uploadPortfolioFile(portfolioId, iPortfolioFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Validates a portfolio
         * @param {number} editFlag Edit Flag
         * @param {ValidatePortfolioRequest} validatePortfolioRequest Validate portfolio before saving it. Endpoint is not finished. should return report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePortfolio(editFlag: number, validatePortfolioRequest: ValidatePortfolioRequest, options?: any): AxiosPromise<string> {
            return localVarFp.validatePortfolio(editFlag, validatePortfolioRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortfolioApi - interface
 * @export
 * @interface PortfolioApi
 */
export interface PortfolioApiInterface {
    /**
     * Deletes a portfolio
     * @param {number} portfolioId Portfolio id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApiInterface
     */
    deletePortfolio(portfolioId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get assets by portfolio ID
     * @param {number} portfolioId Portfolio id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApiInterface
     */
    getAssets(portfolioId: number, options?: AxiosRequestConfig): AxiosPromise<Array<IAsset>>;

    /**
     * Get portfolio file by Portfolio and Asset ID
     * @param {number} portfolioId Portfolio id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApiInterface
     */
    getPortfolioFile(portfolioId: number, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Generate report url for portfolio
     * @param {number} portfolioId Portfolio id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApiInterface
     */
    getPortfolioReportURL(portfolioId: number, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Get all portfolios
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApiInterface
     */
    getPortfolios(options?: AxiosRequestConfig): AxiosPromise<Array<IPortfolio>>;

    /**
     * Saves a new portfolio
     * @param {PortfolioPayload} portfolioPayload Portfolio object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApiInterface
     */
    postPortfolio(portfolioPayload: PortfolioPayload, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Updates assets for a portfolio
     * @param {number} portfolioId Portfolio id
     * @param {Array<AssetPayload>} assetPayload Asset array
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApiInterface
     */
    putAssets(portfolioId: number, assetPayload: Array<AssetPayload>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Updates a portfolio
     * @param {number} portfolioId Portfolio id
     * @param {PortfolioPayload} portfolioPayload Portfolio object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApiInterface
     */
    putPortfolio(portfolioId: number, portfolioPayload: PortfolioPayload, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Upload Excel file
     * @param {number} portfolioId Portfolio id
     * @param {File} [excel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApiInterface
     */
    putPortfolioExcel(portfolioId: number, excel?: File, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Upload portfolio file
     * @param {number} portfolioId Portfolio id
     * @param {IPortfolioFile} iPortfolioFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApiInterface
     */
    uploadPortfolioFile(portfolioId: number, iPortfolioFile: IPortfolioFile, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Validates a portfolio
     * @param {number} editFlag Edit Flag
     * @param {ValidatePortfolioRequest} validatePortfolioRequest Validate portfolio before saving it. Endpoint is not finished. should return report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApiInterface
     */
    validatePortfolio(editFlag: number, validatePortfolioRequest: ValidatePortfolioRequest, options?: AxiosRequestConfig): AxiosPromise<string>;

}

/**
 * PortfolioApi - object-oriented interface
 * @export
 * @class PortfolioApi
 * @extends {BaseAPI}
 */
export class PortfolioApi extends BaseAPI implements PortfolioApiInterface {
    /**
     * Deletes a portfolio
     * @param {number} portfolioId Portfolio id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public deletePortfolio(portfolioId: number, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).deletePortfolio(portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get assets by portfolio ID
     * @param {number} portfolioId Portfolio id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public getAssets(portfolioId: number, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).getAssets(portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get portfolio file by Portfolio and Asset ID
     * @param {number} portfolioId Portfolio id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public getPortfolioFile(portfolioId: number, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).getPortfolioFile(portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate report url for portfolio
     * @param {number} portfolioId Portfolio id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public getPortfolioReportURL(portfolioId: number, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).getPortfolioReportURL(portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all portfolios
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public getPortfolios(options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).getPortfolios(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Saves a new portfolio
     * @param {PortfolioPayload} portfolioPayload Portfolio object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public postPortfolio(portfolioPayload: PortfolioPayload, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).postPortfolio(portfolioPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates assets for a portfolio
     * @param {number} portfolioId Portfolio id
     * @param {Array<AssetPayload>} assetPayload Asset array
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public putAssets(portfolioId: number, assetPayload: Array<AssetPayload>, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).putAssets(portfolioId, assetPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a portfolio
     * @param {number} portfolioId Portfolio id
     * @param {PortfolioPayload} portfolioPayload Portfolio object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public putPortfolio(portfolioId: number, portfolioPayload: PortfolioPayload, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).putPortfolio(portfolioId, portfolioPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload Excel file
     * @param {number} portfolioId Portfolio id
     * @param {File} [excel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public putPortfolioExcel(portfolioId: number, excel?: File, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).putPortfolioExcel(portfolioId, excel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload portfolio file
     * @param {number} portfolioId Portfolio id
     * @param {IPortfolioFile} iPortfolioFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public uploadPortfolioFile(portfolioId: number, iPortfolioFile: IPortfolioFile, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).uploadPortfolioFile(portfolioId, iPortfolioFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validates a portfolio
     * @param {number} editFlag Edit Flag
     * @param {ValidatePortfolioRequest} validatePortfolioRequest Validate portfolio before saving it. Endpoint is not finished. should return report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public validatePortfolio(editFlag: number, validatePortfolioRequest: ValidatePortfolioRequest, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).validatePortfolio(editFlag, validatePortfolioRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScenariosApi - axios parameter creator
 * @export
 */
export const ScenariosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add new or update scenarios
         * @param {number} assetId Asset id
         * @param {Array<AddAssetScenariosRequestInner>} addAssetScenariosRequestInner Interventions array
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAssetScenarios: async (assetId: number, addAssetScenariosRequestInner: Array<AddAssetScenariosRequestInner>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('addAssetScenarios', 'assetId', assetId)
            // verify required parameter 'addAssetScenariosRequestInner' is not null or undefined
            assertParamExists('addAssetScenarios', 'addAssetScenariosRequestInner', addAssetScenariosRequestInner)
            const localVarPath = `/scenarios/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addAssetScenariosRequestInner, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add new or update pathway
         * @param {number} assetId Asset id
         * @param {ICustomPathway} iCustomPathway Pathway
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCustomPathway: async (assetId: number, iCustomPathway: ICustomPathway, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('addCustomPathway', 'assetId', assetId)
            // verify required parameter 'iCustomPathway' is not null or undefined
            assertParamExists('addCustomPathway', 'iCustomPathway', iCustomPathway)
            const localVarPath = `/customPathways/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iCustomPathway, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete pathway
         * @param {number} assetId asset id
         * @param {number} customPathwayId custom pathway id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomPathway: async (assetId: number, customPathwayId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('deleteCustomPathway', 'assetId', assetId)
            // verify required parameter 'customPathwayId' is not null or undefined
            assertParamExists('deleteCustomPathway', 'customPathwayId', customPathwayId)
            const localVarPath = `/customPathways/{assetId}/{customPathwayId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)))
                .replace(`{${"customPathwayId"}}`, encodeURIComponent(String(customPathwayId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get scenario file by Portfolio and Asset ID
         * @param {number} portfolioId Portfolio id
         * @param {number} assetId Asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioFile: async (portfolioId: number, assetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getScenarioFile', 'portfolioId', portfolioId)
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getScenarioFile', 'assetId', assetId)
            const localVarPath = `/scenario-file/{portfolioId}/{assetId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get scenarios by asset id
         * @param {number} assetId Asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenariosByAssetId: async (assetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getScenariosByAssetId', 'assetId', assetId)
            const localVarPath = `/scenarios/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload Excel file
         * @param {string} assetId Asset ID
         * @param {File} [excel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putExcel: async (assetId: string, excel?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('putExcel', 'assetId', assetId)
            const localVarPath = `/scenario-file/putExcel/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (excel !== undefined) { 
                localVarFormParams.append('excel', excel as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload scenario file
         * @param {number} portfolioId Portfolio id
         * @param {number} assetId Asset id
         * @param {IScenarioFile} iScenarioFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadScenarioFile: async (portfolioId: number, assetId: number, iScenarioFile: IScenarioFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('uploadScenarioFile', 'portfolioId', portfolioId)
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('uploadScenarioFile', 'assetId', assetId)
            // verify required parameter 'iScenarioFile' is not null or undefined
            assertParamExists('uploadScenarioFile', 'iScenarioFile', iScenarioFile)
            const localVarPath = `/scenario-file/{portfolioId}/{assetId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iScenarioFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScenariosApi - functional programming interface
 * @export
 */
export const ScenariosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScenariosApiAxiosParamCreator(configuration)
    return {
        /**
         * Add new or update scenarios
         * @param {number} assetId Asset id
         * @param {Array<AddAssetScenariosRequestInner>} addAssetScenariosRequestInner Interventions array
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAssetScenarios(assetId: number, addAssetScenariosRequestInner: Array<AddAssetScenariosRequestInner>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAssetScenarios(assetId, addAssetScenariosRequestInner, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add new or update pathway
         * @param {number} assetId Asset id
         * @param {ICustomPathway} iCustomPathway Pathway
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCustomPathway(assetId: number, iCustomPathway: ICustomPathway, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddCustomPathway200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCustomPathway(assetId, iCustomPathway, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete pathway
         * @param {number} assetId asset id
         * @param {number} customPathwayId custom pathway id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomPathway(assetId: number, customPathwayId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomPathway(assetId, customPathwayId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get scenario file by Portfolio and Asset ID
         * @param {number} portfolioId Portfolio id
         * @param {number} assetId Asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenarioFile(portfolioId: number, assetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenarioFile(portfolioId, assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get scenarios by asset id
         * @param {number} assetId Asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenariosByAssetId(assetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IScenario>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenariosByAssetId(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload Excel file
         * @param {string} assetId Asset ID
         * @param {File} [excel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putExcel(assetId: string, excel?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putExcel(assetId, excel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload scenario file
         * @param {number} portfolioId Portfolio id
         * @param {number} assetId Asset id
         * @param {IScenarioFile} iScenarioFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadScenarioFile(portfolioId: number, assetId: number, iScenarioFile: IScenarioFile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadScenarioFile(portfolioId, assetId, iScenarioFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScenariosApi - factory interface
 * @export
 */
export const ScenariosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScenariosApiFp(configuration)
    return {
        /**
         * Add new or update scenarios
         * @param {number} assetId Asset id
         * @param {Array<AddAssetScenariosRequestInner>} addAssetScenariosRequestInner Interventions array
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAssetScenarios(assetId: number, addAssetScenariosRequestInner: Array<AddAssetScenariosRequestInner>, options?: any): AxiosPromise<void> {
            return localVarFp.addAssetScenarios(assetId, addAssetScenariosRequestInner, options).then((request) => request(axios, basePath));
        },
        /**
         * Add new or update pathway
         * @param {number} assetId Asset id
         * @param {ICustomPathway} iCustomPathway Pathway
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCustomPathway(assetId: number, iCustomPathway: ICustomPathway, options?: any): AxiosPromise<AddCustomPathway200Response> {
            return localVarFp.addCustomPathway(assetId, iCustomPathway, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete pathway
         * @param {number} assetId asset id
         * @param {number} customPathwayId custom pathway id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomPathway(assetId: number, customPathwayId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomPathway(assetId, customPathwayId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get scenario file by Portfolio and Asset ID
         * @param {number} portfolioId Portfolio id
         * @param {number} assetId Asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioFile(portfolioId: number, assetId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getScenarioFile(portfolioId, assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get scenarios by asset id
         * @param {number} assetId Asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenariosByAssetId(assetId: number, options?: any): AxiosPromise<Array<IScenario>> {
            return localVarFp.getScenariosByAssetId(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload Excel file
         * @param {string} assetId Asset ID
         * @param {File} [excel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putExcel(assetId: string, excel?: File, options?: any): AxiosPromise<void> {
            return localVarFp.putExcel(assetId, excel, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload scenario file
         * @param {number} portfolioId Portfolio id
         * @param {number} assetId Asset id
         * @param {IScenarioFile} iScenarioFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadScenarioFile(portfolioId: number, assetId: number, iScenarioFile: IScenarioFile, options?: any): AxiosPromise<void> {
            return localVarFp.uploadScenarioFile(portfolioId, assetId, iScenarioFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScenariosApi - interface
 * @export
 * @interface ScenariosApi
 */
export interface ScenariosApiInterface {
    /**
     * Add new or update scenarios
     * @param {number} assetId Asset id
     * @param {Array<AddAssetScenariosRequestInner>} addAssetScenariosRequestInner Interventions array
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApiInterface
     */
    addAssetScenarios(assetId: number, addAssetScenariosRequestInner: Array<AddAssetScenariosRequestInner>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Add new or update pathway
     * @param {number} assetId Asset id
     * @param {ICustomPathway} iCustomPathway Pathway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApiInterface
     */
    addCustomPathway(assetId: number, iCustomPathway: ICustomPathway, options?: AxiosRequestConfig): AxiosPromise<AddCustomPathway200Response>;

    /**
     * Delete pathway
     * @param {number} assetId asset id
     * @param {number} customPathwayId custom pathway id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApiInterface
     */
    deleteCustomPathway(assetId: number, customPathwayId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get scenario file by Portfolio and Asset ID
     * @param {number} portfolioId Portfolio id
     * @param {number} assetId Asset id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApiInterface
     */
    getScenarioFile(portfolioId: number, assetId: number, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Get scenarios by asset id
     * @param {number} assetId Asset id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApiInterface
     */
    getScenariosByAssetId(assetId: number, options?: AxiosRequestConfig): AxiosPromise<Array<IScenario>>;

    /**
     * Upload Excel file
     * @param {string} assetId Asset ID
     * @param {File} [excel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApiInterface
     */
    putExcel(assetId: string, excel?: File, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Upload scenario file
     * @param {number} portfolioId Portfolio id
     * @param {number} assetId Asset id
     * @param {IScenarioFile} iScenarioFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApiInterface
     */
    uploadScenarioFile(portfolioId: number, assetId: number, iScenarioFile: IScenarioFile, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ScenariosApi - object-oriented interface
 * @export
 * @class ScenariosApi
 * @extends {BaseAPI}
 */
export class ScenariosApi extends BaseAPI implements ScenariosApiInterface {
    /**
     * Add new or update scenarios
     * @param {number} assetId Asset id
     * @param {Array<AddAssetScenariosRequestInner>} addAssetScenariosRequestInner Interventions array
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public addAssetScenarios(assetId: number, addAssetScenariosRequestInner: Array<AddAssetScenariosRequestInner>, options?: AxiosRequestConfig) {
        return ScenariosApiFp(this.configuration).addAssetScenarios(assetId, addAssetScenariosRequestInner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add new or update pathway
     * @param {number} assetId Asset id
     * @param {ICustomPathway} iCustomPathway Pathway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public addCustomPathway(assetId: number, iCustomPathway: ICustomPathway, options?: AxiosRequestConfig) {
        return ScenariosApiFp(this.configuration).addCustomPathway(assetId, iCustomPathway, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete pathway
     * @param {number} assetId asset id
     * @param {number} customPathwayId custom pathway id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public deleteCustomPathway(assetId: number, customPathwayId: number, options?: AxiosRequestConfig) {
        return ScenariosApiFp(this.configuration).deleteCustomPathway(assetId, customPathwayId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get scenario file by Portfolio and Asset ID
     * @param {number} portfolioId Portfolio id
     * @param {number} assetId Asset id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public getScenarioFile(portfolioId: number, assetId: number, options?: AxiosRequestConfig) {
        return ScenariosApiFp(this.configuration).getScenarioFile(portfolioId, assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get scenarios by asset id
     * @param {number} assetId Asset id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public getScenariosByAssetId(assetId: number, options?: AxiosRequestConfig) {
        return ScenariosApiFp(this.configuration).getScenariosByAssetId(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload Excel file
     * @param {string} assetId Asset ID
     * @param {File} [excel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public putExcel(assetId: string, excel?: File, options?: AxiosRequestConfig) {
        return ScenariosApiFp(this.configuration).putExcel(assetId, excel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload scenario file
     * @param {number} portfolioId Portfolio id
     * @param {number} assetId Asset id
     * @param {IScenarioFile} iScenarioFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public uploadScenarioFile(portfolioId: number, assetId: number, iScenarioFile: IScenarioFile, options?: AxiosRequestConfig) {
        return ScenariosApiFp(this.configuration).uploadScenarioFile(portfolioId, assetId, iScenarioFile, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VersionApi - axios parameter creator
 * @export
 */
export const VersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get database version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatabaseVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionApi - functional programming interface
 * @export
 */
export const VersionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VersionApiAxiosParamCreator(configuration)
    return {
        /**
         * Get database version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatabaseVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatabaseVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VersionApi - factory interface
 * @export
 */
export const VersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VersionApiFp(configuration)
    return {
        /**
         * Get database version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatabaseVersion(options?: any): AxiosPromise<string> {
            return localVarFp.getDatabaseVersion(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VersionApi - interface
 * @export
 * @interface VersionApi
 */
export interface VersionApiInterface {
    /**
     * Get database version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApiInterface
     */
    getDatabaseVersion(options?: AxiosRequestConfig): AxiosPromise<string>;

}

/**
 * VersionApi - object-oriented interface
 * @export
 * @class VersionApi
 * @extends {BaseAPI}
 */
export class VersionApi extends BaseAPI implements VersionApiInterface {
    /**
     * Get database version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public getDatabaseVersion(options?: AxiosRequestConfig) {
        return VersionApiFp(this.configuration).getDatabaseVersion(options).then((request) => request(this.axios, this.basePath));
    }
}



import { createAsyncThunk } from "@reduxjs/toolkit";
import { ConfigApi, IFeatureConfig } from "src/api/open-api";
import { defaultApiConfiguration } from "src/api/configurations";
import { USE_LOCAL_MOCKS } from "src/config/mocks";
import { configMock } from "./__mocks__/configMock";

export const fetchConfigByCompanyId = createAsyncThunk(
  "featuresConfig/fetch",
  async (companyId: number, { rejectWithValue }) => {
    if (USE_LOCAL_MOCKS) return configMock;
    const api = new ConfigApi(defaultApiConfiguration);
    try {
      const response = await api.getFeatureConfigByCompanyId(companyId);
      return response.data;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

export const deleteFeatureConfig = createAsyncThunk(
  "featuresConfig/delete",
  async (
    {
      companyId,
      featureConfigId,
      featureConfigKey,
    }: { companyId: number; featureConfigId: number; featureConfigKey: string },
    { rejectWithValue },
  ) => {
    if (USE_LOCAL_MOCKS) return featureConfigId;
    const api = new ConfigApi(defaultApiConfiguration);
    try {
      await api.deleteFeatureConfig(companyId, featureConfigId);
      return featureConfigKey;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

export const addFeatureConfigByCompanyId = createAsyncThunk(
  "featuresConfig/add",
  async (
    {
      companyId,
      featureConfig,
    }: { companyId: number; featureConfig: IFeatureConfig },
    { rejectWithValue },
  ) => {
    const api = new ConfigApi(defaultApiConfiguration);
    try {
      const response = await api.addFeatureConfigByCompanyId(
        companyId,
        featureConfig,
      );
      return {
        ...featureConfig,
        feature_config_id: Number(response.data.feature_config_id),
      };
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

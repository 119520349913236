import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "holdingYears",
  initialState: {
    selectedHoldYear: 10,
  },
  reducers: {
    setSelectedHoldYear: (state, action: PayloadAction<number>) => {
      state.selectedHoldYear = action.payload;
    },
  },
});

export const { setSelectedHoldYear } = slice.actions;
export default slice;

import React from "react";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Alert } from "src/common/components/atoms/Alert";
import { useTranslation } from "react-i18next";
import { genErrorMessage } from "src/common/utils/errors";
import { Ii18nError } from "src/redux/interfaces/Ii18nError";

interface IProps {
  isOpen: boolean;
  title: string;
  objectDescription: string;
  onClose: () => void;
  onDelete: () => void;
  error?: Ii18nError | null;
  isLoading: boolean;
}

export const DeleteModal: React.FC<IProps> = ({
  isOpen,
  isLoading,
  onClose,
  title,
  objectDescription,
  onDelete,
  error,
}) => {
  const { t } = useTranslation();

  const errorMessage = error
    ? genErrorMessage(error, t, "Cannot delete. ")
    : null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      closeOnOverlayClick={!isOpen}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="normal" fontFamily="heading" color="red.500">
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          pt="30px"
          pb="40px"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Text mb="10px">
            Are you sure you want to delete <b>{objectDescription}</b>?
          </Text>
          <Text pb="40px">All data will be permanently lost.</Text>
          {errorMessage ? (
            <Box mb="1rem">
              <Alert message={errorMessage} isError />
            </Box>
          ) : null}
          <Button
            colorScheme="red"
            variant="solidPrimary"
            onClick={onDelete}
            isDisabled={isLoading}
          >
            Delete
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

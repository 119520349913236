import React from "react";
import { Table, Text, Tbody, Thead, Tfoot, Th, Tr, Td } from "@chakra-ui/react";
import { currencyCodeToSymbol } from "src/common/utils/units";
import { MeasureSystem } from "src/redux/interfaces/IAppPortfolio";
import { roundMonetary, sum } from "src/common/utils/math";
import { Units, getUnits } from "src/constants/measureUnits";
import { IAppIntervention } from "src/redux/interfaces/IAppIntervention";
import { formatMonetaryValue } from "../../utils/getTableDisplayValue";

type IProps = {
  interventions: IAppIntervention[];
  assetArea: number;
  isoCurrencyCode?: string;
  measureSystem?: MeasureSystem;
};

export const CapexTable: React.FC<IProps> = ({
  interventions,
  assetArea,
  isoCurrencyCode,
  measureSystem,
}) => {
  if (!interventions?.length) return <></>;
  const roundedCapexValues = interventions.map((intervention) => ({
    totalAbs: roundMonetary(intervention.capexAbsoluteTotal || 0),
    totalRel: (intervention.capexAbsoluteTotal || 0) / assetArea,
    normBau: (intervention.capexAbsoluteEol || 0) / assetArea,
    normAdd: (intervention.capexAbsoluteDecarb || 0) / assetArea,
    interventionCode: intervention.interventionCode,
    interventionDescription: intervention.interventionDescription,
  }));
  const capexTotal = sum(roundedCapexValues.map((i) => i.totalAbs));
  const capexTotalNormalised = sum(roundedCapexValues.map((i) => i.totalRel));
  const capexBauNormalised = sum(roundedCapexValues.map((i) => i.normBau));
  const capexAdditionalNormalised = sum(
    roundedCapexValues.map((i) => i.normAdd),
  );
  const normalizedUnits = `${currencyCodeToSymbol(isoCurrencyCode)}/${getUnits(
    Units.area,
    measureSystem,
  )}`;

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th lineHeight="1.2">Measure Description</Th>
          <Th lineHeight="1.2" textAlign="right">
            CAPEX Total{" "}
            <Text fontSize="0.7em">
              {currencyCodeToSymbol(isoCurrencyCode)}
            </Text>
          </Th>
          <Th lineHeight="1.2" textAlign="right">
            CAPEX Total
            <Text fontSize="0.7em">Normalised, {normalizedUnits}</Text>
          </Th>
          <Th lineHeight="1.2" textAlign="right">
            CAPEX BAU
            <Text fontSize="0.7em">Normalised, {normalizedUnits}</Text>
          </Th>
          <Th lineHeight="1.2" textAlign="right">
            CAPEX Additional
            <Text fontSize="0.7em">Normalised, {normalizedUnits}</Text>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {roundedCapexValues.map((intervention) => (
          <Tr key={intervention.interventionCode}>
            <Td>
              {intervention.interventionCode}:{" "}
              {intervention.interventionDescription}
            </Td>
            <Td isNumeric>{formatMonetaryValue(intervention.totalAbs)}</Td>
            <Td isNumeric>
              {formatMonetaryValue(intervention.totalRel, 2, 2)}
            </Td>
            <Td isNumeric>{formatMonetaryValue(intervention.normBau, 2, 2)}</Td>
            <Td isNumeric>{formatMonetaryValue(intervention.normAdd, 2, 2)}</Td>
          </Tr>
        ))}
      </Tbody>
      <Tfoot>
        <Tr>
          <Th color="black">Total</Th>
          <Th color="black" isNumeric>
            {formatMonetaryValue(capexTotal)}
          </Th>
          <Th color="black" isNumeric>
            {formatMonetaryValue(capexTotalNormalised, 2, 2)}
          </Th>
          <Th color="black" isNumeric>
            {formatMonetaryValue(capexBauNormalised, 2, 2)}
          </Th>
          <Th color="black" isNumeric>
            {formatMonetaryValue(capexAdditionalNormalised, 2, 2)}
          </Th>
        </Tr>
      </Tfoot>
    </Table>
  );
};

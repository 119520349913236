import { TabList, Tab, TabIndicator } from "@chakra-ui/react";
import React from "react";
import { useWindowSize } from "src/common/utils/useWindowResize";

type IProps = {
  tabTitles: string[];
  customWidth?: string[];
  ml?: string;
};

export const TabsHeader: React.FC<IProps> = ({
  tabTitles,
  customWidth,
  ml,
}) => {
  const defaultWidth = Math.floor(100 / tabTitles.length) - 1 + "%";
  // hack to make TabIndicator aware of window resizing
  const [width] = useWindowSize();
  return (
    <>
      <TabList data-testid="TabsHeader" ml={ml || "0px"} key={width + "px"}>
        {tabTitles.map((title, i) => (
          <Tab
            data-testid="Tabs"
            key={title}
            _selected={{ color: "red.500" }}
            fontSize="xs"
            width={
              customWidth && customWidth[i] ? customWidth[i] : defaultWidth
            }
          >
            {title}
          </Tab>
        ))}
      </TabList>
      <TabIndicator
        mt="-1.5px"
        height="2px"
        bg="red.500"
        borderRadius="1px"
        key={window?.innerWidth}
      />
    </>
  );
};

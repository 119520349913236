import { createSlice } from "@reduxjs/toolkit";
import { fetchCompanyUsers } from "../../../thunks/admin";
import { companyUsersAdapter } from "./adapter";
import {
  Ii18nError,
  apiErrorToI18nError,
} from "src/redux/interfaces/Ii18nError";

const slice = createSlice({
  name: "companyUsers",
  initialState: {
    ...companyUsersAdapter.getInitialState(),
    requestStatus: "initial",
    isLoading: false,
    isError: false,
    error: null as null | Ii18nError,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyUsers.pending, (state) => {
      companyUsersAdapter.removeAll(state);
      state.requestStatus = "pending";
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchCompanyUsers.rejected, (state, action) => {
      state.requestStatus = "rejected";
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload
        ? apiErrorToI18nError(action.payload)
        : { errorCode: "GENERIC" };
    });
    builder.addCase(fetchCompanyUsers.fulfilled, (state, { payload }) => {
      companyUsersAdapter.setMany(state, payload);
      state.requestStatus = "fulfilled";
      state.isLoading = false;
      state.error = null;
    });
  },
});

export default slice;

import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { Performance } from "src/common/components/molecules/PerformanceTable/references";
import { getCapexReferences } from "../PerformanceTable/getReference";
import { sum } from "src/common/utils/math";

export const getDecarbCostBreakpointsForAsset = (asset?: IAppAsset) => {
  if (!asset)
    return {
      [Performance.Low]: 0,
      [Performance.Medium]: 0,
      [Performance.High]: 0,
    };
  return getCapexReferences({
    buildingType: asset.primaryBusinessBuildingType,
    locationName: asset.location.businessLocationName,
  });
};

export const getDecarbCostBreakpointsForAssets = (assets: IAppAsset[]) => {
  const totalArea = assets.map((a) => a.area || 0).reduce((a, b) => a + b, 0);
  const refs = assets.map((a) => {
    const assetCapex = getCapexReferences({
      buildingType: a.primaryBusinessBuildingType,
      locationName: a.location.businessLocationName,
    });
    return {
      [Performance.Low]: assetCapex[Performance.Low] * a.area,
      [Performance.Medium]: assetCapex[Performance.Medium] * a.area,
      [Performance.High]: assetCapex[Performance.High] * a.area,
    };
  });

  const averageAbsoluteBreakpoints = {
    [Performance.Low]: sum(refs.map((r) => (r && r[Performance.Low]) || 0)),
    [Performance.Medium]: sum(
      refs.map((r) => (r && r[Performance.Medium]) || 0),
    ),
    [Performance.High]: sum(refs.map((r) => (r && r[Performance.High]) || 0)),
  };

  return {
    [Performance.Low]: averageAbsoluteBreakpoints[Performance.Low] / totalArea,
    [Performance.Medium]:
      averageAbsoluteBreakpoints[Performance.Medium] / totalArea,
    [Performance.High]:
      averageAbsoluteBreakpoints[Performance.High] / totalArea,
  };
};

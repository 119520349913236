import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import {
  averageEolDecarbCost,
  averageTotalDecarbCost,
} from "src/common/utils/costing/costing";
import { EconomicHighlight } from "src/common/components/atoms/EconomicHighlight";
import { closest50 } from "src/common/utils/math";

interface IProps {
  assets: IAppAsset[];
  units: string;
  areaUnits: string;
}
export const CapexPerformance: React.FC<IProps> = ({
  assets,
  units,
  areaUnits,
}) => {
  const eolCapex = closest50(averageEolDecarbCost(assets));
  const decarbCapex = closest50(averageTotalDecarbCost(assets));

  return (
    <Box minWidth="100px" mb="2rem">
      <Flex direction="row" justifyContent="space-evenly">
        <EconomicHighlight
          title="Total CAPEX"
          description={`Total investment costs required for a "decarbonisation" scenario, accumulated up to 2050 [per ${areaUnits} GIA IPMS2] (costs related to energy conservation measures exclusively)`}
          color="yellow.400"
          value={eolCapex + decarbCapex}
          units={units}
        />
        <EconomicHighlight
          title="Business as Usual CAPEX"
          description={`Total investment cost related to business-as-usual necessary replacements accumulated up to 2050 (cost that would have been incurred anyway) [per ${areaUnits} GIA IPMS2]`}
          value={eolCapex}
          units={units}
        />
        <EconomicHighlight
          title="Decarb. CAPEX"
          description={`Total additional investment cost required to achieve GHG targets accumulated up to 2050 ("true" cost of decarbonisation). [per ${areaUnits} GIA IPMS2]`}
          value={decarbCapex}
          units={units}
        />
      </Flex>
    </Box>
  );
};

import { EntityState, createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { scenariosAdapter } from "./adapter";
import { ITimeSeriesPoint } from "src/api/open-api";
import { hardcodedScenarioChartTypes } from "src/constants/intensityTypes";
import { IReduxChartTarget } from "src/redux/interfaces/IReduxChartTarget";
import { theme } from "src/config/theme";
import {
  selectSlice as selectArchetypeSlice,
  findArchetypeForSelectedAsset,
} from "../archetypes/selectors";
import { selectSlice as selectAssetSlice } from "../assets/selectors";
import { IAppScenario } from "src/redux/interfaces/IAppScenario";
import { IAppArchetype } from "src/redux/interfaces/IAppArchetype";
import { assetAdapter } from "../assets/adapter";

const selectSlice = (state: RootState) => state.scenarios;

export const selectScenarios = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<IAppScenario>) =>
    scenariosAdapter.getSelectors().selectAll(state),
);
export const selectScenariosError = createDraftSafeSelector(
  [selectSlice],
  (state) => state.error,
);

const _selectAllScenarios = (
  scenariosSlice: EntityState<IAppScenario>,
  archetypeSlice: EntityState<IAppArchetype>,
  selectedAssetId?: number | null,
) => {
  if (selectedAssetId === null || selectedAssetId === undefined) {
    return [];
  }
  const allScenarios = scenariosAdapter
    .getSelectors()
    .selectAll(scenariosSlice);
  const archetypeForSelectedAsset = findArchetypeForSelectedAsset(
    archetypeSlice,
    selectedAssetId,
  );
  const result = [...allScenarios];
  if (archetypeForSelectedAsset) result.push(archetypeForSelectedAsset);

  return result;
};

export const selectIsScenariosDataLoading = createDraftSafeSelector(
  [selectSlice],
  (slice) => {
    return (
      slice.requestStatus === "initial" || slice.requestStatus === "pending"
    );
  },
);

export const selectGroupedByPackageScenarios = createDraftSafeSelector(
  [selectSlice, selectArchetypeSlice, selectAssetSlice],
  (state: EntityState<IAppScenario>, archetypeState, assetState) =>
    _selectAllScenarios(state, archetypeState, assetState.selectedAssetId),
);

export const selectSelectedScenarioNames = createDraftSafeSelector(
  [selectSlice],
  (state): string[] => state.selectedScenarios,
);

export const selectSelectedScenarios = createDraftSafeSelector(
  [selectSlice, selectArchetypeSlice, selectAssetSlice],
  (state, archetypeState, assetState) =>
    _selectAllScenarios(
      state,
      archetypeState,
      assetState.selectedAssetId,
    ).filter((scenario) =>
      state.selectedScenarios.includes(scenario.scenario_name),
    ),
);

export type ICostScenarioResults = {
  capexEolTotal: number;
  capexDecarbTotal: number;
  capexCostTotal: number;
  capexEolLine?: ITimeSeriesPoint[];
  capexDecarbLine?: ITimeSeriesPoint[];
  opexLine?: ITimeSeriesPoint[];
  opexTotal: number;
  scenarioName: string;
};

export const selectCostForSelectedScenarios = createDraftSafeSelector(
  [selectSlice, selectArchetypeSlice, selectAssetSlice],
  (state, archetypeState, assetState): ICostScenarioResults[] => {
    const selectedAsset = assetState.selectedAssetId
      ? assetAdapter
          .getSelectors()
          .selectById(assetState, assetState.selectedAssetId)
      : null;
    return _selectAllScenarios(
      state,
      archetypeState,
      assetState.selectedAssetId,
    )
      .filter((scenario) =>
        state.selectedScenarios.includes(scenario.scenario_name),
      )
      .map((scenario) => {
        const multiplier =
          scenario.scenario_name === "Archetype" ? selectedAsset?.area || 1 : 1;
        return {
          capexEolTotal: scenario.cost.capex_eol_total,
          capexDecarbTotal: scenario.cost.capex_decarb_total,
          capexCostTotal: scenario.cost.capex_cost_total,
          capexEolLine: scenario.pathways
            .find(
              (l) =>
                l.scenario_chart_type_id ===
                hardcodedScenarioChartTypes.capexEol,
            )
            ?.time_series_points?.map((v) => ({
              ...v,
              value: v.value * multiplier,
            })),
          capexDecarbLine: scenario.pathways
            .find(
              (l) =>
                l.scenario_chart_type_id ===
                hardcodedScenarioChartTypes.capexDecarb,
            )
            ?.time_series_points?.map((v) => ({
              ...v,
              value: v.value * multiplier,
            })),
          opexLine: scenario.pathways.find(
            (l) =>
              l.scenario_chart_type_id === hardcodedScenarioChartTypes.opex,
          )?.time_series_points,
          opexTotal: scenario.cost.opex_total,
          scenarioName: scenario.scenario_name,
        };
      });
  },
);

const colors = ["purple", "orange", "blue", "green"];
const _getSpecificIntensityTypeForSelectedScenarios = (
  state: EntityState<IAppScenario> & { selectedScenarios: string[] },
  archetypeState: EntityState<IAppArchetype>,
  intensityTypeId: number,
  assetId?: number | null,
) => {
  const allScenarios = _selectAllScenarios(state, archetypeState, assetId).map(
    (s, index) => ({
      ...s,
      lineColor: theme.colors[colors[index % 4]]["400"],
    }),
  );

  return allScenarios
    .filter((scenario) =>
      state.selectedScenarios.includes(scenario.scenario_name),
    )
    .map((scenario) => ({
      modelVersionId: 0, // Never used anyways
      lineColor: scenario.lineColor,
      targetName: scenario.scenario_name,
      timeSeries:
        scenario.pathways.find(
          (p) => p.scenario_chart_type_id === intensityTypeId,
        )?.time_series_points || [],
      strandingX: 2050, // Never used
    }));
};

export const selectGHGForSelectedScenarios = createDraftSafeSelector(
  [selectSlice, selectArchetypeSlice, selectAssetSlice],
  (state, archetypeState, assetState): IReduxChartTarget[] => {
    return _getSpecificIntensityTypeForSelectedScenarios(
      state,
      archetypeState,
      hardcodedScenarioChartTypes.ghg,
      assetState.selectedAssetId,
    );
  },
);

export const selectEnergyForSelectedScenarios = createDraftSafeSelector(
  [selectSlice, selectArchetypeSlice, selectAssetSlice],
  (state, archetypeState, assetState): IReduxChartTarget[] => {
    return _getSpecificIntensityTypeForSelectedScenarios(
      state,
      archetypeState,
      hardcodedScenarioChartTypes.energy,
      assetState.selectedAssetId,
    );
  },
);

//function to append a files last modified date to its file name
export const formatExcelFile = (file: File) => {
  const existingLMT = file.name.includes("_LMT_");

  //get original last modified date reference as new file below will have different mod date on creation
  const fileLastMod = file.lastModified;

  //using _LMT_ prefix to last modified date to pull reference
  //the below will either overwrite an existing _LMT_ date or inject a new
  const fileNameWrite = existingLMT
    ? `${file.name.substring(
        0,
        file.name.indexOf("_LMT_"),
      )}_LMT_${fileLastMod}.xlsx`
    : `${file.name.substring(0, file.name.length - 5)}_LMT_${fileLastMod}.xlsx`;

  return {
    file: new File([file], fileNameWrite, {
      type: file.type,
    }),
    lastMod: fileLastMod,
  };
};

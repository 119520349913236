import { IPortfolio } from "src/api/open-api";

export const mockedApiPortfolios: IPortfolio[] = [
  {
    total_value: 2000060,
    company_id: 1,
    total_area: 100,
    fund_type: "Art 8",
    data_quality: 30,
    portfolio_name: "Arup Portfolio",
    countries: ["United Kingdom"],
    certifications: 30,
    company_address: "company_address",
    num_of_assets: 5,
    stranding_year_l_to_m: 15,
    building_types: ["Office"],
    stranding_year_m_to_h: 5,
    updated_datetime: "2021-01-23T04:56:07.000+00:00",
    company_name: "Arup",
    stranding_risk_level: 2,
    portfolio_id: 1,
    total_taxonomy: 70,
  },
  {
    total_value: 1000000,
    company_id: 6,
    total_area: 200,
    fund_type: "Art 8",
    data_quality: 70,
    portfolio_name: "CRISP Portfolio",
    countries: ["Germany"],
    certifications: 50,
    company_address: "company_address",
    num_of_assets: 2,
    stranding_year_l_to_m: 5,
    building_types: ["Office", "Warehouse"],
    stranding_year_m_to_h: 5,
    updated_datetime: "2023-01-23T04:56:07.000+00:00",
    company_name: "CRISP",
    stranding_risk_level: 1,
    portfolio_id: 2,
    total_taxonomy: 50,
  },
];

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { defaultRiskLevels } from "src/constants/riskLevel";
import {
  IPortfolioFilters,
  TUpdateFilterPayload,
} from "src/redux/interfaces/IPortfolioFilter";
import { fetchPortfolios } from "src/redux/thunks/portfolios";
import { buildFilter, buildFilterFromDefault } from "./helpers";
import { IAppPortfolio } from "src/redux/interfaces/IAppPortfolio";

const defaultfundtypes = [
  { label: "Art 6", value: "Art 6" },
  { label: "Art 8", value: "Art 8" },
  { label: "Art 9", value: "Art 9" },
];
const initialState = {
  buildingTypes: [],
  countries: [],
  riskLevels: [],
  fundTypes: [],
  selectedBuildingTypes: [],
  selectedCountries: [],
  selectedRiskLevels: [],
  selectedFundTypes: [],
};
const slice = createSlice({
  name: "portfoliosFilter",
  initialState: {
    requestStatus: "initial",
    ...initialState,
  } as IPortfolioFilters,
  reducers: {
    updateFilter: (state, { payload }: PayloadAction<TUpdateFilterPayload>) => {
      state[payload.filterType] = payload.filterValues;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPortfolios.pending, (state) => {
      state = initialState;
    });
    builder.addCase(fetchPortfolios.fulfilled, (state, { payload }) => {
      state.countries = buildFilter(
        payload,
        (p: IAppPortfolio) => p.summary.countries,
      );

      const allUses = buildFilter(
        payload,
        (p: IAppPortfolio) => p.summary.uses,
      );
      state.buildingTypes = allUses;
      state.selectedBuildingTypes = allUses.map((use) => use.value);

      const allRiskLevels = buildFilterFromDefault<number | undefined>(
        defaultRiskLevels,
        payload,
        (portfolio) => portfolio.summary.riskLevelId,
      );

      state.riskLevels = allRiskLevels;
      state.selectedRiskLevels = allRiskLevels.map((level) => level.value);

      const allFundTypes = buildFilterFromDefault<string | undefined>(
        defaultfundtypes,
        payload,
        (portfolio) => portfolio.summary.fundTypeName,
      );
      state.fundTypes = allFundTypes;
      state.selectedFundTypes = [];
    });
  },
});

export const { updateFilter } = slice.actions;
export default slice;

import React, { useState } from "react";
import { Flex, Button, Link } from "@chakra-ui/react";
import { Alert } from "../../atoms/Alert";

interface IProps {
  getLatestFileUrl?: () => Promise<string | void>;
  templateLink?: string;
  isUpdate?: boolean;
}

export const FileDownloadSection: React.FC<IProps> = ({
  isUpdate,
  templateLink,
  getLatestFileUrl,
}) => {
  const [oldFileError, setOldFileError] = useState(false);
  if ((isUpdate && !getLatestFileUrl) || (!isUpdate && !templateLink))
    return null;

  return (
    <>
      <Flex mt={4} justifyContent="flex-end">
        {isUpdate
          ? !!getLatestFileUrl && (
              <Button
                fontSize="md"
                px={0}
                variant="noBackground"
                color="gray.900"
                _hover={{ cursor: "pointer", color: "#E61E28" }}
                textDecoration="underline"
                onClick={async () => {
                  let url: string | void = "";
                  try {
                    setOldFileError(false);
                    url = await getLatestFileUrl();
                  } catch (error) {
                    setOldFileError(true);
                  }
                  if (!url) {
                    setOldFileError(true);
                  } else {
                    for (let i = 0; i < url.length; i++) {
                      const newWin = window.open(url[i], "_blank");
                      if (
                        !newWin ||
                        newWin.closed ||
                        typeof newWin.closed == "undefined"
                      ) {
                        //POPUP BLOCKED
                        window.alert(
                          "Pop-up blocked. Please enable site pop-ups and try again.",
                        );
                      }
                    }
                  }
                }}
              >
                Download latest version
              </Button>
            )
          : !!templateLink && (
              <Link
                fontSize="md"
                href={"/" + templateLink}
                color="gray.800"
                target="_blank"
                rel="noopener noreferrer"
                download={"/" + templateLink}
                _hover={{ cursor: "pointer", color: "#E61E28 " }}
                textDecoration="underline"
              >
                Download template file
              </Link>
            )}
      </Flex>
      {oldFileError && (
        <Alert
          message="Something went wrong. We cannot find requested file in our system"
          isError
          mt="0.5em"
        />
      )}
    </>
  );
};

export const ArcChartsColorsSequence = [
  "#E61E28", //arc-red-050 RGB 230, 30, 40
  "#FFB1B3", //arc-red-020 RGB: 255, 177, 179
  "#7D4196", // arc-purple-050 RGB: 125, 65, 150
  "#E3B2EE", // arc-purple-020 RGB: 227, 178, 238
  "#1E9BD7", // arc-blue-050 RGB: 30, 155, 215
  "#6CC9F6", // arc-blue-030 RGB: 108, 201, 246
  "#104B65", // arc-teal-080 RGB: 16, 75, 101
  "#9DEDDA", // arc-teal-020 RGB: 157, 237, 218
  "#E66E23", // arc-orange-050 RGB: 230, 110, 35
  "#FEB377", // arc-orange-030 RGB: 254, 179, 119
  "#C83C96", // arc-pink-050 RGB: 200, 60, 150
  "#FBD5EC", // arc-pink-010 RGB: 251, 213, 236
  "#005AAA", // arc-blue-070 RGB: 0, 90, 170
  "#C3EAFE", // arc-blue-010 RGB: 195, 234, 254
];

import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

interface IProps {
  isGhost?: boolean;
  name: string;
  children: React.ReactElement;
}

const AssetBadgeItem: React.FC<IProps> = (props) => {
  const { isGhost, name, children } = props;
  const certification = name.split(" ");

  return (
    <Flex fontSize={{ base: "9px", lg: "11px" }} alignItems="center">
      <Box
        mr="1rem"
        filter={isGhost ? "grayscale(100%)" : "none"}
        opacity={isGhost ? "0.2" : "1"}
      >
        {children}
      </Box>
      <Flex flexDir="column" opacity={isGhost ? "0.3" : "1"}>
        {certification.map((word, i) => (
          <Text key={i} textTransform="uppercase" fontWeight="bold" as="span">
            {word}
          </Text>
        ))}
      </Flex>
    </Flex>
  );
};
export default AssetBadgeItem;

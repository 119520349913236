import React from "react";
import { SelectableChart } from "src/common/components/molecules/SelectableChart";
import { ITimeSeriesPoint } from "src/api/open-api";
import { Flex } from "@chakra-ui/react";
import { Alert } from "src/common/components/atoms/Alert";
import { IReduxChartTarget } from "src/redux/interfaces/IReduxChartTarget";
import { roundTo } from "src/common/utils/math";

interface IProps {
  forecast?: ITimeSeriesPoint[] | null;
  scenarios: IReduxChartTarget[];
  targets: IReduxChartTarget[];
  disableSelection?: boolean;
  errorMessage?: string;
  units: string;
}

export const SelectableChartTab: React.FC<IProps> = ({
  forecast,
  scenarios,
  targets,
  disableSelection,
  errorMessage,
  units,
}) => {
  if (!forecast) {
    return (
      <Flex
        fontSize="small"
        flexDir="column"
        marginRight="0.5rem"
        align="center"
      >
        <Alert
          message="Baseline data does not exist for selected asset"
          isError={false}
        />
      </Flex>
    );
  }

  const warnings = scenarios
    ?.map((scenario) => {
      if (!scenario.timeSeries?.length) {
        return "";
      }
      return scenario.timeSeries.every(
        (p) =>
          p.value ===
          roundTo(forecast.find((fp) => fp.year === p.year)?.value, 2),
      )
        ? scenario.targetName + " is equal to Asset Forecast"
        : "";
    })
    .filter((m) => !!m);

  return (
    <SelectableChart
      forecast={forecast}
      selectableTargets={disableSelection ? [] : targets}
      staticTargets={disableSelection ? targets : []}
      scenarios={scenarios}
      errorMessage={errorMessage}
      warningMessages={warnings}
      units={units}
    />
  );
};

import { Flex } from "@chakra-ui/react";
import React from "react";
import { RoundSpinner } from "./RoundSpinner";

export const LoadComponentPlaceholder: React.FC = () => {
  return (
    <Flex
      flexDir="row"
      w="100%"
      height="100%"
      minHeight="100px"
      px={{ base: "3rem", xs: "6rem" }}
      justifyContent="center"
      alignItems="center"
    >
      <RoundSpinner />
    </Flex>
  );
};

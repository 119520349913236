import { EntityState, createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { featureConfigAdapter } from "./adapter";
import { IFeatureConfig } from "src/api/open-api";
import {
  defaultPortfolioCardSetup,
  tabMap,
  defaultTabList,
} from "./data/features";

export const selectSlice = (state: RootState) => state.featureConfig;

export const selectFullFeatureConfig = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<IFeatureConfig>) =>
    featureConfigAdapter.getSelectors().selectAll(state),
);

export const selectTabList = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<IFeatureConfig> & { requestStatus: string }) => {
    if (state.requestStatus === "initial" || state.requestStatus === "pending")
      return [];
    const fullAppConfig = featureConfigAdapter.getSelectors().selectAll(state);
    const tabsConfig = defaultTabList
      .map((tab) => {
        const companyConfig = fullAppConfig.find((c) => c.feature_key === tab);
        return (
          companyConfig || {
            feature_key: tab,
            feature_config: tabMap[tab].default_order,
            feature_status: true,
          }
        );
      })
      .filter((c) => !!c.feature_status);
    return tabsConfig
      .sort(
        (a, b) =>
          (Number.isNaN(Number(a.feature_config))
            ? tabMap[a.feature_key as keyof typeof tabMap].default_order
            : Number(a.feature_config)) -
          (Number.isNaN(Number(b.feature_config))
            ? tabMap[b.feature_key as keyof typeof tabMap].default_order
            : Number(b.feature_config)),
      )
      .map((c) => tabMap[c.feature_key as keyof typeof tabMap]);
  },
);

export const selectPortfolioCardSetup = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<IFeatureConfig> & { requestStatus: string }) => {
    if (state.requestStatus === "initial" || state.requestStatus === "pending")
      return {
        portfolio_card_fund_type: false,
        portfolio_card_value: false,
        portfolio_card_taxonomy: false,
        portfolio_card_certifications: false,
        portfolio_card_data_maturity: false,
      };
    const cardConfig = featureConfigAdapter
      .getSelectors()
      .selectAll(state)
      .filter((c) => c.feature_key?.startsWith("portfolio_card_"));
    const config: { [key: string]: boolean } = { ...defaultPortfolioCardSetup };
    cardConfig.forEach((feature) => {
      config[feature.feature_key] = feature.feature_status;
    });
    return config;
  },
);

export const selectShouldShowValue = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<IFeatureConfig> & { requestStatus: string }) => {
    if (state.requestStatus === "initial" || state.requestStatus === "pending")
      return false;
    const globalValueConfig = featureConfigAdapter
      .getSelectors()
      .selectAll(state)
      .find((c) => c.feature_key?.startsWith("global_value"));
    // if not configured - show.
    return globalValueConfig ? globalValueConfig.feature_status : true;
  },
);

export const selectShouldShowAssetReportButton = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<IFeatureConfig> & { requestStatus: string }) => {
    if (state.requestStatus === "initial" || state.requestStatus === "pending")
      return null;
    const assetReportButtonConfig = featureConfigAdapter
      .getSelectors()
      .selectAll(state)
      .find((c) => c.feature_key?.startsWith("asset_report_generation"));
    // if not configured - hide.
    return assetReportButtonConfig
      ? assetReportButtonConfig.feature_status
      : false;
  },
);

export const selectShouldShowPortfolioReportButton = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<IFeatureConfig> & { requestStatus: string }) => {
    if (state.requestStatus === "initial" || state.requestStatus === "pending")
      return null;
    const portfolioReportButtonConfig = featureConfigAdapter
      .getSelectors()
      .selectAll(state)
      .find((c) => c.feature_key?.startsWith("portfolio_report_generation"));
    // if not configured - hide.
    return portfolioReportButtonConfig
      ? portfolioReportButtonConfig.feature_status
      : false;
  },
);

export const selectShouldShowCapex = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<IFeatureConfig> & { requestStatus: string }) => {
    if (state.requestStatus === "initial" || state.requestStatus === "pending")
      return false;
    const globalValueConfig = featureConfigAdapter
      .getSelectors()
      .selectAll(state)
      .find((c) => c.feature_key?.startsWith("global_capex"));
    // if not configured - show.
    return globalValueConfig ? globalValueConfig.feature_status : true;
  },
);

export const selectAssetArchetypeMACConfg = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<IFeatureConfig> & { requestStatus: string }) => {
    if (state.requestStatus === "initial" || state.requestStatus === "pending")
      return null;
    const config = featureConfigAdapter
      .getSelectors()
      .selectAll(state)
      .find((c) => c.feature_key?.startsWith("asset_archetype_mac"));
    // if not configured - hide.
    return config || null;
  },
);

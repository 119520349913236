import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { ComplianceIndicator } from "src/common/components/atoms/ComplianceIndicator";

interface IProps {}

const TaxonomyLegend: React.FC<IProps> = (props) => {
  return (
    <Flex flex={1} justify="end" align="center">
      <ComplianceIndicator substantialContribution={true} />
      <Text fontSize="small" ml={1} mr={3}>
        Substantial Contribution
      </Text>
      <ComplianceIndicator compliance={true} substantialContribution={false} />
      <Text fontSize="small" ml={1} mr={3}>
        Compliant
      </Text>
      <ComplianceIndicator compliance={false} substantialContribution={false} />
      <Text fontSize="small" ml={1} mr={3}>
        Not Compliant
      </Text>
    </Flex>
  );
};
export default TaxonomyLegend;

import React, { useState, useMemo } from "react";
import {
  VStack,
  Heading,
  Text,
  Box,
  Select,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Button,
  Tbody,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { ICompany, IFeatureConfig } from "src/api/open-api";
import { useAppDispatch } from "src/redux/hooks";
import { Input } from "src/common/components/atoms/Input";
import { allAvailableFeatures } from "src/redux/slices/featureConfig/data/features";
import {
  addFeatureConfigByCompanyId,
  deleteFeatureConfig,
} from "src/redux/thunks/featureConfig";

interface IProps {
  company?: ICompany;
  featureConfig: IFeatureConfig[];
}

const availablePermissionTypes = allAvailableFeatures.map((key) => ({
  value: key,
  label: key,
}));

const featureStatusOptions = [
  {
    value: "true",
    label: "Enabled",
  },
  {
    value: "false",
    label: "Disabled",
  },
];

export const CompanyConfig: React.FC<IProps> = ({ company, featureConfig }) => {
  const [selectedFeature, setSelectedFeature] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<boolean | undefined>();
  const [config, setConfig] = useState<string>("");
  const dispatch = useAppDispatch();

  const handleSelectedFeature = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFeature(e?.target?.value);
  };

  const handleSelectedStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(e?.target?.value === "true");
  };

  const handleChangeConfig = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfig(e?.target?.value);
  };

  const isAddEnabled = useMemo(() => {
    return selectedFeature && selectedStatus !== undefined;
  }, [selectedFeature, selectedStatus]);

  const handleAdd = () => {
    const featureToAdd = {
      company_id: company?.company_id,
      feature_key: selectedFeature,
      feature_config: config || "-",
      feature_status: selectedStatus,
    };
    if (company) {
      dispatch(
        addFeatureConfigByCompanyId({
          companyId: company.company_id,
          featureConfig: featureToAdd as unknown as IFeatureConfig,
        }),
      );
    }
  };

  const handleDelete = (id: number, key: string) => {
    if (company) {
      dispatch(
        deleteFeatureConfig({
          companyId: company.company_id,
          featureConfigId: id,
          featureConfigKey: key,
        }),
      );
    }
  };

  return (
    <>
      <Heading
        as="h3"
        fontSize="heading1"
        fontWeight="normal"
        color="red.500"
        mt="1rem"
      >
        Add config
      </Heading>
      <VStack
        mt="0.5rem"
        mb="2rem"
        padding="1rem"
        backgroundColor="gray.50"
        borderRadius="8px"
        align="stretch"
      >
        <Grid templateColumns="repeat(12, 1fr)" gap="1.5rem">
          <GridItem colSpan={{ base: 12, md: 6, lg: 4 }}>
            <Text>Feature</Text>
            <Select
              fontSize="small"
              placeholder="Please select permission type"
              size="sm"
              width="100%"
              variant="outlineMuted"
              onChange={handleSelectedFeature}
            >
              {availablePermissionTypes.map((permission) => (
                <option key={permission.value} value={permission.value}>
                  {permission.label}
                </option>
              ))}
            </Select>
          </GridItem>
          <GridItem colSpan={{ base: 12, md: 6, lg: 4 }}>
            <Text>Status</Text>
            <Select
              fontSize="small"
              placeholder="Please select status"
              size="sm"
              width="100%"
              variant="outlineMuted"
              onChange={handleSelectedStatus}
            >
              {featureStatusOptions.map((permission) => (
                <option key={permission.value} value={permission.value}>
                  {permission.label}
                </option>
              ))}
            </Select>
          </GridItem>
          <GridItem colSpan={{ base: 12, md: 6, lg: 4 }}>
            <Text>Config</Text>

            <Input
              placeholder="Config"
              backgroundColor="white"
              onChange={handleChangeConfig}
              hasBorder={true}
            />
          </GridItem>
        </Grid>

        <Box textAlign="center">
          <Button
            mt="1rem"
            variant="solidPrimary"
            isDisabled={!isAddEnabled}
            onClick={handleAdd}
          >
            Add
          </Button>
        </Box>
      </VStack>

      <Heading
        as="h3"
        fontSize="heading1"
        fontWeight="normal"
        mt="2rem"
        mb="1rem"
        color="red.500"
      >
        All feature settings
      </Heading>
      <Table variant="simple" width="100%">
        <Thead>
          <Tr>
            <Th>Key</Th>
            <Th>Status</Th>
            <Th>Config</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {featureConfig.map((feature) => (
            <Tr key={feature.feature_config_id} _hover={{ bg: "white.400" }}>
              <Td>{feature.feature_key}</Td>
              <Td>{feature.feature_status ? "Enabled" : "Disabled"}</Td>
              <Td>{feature.feature_config}</Td>
              <Td w="2rem">
                <Button
                  w={{ base: "100%" }}
                  variant="outlineGrey"
                  placeSelf={{ base: "center", xs: "inherit" }}
                  mb="0.25rem"
                  onClick={() =>
                    handleDelete(feature.feature_config_id, feature.feature_key)
                  }
                >
                  Delete
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { theme } from "src/config/theme";
import { getConsumptionShares } from "./helpers";
import { averageTotalCapexCost } from "src/common/utils/costing/costing";
import { CustomPieChart } from "../CustomPieChart";
import { EconomicHighlight } from "../../atoms/EconomicHighlight";
interface IProps {
  assets: IAppAsset[];
  units: string;
  areaUnits: string;
  shouldShowCapex?: boolean;
}

const toPercentsWithTwoDecimal = (value: number) =>
  Math.round(value * 100 * 100) / 100;

export const PerformanceHighlights: React.FC<IProps> = ({
  assets,
  units,
  areaUnits,
  shouldShowCapex,
}) => {
  const energies = getConsumptionShares(
    assets.map((asset) => asset.energiesConsumption),
  );
  const chartCategories = [
    {
      color: theme.colors.green[400],
      value: toPercentsWithTwoDecimal(
        energies.consumedRenewables / energies.totalEnergy,
      ),
      focused: true,
      label: "Consumed renewable [%]",
    },
    {
      color: theme.colors.green[50],
      value: toPercentsWithTwoDecimal(
        energies.exportedRenewables / energies.totalEnergy,
      ),
      focused: true,
      label: "Exported renewable [%]",
    },
    {
      color: theme.colors.gray[50],
      value: toPercentsWithTwoDecimal(
        energies.electricity / energies.totalEnergy,
      ),
      focused: false,
      label: "Electricity [%]",
    },
    {
      color: theme.colors.gray[200],
      value: toPercentsWithTwoDecimal(energies.district / energies.totalEnergy),
      focused: false,
      label: "District Heating/Cooling [%]",
    },
    {
      color: theme.colors.gray[500],
      value: toPercentsWithTwoDecimal(
        energies.fossilFuels / energies.totalEnergy,
      ),
      focused: false,
      label: "Fossil Fuels [%]",
    },
  ];

  return (
    <Flex direction="row" justifyContent="space-evenly" p="0.5rem">
      {shouldShowCapex ? (
        <EconomicHighlight
          title="Total CAPEX"
          description={`Total investment costs required for a "decarbonisation" scenario, accumulated up to 2050 [per ${areaUnits} GIA IPMS2] (costs related to energy conservation measures exclusively)`}
          color="yellow.400"
          value={averageTotalCapexCost(assets)}
          units={units}
        />
      ) : null}
      <Box maxWidth="100px" textAlign="center">
        <Text fontFamily="body" fontSize="small" fontWeight="bold" mb="10px">
          Energy Split
        </Text>
        <CustomPieChart
          categories={chartCategories}
          customSubLabel="renewables"
        />
      </Box>
    </Flex>
  );
};

import React from "react";
import { Image } from "@chakra-ui/react";

import Leed from "src/assets/img/LEED_00.png";
import LeedCert from "src/assets/img/LEED_01.png";
import LeedSilv from "src/assets/img/LEED_02.png";
import LeedGold from "src/assets/img/LEED_03.png";
import LeedPlat from "src/assets/img/LEED_04.png";

type TLeedBadgeTypes = "certified" | "silver" | "gold" | "platinum";
interface IProps {
  size?: "sm" | "md";
  level?: TLeedBadgeTypes;
}

const _getImageByName = (name?: TLeedBadgeTypes) => {
  switch (name) {
    case "certified":
      return LeedCert;
    case "silver":
      return LeedSilv;
    case "gold":
      return LeedGold;
    case "platinum":
      return LeedPlat;
    default:
      return Leed;
  }
};

export const LeedBadge: React.FC<IProps> = ({ size, level }) => {
  const img = _getImageByName(level);

  return (
    <Image
      src={img}
      maxW={
        size === "sm"
          ? { base: "1.2rem", lg: "1.7rem" }
          : { base: "3rem", lg: "4rem" }
      }
      style={{ aspectRatio: `1 / 1` }}
    />
  );
};

import { Box, Tabs, TabPanels, TabPanel } from "@chakra-ui/react";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  selectEnergyTargetsForOpenedAsset,
  selectGHGTargetsForOpenedAsset,
  selectOpexLineForOpenedAsset,
} from "src/redux/slices/portfolios/assets/openedAssetSelectors";
import { StandardContent } from "src/common/components/templates/StandardContent";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import ScenarioArea from "./components/ScenarioArea";
import { SelectableChartTab } from "../../components/ChartTabs/SelectableChartTab";
import CostTab from "../../components/ChartTabs/CostTab";
import InterventionsTab from "./components/InterventionsTab";
import { InterventionsCostTab } from "./components/InterventionsCostTab/InterventionsCostTab";
import {
  selectCostForSelectedScenarios,
  selectEnergyForSelectedScenarios,
  selectGHGForSelectedScenarios,
  selectIsScenariosDataLoading,
  selectScenariosError,
} from "src/redux/slices/portfolios/scenarios/selectors";
import { LoadComponentPlaceholder } from "src/common/components/atoms/Spinner";
import { fetchScenarios } from "src/redux/thunks/scenarios";
import { fetchArchetypeByAssetId } from "src/redux/thunks/archetypes";
import {
  findArchetypeErrorForSelectedAsset,
  selectIsArchetypesDataLoading,
} from "src/redux/slices/portfolios/archetypes/selectors";
import { genErrorMessage } from "src/common/utils/errors";
import { selectOpenedPortfolio } from "src/redux/slices/portfolios/selectors";
import { Units, getUnits } from "src/constants/measureUnits";
import { getScaledStrandingChartValuesForGHG } from "src/common/utils/units/ghgChartScaling";
import { TabsHeader } from "src/common/components/atoms/TabsHeader";

type TProps = {
  asset: IAppAsset;
};
export const AssetScenarioTab: React.FC<TProps> = ({ asset }) => {
  const dispatch = useAppDispatch();

  const [tabIndex, setTabIndex] = useState(0);

  const isScenarioLoading = useAppSelector(selectIsScenariosDataLoading);
  const isArchetypeLoading = useAppSelector(selectIsArchetypesDataLoading);
  const archetypeError = useAppSelector(findArchetypeErrorForSelectedAsset);
  const statusQuoOpexLine = useAppSelector(selectOpexLineForOpenedAsset);
  const targetsGHG = useAppSelector(selectGHGTargetsForOpenedAsset);
  const scenariosEnergy = useAppSelector(selectEnergyForSelectedScenarios);
  const scenariosGHG = useAppSelector(selectGHGForSelectedScenarios);
  const targetsEnergy = useAppSelector(selectEnergyTargetsForOpenedAsset);
  const costsPerScenario = useAppSelector(selectCostForSelectedScenarios);
  const openedPortfolio = useAppSelector(selectOpenedPortfolio);

  const scenarioError = useAppSelector(selectScenariosError);
  const { t } = useTranslation();

  useEffect(() => {
    if (asset) {
      dispatch(fetchScenarios(Number(asset.assetId)));
      dispatch(fetchArchetypeByAssetId(Number(asset.assetId)));
    }
  }, [dispatch, asset]);

  const ghgScaledValues = useMemo(
    () =>
      getScaledStrandingChartValuesForGHG(
        asset.ghgResults.emissionsForecast || [],
        targetsGHG,
        scenariosGHG,
        openedPortfolio?.measureSystem,
      ),
    [
      asset.ghgResults.emissionsForecast,
      targetsGHG,
      scenariosGHG,
      openedPortfolio,
    ],
  );

  if (isScenarioLoading || isArchetypeLoading) {
    return <LoadComponentPlaceholder />;
  }

  const scenarioErrorMessage = scenarioError
    ? genErrorMessage(scenarioError, t, "Cannot display scenarios. ")
    : "";
  const errorMessage = archetypeError ? "Cannot display archetype" : "";

  return (
    <Box>
      <StandardContent
        disableStretch={!scenarioErrorMessage}
        errorMessage={scenarioErrorMessage}
        chartAreaContent={
          <>
            <Tabs
              colorScheme="red"
              size="sm"
              variant="unstyled"
              width="100%"
              onChange={setTabIndex}
            >
              <TabsHeader
                ml="15px"
                tabTitles={[
                  "GHG Emissions Intensity",
                  "Energy Intensity",
                  "Cost Forecast",
                  "Intervention GHG and Energy Analysis",
                  "Intervention Cost Analysis",
                ]}
                customWidth={["20%", "15%", "15%", "30%", "20%"]}
              />

              <TabPanels>
                <TabPanel>
                  {tabIndex === 0 ? (
                    <SelectableChartTab
                      forecast={ghgScaledValues.forecast}
                      targets={ghgScaledValues.targets}
                      scenarios={ghgScaledValues.scenarios}
                      errorMessage={errorMessage}
                      units={ghgScaledValues.units}
                    />
                  ) : null}
                </TabPanel>
                <TabPanel>
                  {tabIndex === 1 ? (
                    <SelectableChartTab
                      forecast={asset.energyResults.emissionsForecast}
                      scenarios={scenariosEnergy}
                      targets={targetsEnergy}
                      errorMessage={errorMessage}
                      units={getUnits(
                        Units.energyIntensityYearly,
                        openedPortfolio?.measureSystem,
                      )}
                    />
                  ) : null}
                </TabPanel>
                <TabPanel>
                  <CostTab
                    assetTotalCapex={asset.capex.capex_cost_total || 0}
                    assetTotalOpex={
                      asset.opex.opex_status_quo_energy
                        ? asset.opex.opex_status_quo_energy +
                          asset.opex.opex_status_quo_carbon
                        : 0
                    }
                    statusQuoOpexLine={statusQuoOpexLine}
                    scenarios={costsPerScenario}
                    isoCurrencyCode={openedPortfolio?.currency}
                  />
                </TabPanel>
                <TabPanel>
                  <InterventionsTab
                    assetArea={asset.area}
                    measureSystem={openedPortfolio?.measureSystem}
                  />
                </TabPanel>
                <TabPanel>
                  <InterventionsCostTab
                    assetArea={asset.area || 1}
                    isoCurrencyCode={openedPortfolio?.currency}
                    measureSystem={openedPortfolio?.measureSystem}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        }
      >
        <Box width="100%" minHeight="30vh">
          <ScenarioArea asset={asset} />
        </Box>
      </StandardContent>
    </Box>
  );
};

import { Auth } from "aws-amplify";

export const getCurrentSession = async () => {
  return await Auth.currentSession();
};

const getAccessJwtToken = async () => {
  return (await Auth.currentSession()).getAccessToken().getJwtToken();
};

export const getCognitoAuthToken = async () => {
  //get auth token from cognito
  const userAccessToken = await getAccessJwtToken();
  if (!userAccessToken) throw Error("Invalid access token");
  return userAccessToken;
};

export const signOut = async (): Promise<void> => {
  return await Auth.signOut();
};

import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Flex,
  Icon,
  Image,
  AspectRatio,
  Button,
  useToast,
} from "@chakra-ui/react";

import { BsCloudUpload } from "react-icons/bs";
import { FiImage } from "react-icons/fi";

import { useAppSelector, useAppDispatch } from "src/redux/hooks";
import { useModalState } from "src/common/utils/useModalState";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { selectAllCountries } from "src/redux/slices/countries/selectors";
import FileUploadModal from "src/common/components/organisms/FileUploadModal/FileUploadModal";
import {
  getAssetImage,
  saveAssetImage,
  getAssetReport,
} from "src/redux/thunks/assets";
import { formatMoney, formatWeight } from "src/common/utils/formatters";
import { selectIsImageRequested } from "src/redux/slices/portfolios/assets/selectors";
import { MeasureSystem } from "src/redux/interfaces/IAppPortfolio";
import { Units, getUnits } from "src/constants/measureUnits";
import {
  selectShouldShowValue,
  selectShouldShowAssetReportButton,
} from "src/redux/slices/featureConfig/selectors";
import { AssetSidebarLine } from "./AssetSidebarLine";
import { NabersRating } from "src/common/components/atoms/NabersRating";
import { selectCanIGenerateReport } from "src/redux/slices/auth/authInfo/selectors";
import { apiErrorToI18nError } from "src/redux/interfaces/Ii18nError";
import { genErrorMessage } from "src/common/utils/errors";
import { useTranslation } from "react-i18next";

type TProps = {
  asset: IAppAsset;
  isoCurrencyCode?: string;
  measureSystem?: MeasureSystem;
};
const toLocaleString = (value?: number) =>
  value?.toLocaleString(undefined, {
    maximumFractionDigits: 1,
  }) || "0";
export const AssetSidebar: React.FC<TProps> = ({
  asset,
  isoCurrencyCode,
  measureSystem,
}) => {
  const {
    isOpen: isModalOpen,
    setOpen: setModalOpen,
    setClose: setModalClose,
  } = useModalState();

  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  const allCountries = useAppSelector(selectAllCountries);
  const isImageRequested = useAppSelector(selectIsImageRequested);
  const shouldShowValue = useAppSelector(selectShouldShowValue);
  const shouldShowButton = useAppSelector(selectShouldShowAssetReportButton);
  const canIGenerateReport = useAppSelector(selectCanIGenerateReport);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isImageRequested && asset.assetId) {
      dispatch(getAssetImage(asset.assetId));
    }
  }, [dispatch, asset?.assetId, isImageRequested]);

  const toastMessage = (title: string, description: string) => {
    toast({
      title: title,
      description: description,
      status: "error",
      isClosable: true,
      position: "top",
    });
  };

  const year = asset.reportingYear || new Date().getFullYear();

  const ghgBaseline = asset.ghgResults.emissionsForecast;
  // We want to show emissions for reporting year, but sometimes reporting year value is just not provided
  const carbonEmissions =
    (ghgBaseline?.find((e) => e.year === year)?.value || 0) * asset.area;

  const totalEnergyConsumption =
    (asset.energiesConsumption.cooling || 0) +
    (asset.energiesConsumption.electricity || 0) +
    (asset.energiesConsumption.gas || 0) +
    (asset.energiesConsumption.heating || 0) +
    (asset.energiesConsumption.oil || 0) +
    (asset.energiesConsumption.otherTypeValue || 0);

  const location = [];
  const country = allCountries.find(
    (c) => c.country_iso_code === asset.location.isoCountryCode,
  )?.country_label;
  if (country) location.push(country);
  if (asset.location.city) location.push(asset.location.city);

  const handleImageUpload = async (file: File) => {
    await dispatch(
      saveAssetImage({
        portfolioId: asset.portfolioId,
        assetId: asset.assetId,
        image: file,
      }),
    );
    setModalClose();
  };

  const getAssetReportURL = async () => {
    setLoading(true);
    const res = await dispatch(getAssetReport(asset.assetId));
    // @ts-expect-error error type is unknown
    if (res.payload?.errorCode) {
      toastMessage(
        "Issue occured during report generation",
        genErrorMessage(apiErrorToI18nError(res.payload), t),
      );
    } else {
      // @ts-expect-error payload had wrong type in openAPI, should fix later
      const newWin = window.open(res.payload?.report_URL, "_blank");
      if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
        //POPUP BLOCKED
        window.alert(
          "Pop-up blocked. Please enable site pop-ups and try again.",
        );
      }
    }
    setLoading(false);
  };

  return (
    <Box>
      <Text mt="16px" as="h2" color="red.400" fontSize="xs">
        {asset.companyName} / {asset.portfolioName}
      </Text>
      <Text mt="0" as="h3">
        {asset.assetLabel}
      </Text>
      <FileUploadModal
        title="Upload Asset Image"
        isOpen={isModalOpen}
        onClose={setModalClose}
        onUpload={handleImageUpload}
        acceptedFileTypes={{
          "image/jpeg": [".jpg", ".jpeg"],
          "image/png": [".png"],
        }}
        acceptedFileTypesDescription={".jpg, .jpeg, .png"}
      />
      <Box
        position="relative"
        width="100%"
        role="group"
        marginBottom="1.875rem"
        marginTop="1.5rem"
      >
        <AspectRatio ratio={16 / 9}>
          <Box
            backgroundColor="gray.50"
            borderRadius="0.25rem"
            title=""
            _hover={{ cursor: "pointer" }}
            onClick={setModalOpen}
          >
            {!asset.assetImageUrl && (
              <>
                <Icon
                  as={BsCloudUpload}
                  color="gray.500"
                  boxSize="25%"
                  left="50%"
                  position="absolute"
                  top="40%"
                  marginBottom="1rem"
                  transform="translate(-50%, -50%)"
                  textAlign="center"
                />
                <Text
                  textColor="gray.600"
                  position="absolute"
                  left="50%"
                  textAlign="center"
                  top="70%"
                  transform="translate(-50%, -50%)"
                  fontSize="1rem"
                >
                  Upload image here
                </Text>
              </>
            )}
            {asset.assetImageUrl && (
              <Image
                borderRadius="0.25rem"
                src={asset.assetImageUrl}
                _hover={{ cursor: "pointer" }}
                _groupHover={{ opacity: 0.7 }}
                onClick={setModalOpen}
                title="Upload image"
              />
            )}
          </Box>
        </AspectRatio>
        {!asset.assetImageUrl && (
          <Icon
            as={FiImage}
            color="white.200"
            boxSize="12.5%"
            position="absolute"
            top="89.5%"
            left="94%"
            transform="translate(-50%, -50%)"
            textAlign="center"
            opacity={0}
            title="Upload image"
            _groupHover={{ opacity: 1 }}
            _hover={{ cursor: "pointer" }}
          />
        )}
      </Box>
      {canIGenerateReport && shouldShowButton ? (
        <Flex direction="row" mt="0.7em" mb="0.7em">
          <Button
            size="sm"
            variant="outlineGray"
            width="100%"
            onClick={getAssetReportURL}
            isLoading={isLoading}
          >
            Generate Report
          </Button>
        </Flex>
      ) : null}
      <AssetSidebarLine title="Location" content={location.join(", ")} />
      <AssetSidebarLine
        title="CRREM Property Type"
        content={asset.businessBuildingTypes
          .map((t) => t.business_building_type_label)
          .join(", ")}
      />
      <AssetSidebarLine
        title="Asset Area"
        content={`${toLocaleString(asset.area)} ${getUnits(
          Units.area,
          measureSystem,
        )}`}
      />
      {shouldShowValue ? (
        <AssetSidebarLine
          title="Total Value"
          content={
            asset.value ? formatMoney(asset.value, isoCurrencyCode) : undefined
          }
        />
      ) : null}

      <AssetSidebarLine
        title="Energy Consumption"
        content={`${toLocaleString(totalEnergyConsumption / 1000)} ${getUnits(
          Units.energyYearlyx1000,
          measureSystem,
        )} (in ${year})`}
      />
      <AssetSidebarLine
        title="Carbon Emissions"
        content={
          carbonEmissions &&
          `${formatWeight(
            getUnits(Units.ghgYearly, measureSystem),
            carbonEmissions,
            measureSystem,
          )} (in ${year})`
        }
      />
      {asset.nabers_rating ? (
        <Flex flexWrap="wrap" my="0.5rem">
          <Text fontSize="sm" color="gray.500">
            NABERS rating (current):&nbsp;
          </Text>
          <Box mt="-3px">
            <NabersRating rating={asset.nabers_rating} />
          </Box>
        </Flex>
      ) : (
        <AssetSidebarLine
          title="EPC Rating"
          content={asset.epc.rating && `Class ${asset.epc.rating}`}
        />
      )}
      <AssetSidebarLine
        title="Listed Building"
        content={asset.listedBuilding ? "Yes" : "No"}
      />
    </Box>
  );
};

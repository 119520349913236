import {
  Box,
  Button,
  VStack,
  Link,
  Divider,
  Text,
  HStack,
  Flex,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import React, { FormEvent, useState } from "react";
import { ICrispUser } from "src/api/open-api";
import { useAppDispatch } from "src/redux/hooks";
import { FormInput } from "src/common/components/atoms/Input";
import {
  addUserToCompany,
  fetchCompanyPermissions,
  fetchCompanyUsers,
  fetchCrispUserByEmail,
} from "src/redux/thunks/admin";

interface IProps {
  onSubmitCallback?: () => void;
  createUserCallback?: () => void;
  companyId: number;
}

const UserSearchForm: React.FC<IProps> = (props: IProps) => {
  const { onSubmitCallback, createUserCallback, companyId } = props;
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [emailSearchTerm, setEmailSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState<ICrispUser | null>();
  const [isLoading, setIsLoading] = useState(false);

  const toastMessage = (title: string, description: string) => {
    toast({
      title: title,
      description: description,
      status: "error",
      isClosable: true,
      position: "top",
    });
  };

  const resetSearch = () => {
    setIsLoading(false);
    setSearchResult(null);
  };

  const searchRequestSubmit = () => {
    resetSearch();
    setIsLoading(true);
    dispatch(fetchCrispUserByEmail(emailSearchTerm))
      .unwrap()
      .then((user: ICrispUser) => {
        setIsLoading(false);

        if (!user) {
          toastMessage(
            "No search results",
            `Active user not found with email ${emailSearchTerm}. Check the user is activated or create a new user.`,
          );
        } else {
          setSearchResult(user);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage(
          "System Error",
          "Error searching for user, please try again.",
        );
      });
  };

  const handleSearchChange = (target: { value: string }) => {
    setEmailSearchTerm(target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (searchResult)
      dispatch(
        addUserToCompany({
          userId: searchResult.user_id,
          companyId: companyId,
        }),
      )
        .then(() => dispatch(fetchCompanyUsers(companyId)))
        .then(() => dispatch(fetchCompanyPermissions(companyId)))
        .then(() => {
          onSubmitCallback && onSubmitCallback();
        });
  };

  return (
    <>
      <Box>
        <form
          encType="multipart/form-data"
          onSubmit={(e) => {
            handleSubmit(e);
            e.preventDefault();
          }}
        >
          <VStack spacing="1.5rem" textAlign="left" alignItems="left">
            <VStack spacing="1rem">
              <FormInput
                name="emailSearch"
                value={emailSearchTerm}
                type="email"
                onBlur={handleSearchChange}
                placeholder="Input user email address"
                fontSize="md"
                label="Search for existing user"
              />
              <Button
                alignSelf="end"
                variant="outlineGray"
                size="md"
                onClick={searchRequestSubmit}
              >
                Search
              </Button>
            </VStack>
            {isLoading && (
              <Box>
                <Flex
                  h="100%"
                  w="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Spinner />
                </Flex>
              </Box>
            )}
            {searchResult && (
              <>
                <Divider />
                <Text fontSize="md" textColor="black.200">
                  Results
                </Text>
                <Box
                  pb="1.5rem"
                  alignItems="right"
                  justifyContent="right"
                  textAlign="right"
                >
                  <HStack padding="1rem" bg="white.200" borderRadius="0.5rem">
                    <Box flex={1} flexDirection="column" textAlign="left">
                      <Text
                        color="black.700"
                        fontWeight="bold"
                        fontSize="md"
                        m={0}
                      >
                        {searchResult.user_name}
                      </Text>
                      <Text color="black.500" fontSize="sm">
                        {searchResult.user_email}
                      </Text>
                    </Box>

                    <Button
                      type="submit"
                      variant="outlinePrimary"
                      placeSelf={{ base: "center" }}
                    >
                      Add to company
                    </Button>
                  </HStack>
                </Box>
              </>
            )}

            <Divider />
            <Text fontSize="md">
              User not found?{" "}
              <Link
                fontSize="md"
                fontWeight="bold"
                textColor="black.200"
                onClick={createUserCallback}
              >
                Create a new user
              </Link>
            </Text>
          </VStack>
        </form>
      </Box>
    </>
  );
};

export default UserSearchForm;

import React, { useState } from "react";
import {
  Box,
  Text,
  Flex,
  Stack,
  Icon,
  IconButton,
  Spinner,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { AttachmentIcon, CloseIcon } from "@chakra-ui/icons";
import { BsCloudUpload } from "react-icons/bs";
import { checkVersion } from "./checkVersion";

interface IProps {
  acceptedFileTypes?: { [key: string]: string[] };
  acceptedFileTypesDescription?: string;
  currentFileVersion?: string;
  onDrop: (file: File) => void;
  onClean: () => void;
}

const acceptedTypes = {
  "text/plain": [".xls", ".xlsx", ".xlsb", ".xlsm"],
  "application/vnd.ms-excel": [".xls", ".xlsx", ".xlsb", ".xlsm"],
};

export const Drop: React.FC<IProps> = ({
  acceptedFileTypes = acceptedTypes,
  acceptedFileTypesDescription = ".xls, .xlsx, .xlsb, .xlsm",
  currentFileVersion,
  onDrop,
  onClean,
}) => {
  const [isOldVersion, setOldVersion] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    setOldVersion(false);
    setContent(undefined);
    onClean();
  };

  const [content, setContent] = useState<File>();
  const handleDrop = async <T extends File>(files: T[]) => {
    setLoading(true);
    setContent(files[0]);
    onDrop(files[0]);
    if (currentFileVersion) {
      const isLatest = await checkVersion(files[0], currentFileVersion);
      if (!isLatest) {
        setOldVersion(true);
      }
    }
    setLoading(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileTypes,
    multiple: false,
    onDropAccepted: handleDrop,
  });

  return (
    <Box className="container">
      {loading ? (
        <Flex
          borderStyle="dashed"
          borderRadius="0.25rem"
          style={{ borderStyle: "dashed" }}
          bgColor="gray.100"
          border="1px"
          borderColor="gray.500"
          padding="1rem"
          title="Upload file"
          height="192px"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner />
        </Flex>
      ) : (
        <Box
          borderStyle="dashed"
          borderRadius="0.25rem"
          _hover={{
            cursor: "pointer",
            transition: "ease-in-out 0.3s",
          }}
          style={{ borderStyle: "dashed" }}
          {...getRootProps({ className: "dropzone" })}
          bgColor="gray.50"
          border="1px"
          borderColor="gray.400"
          padding="1rem"
          title="Upload file"
        >
          <input {...getInputProps()} />
          <Stack alignItems="center" spacing={3}>
            <Text
              fontSize="small"
              fontWeight="bold"
              textColor="gray.700"
              textAlign="center"
            >
              Drag and drop a compatible file here,
              <br /> or click to select a file.
            </Text>
            <Icon
              as={BsCloudUpload}
              boxSize={14}
              fill="gray.700"
              margin="10px"
            />
            <Text fontSize="small" textColor="gray.700" textAlign="center">
              Only files with the following formats are allowed:{" "}
              <b>{acceptedFileTypesDescription}</b>
            </Text>
          </Stack>
        </Box>
      )}

      <aside>
        {isOldVersion ? (
          <Text
            mt={4}
            mb={-2}
            fontSize="small"
            fontWeight="bold"
            color="blue.500"
          >
            Warning: The template file format is outdated. Please download the
            template file again and populate with the Portfolio data.
          </Text>
        ) : null}
        {content && (
          <Flex
            mt={4}
            dir="row"
            alignItems="center"
            borderColor="gray.200"
            borderRadius="0.25rem"
            borderWidth="1px"
            paddingX={4}
            paddingY={1}
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" spacing={0}>
              <Icon
                as={AttachmentIcon}
                boxSize={6}
                marginRight={4}
                textAlign="center"
                fill="gray.800"
              />
              <Stack direction="column" alignItems="left" spacing="0.2rem">
                <Text
                  color="gray.800"
                  fontSize="small"
                  fontWeight="bold"
                  listStyleType="none"
                >
                  {content.name}
                </Text>
                <Text color="gray.400" fontSize="small">
                  {Math.round(content.size / 1e3).toLocaleString()} kB
                </Text>
              </Stack>
            </Stack>
            <IconButton
              aria-label="Delete downloaded image"
              size="xs"
              icon={<CloseIcon />}
              variant="noBackground"
              justifySelf="end"
              onClick={handleDelete}
              title="Delete"
            />
          </Flex>
        )}
      </aside>
    </Box>
  );
};

import { extendTheme } from "@chakra-ui/react";

import Button from "./theme/Button";
import Checkbox from "./theme/Checkbox";
import Tabs from "./theme/Tabs";
import Table from "./theme/Table";
import Select from "./theme/Select";
import Input from "./theme/input";
import Menu from "./theme/Menu";

/*arc styles*/
export const theme = extendTheme({
  colors: {
    gray: {
      0: "#FAFAFA",
      50: "#EFEFEF",
      100: "#DBDBDB",
      200: "#BEBEBE",
      300: "#AAAAAA",
      400: "#9B9B9B",
      500: "#767676",
      600: "#575757",
      700: "#424242",
      800: "#353535",
      900: "#1C1C1C",
    },
    blue: {
      0: "#DBF2FF",
      50: "#C3EAFE",
      100: "#96DCF9",
      200: "#6CC9F6",
      300: "#43B3EA",
      400: "#1E9BD7",
      500: "#197CBE",
      600: "#005AAA",
      700: "#16477F",
      800: "#15335E",
      900: "#0E223F",
    },
    green: {
      0: "E4FDE3",
      50: "#CCFBC9",
      100: "#A3EB9F",
      200: "#85DB80",
      300: "#6BD15F",
      400: "#4BA046",
      500: "#4A7F45",
      600: "#3E5B3C",
      700: "#3D4E3B",
      800: "#2B372A",
      900: "#16291F",
    },
    orange: {
      0: "#FFEDDB",
      50: "#FFE3C7",
      100: "#FFCC99",
      200: "#FEB377",
      300: "#F59148",
      400: "#E66E23",
      500: "#CC4A0D",
      600: "#AD2E01",
      700: "#8B1E00",
      800: "#691300",
      900: "#100E00",
    },
    pink: {
      0: "#FFF0F9",
      50: "#FBD5EC",
      100: "#F6BEE2",
      200: "#ED90CB",
      300: "#DE63B1",
      400: "#C83C96",
      500: "#B31B79",
      600: "#99075E",
      700: "#7C044A",
      800: "#5F0136",
      900: "#4B012B",
    },
    purple: {
      0: "#F9E9FC",
      50: "#F0DBF3",
      100: "#E3B2EE",
      200: "#CD9CE3",
      300: "#A76BC7",
      400: "#7D4196",
      500: "#622B82",
      600: "#4F1E75",
      700: "#3F166E",
      800: "#2E1259",
      900: "#1F0C3B",
    },
    red: {
      0: "#FFEFEF",
      50: "#FFD9D9",
      100: "#FFB3B3",
      200: "#FA777C",
      300: "#F2454D",
      400: "#E61E28",
      500: "#D10911",
      600: "#B60007",
      700: "#940004",
      800: "#6D0003",
      900: "#4A0002",
    },
    teal: {
      0: "#D6FFF5",
      50: "#BFF7EA",
      100: "#9DEDDA",
      200: "#77DCCC",
      300: "#52C4BA",
      400: "#32A4A0",
      500: "#21808B",
      600: "#14586B",
      700: "#104B65",
      800: "#0D3D59",
      900: "#092B3E",
    },
    yellow: {
      0: "#FFF8DB",
      50: "#FFFCA8",
      100: "#FFF780",
      200: "#FFEAAA",
      300: "#FFDF4C",
      400: "#FFD110",
      500: "#FFA900",
      600: "#BD8400",
      700: "#986100",
      800: "#724403",
      900: "#4B2C02",
    },
    white: "#FFFFFF",

    art9: "rgba(60, 183, 178, 1)",
    art8: "rgba(157, 219, 216, 1)",
    art6: "rgba(157, 219, 216, .75)",
    energyRating: {
      a: "#01892F",
      b: "#42A62A",
      c: "#99BE0D",
      d: "#FCE910",
      e: "#F8B334",
      f: "#EA5D0D",
      g: "#E3181A",
    },
  },
  fonts: {
    body: "Arial, sans-serif",
    heading: "Times New Roman",
  },
  fontSizes: {
    xxs: "0.6rem",
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.25rem",
    xl: "1.5rem",
    "2xl": "2.25rem",
    "3xl": "3rem",
    "4xl": "4.5rem",
    "5xl": "5rem",
    "6xl": "5.5rem",
    "7xl": "6rem",
    "8xl": "7rem",
    "9xl": "8rem",
  },
  fontWeights: {
    light: 300,
    normal: 400,
    semibold: 500,
    bold: 700,
  },
  lineHeights: {
    arcDense: 1.4,
    arcNormal: 1.8,
    arcLoose: 2.2,
  },
  letterSpacings: {
    arcDense: "-0.015em",
    arcNormal: "normal",
    arcLoose: "0.075em",
  },
  components: {
    Button,
    Checkbox,
    Tabs,
    Table,
    Select,
    Input,
    Textarea: Select,
    Menu,
    NumberInputField: Input,
  },
});

import { MeasureSystem } from "src/redux/interfaces/IAppPortfolio";

type TMeasureUnits = {
  area: string;
  energyIntensity: string;
  energyIntensityYearly: string;
  energyYearly: string;
  energyYearlyx1000: string;
  ghgIntensity: string;
  ghgIntensityYearly: string;
  ghgYearly: string;
  weight: string;
  weightx1000: string;
  weightd20?: string;
  weightd2000?: string;
};

export enum Units {
  area = "area",
  energyIntensity = "energyIntensity",
  energyIntensityYearly = "energyIntensityYearly",
  energyYearly = "energyYearly",
  energyYearlyx1000 = "energyYearlyx1000",
  ghgIntensityYearly = "ghgIntensityYearly",
  ghgYearly = "ghgYearly",
  weight = "weight",
  weightx1000 = "weightx1000",
  weightd20 = "weightd20",
  weightd2000 = "weightd2000",
}

const measureUnits: { [key: string]: TMeasureUnits } = {
  [MeasureSystem.international]: {
    area: "m²",
    energyIntensity: "kWh/m²",
    energyIntensityYearly: "kWh/m²/yr",
    energyYearly: "kWh/yr",
    energyYearlyx1000: "MWh/yr",
    ghgIntensity: "{weight}CO₂e/m²",
    ghgIntensityYearly: "{weight}CO₂e/m²/yr",
    ghgYearly: "{weight}CO₂e/yr",
    weight: "kg",
    weightx1000: "t",
  },
  [MeasureSystem.us]: {
    area: "sqft",
    energyIntensity: "Kbtu/sqft",
    energyIntensityYearly: "Kbtu/sqft/yr",
    energyYearly: "Kbtu/yr",
    energyYearlyx1000: "Mbtu/yr",
    ghgIntensity: "{weight}CO₂e/sqft",
    ghgIntensityYearly: "{weight}CO₂e/sqft/yr",
    ghgYearly: "{weight}CO₂e/yr",
    weight: "ton",
    weightx1000: "kton",
    weightd20: "cwt",
    weightd2000: "lbs",
  },
};

// TODO: make it come from backend.
export const getUnits = (
  unitType: Units,
  measureSystem?: MeasureSystem,
): string => {
  return (
    measureUnits[measureSystem || MeasureSystem.international][unitType] || ""
  );
};

export const getNonScaledUnits = (
  unitType: Units,
  measureSystem?: MeasureSystem,
): string => {
  return getUnits(unitType, measureSystem).replace(
    "{weight}",
    getUnits(Units.weight, measureSystem),
  );
};

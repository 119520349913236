import React from "react";
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { ICompany, IFeatureConfig } from "src/api/open-api";
import { IAppCompanyUser } from "src/redux/interfaces/IAppCompanyUser";
import CompanyUserList from "./CompanyUserList";
import { AccessSettings } from "./AccessSettings/AccessSettings";
import { CompanyConfig } from "./CompanyConfig";

interface IProps {
  company: ICompany;
  users: IAppCompanyUser[];
  isAppAdmin?: boolean;
  featureConfig: IFeatureConfig[];
}

export const CompanyContent: React.FC<IProps> = ({
  company,
  users,
  isAppAdmin,
  featureConfig,
}) => {
  return (
    <Box
      bg="white"
      w="100%"
      height="100%"
      borderRadius=".5rem"
      pt="1rem"
      pl="2rem"
      pr="2rem"
      pb="2rem"
    >
      <Tabs colorScheme="red">
        <TabList>
          <Tab>Users</Tab>
          <Tab>Access settings</Tab>
          {isAppAdmin ? <Tab>Company config</Tab> : null}
        </TabList>
        <TabPanels>
          <TabPanel>
            <CompanyUserList company={company} users={users} />
          </TabPanel>
          <TabPanel>
            <AccessSettings company={company} users={users} />
          </TabPanel>
          {isAppAdmin ? (
            <TabPanel>
              <CompanyConfig company={company} featureConfig={featureConfig} />
            </TabPanel>
          ) : null}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

import { Flex, Box, Text } from "@chakra-ui/react";
import React from "react";
import { Alert } from "../atoms/Alert";

type TProps = {
  children: React.ReactNode;
  chartAreaContent: React.ReactElement;
  message?: string;
  errorMessage?: string;
  disableStretch?: boolean;
};
export const StandardContent: React.FC<TProps> = ({
  children,
  chartAreaContent,
  message,
  errorMessage,
  disableStretch,
}) => {
  return (
    <Flex
      direction="row"
      justifyContent="stretch"
      alignItems={disableStretch ? "flex-start" : "stretch"}
      mb="16px"
    >
      <Flex
        width="500px"
        direction="row"
        backgroundColor="white"
        p="15px"
        mr="15px"
        minHeight="460px"
        flexGrow="1"
        borderRadius="8px"
      >
        {message || errorMessage ? (
          <Box
            color="gray.500"
            fontSize="0.8em"
            textAlign="center"
            mt="1.5rem"
            width="100%"
          >
            {message ? (
              <Text>{message}</Text>
            ) : (
              <Alert message={errorMessage || ""} isError />
            )}
          </Box>
        ) : (
          chartAreaContent
        )}
      </Flex>
      <Flex
        backgroundColor="white"
        minWidth="30%"
        flexGrow="1"
        p="15px"
        maxWidth="350px"
        borderRadius="8px"
      >
        {message || errorMessage ? (
          <Box
            width="100%"
            color="gray.500"
            fontSize="0.8em"
            textAlign="center"
            mt="1.5rem"
          >
            <Text>{message ? message : ""}</Text>
          </Box>
        ) : (
          children
        )}
      </Flex>
    </Flex>
  );
};

import { utils, WorkSheet } from "xlsx";
import ValidationError from "src/common/utils/ValidationError";
import {
  AssetPayload,
  AssetPayloadAsTyEnum,
  AssetPayloadEpcLabelEnum,
} from "src/api/open-api";
import { getAssetCoordinates } from "./gps";
import { cleanFlatObject } from "src/common/utils/cleaners";
import { validateExcelAsset } from "src/api/typeguards";
import { IExcelAsset } from "src/api/typeguards/generated/excelTypes/IExcelAsset";

const englishToBoolean = (value?: string) => (value === "Yes" ? true : false);
const SubstantialContributionMapper = new Map([
  ["Climate Change Mitigation", "ccm"],
  ["Climate Change Adaptation", "cca"],
  ["Transition to Circular Economy", "tce"],
  ["Water Resource Management", "wrm"],
  ["Pollution Prevention and Control", "ppc"],
  ["Biodiversity and Ecosystems", "bde"],
]);
export type TaxonomyCategory = "ccm" | "cca" | "tce" | "wrm" | "ppc" | "bde";

const getTaxonomyDict = (asset: IExcelAsset) => {
  let taxonomy: Partial<AssetPayload> = {
    tax_sc: SubstantialContributionMapper.get(asset.TAX_SC as TaxonomyCategory),
    tax_sc_compl: englishToBoolean(asset.TAX_SC_COMPL),
  };
  if (taxonomy.tax_sc === "ccm") {
    taxonomy = {
      ...taxonomy,
      tax_dsh_ccm_cca1: englishToBoolean(asset.TAX_DSH_CCM_CCA1),
      tax_dsh_ccm_cca2: englishToBoolean(asset.TAX_DSH_CCM_CCA2),
    };
  } else if (taxonomy.tax_sc === "cca") {
    taxonomy = {
      ...taxonomy,
      tax_dsh_cca_ccm1: englishToBoolean(asset.TAX_DSH_CCA_CCM1),
      tax_dsh_cca_ccm2: englishToBoolean(asset.TAX_DSH_CCA_CCM2),
    };
  } else if (taxonomy.tax_sc === "bde") {
    taxonomy = {
      ...taxonomy,
      tax_dsh_bde_cca1: asset.TAX_DSH_BDE_CCA1 === "Yes" ? true : false,
      tax_dsh_bde_cca2: asset.TAX_DSH_BDE_CCA2 === "Yes" ? true : false,
    };
  }

  return taxonomy;
};

const asBoolean = (value?: string) => (value === "Yes" ? true : false);

const assetError = new ValidationError(
  "There is an issue with the file format you have uploaded, it may be outdated or corrupt. Please download the template file again and populate with the Portfolio data.",
);

const parseAssets = async (sheet: WorkSheet) => {
  const dataJson: IExcelAsset[] = utils.sheet_to_json(sheet, {
    range: 6,
  });
  const filteredDataJson = dataJson.filter(
    (a) =>
      typeof a === "object" && "NAME" in a && a?.NAME !== "x" && a?.NAME !== "",
  );
  if (!filteredDataJson?.length) {
    throw assetError;
  }

  const assets: AssetPayload[] = [];
  for (let i = 0; i < filteredDataJson?.length; i++) {
    const item = filteredDataJson[i];

    // prevent csv exploit
    const cleanValues = cleanFlatObject<typeof item>(item);

    // Type validation. If this validation fails, it means that wrong file was used
    const initialValidationResult = validateExcelAsset(cleanValues);
    if (!initialValidationResult.success || !cleanValues) {
      console.log("Validation failed", initialValidationResult, cleanValues);
      throw assetError;
    }

    const taxonomy = getTaxonomyDict(cleanValues);
    const newItem: AssetPayload = {
      // General Information
      name: cleanValues.NAME,
      as_yr: cleanValues.AS_YR ?? 0,
      build_yr: cleanValues.BUILD_YR,
      gav: cleanValues.GAV,

      // Building Characteristics
      country: cleanValues.COUN ?? "",
      city: cleanValues.CITY,
      zip: "ZIP" in cleanValues ? String(cleanValues.ZIP) : "None",
      address: cleanValues.Address,
      lb: "LB" in cleanValues ? asBoolean(cleanValues.LB) : false,
      as_ty: Object.values(AssetPayloadAsTyEnum).includes(
        cleanValues.AS_TY as AssetPayloadAsTyEnum,
      )
        ? (cleanValues.AS_TY as AssetPayloadAsTyEnum)
        : "Unknown",
      ac: "AC" in cleanValues ? asBoolean(cleanValues.AC) : false,
      ty_res: cleanValues.TY_RES ?? 0,
      ty_rem: cleanValues.TY_REM ?? 0,
      ty_off: cleanValues.TY_OFF ?? 0,
      ty_rhs: cleanValues.TY_RHS ?? 0,
      ty_rsm: cleanValues.TY_RSM ?? 0,
      ty_rwb: cleanValues.TY_RWB ?? 0,
      ty_hot: cleanValues.TY_HOT ?? 0,
      ty_hec: cleanValues.TY_HEC ?? 0,
      ty_lei: cleanValues.TY_LEI ?? 0,
      ty_dat: cleanValues.TY_DAT ?? 0,
      ty_pdn: cleanValues.TY_PDN ?? 0,
      ty_dwc: cleanValues.TY_DWC ?? 0,
      ty_dww: cleanValues.TY_DWW ?? 0,
      climate: "CLIMATE" in cleanValues ? cleanValues.CLIMATE : undefined,
      gresb: "GRESB" in cleanValues ? cleanValues.GRESB : undefined,

      fc_age: "FC_AGE" in cleanValues ? cleanValues.FC_AGE : undefined,
      mj_renov: "MJ_RENOV" in cleanValues ? cleanValues.MJ_RENOV : 0,
      to_fl: cleanValues.TO_FL ?? 0,

      // Energy Consumption
      el_grid: "EL_GRID" in cleanValues ? cleanValues.EL_GRID : 0,
      ng_con: cleanValues.NG_CON ?? 0,
      ol_con: cleanValues.OL_CON ?? 0,
      dh_con: cleanValues.DH_CON ?? 0,
      dc_con: cleanValues.DC_CON ?? 0,
      ot1_ty: cleanValues.OT1_TY || "None",
      ot1_con: "OT1_CON" in cleanValues ? cleanValues.OT1_CON : 0,

      // Fugitive Emissions
      ghg_leak1_type:
        "GHG_Leak1_Type" in cleanValues ? cleanValues.GHG_Leak1_Type : "None",
      ghg_leak1_amount:
        "GHG_Leak1_Amount" in cleanValues ? cleanValues.GHG_Leak1_Amount : 0,
      ghg_leak2_type:
        "GHG_Leak2_Type" in cleanValues ? cleanValues.GHG_Leak2_Type : "None",
      ghg_leak2_amount:
        "GHG_Leak2_Amount" in cleanValues ? cleanValues.GHG_Leak2_Amount : 0,

      // Renewable Energy
      // On-site renewable electricity (PV, wind)
      rnw_el_con: cleanValues.RNW_EL_CON ?? 0,
      rnw_el_exp: cleanValues.RNW_EL_EXP ?? 0,
      // Other on-site renewable energy source (heatpump, solar thermal)
      rnw_ot_con: cleanValues.RNW_OT_CON ?? 0,
      rnw_ot_exp: cleanValues.RNW_OT_EXP ?? 0,

      // Energy Efficiency
      epc_label:
        cleanValues.EPC_Label?.toUpperCase() as AssetPayloadEpcLabelEnum,
      epc_intensity: cleanValues.EPC_Intensity,
      epc_performance:
        cleanValues.EPC_Performance?.toUpperCase() as AssetPayloadEpcLabelEnum,
      nabers_rating: cleanValues.NABERS_Rating,

      // Certifications
      leed: cleanValues.LEED?.toLowerCase(),
      well: cleanValues.WELL?.toLowerCase(),
      dgnb: cleanValues.DGNB?.toLowerCase(),

      // Other
      dqua: "DQUA" in cleanValues ? cleanValues.DQUA : 100,

      ...taxonomy,
    };

    const gpsCoordinates =
      cleanValues.Address &&
      (await getAssetCoordinates(cleanValues.Address, cleanValues.CITY));

    assets.push({
      ...newItem,
      gps_coordinates: gpsCoordinates
        ? JSON.stringify([gpsCoordinates.lon, gpsCoordinates.lat])
        : undefined,
    } as AssetPayload);
  }
  return assets;
};

export default parseAssets;

import React from "react";
import {
  Flex,
  Text,
  Table,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { roundMonetary } from "src/common/utils/math";
import { ICostScenarioResults } from "src/redux/slices/portfolios/scenarios/selectors";
import { currencyCodeToSymbol } from "src/common/utils/units";

type IProps = {
  scenarios: ICostScenarioResults[];
  isoCurrencyCode?: string;
  baseline: {
    capexTotal: number;
    opexTotal: number;
  };
  selectedScenarioName: string;
  onSelectScenario: (scenarioName: string) => void;
};
const transformCost = (value: number) =>
  roundMonetary(value).toLocaleString("en-US");

export const CostTable: React.FC<IProps> = ({
  baseline,
  scenarios,
  selectedScenarioName,
  onSelectScenario,
  isoCurrencyCode,
}) => {
  // to those who wonder wth is happening in the next line: when we were doing Scenario functionality
  // domain confirmed they want baseline Capex to be capex EOL of scenario (never specified of which one)
  const baselineCapex = (scenarios && scenarios[0]?.capexEolTotal) || 0;

  const currency = currencyCodeToSymbol(isoCurrencyCode);
  return (
    <>
      <Flex backgroundColor="gray.100" p="0.5em" mt="2em" mb="0.5em">
        <Text as="h4" color="black.200" fontSize="0.8em" fontWeight="bold">
          Cumulative Cost Net Current Year - 2050
        </Text>
      </Flex>

      <RadioGroup
        onChange={onSelectScenario}
        value={selectedScenarioName}
        colorScheme="red"
      >
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th></Th>
              <Th></Th>
              <Th textColor="black.200">CAPEX</Th>
              <Th textColor="black.200">OPEX</Th>
              <Th textColor="black.200">TOTAL</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td></Td>
              <Td fontSize="small">Baseline</Td>
              <Td fontSize="small" whiteSpace="nowrap">
                {transformCost(baselineCapex)} {currency}
              </Td>
              <Td fontSize="small">
                {transformCost(baseline.opexTotal)} {currency}
              </Td>
              <Td>
                {transformCost(baselineCapex + baseline.opexTotal)} {currency}
              </Td>
            </Tr>
            {scenarios.map((scenario) => {
              return (
                <Tr key={scenario.scenarioName}>
                  <Td>
                    {scenarios?.length > 1 ? (
                      <Radio value={scenario.scenarioName} />
                    ) : null}
                  </Td>
                  <Td fontSize="small">
                    {scenario.scenarioName}
                    <br />
                    {scenario.scenarioName} Δ
                  </Td>
                  <Td fontSize="small" whiteSpace="nowrap">
                    {transformCost(scenario.capexCostTotal)} {currency}
                    <br />
                    {transformCost(scenario.capexDecarbTotal)} {currency}
                  </Td>
                  <Td fontSize="small">
                    {transformCost(scenario.opexTotal)} {currency}
                    <br />
                    {transformCost(
                      scenario.opexTotal - baseline.opexTotal,
                    )}{" "}
                    {currency}
                  </Td>
                  <Td>
                    {transformCost(
                      scenario.capexCostTotal + scenario.opexTotal,
                    )}{" "}
                    {currency}
                    <br />
                    {transformCost(
                      scenario.capexDecarbTotal +
                        (scenario.opexTotal - baseline.opexTotal),
                    )}{" "}
                    {currency}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </RadioGroup>
    </>
  );
};

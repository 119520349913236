import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorReporter } from "src/common/utils/errorReporter/errorReporter";
import { mockApiAssets } from "./__mocks__/assets";
import { appAssetTransform } from "./appAssetTransform";
import { PortfolioApi, AssetApi, AssetPayload } from "src/api/open-api";
import { defaultApiConfiguration } from "src/api/configurations";
import { USE_LOCAL_MOCKS } from "src/config/mocks";

export const fetchAssets = createAsyncThunk(
  "assets/fetch",
  async (portfolioId: number, { rejectWithValue }) => {
    if (USE_LOCAL_MOCKS) {
      return mockApiAssets.map((a) => appAssetTransform(a, true));
    }
    const api = new PortfolioApi(defaultApiConfiguration);
    let response;
    try {
      response = await api.getAssets(portfolioId);
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
    try {
      const allAssetsHaveValue = !response.data.find((a) => !a.value);
      return response.data
        .map((a) => appAssetTransform(a, allAssetsHaveValue))
        .sort((a, b) =>
          a.assetLabel.toLowerCase().localeCompare(b.assetLabel.toLowerCase()),
        );
    } catch (_error) {
      errorReporter({
        activeUrl: window?.location?.pathname,
        stack: JSON.stringify(_error),
        context: "thunks/appAssetTransform",
      });
      return rejectWithValue({ errorCode: "UI_DATA_IS_BROCKEN" });
    }
  },
);

export const getAssetImage = createAsyncThunk(
  "assets/getImage",
  async (assetId: number, { rejectWithValue }) => {
    const api = new AssetApi(defaultApiConfiguration);
    try {
      const response = await api.getAssetImage(assetId);
      return { assetId, imageUrl: response.data };
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

export const getAssetReport = createAsyncThunk(
  "assets/getReportURL",
  async (assetId: number, { rejectWithValue }) => {
    const api = new AssetApi(defaultApiConfiguration);
    try {
      const response = await api.getAssetReport(assetId);
      return { report_URL: response.data };
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

export const saveAssets = async (payload: {
  portfolioId: number;
  assets: AssetPayload[];
}) => {
  const api = new PortfolioApi(defaultApiConfiguration);

  return await api.putAssets(payload.portfolioId, payload.assets);
};

export const saveAssetImage = createAsyncThunk(
  "assets/saveImage",
  async (
    {
      portfolioId,
      assetId,
      image,
    }: {
      portfolioId: number;
      assetId: number;
      image: File;
    },
    { rejectWithValue },
  ) => {
    const api = new AssetApi(defaultApiConfiguration);
    try {
      const response = await api.putAssetImage(portfolioId, assetId, image);
      return { assetId, imageUrl: response.data };
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

import { createSlice } from "@reduxjs/toolkit";

import { ICountry } from "src/api/open-api";
import { fetchCountries } from "src/redux/thunks/countries";
import { countryAdapter } from "./adapter";
import {
  Ii18nError,
  apiErrorToI18nError,
} from "src/redux/interfaces/Ii18nError";

const slice = createSlice({
  name: "countries",
  initialState: {
    ...countryAdapter.getInitialState(),
    requestStatus: "initial",
    error: null as null | Ii18nError,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountries.pending, (state) => {
      state.requestStatus = "pending";
      state.error = null;
      countryAdapter.removeAll(state);
    });
    builder.addCase(fetchCountries.rejected, (state, action) => {
      state.requestStatus = "rejected";
      state.error = action.payload
        ? apiErrorToI18nError(action.payload)
        : { errorCode: "GENERIC" };
    });
    builder.addCase(fetchCountries.fulfilled, (state, { payload }) => {
      countryAdapter.setMany(state, payload as ICountry[]);
      state.requestStatus = "fulfilled";
    });
  },
});

export default slice;

import { Configuration } from "src/api/open-api";
import getConfig from "./";

//TODO: setup it depending on env variables, test out access token part (seems to work automatically, need to check)
//console.log("Config", getConfig);
export const defaultApiConfiguration = new Configuration({
  basePath: getConfig.API,
});

// export interface IConfig {
//   API: string;
//   AWS_REGION: string;
//   COGNITO_DOMAIN: string;
//   COGNITO_POOL_ID: string;
//   COGNITO_CLIENT_ID: string;
//   COGNITO_AZURE_PROVIDER_ID: string;
//   CYPRESS_TEST_USER_EMAIL: string;
//   CYPRESS_TEST_USER_PASSWORD?: string;
//   CYPRESS_BASE_URL: string;
// }

// export const defaultApiConfiguration: IConfig = {
//   API: process.env.API || "https://api.dev.crisp.arup.com",
//   AWS_REGION: process.env.AWS_REGION || "us-west-2",
//   COGNITO_DOMAIN:
//     process.env.COGNITO_DOMAIN || "crispauth.auth.us-west-2.amazoncognito.com",
//   COGNITO_POOL_ID: process.env.COGNITO_POOL_ID || "us-west-2_2W1gWlYVB",
//   COGNITO_CLIENT_ID:
//     process.env.COGNITO_CLIENT_ID || "2t04e8aaoogqk9hr5oae8k3kn7",
//   COGNITO_AZURE_PROVIDER_ID: process.env.COGNITO_CLIENT_ID || "AzureAD",
//   CYPRESS_TEST_USER_EMAIL:
//     process.env.CYPRESS_TEST_USER_EMAIL ||
//     "e2eTestUserDemo@aruptestaccount.com",
//   CYPRESS_TEST_USER_PASSWORD: process.env.CYPRESS_TEST_USER_PASSWORD || "",
//   CYPRESS_BASE_URL: process.env.CYPRESS_BASE_URL || "http://localhost:3000",
// };

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AddAdminUserPermissionsRequestInner,
  AddUserPermissionsRequestInner,
  AdminApi,
  IAdminPermission,
  UpsertCrispUserRequest,
} from "src/api/open-api";
import { defaultApiConfiguration } from "src/api/configurations";
import { USE_LOCAL_MOCKS } from "src/config/mocks";
import { usersListMock } from "./__mocks__/userListMock";
import { adminPermissionsMock } from "./__mocks__/adminPermissionsMock";
import { IAppRole } from "src/redux/interfaces/IAppRole";
import { companyLevelPermissions } from "./__mocks__/companyLevelPermissions";
import { hardcodedEntityTypes } from "src/constants/accessEntityTypes";
import {
  hardcodedAppRoles,
  hardcodedPermissionTypes,
} from "src/constants/accessRolesAndPermissions";

/**
 * App users management
 */

export const fetchAllUsers = createAsyncThunk(
  "users/fetch",
  async (_, { rejectWithValue }) => {
    const api = new AdminApi(defaultApiConfiguration);
    if (USE_LOCAL_MOCKS) return usersListMock;
    const response = await api.getAllCrispUsers();
    try {
      return response.data.sort((a, b) =>
        a.user_name.localeCompare(b.user_name),
      );
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

export const fetchCompanyUsers = createAsyncThunk(
  "usersByCompany/fetch",
  async (companyId: number, { rejectWithValue }) => {
    if (USE_LOCAL_MOCKS) {
      return usersListMock;
    }
    const api = new AdminApi(defaultApiConfiguration);
    try {
      const response = await api.getCrispUsersByCompanyId(companyId);
      return response.data.sort((a, b) =>
        a.user_name.localeCompare(b.user_name),
      );
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

export const fetchCrispUserByEmail = createAsyncThunk(
  "usersByEmail/fetch",
  async (userEmail: string, { rejectWithValue }) => {
    const api = new AdminApi(defaultApiConfiguration);
    try {
      const response = await api.getCrispUserByEmail(userEmail);
      return response.data;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

type TProps = {
  user: UpsertCrispUserRequest;
  adminRole?: number;
  companyPermissions?: Omit<AddUserPermissionsRequestInner, "user_id">[];
};
export const addUser = createAsyncThunk(
  "users/add",
  async (
    { user, companyPermissions, adminRole }: TProps,
    { rejectWithValue },
  ) => {
    const api = new AdminApi(defaultApiConfiguration);
    try {
      const response = await api.upsertCrispUser(user);
      if (response.data) {
        if (adminRole !== undefined) {
          await api.addAdminUserPermissions([
            { user_id: response.data.user_id, admin_type_id: adminRole },
          ]);
        }
        if (companyPermissions?.length) {
          await api.addUserPermissions(
            companyPermissions.map((p) => ({
              ...p,
              user_id: response.data.user_id,
            })),
          );
        }
      }
      return response.data;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

type TAddCompanyUserProps = {
  user: UpsertCrispUserRequest;
  companyId: number;
};
export const addCompanyUser = createAsyncThunk(
  "users/add-company-user",
  async ({ user, companyId }: TAddCompanyUserProps, { rejectWithValue }) => {
    const api = new AdminApi(defaultApiConfiguration);
    try {
      const response = await api.upsertCrispUser(user);
      if (response.data) {
        await api.addUserPermissions([
          {
            user_id: response.data.user_id,
            entity_type_id: Number(hardcodedEntityTypes.company),
            entity_id: companyId,
            permission_type_id: hardcodedPermissionTypes.read,
          },
        ]);
      }
      return response.data;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

type TAddUserToCompanyProps = {
  userId: number;
  companyId: number;
};
export const addUserToCompany = createAsyncThunk(
  "users/add-company-user",
  async (
    { userId, companyId }: TAddUserToCompanyProps,
    { rejectWithValue },
  ) => {
    const api = new AdminApi(defaultApiConfiguration);
    try {
      const response = await api.addUserPermissions([
        {
          user_id: userId,
          entity_type_id: Number(hardcodedEntityTypes.company),
          entity_id: companyId,
          permission_type_id: hardcodedPermissionTypes.read,
        },
      ]);
      return response.data;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

export const editUser = createAsyncThunk(
  "users/update",
  async (user: UpsertCrispUserRequest, { rejectWithValue }) => {
    const api = new AdminApi(defaultApiConfiguration);
    try {
      await api.upsertCrispUser(user);
      return user;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);
export const deleteUser = createAsyncThunk(
  "users/delete",
  async (userId: number, { rejectWithValue }) => {
    const api = new AdminApi(defaultApiConfiguration);
    try {
      await api.deleteUser(userId);
      return userId;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);
export const activateUser = createAsyncThunk(
  "users/activate",
  async (userId: number, { rejectWithValue }) => {
    const api = new AdminApi(defaultApiConfiguration);
    try {
      await api.activateCrispUser(userId, { is_active: true });
      return userId;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

/**
 * Permissions
 */

export const fetchCompanyPermissions = createAsyncThunk(
  "companyPermissions/fetch",
  async (companyId: number, { rejectWithValue }) => {
    const api = new AdminApi(defaultApiConfiguration);
    try {
      const response = await api.getPermissionsByCompanyId(companyId);
      return response.data;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

export const fetchCompanyPermissionsByUserId = createAsyncThunk(
  "companyPermissionsByUserId/fetch",
  async (userId: number, { rejectWithValue }) => {
    if (USE_LOCAL_MOCKS) return companyLevelPermissions;
    const api = new AdminApi(defaultApiConfiguration);
    try {
      const response = await api.getPermissionsByUserId(userId);
      return response.data;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

export const addCompanyPermissions = createAsyncThunk(
  "companyPermissions/add",
  async (
    permisisons: AddUserPermissionsRequestInner[],
    { rejectWithValue },
  ) => {
    const api = new AdminApi(defaultApiConfiguration);
    try {
      const response = await api.addUserPermissions(permisisons);
      return response.data;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

export const deleteCompanyPermissions = createAsyncThunk(
  "companyPermissions/delete",
  async (permissionIds: number[], { rejectWithValue }) => {
    const api = new AdminApi(defaultApiConfiguration);
    try {
      await api.deleteUserPermissions(permissionIds);
      return permissionIds;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

/**
 * Admin roles
 */
const _transformRole = (role: IAdminPermission): IAppRole => ({
  ...role,
  admin_type_name:
    role.admin_type_id === hardcodedAppRoles.system
      ? "System Admin"
      : "App Admin",
});
export const fetchUserAdminRoles = createAsyncThunk(
  "adminPermissions/fetch",
  async (userId: number, { rejectWithValue }) => {
    if (USE_LOCAL_MOCKS) return adminPermissionsMock.map(_transformRole);
    const api = new AdminApi(defaultApiConfiguration);
    let response;
    try {
      response = await api.getAdminPermissionsByUserId(userId);
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
    try {
      return response.data?.map(_transformRole);
    } catch (error) {
      return rejectWithValue({ errorCode: "UI_DATA_IS_BROCKEN" });
    }
  },
);

export const addAdminRoles = createAsyncThunk(
  "adminPermissions/add",
  async (
    permisisons: AddAdminUserPermissionsRequestInner[],
    { rejectWithValue },
  ) => {
    const api = new AdminApi(defaultApiConfiguration);
    try {
      const response = await api.addAdminUserPermissions(permisisons);
      return response.data;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

export const deleteAdminRoles = createAsyncThunk(
  "adminPermissions/delete",
  async (permissionIds: number[], { rejectWithValue }) => {
    const api = new AdminApi(defaultApiConfiguration);
    try {
      await api.deleteAdminPermissions(permissionIds);
      return permissionIds;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

export const startRecalc = createAsyncThunk(
  "recalc/start",
  async (_, { rejectWithValue }) => {
    const api = new AdminApi(defaultApiConfiguration);
    try {
      await api.recalculate();
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

export const fetchRecalcLogs = createAsyncThunk(
  "recalc/fetch",
  async (_, { rejectWithValue }) => {
    const api = new AdminApi(defaultApiConfiguration);
    try {
      const results = await api.recalculateLogs();
      return results?.data;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

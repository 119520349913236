import React from "react";
import { Text } from "@chakra-ui/react";

interface IProps {
  title: string;
  content?: string | number;
}

export const AssetSidebarLine: React.FC<IProps> = ({ title, content }) => {
  return (
    <Text flexWrap="wrap" my="0.5rem" fontSize="sm" color="gray.500">
      {title}:&nbsp;
      <Text as="span" fontWeight="bold" color="gray.900">
        {content || "Not provided"}
      </Text>
    </Text>
  );
};

import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { selectAnatomy } from "@chakra-ui/anatomy";
import {
  baseFieldFocusStyles,
  baseFieldHoverStyles,
  baseStyle,
} from "./FieldBaseStyles";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const hoverSelectStyle = {
  ...baseStyle,
  backgroundColor: "gray.50",
};

const outline = definePartsStyle({
  field: {
    borderColor: "gray.600",
    backgroundColor: "white",
    _disabled: {
      backgroundColor: "gray.100",
      borderColor: "gray.500",
      color: "gray.500",
    },
    _hover: baseFieldHoverStyles,
    _focus: baseFieldFocusStyles,
    _focusVisible: baseFieldFocusStyles,
  },
  listItem: {
    _hover: hoverSelectStyle,
  },
});

const outlineMuted = definePartsStyle({
  field: {
    borderColor: "gray.200",
    borderWidth: "1px",
    backgroundColor: "white",
    _disabled: {
      backgroundColor: "gray.100",
      borderColor: "gray.500",
      color: "gray.500",
    },
    _hover: baseFieldHoverStyles,
    _focus: baseFieldFocusStyles,
    _focusVisible: baseFieldFocusStyles,
  },
  listItem: {
    _hover: hoverSelectStyle,
  },
});

const solid = definePartsStyle({
  field: {
    backgroundColor: "gray.50",
    _disabled: {
      borderColor: "gray.500",
      color: "gray.500",
      backgroundColor: "gray.50",
    },
  },
});

const flushed = definePartsStyle({
  field: {
    backgroundColor: "white",
    _disabled: {
      borderColor: "gray.200",
      color: "gray.600",
    },
  },
  listItem: {
    _hover: hoverSelectStyle,
  },
});

const selectTheme = defineMultiStyleConfig({
  baseStyle: baseStyle,
  variants: {
    outline,
    outlineMuted,
    solid,
    flushed,
  },
});

export default selectTheme;

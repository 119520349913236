import React from "react";
import {
  Accordion,
  Box,
  Button,
  Checkbox,
  useDisclosure,
  Text,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { useAppSelector } from "src/redux/hooks";
import { uploadScenario } from "../utils/uploadScenario";
import { PackageInfo } from "./PackageInfo";

import {
  selectGroupedByPackageScenarios,
  selectScenarios,
  selectSelectedScenarioNames,
} from "src/redux/slices/portfolios/scenarios/selectors";
import { IIntervention } from "src/api/open-api";
import { metricTypes } from "../utils/getInterventionValues";
import FileUploadModal from "src/common/components/organisms/FileUploadModal/FileUploadModal";
import { setSelectedScenarios } from "src/redux/thunks/scenarios";
import { getScenariosFile } from "src/api/files";
import { selectCanIEditOpenedPortfolio } from "src/redux/slices/auth/authInfo/selectors";
import {
  findArchetypeErrorForSelectedAsset,
  selectIsNoArchetypeAvailable,
} from "src/redux/slices/portfolios/archetypes/selectors";
import { Alert } from "src/common/components/atoms/Alert";
import { genErrorMessage } from "src/common/utils/errors";
import { errorReporter } from "src/common/utils/errorReporter/errorReporter";
import { selectOpenedPortfolio } from "src/redux/slices/portfolios/selectors";
import { ArchetypeSummary } from "./ArchetypeSummary";
import { IAppArchetype } from "src/redux/interfaces/IAppArchetype";

export interface IScenarioInfo {
  scenario_name: string;
  packages: {
    package_code: string;
    intervections: IIntervention[];
  };
}

const ScenarioArea: React.FC<{
  asset: IAppAsset;
}> = ({ asset }) => {
  const dispatch = useDispatch();
  const {
    isOpen: isUpdateModalOpen,
    onOpen: openUpdateModal,
    onClose: closeUpdateModal,
  } = useDisclosure();
  const toast = useToast();
  const { t } = useTranslation();
  const allCustomScenarios = useAppSelector(selectScenarios);
  const openedPortfolio = useAppSelector(selectOpenedPortfolio);
  const archetypeError = useAppSelector(findArchetypeErrorForSelectedAsset);
  const isNoArchetype = useAppSelector(selectIsNoArchetypeAvailable);
  const groupedScenarios = useAppSelector(selectGroupedByPackageScenarios);
  const selectedScenarioNames = useAppSelector(selectSelectedScenarioNames);
  const hasEditPermission = useAppSelector(selectCanIEditOpenedPortfolio);

  const handleUpload = (file: File) => {
    return uploadScenario(file, asset.assetId, asset.portfolioId, t)
      .then((errors) => {
        if (errors?.length) {
          return {
            errors: errors,
          };
        }
        toast({
          title: "Scenarios successfully updated",
          description: "All data has been saved. This page will refresh now.",
          status: "success",
          isClosable: true,
          position: "top",
        });
        window.location.reload();
      })
      .catch((err) => {
        if (err?.length) {
          errorReporter({
            activeUrl: window?.location?.pathname,
            stack: JSON.stringify(err),
            context: "handleUpload, attempt to uploadScenario",
          });
          return {
            errors: err as string[],
          };
        } else {
          return {
            errors: [
              `Cannot upload file. Status: ${err?.response?.status}. Message: ${err?.message}`,
            ],
          };
        }
      });
  };

  const handleSelectScenario = (
    scenario: string,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.checked) {
      dispatch(setSelectedScenarios([...selectedScenarioNames, scenario]));
      return;
    }
    dispatch(
      setSelectedScenarios(selectedScenarioNames.filter((s) => s !== scenario)),
    );
  };

  const getLatestFileUrl = () => {
    return getScenariosFile(asset.portfolioId, asset.assetId);
  };

  const isNoScenarios = !groupedScenarios?.length;

  return (
    <Box maxHeight="calc(100vh - 165px)" overflowY="scroll">
      {isNoArchetype ? (
        <Box mb="1em" color="orange.500">
          <Alert message="No Archetype scenario available for this asset" />
        </Box>
      ) : null}
      <Flex justifyContent="space-between" mb="1.25rem" mt="0.5em">
        {hasEditPermission && (
          <Button
            w={{ base: allCustomScenarios?.length ? "48%" : "100%" }}
            variant="outlinePrimary"
            placeSelf={{ base: "center", xs: "inherit" }}
            onClick={openUpdateModal}
          >
            Upload
          </Button>
        )}

        {!!allCustomScenarios?.length && (
          <Button
            w={{ base: hasEditPermission ? "48%" : "100%" }}
            variant="outlinePrimary"
            placeSelf={{ base: "center", xs: "inherit" }}
            onClick={async () => {
              const url = await getLatestFileUrl();
              if (!url) {
                toast({
                  title: "File not found.",
                  description: "We couldn't find this file in our system",
                  status: "error",
                  position: "top",
                });
              } else {
                window.open(url, "_blank");
              }
            }}
          >
            Download
          </Button>
        )}
      </Flex>
      {isNoScenarios ? (
        <Box color="gray.500" fontSize="0.8em" textAlign="center" mb="1.25rem">
          <Text fontWeight="bold">
            No custom scenarios found for this asset.
          </Text>
          {hasEditPermission ? (
            <Text>You can upload scenarios using Upload button</Text>
          ) : (
            <Text>You can ask your admin to add custom scenarios</Text>
          )}
        </Box>
      ) : null}

      {archetypeError ? (
        <Box mt="1.25rem" textAlign="left" color="orange.800">
          <Alert
            message={genErrorMessage(archetypeError, t, "Archetypes error: ")}
          />
        </Box>
      ) : null}
      {!isNoScenarios ? (
        <>
          {groupedScenarios.map((s) => (
            <Box key={s.scenario_name}>
              <Flex backgroundColor="red.500" p="0.5em" mt="1em">
                <Checkbox
                  variant="primary"
                  isChecked={
                    !!selectedScenarioNames.find((n) => n === s.scenario_name)
                  }
                  onChange={(e) => handleSelectScenario(s.scenario_name, e)}
                  mr="0.5em"
                  fontWeight="bold"
                  fontSize="0.8em"
                >
                  <Text color="white" as="span">
                    {s.scenario_name} Scenario
                  </Text>
                </Checkbox>
              </Flex>
              <Accordion defaultIndex={[0]} allowMultiple size="sm">
                {s.scenario_name === "Archetype"
                  ? (s.packages as IAppArchetype["packages"])?.map((p) => (
                      <ArchetypeSummary
                        key={p.package_code}
                        name={p.package_code}
                        interventions={p.interventions}
                        assetArea={asset.area}
                        isoCurrencyCode={openedPortfolio?.currency}
                        measureSystem={openedPortfolio?.measureSystem}
                      />
                    ))
                  : s.packages?.map((p) => (
                      <PackageInfo
                        selectedType={metricTypes.Absolute}
                        key={p.package_code}
                        name={p.package_code}
                        assetArea={asset.area || 1}
                        interventions={p.interventions}
                        measureSystem={openedPortfolio?.measureSystem}
                      />
                    ))}
              </Accordion>
            </Box>
          ))}
        </>
      ) : null}
      <FileUploadModal
        title="Upload Scenarios"
        isOpen={isUpdateModalOpen}
        onClose={closeUpdateModal}
        onUpload={handleUpload}
        templateLink="InterventionsTemplate.xlsx"
        isUpdate={!!allCustomScenarios?.length}
        getLatestFileUrl={getLatestFileUrl}
      />
    </Box>
  );
};
export default ScenarioArea;

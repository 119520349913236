import React, { useMemo } from "react";
import { Scatter } from "react-chartjs-2";
import { Box } from "@chakra-ui/react";
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  ChartData,
  ChartOptions,
} from "chart.js";
import { theme } from "src/config/theme";
import { getChartLine, getChartPoint } from "./chartElements";
import { ITimeSeriesPoint } from "src/api/open-api";
import { ErrorBoundary } from "react-error-boundary";
import { globalMinYear } from "src/constants";
import { IReduxChartTarget } from "src/redux/interfaces/IReduxChartTarget";
import { intersectTimeSeries } from "src/common/utils/pathways";

ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title);

const gray = theme.colors.gray["500"];

interface IProps {
  year: number;
  targets: IReduxChartTarget[];
  forecast: ITimeSeriesPoint[];
  scenarios?: IReduxChartTarget[];
  isSelectable?: boolean;
  selectedLineIndex?: number;
  criticalIssueCallback?: () => void;
  aspectRatio?: number;
}

export const EmissionsChart: React.FC<IProps> = (props) => {
  const {
    year,
    targets,
    forecast,
    scenarios,
    isSelectable,
    selectedLineIndex,
    criticalIssueCallback,
    aspectRatio,
  } = props;

  const maxEmission = useMemo(() => {
    return Math.max(
      ...[...forecast, ...targets.map((t) => t.timeSeries || []).flat()].map(
        (e) => e.value,
      ),
    );
  }, [targets, forecast]);
  const minYear = Math.min(...forecast.map((e) => e.year));
  const maxYear = Math.max(...forecast.map((e) => e.year));
  const top = Array(forecast.length).fill(maxEmission);
  const intersections: { x: number; y: number; name: string }[] =
    targets?.map((t) => ({
      x: t.strandingX || -100, // Chart from current library breaks if we change amount of enitites, so we keep all intersections
      y: t.strandingY || -100,
      name: t.targetName,
    })) || [];

  // If we have additional targets - find intersections.
  if (scenarios?.length) {
    scenarios.forEach((s) => {
      targets?.forEach((t) => {
        const strandingPoint = intersectTimeSeries(s.timeSeries, t.timeSeries);
        intersections.push({
          x: strandingPoint.strandingX || -100,
          y: strandingPoint.strandingY || -100,
          name: t.targetName,
        });
      });
    });
  }

  const topline = {
    data: top,
    borderColor: gray,
    pointHoverRadius: 0,
    pointRadius: 0,
  };

  const data: ChartData<"scatter"> = {
    labels: forecast.map((e) => e.year),
    datasets: [
      getChartPoint({
        label: "Emissions",
        x: year,
        y:
          Math.round(
            (forecast.find((e) => e.year === year)?.value || 0) * 100,
          ) / 100,
        radius: 6,
      }),
      ...intersections.map((intersection) =>
        getChartPoint({
          label: "Stranding point",
          x: intersection.x,
          y: intersection.y,
        }),
      ),
      topline,
      ...targets
        .filter((target) => !!target.timeSeries)
        .map((target, index) =>
          getChartLine({
            name: target.targetName,
            timeSeries: target.timeSeries as ITimeSeriesPoint[],
            width: !isSelectable ? 2 : selectedLineIndex === index ? 3 : 1,
            color: target.lineColor,
          }),
        ),
      ...(scenarios || [])
        .filter((target) => !!target.timeSeries)
        .map((target) =>
          getChartLine({
            name: target.targetName,
            timeSeries: target.timeSeries as ITimeSeriesPoint[],
            width: isSelectable ? 3 : 2,
            color: target.lineColor,
          }),
        ),
      getChartLine({
        name: "Forecast",
        timeSeries: forecast,
        width: isSelectable ? 3 : 2,
        color: gray,
      }),
    ],
  };

  const options: ChartOptions<"scatter"> = {
    scales: {
      x: {
        min: Math.max(minYear, globalMinYear),
        max: maxYear,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 10,
          minRotation: 0,
          maxRotation: 0,
        },
      },
      y: {
        max: Math.ceil(maxEmission / 10) * 10,
        min: 0,
        grid: {
          display: true,
        },
        display: true,
        ticks: {
          // Fixed width for Y-axis numbers
          callback: function (value) {
            // Convert value to string
            const stringValue = value.toString();
            // Define the desired width for all values
            const desiredWidth = 3; // You can adjust this as needed
            if (stringValue.length === 2)
              return stringValue.padStart(
                desiredWidth + stringValue.length - 1,
                " ",
              );
            else {
              return stringValue.padStart(
                desiredWidth + stringValue.length - 2,
                " ",
              );
            }
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        intersect: true,
        callbacks: {
          label: (ctx) => {
            if (ctx.dataset.label === "Emissions") return "";
            if (ctx.dataset.label === "Stranding point")
              return "Stranding event: within " + Math.floor(ctx.parsed.x);
            return (
              ctx.dataset.label +
              ": " +
              ctx.parsed.y.toLocaleString(undefined, {
                maximumFractionDigits: 1,
              })
            );
          },
          title: (ctx) => {
            // this is needed bc stranding point befault label is glitchy
            return String(Math.floor(ctx[ctx.length - 1].parsed.x));
          },
        },
      },
    },
    animation: {
      duration: 0,
    },
    aspectRatio: aspectRatio || 2,
    responsive: true,
    locale: "es",
  };
  return (
    <ErrorBoundary
      fallbackRender={() => {
        criticalIssueCallback && criticalIssueCallback();
        return (
          <Box height="40%">
            Something went really wrong, please contact support
          </Box>
        );
      }}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <Scatter data={data} options={options} width="100%" />
    </ErrorBoundary>
  );
};

import React, { useMemo, useEffect } from "react";
import {
  Box,
  Button,
  VStack,
  Text,
  Select,
  HStack,
  StackDivider,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useState } from "react";
import { ICrispUser } from "src/api/open-api";
import { ICompany } from "src/api/open-api";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { selectAllCompanyAdminPermissions } from "src/redux/slices/admin/permissions/selectors";
import { selectUsersAdminPermissions } from "src/redux/slices/admin/usersAdminRoles/selectors";
import {
  addAdminRoles,
  addCompanyPermissions,
  deleteAdminRoles,
  deleteCompanyPermissions,
  fetchCompanyPermissionsByUserId,
  fetchUserAdminRoles,
} from "src/redux/thunks/admin";
import { hardcodedEntityTypes } from "src/constants/accessEntityTypes";
import { hardcodedPermissionTypes } from "src/constants/accessRolesAndPermissions";

interface IProps {
  user?: ICrispUser;
  companies?: ICompany[];
}

const adminRoles = [
  {
    role_id: 1,
    role_name: "System Admin",
  },
  {
    role_id: 2,
    role_name: "App Admin",
  },
  {
    role_id: 3,
    role_name: "Company Admin",
  },
];

const UserPermissionsTable: React.FC<IProps> = (props: IProps) => {
  const { user, companies } = props;
  const dispatch = useAppDispatch();

  const [selectedRole, setSelectedRole] = useState("");
  const companyAdminPermissions = useAppSelector(
    selectAllCompanyAdminPermissions,
  );
  const appAdminPermissions = useAppSelector(selectUsersAdminPermissions);
  const userCompanyAdminPermissions = companyAdminPermissions.filter(
    (p) => p.user_id === user?.user_id,
  );
  const [selectedEntity, setSelectedEntity] = useState<{
    label: string;
    value: string;
  } | null>();

  useEffect(() => {
    if (user) {
      dispatch(fetchUserAdminRoles(user.user_id));
      dispatch(fetchCompanyPermissionsByUserId(user.user_id));
    }
  }, [user, dispatch]);

  const handleSelectedRole = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRole(e?.target?.value);
  };

  const isAddPermissionEnabled = useMemo(() => {
    if (selectedRole === "1" || selectedRole === "2") return true;
    return selectedRole === "3" && selectedEntity;
  }, [selectedRole, selectedEntity]);

  const addPermission = async () => {
    if (!user) return;

    if (selectedRole === "1" || selectedRole === "2") {
      // if admin role
      await dispatch(
        addAdminRoles([
          { user_id: user.user_id, admin_type_id: Number(selectedRole) },
        ]),
      )
        .then(() => {
          user && dispatch(fetchUserAdminRoles(user.user_id));
        })
        .catch((error) => {
          // TODO: replace with proper error handling
          console.log(error);
        });
    } else {
      await dispatch(
        addCompanyPermissions([
          {
            user_id: user.user_id,
            permission_type_id: hardcodedPermissionTypes.full,
            entity_type_id: Number(hardcodedEntityTypes.company),
            entity_id: Number(selectedEntity?.value),
          },
        ]),
      );
      user && dispatch(fetchCompanyPermissionsByUserId(user.user_id));
    }
  };

  const deleteAdminPermission = async (id: number) => {
    if (id !== undefined) {
      await dispatch(deleteAdminRoles([id]));
      user && dispatch(fetchUserAdminRoles(user.user_id));
    }
  };
  const deleteCompanyPermission = async (id: number) => {
    if (id !== undefined) {
      await dispatch(deleteCompanyPermissions([id]));
      user && dispatch(fetchCompanyPermissionsByUserId(user.user_id));
    }
  };

  if (!user) {
    return <Text>Unexpected error: no user found</Text>;
  }

  return (
    <>
      <Box>
        <Text>
          For user <b>{user.user_name}</b> ({user.user_email})
        </Text>
        <VStack
          mt="1rem"
          mb="2rem"
          padding="1rem"
          border="1px solid #EEEEEE"
          align="stretch"
        >
          <HStack
            divider={<StackDivider borderColor="gray.200" />}
            spacing={4}
            align="stretch"
          >
            <Box w="50%">
              <Text mb="0.5rem">Role</Text>
              <Select
                fontSize="small"
                placeholder="Please select role"
                size="sm"
                width="100%"
                onChange={handleSelectedRole}
              >
                {adminRoles.map((role) => (
                  <option key={role.role_id} value={role.role_id}>
                    {role.role_name}
                  </option>
                ))}
              </Select>
            </Box>
            {selectedRole === "3" && companies?.length && (
              <Box w="50%">
                <Text mb="0.5rem">Company</Text>
                <Select
                  fontSize="small"
                  placeholder="Please select company"
                  size="sm"
                  width="100%"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setSelectedEntity({ value: e?.target?.value, label: "" })
                  }
                >
                  {companies.map((company) => (
                    <option key={company.company_id} value={company.company_id}>
                      {company.company_name}
                    </option>
                  ))}
                </Select>
              </Box>
            )}
          </HStack>
          <Box textAlign="center">
            <Button
              colorScheme="red"
              mt="0.5rem"
              disabled={!isAddPermissionEnabled}
              onClick={addPermission}
            >
              Add
            </Button>
          </Box>
        </VStack>

        {appAdminPermissions?.length || userCompanyAdminPermissions?.length ? (
          <>
            <Table variant="simple" width="100%">
              <Thead>
                <Tr>
                  <Th>Role</Th>
                  <Th>Entity type</Th>
                  <Th>Entity</Th>
                  <Th>Permission level</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {appAdminPermissions?.map((permission) => (
                  <Tr
                    key={permission.admin_permission_id}
                    _hover={{ bg: "white.400" }}
                  >
                    <Td>{permission.admin_type_name}</Td>
                    <Td>App</Td>
                    <Td></Td>
                    <Td>full</Td>
                    <Td w="2rem">
                      <Button
                        w={{ base: "100%" }}
                        variant="outlineGrey"
                        placeSelf={{ base: "center", xs: "inherit" }}
                        mb="0.25rem"
                        onClick={() =>
                          deleteAdminPermission(permission.admin_permission_id)
                        }
                      >
                        Delete
                      </Button>
                    </Td>
                  </Tr>
                ))}
                {userCompanyAdminPermissions.map((permission) => (
                  <Tr
                    key={permission.company_level_permission_id}
                    _hover={{ bg: "white.400" }}
                  >
                    <Td>Company admin</Td>
                    <Td>Company</Td>
                    <Td>{permission.entity_name}</Td>
                    <Td>{permission.permission_type_name}</Td>
                    <Td w="2rem">
                      <Button
                        w={{ base: "100%" }}
                        variant="outlineGrey"
                        placeSelf={{ base: "center", xs: "inherit" }}
                        mb="0.25rem"
                        onClick={() =>
                          deleteCompanyPermission(
                            permission.company_level_permission_id,
                          )
                        }
                      >
                        Delete
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </>
        ) : (
          <>User has no app level permissions yet.</>
        )}
      </Box>
    </>
  );
};

export default UserPermissionsTable;

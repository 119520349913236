export const tabMap = {
  tab_stranding: {
    name: "Stranding",
    to: "stranding",
    // we use 100 + x, bc we want it to be easy to set some tabs be "first" for a company
    default_order: 101,
  },
  tab_economic: {
    name: "Economic",
    to: "economic",
    default_order: 102,
  },
  tab_efficiency: {
    name: "Efficiency",
    to: "inefficientAssets",
    default_order: 103,
  },
  tab_taxonomy: {
    name: "Taxonomy",
    to: "taxonomy",
    default_order: 104,
  },
  tab_sustainability: {
    name: "Sustainability",
    to: "sustainability",
    default_order: 105,
  },
  tab_scenario_planning: {
    name: "Scenario Planning",
    to: "scenario",
    default_order: 106,
  },
  tab_custom_pathways: {
    name: "Custom Pathways",
    to: "customPathways",
    default_order: 107,
  },
};

export const defaultTabList = Object.keys(tabMap) as (keyof typeof tabMap)[];

export const defaultPortfolioCardSetup = {
  portfolio_card_fund_type: true,
  portfolio_card_value: true,
  portfolio_card_taxonomy: true,
  portfolio_card_certifications: true,
  portfolio_card_data_maturity: true,
};

export const allAvailableFeatures = [
  "portfolio_card_fund_type",
  "portfolio_card_value",
  "portfolio_card_taxonomy",
  "portfolio_card_certifications",
  "portfolio_card_data_maturity",
  "portfolio_report_generation",
  "global_value",
  "global_capex",
  "tab_stranding",
  "tab_economic",
  "tab_efficiency",
  "tab_taxonomy",
  "tab_sustainability",
  "tab_scenario_planning",
  "tab_custom_pathways",
  "asset_archetype_mac",
  "asset_report_generation",
];

import React from "react";
import { Box, SimpleGrid, Text, Divider } from "@chakra-ui/react";
import {
  LeedBadge,
  BadgeItem,
  WellBadge,
  DgnbBadge,
} from "src/common/components/atoms/CertificationBadge";
import CertSummary from "./CertSummary";
import {
  IAppAsset,
  IAppAssetCertifications,
} from "src/redux/interfaces/IAppAsset";

import Leed from "src/assets/img/LEED_00.png";
import Well from "src/assets/img/WELL_00.png";
import DGNB from "src/assets/img/DGNB_00.jpg";
import { decimalToPercent } from "src/common/utils/math";

interface IProps {
  assets: IAppAsset[];
}

const CertificationBadges: React.FC<IProps> = (props) => {
  const { assets } = props;
  const getPercentage = (key: keyof IAppAssetCertifications) => {
    return (
      decimalToPercent(
        assets
          .map((m) => key in m.certifications && m.certifications[key])
          .filter((a) => a).length / assets.length,
      ) || 0
    );
  };

  const getLeedNo = (value: string) => {
    return assets.map((a) => a.certifications).filter((a) => a.leed === value)
      .length;
  };
  const getWellNo = (value: string) => {
    return assets.map((a) => a.certifications).filter((a) => a.well === value)
      .length;
  };
  const getDGNBNo = (value: string) => {
    return assets.map((a) => a.certifications).filter((a) => a.dgnb === value)
      .length;
  };

  return (
    <Box w="100%" p="15px" bg="white">
      <Divider />

      {/* Leed Certification */}
      <SimpleGrid columns={{ base: 1, md: 2, lg: 6 }} gap={2} py="0.8rem">
        <CertSummary name={"Leed"} value={getPercentage("leed")} img={Leed} />
        <Text fontSize={{ base: "xxs", lg: "xs" }} ml={2}>
          Rating system with a focus on environmental criteria
        </Text>
        <BadgeItem name={"Leed Certified"} projects={getLeedNo("certified")}>
          <LeedBadge level="certified" />
        </BadgeItem>
        <BadgeItem name={"Leed Silver"} projects={getLeedNo("silver")}>
          <LeedBadge level="silver" />
        </BadgeItem>
        <BadgeItem name={"Leed Gold"} projects={getLeedNo("gold")}>
          <LeedBadge level="gold" />
        </BadgeItem>
        <BadgeItem name={"Leed Platinum"} projects={getLeedNo("platinum")}>
          <LeedBadge level="platinum" />
        </BadgeItem>
      </SimpleGrid>

      <Divider />

      {/* Well Certification */}
      <SimpleGrid columns={{ base: 1, md: 2, lg: 6 }} gap={2} py="0.8rem">
        <CertSummary name={"Well"} value={getPercentage("well")} img={Well} />
        <Text fontSize={{ base: "xxs", lg: "xs" }} ml={2}>
          Certification for advancing health and well-being
        </Text>
        <BadgeItem name={"Well Silver"} projects={getWellNo("silver")}>
          <WellBadge level="silver" />
        </BadgeItem>
        <BadgeItem name={"Well Gold"} projects={getWellNo("gold")}>
          <WellBadge level="gold" />
        </BadgeItem>
        <BadgeItem name={"Well Platinum"} projects={getWellNo("platinum")}>
          <WellBadge level="platinum" />
        </BadgeItem>
      </SimpleGrid>
      <Divider />

      {/* DGNB Certification */}
      <SimpleGrid columns={{ base: 1, md: 2, lg: 6 }} gap={2} py="0.8rem">
        <CertSummary name={"DGNB"} value={getPercentage("dgnb")} img={DGNB} />
        <Text fontSize={{ base: "xxs", lg: "xs" }} ml={2}>
          Standard on environmental, economic, and social performance
        </Text>
        <BadgeItem name={"DGNB Bronze"} projects={getDGNBNo("bronze")}>
          <DgnbBadge level="bronze" />
        </BadgeItem>

        <BadgeItem name={"DGNB Silver"} projects={getDGNBNo("silver")}>
          <DgnbBadge level="silver" />
        </BadgeItem>
        <BadgeItem name={"DGNB Gold"} projects={getDGNBNo("gold")}>
          <DgnbBadge level="gold" />
        </BadgeItem>
        <BadgeItem name={"DGNB Platinum"} projects={getDGNBNo("platinum")}>
          <DgnbBadge level="platinum" />
        </BadgeItem>
      </SimpleGrid>
      <Divider />
    </Box>
  );
};

export default CertificationBadges;

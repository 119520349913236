import { createSlice } from "@reduxjs/toolkit";

import { portfolioAdapter } from "./adapter";
import { fetchPortfolios, deletePortfolio } from "src/redux/thunks/portfolios";
import { IAppPortfolio } from "src/redux/interfaces/IAppPortfolio";
import {
  Ii18nError,
  apiErrorToI18nError,
} from "src/redux/interfaces/Ii18nError";

const slice = createSlice({
  name: "portfolios",
  initialState: {
    ...portfolioAdapter.getInitialState(),
    requestStatus: "initial",
    deletionStatus: "initial",
    deletionError: null as null | Ii18nError,
    isLoading: false,
    error: null as null | Ii18nError,
    deletedIds: [] as number[],
    selectedPortfolioId: null,
  },
  reducers: {
    setPortfolioSelected: (state, { payload }) => {
      if (state.selectedPortfolioId !== payload) {
        state.selectedPortfolioId = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPortfolios.pending, (state) => {
      state.requestStatus = "pending";
      state.deletionStatus = "initial";
      portfolioAdapter.removeAll(state);
      state.error = null;
    });
    builder.addCase(fetchPortfolios.rejected, (state, action) => {
      state.requestStatus = "rejected";
      state.error = action.payload
        ? apiErrorToI18nError(action.payload)
        : { errorCode: "GENERIC" };
    });
    builder.addCase(fetchPortfolios.fulfilled, (state, { payload }) => {
      portfolioAdapter.setMany(state, payload as IAppPortfolio[]);
      state.requestStatus = "fulfilled";
      state.error = null;
      state.deletedIds = [];
    });
    builder.addCase(deletePortfolio.pending, (state) => {
      state.deletionStatus = "pending";
      state.deletionError = null;
    });
    builder.addCase(deletePortfolio.rejected, (state, action) => {
      state.deletionStatus = "rejected";
      state.deletionError = action.payload
        ? apiErrorToI18nError(action.payload)
        : { errorCode: "GENERIC" };
    });
    builder.addCase(deletePortfolio.fulfilled, (state, { payload }) => {
      portfolioAdapter.removeOne(state, payload);
      state.deletedIds = [...state.deletedIds, payload];
      state.deletionStatus = "fulfilled";
      state.deletionError = null;
      state.selectedPortfolioId = null;
    });
  },
});

export const { setPortfolioSelected } = slice.actions;
export default slice;

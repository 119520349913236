import { EntityState, createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { recalcAdapter } from "./adapter";
import { RecalculateLogs200ResponseInner as IRecalcLogItem } from "src/api/open-api";

export const selectSlice = (state: RootState) => state.recalc;

export const selectRecalcLogs = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<IRecalcLogItem>) =>
    recalcAdapter.getSelectors().selectAll(state),
);

export const sselectRecalcStateError = createDraftSafeSelector(
  [selectSlice],
  (state) => state.error,
);

export const selectRecalcIsLoading = createDraftSafeSelector(
  [selectSlice],
  (state) => state.isLoading,
);

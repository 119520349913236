import { IAppAssetCapex } from "src/redux/interfaces/IAppAsset";

type TPartialAssetCapex = {
  capex: IAppAssetCapex;
};
type TPartialAsset = TPartialAssetCapex & {
  area: number;
};
const totalCosts = (assets: TPartialAssetCapex[]) =>
  assets.map((a) => a.capex?.capex_cost_total || 0).reduce((a, b) => a + b, 0);
const eolCosts = (assets: TPartialAssetCapex[]) =>
  assets.map((a) => a.capex?.capex_cost_oel || 0).reduce((a, b) => a + b, 0);
const totalDecarbCosts = (assets: TPartialAssetCapex[]) =>
  assets.map((a) => a.capex?.capex_cost_decarb || 0).reduce((a, b) => a + b, 0);
const totalArea = (assets: { area: number }[]) =>
  assets.map((a) => a.area).reduce((a, b) => a + b, 0);

export const averageTotalCapexCost = (assets: TPartialAsset[]) =>
  Math.round(totalCosts(assets) / totalArea(assets));

export const averageEolDecarbCost = (assets: TPartialAsset[]) =>
  Math.round(eolCosts(assets) / totalArea(assets));

export const averageTotalDecarbCost = (assets: TPartialAsset[]) =>
  Math.round(totalDecarbCosts(assets) / totalArea(assets));

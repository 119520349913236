import { ITargetTimeSeries } from "src/api/open-api";
import {
  energyIntensityTypeId,
  ghgIntensityTypeId,
  hardcodedLineIds,
} from "src/constants/targets";

export const basicTargetTimeSeriesMock: ITargetTimeSeries[] = [
  {
    model_version_id: 1, // never used, can be removed from api spec
    intensity_type_name: "GHG",
    time_series_points: [
      { year: 2020, value: 80 },
      { year: 2030, value: 75 },
      { year: 2050, value: 35 },
    ],
    model_description: hardcodedLineIds.crremCritical.model_description,
    intensity_type_id: ghgIntensityTypeId,
    model_version_name: hardcodedLineIds.crremCritical.model_version_name,
  },
  {
    model_version_id: 1, // never used, can be removed from api spec
    intensity_type_name: "Energy",
    time_series_points: [
      { year: 2020, value: 260 },
      { year: 2030, value: 200 },
      { year: 2050, value: 195 },
    ],
    model_description: hardcodedLineIds.crremWarn.model_description,
    intensity_type_id: energyIntensityTypeId,
    model_version_name: hardcodedLineIds.crremWarn.model_version_name,
  },
  {
    model_version_id: 1, // never used, can be removed from api spec
    intensity_type_name: "Energy",
    time_series_points: [
      { year: 2020, value: 350 },
      { year: 2030, value: 250 },
      { year: 2050, value: 200 },
    ],
    model_description: hardcodedLineIds.crremCritical.model_description,
    intensity_type_id: energyIntensityTypeId,
    model_version_name: hardcodedLineIds.crremCritical.model_version_name,
  },
];

import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Box, Text } from "@chakra-ui/react";
import { theme } from "src/config/theme";

export const MiniDoughnutChart: React.FC<{
  value: number;
}> = ({ value }) => {
  const cutout = 0.68;

  const data = {
    labels: ["", ""],
    datasets: [
      {
        data: [value, 100 - value],
        backgroundColor: ["gray", "lightgray"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: true,
    responsive: true,
    animation: {
      duration: 0,
    },
    layout: {
      autoPadding: false,
      padding: 0,
    },
    title: {
      display: false,
    },
    cutout: `${cutout * 100}%`,
    spacing: 0,
  };

  return (
    <>
      <Box boxSize="1.3rem">
        <Doughnut data={data} options={options} />
      </Box>
      <Text fontSize={"14px"} ml="10px" color={theme.colors.gray["500"]}>
        {value + "%"}
      </Text>
    </>
  );
};

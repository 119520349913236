export const parseGPSCoordinates = (input?: string) => {
  if (!input) return [];
  try {
    const result = JSON.parse(input);
    if (result?.length < 2) {
      throw new Error("Coordinates have unexpected type");
    }
    return result;
  } catch (error) {
    console.log(error);
    return [];
  }
};

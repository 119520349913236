import { createAsyncThunk } from "@reduxjs/toolkit";
import { defaultApiConfiguration } from "src/api/configurations";
import { AuthApi } from "src/api/open-api";
import { USE_LOCAL_MOCKS } from "src/config/mocks";
import { IAppAuthData } from "src/redux/interfaces/IAppAuthData";

const mockUser = {
  userId: 0,
  userName: "John Doe",
  userEmail: "example@email.com",
  permissions: [
    {
      company_level_permission_id: 0,
      user_id: 0,
      entity_type_id: 0,
      entity_id: 0,
      permission_type_id: 0,
      permission_type_name: "full",
    },
  ],
  isAppAdmin: false,
  isSystemAdmin: true,
};

export const fetchAuthInfo = createAsyncThunk<IAppAuthData>(
  "auth/fetch",
  async (_, { rejectWithValue }) => {
    const api = new AuthApi(defaultApiConfiguration);

    if (USE_LOCAL_MOCKS) return mockUser;
    try {
      const response = await api.getMyAuthData();
      const user = response.data;

      return {
        userId: user.user_id,
        userName: user.user_name || user.user_email,
        userEmail: user.user_email,
        permissions: user.permissions || [],
        isSystemAdmin: Boolean(user.is_sys_admin),
        isAppAdmin: Boolean(user.is_app_admin),
      };
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

import React from "react";
import { Box, Table, Thead, Tbody, Tr, Th } from "@chakra-ui/react";
import {
  getAvgEnergyIntensity,
  getAvgIntensityLevelPerformance,
  getCo2ReductionEffortLevelPerformance,
  getNetZeroReadinessLevelPerformance,
  getPortfolioElectrifiedPercentage,
  getReductionEffortTo2050,
} from "./getPerformanceLevel";
import {
  getStrandingReferences,
  getAssetPermormanceThresholds,
} from "./getReference";
import { IThreshold, Performance } from "./references";
import {
  calcAllStranding,
  find15Target,
  mapStradingValueToTableItem,
} from "./getStrandingStatus";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { avg } from "src/common/utils/math";
import { IReduxChartTarget } from "src/redux/interfaces/IReduxChartTarget";
import { ITimeSeriesPoint } from "src/api/open-api";
import { PerformanceTableRow } from "./PerformanceTableRow";
import { Units, getUnits } from "src/constants/measureUnits";
import { MeasureSystem } from "src/redux/interfaces/IAppPortfolio";

interface IProps {
  customStrandingThresholds?: IThreshold;
  assets: IAppAsset[];
  targets: IReduxChartTarget[];
  maxReportingYear: number;
  forecastEnergy: ITimeSeriesPoint[];
  forecastGHG: ITimeSeriesPoint[];
  measureSystem?: MeasureSystem;
}

export const PerformanceTable: React.FC<IProps> = ({
  customStrandingThresholds,
  assets,
  targets,
  maxReportingYear,
  forecastEnergy,
  forecastGHG,
  measureSystem,
}) => {
  if (!assets.length) return <></>;

  const assetsRefData = assets.map((a) => ({
    buildingType: a.primaryBusinessBuildingType,
    locationName: a.location.businessLocationName,
  }));

  const references = assetsRefData.map((a) =>
    getStrandingReferences(a, customStrandingThresholds),
  );
  const threshold = {
    1: avg(references.map((it) => it[Performance.Medium])),
    2: avg(references.map((it) => it[Performance.High])),
  };

  const allStrandingValues =
    calcAllStranding(targets, maxReportingYear, threshold) || [];

  const assetReferences = assetsRefData.map(getAssetPermormanceThresholds);

  const averageIntensity = getAvgEnergyIntensity(forecastEnergy);
  const co2ReductionEffort =
    getReductionEffortTo2050(forecastGHG, find15Target(targets)?.timeSeries) *
    100;
  const netZeroCo2Readiness = getPortfolioElectrifiedPercentage(assets) * 100;

  const performanceItems = allStrandingValues
    .map((it) => it && mapStradingValueToTableItem(it, assets?.length > 1))
    .filter((i) => !!i?.name);

  return (
    <Box fontSize="small" mb="20px">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th colSpan={3}>
              Stranding Risk Overview{" "}
              {maxReportingYear ? `(${maxReportingYear})` : ""}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {performanceItems.map((item, i) => {
            if (!item) return null;
            return (
              <PerformanceTableRow
                key={i}
                name={item.name}
                value={item.value}
                rating={item.rating as 0 | 1 | 2}
                tooltipInfo={item.tooltipInfo}
              />
            );
          })}
          <PerformanceTableRow
            name="Avg. Energy Intensity"
            value={
              isNaN(averageIntensity)
                ? "No data"
                : Math.round(averageIntensity).toLocaleString() +
                  ` ${getUnits(Units.energyIntensity, measureSystem)}`
            }
            rating={getAvgIntensityLevelPerformance(
              assetReferences,
              averageIntensity,
            )}
            tooltipInfo={`Average energy use intensity of the ${
              assets?.length === 1 ? "asset" : "portfolio"
            }`}
          />
          <PerformanceTableRow
            name="CO₂ Reduction Effort to 2050"
            value={`${Math.round(co2ReductionEffort)}%`}
            rating={getCo2ReductionEffortLevelPerformance(
              assetReferences,
              co2ReductionEffort,
            )}
            tooltipInfo="CO2 reduction required to achieve 2050 target"
          />
          <PerformanceTableRow
            name="Net Zero CO₂ Readiness"
            value={`${Math.round(netZeroCo2Readiness)}%`}
            rating={getNetZeroReadinessLevelPerformance(
              assetReferences,
              netZeroCo2Readiness,
            )}
            tooltipInfo="Percentage of CO2 emissions that can be abated through high-quality renewable energy procurement agreements"
          />
        </Tbody>
      </Table>
    </Box>
  );
};

import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { InefficientAssetsList } from "src/common/components/organisms/AssetList/InefficientAssetsList";
import { theme } from "src/config/theme";
import { useAppSelector } from "src/redux/hooks";
import {
  selectFilteredAndSelectedAssets,
  selectFilteredAssets,
  selectIsAssetsDataLoading,
} from "src/redux/slices/portfolios/assets/selectors";
import { selectSelectedAssetIds } from "src/redux/slices/uiState/assetsFilter/selectors";
import { InfoTooltip } from "src/common/components/atoms/InfoTooltip";
import { CustomPieChart } from "src/common/components/molecules/CustomPieChart";
import DeviationBubbleChart from "../components/DeviationBubbleChart";
import { LoadComponentPlaceholder } from "src/common/components/atoms/Spinner";
import { StandardContent } from "src/common/components/templates/StandardContent";
import { BooleanIcon } from "src/common/components/atoms/BooleanIcon";
import { decimalToPercent } from "src/common/utils/math";
import { selectOpenedPortfolio } from "src/redux/slices/portfolios/selectors";
import { Alert } from "src/common/components/atoms/Alert";

export const InefficientAssetsTab: React.FC = () => {
  const selectedAssetIds = useAppSelector(selectSelectedAssetIds);
  const filteredAssets = useAppSelector(selectFilteredAssets);
  const selectedAssets = useAppSelector(selectFilteredAndSelectedAssets);
  const isLoading = useAppSelector(selectIsAssetsDataLoading);
  const openedPortfolio = useAppSelector(selectOpenedPortfolio);

  if (isLoading) {
    return <LoadComponentPlaceholder />;
  }

  const haveEPCInfo = !!selectedAssets.find((asset) => asset.epc.rating);

  if (!haveEPCInfo)
    return (
      <Box mt=".5em">
        <Alert message="No data provided for analysis" />
        <Box
          pt="10px"
          backgroundColor="white"
          width="100%"
          borderRadius="8px 8px 0 0"
          mt="1em"
        >
          <InefficientAssetsList
            selectedAssetIds={selectedAssetIds || 1}
            assets={filteredAssets}
            measureSystem={openedPortfolio?.measureSystem}
          />
        </Box>
      </Box>
    );

  const totalOfValues = selectedAssets.length;

  const values = {
    A: selectedAssets.filter((asset) => asset.epc.rating === "A").length,
    B: selectedAssets.filter((asset) => asset.epc.rating === "B").length,
    C: selectedAssets.filter((asset) => asset.epc.rating === "C").length,
    D: selectedAssets.filter((asset) => asset.epc.rating === "D").length,
    E: selectedAssets.filter((asset) => asset.epc.rating === "E").length,
    F: selectedAssets.filter((asset) => asset.epc.rating === "F").length,
    G: selectedAssets.filter((asset) => asset.epc.rating === "G").length,
  };

  const categories = [
    {
      color: theme.colors.energyRating.a,
      value: values.A,
      focused: false,
      label: `A Rating`,
      shortlabel: `A`,
      share: (values.A / totalOfValues) * 100,
    },
    {
      color: theme.colors.energyRating.b,
      value: values.B,
      focused: false,
      label: `B Rating`,
      shortlabel: `B`,
      share: (values.B / totalOfValues) * 100,
    },
    {
      color: theme.colors.energyRating.c,
      value: values.C,
      focused: false,
      label: `C Rating`,
      shortlabel: `C`,
      share: (values.C / totalOfValues) * 100,
    },
    {
      color: theme.colors.energyRating.d,
      value: values.D,
      focused: false,
      label: `D Rating`,
      shortlabel: `D`,
      share: (values.D / totalOfValues) * 100,
    },
    {
      color: theme.colors.energyRating.e,
      value: values.E,
      focused: false,
      label: `E Rating`,
      shortlabel: `E`,
      share: (values.E / totalOfValues) * 100,
    },
    {
      color: theme.colors.energyRating.f,
      value: values.F,
      focused: false,
      label: `F Rating`,
      shortlabel: `F`,
      share: (values.F / totalOfValues) * 100,
    },
    {
      color: theme.colors.energyRating.g,
      value: values.G,
      focused: false,
      label: `G Rating`,
      shortlabel: `G`,
      share: (values.G / totalOfValues) * 100,
    },
  ];

  const red = theme.colors.red[400];
  const gray = theme.colors.gray[100];

  const deviatedAssets = selectedAssets.filter(
    (a) => a.epc.rating !== a.epc.actualPerformance,
  );
  const deviated = decimalToPercent(
    deviatedAssets.length / selectedAssets.length,
  );
  const aligned = Math.round(100 - deviated);

  const deviateLegend = [
    {
      color: red,
      label: "Deviate ",
      share: deviated,
    },
    {
      color: gray,
      label: "Aligned ",
      share: aligned,
    },
  ];

  const deviateCategories = [
    {
      color: red,
      value: deviated,
      focused: true,
      label: "Deviate [%]",
    },
    {
      color: gray,
      value: aligned,
      focused: false,
      label: "Aligned [%]",
    },
  ];

  const hasRatingPair = selectedAssets
    .map((a) => {
      return {
        epcRating: a.epc.rating,
        epcPerformance: a.epc.actualPerformance,
      };
    })
    .some((r) => r.epcRating && r.epcPerformance);

  return (
    <Box>
      <StandardContent
        message={
          selectedAssetIds?.length < 1 ? "No assets were selected" : undefined
        }
        chartAreaContent={
          <Flex direction={{ base: "column", md: "row" }}>
            <Box mr="2rem" w="49%">
              <Text fontWeight="bold" fontSize="small">
                Share of Portfolio Exposed to Inefficient Assets According to
                PAIs
                <InfoTooltip text="Share of portfolio exposed to “inefficient real estate assets”according to Principal Adverse Impact (PAI) indicators under SFDR." />
              </Text>
              <Flex
                mt="0.5rem"
                fontSize="small"
                h="90%"
                justifyContent="flex-start"
                alignItems="center"
                direction={{ base: "row", md: "column" }}
                mb="1.5rem"
              >
                <Box
                  width={{ base: "50%", md: "60%" }}
                  pr={{ base: "2rem", md: "0" }}
                  pb={{ base: "0", md: "1.5rem" }}
                  height="50%"
                  minW="130px"
                  minH="130px"
                >
                  {selectedAssetIds.length > 0 && (
                    <CustomPieChart
                      customLabel={`${Math.round(
                        categories.slice(3).reduce((acc, asset) => {
                          return acc + asset.share;
                        }, 0),
                      )}%`}
                      epcRating={true}
                      categories={categories}
                    />
                  )}
                </Box>

                <Box>
                  {categories.map((category, id) => (
                    <Flex key={"id" + id}>
                      <Box
                        backgroundColor={category.color}
                        boxSize="0.6rem"
                        borderRadius="50%"
                      />
                      <Box marginLeft={2}>
                        <Text
                          display="inline"
                          fontWeight="bold"
                          whiteSpace="nowrap"
                        >
                          {category.shortlabel} Level{" "}
                          {Math.floor(category.share)}%
                        </Text>
                      </Box>
                    </Flex>
                  ))}
                </Box>
              </Flex>
            </Box>
            <Box w="49%">
              <Text fontWeight="bold" fontSize="small">
                Percentage of Assets that Significantly Deviate from Expected
                Performance
              </Text>
              <Flex
                mt="0.5rem"
                fontSize="small"
                h="100%"
                justifyContent="flex-start"
                alignItems="center"
                direction={{ base: "row", md: "column" }}
              >
                <Box
                  width={{ base: "50%", md: "60%" }}
                  pr={{ base: "2rem", md: "0" }}
                  pb={{ base: "0", md: "1.5rem" }}
                  height="50%"
                  minW="130px"
                  minH="130px"
                >
                  {selectedAssetIds.length > 0 && (
                    <CustomPieChart
                      customLabel={deviated.toFixed(0) + "%"}
                      labeIsFocusColor={true}
                      categories={deviateCategories}
                      units={"Deviate"}
                    />
                  )}
                </Box>

                <Box>
                  {deviateLegend &&
                    deviateLegend.map((category, id) => (
                      <Flex
                        justify-content="center"
                        alignItems="center"
                        key={"id" + id}
                      >
                        <Box
                          backgroundColor={category.color}
                          boxSize="0.6rem"
                          borderRadius="50%"
                        />
                        <Box marginLeft={2}>
                          <Text
                            display="inline"
                            fontWeight="bold"
                            whiteSpace="nowrap"
                          >
                            {category.label} {category.share.toFixed(0)} %
                          </Text>
                        </Box>
                      </Flex>
                    ))}
                </Box>
              </Flex>
            </Box>
          </Flex>
        }
      >
        <Box width="100%" p="0.5rem">
          <Text fontWeight="bold" fontSize="small">
            Assets that Significantly Deviate from Expected Performance
          </Text>
          {hasRatingPair ? (
            <DeviationBubbleChart assets={selectedAssets} />
          ) : (
            <Box
              width="100%"
              color="gray.500"
              fontSize="0.8em"
              textAlign="center"
              mt="1.5rem"
            >
              <Text>
                <BooleanIcon isTrue />
                No performance deviations detected
              </Text>
            </Box>
          )}
        </Box>
      </StandardContent>

      <Box
        pt="10px"
        backgroundColor="white"
        width="100%"
        borderRadius="8px 8px 0 0"
      >
        <InefficientAssetsList
          selectedAssetIds={selectedAssetIds || 1}
          assets={filteredAssets}
          measureSystem={openedPortfolio?.measureSystem}
        />
      </Box>
    </Box>
  );
};

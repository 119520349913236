import React, { useRef, useState, useCallback } from "react";
import { Text, Box, Flex } from "@chakra-ui/react";
import { closest10 } from "src/common/utils/math";
import { DecarbCostSliderTooltip } from "./DecarbCostSliderTooltip";

interface IProps {
  isPortfolio?: boolean;
  units: string;
  breakpoints: {
    low: number;
    medium: number;
    high: number;
  };
  valueAbove?: {
    label: string;
    value: number;
  };
  valueBelow?: {
    label: string;
    value: number;
  };
}

export const DottedSlider: React.FC<IProps> = ({
  valueAbove,
  valueBelow,
  breakpoints,
  isPortfolio,
  units,
}) => {
  const [numberOfBullets, setNumberOfBullets] = useState(40);

  const resizeObserver = useRef<ResizeObserver>(
    new ResizeObserver((entries: ResizeObserverEntry[]) => {
      setNumberOfBullets(Math.round(entries[0].contentRect.width / 10));
    }),
  );

  const resizedContainerRef = useCallback((container: HTMLDivElement) => {
    if (container !== null) {
      resizeObserver.current.observe(container);
    }
    // When the element is unmounted, ref callback is called with a null argument
    // => the best time to clean up the observer
    else {
      if (resizeObserver.current) resizeObserver.current.disconnect();
    }
  }, []);

  const mediumCostIndex = Math.floor(
    (breakpoints.medium / breakpoints.low) * numberOfBullets,
  );

  const lowCostIndex = Math.floor(
    (breakpoints.high / breakpoints.low) * numberOfBullets,
  );
  const belowValueIndex = Math.floor(
    ((valueBelow?.value || 0) / breakpoints.low) * numberOfBullets,
  );

  const aboveValueIndex = valueAbove?.value
    ? Math.floor((valueAbove.value / breakpoints.low) * numberOfBullets)
    : null;

  const getColor = (x: number) => {
    if (x > breakpoints.medium) return "red.500";
    if (x < breakpoints.high) return "green.500";
    return "yellow.500";
  };

  const setBeforePseudoElement = (x: number) => {
    const pseudoElement = {
      position: "relative",
      display: "inline-block",
      top: "-1.8rem",
      fontSize: "xs",
      color: "gray.500",
    };
    if (x === lowCostIndex) {
      return {
        content: `"${closest10(breakpoints.high)}"`,
        ...pseudoElement,
      };
    } else if (x === mediumCostIndex) {
      return {
        content: `"${closest10(breakpoints.medium)}"`,
        ...pseudoElement,
      };
    } else {
      return undefined;
    }
  };

  return (
    <Box pb="1rem" ref={resizedContainerRef}>
      <Text fontSize="small" fontWeight="bold" mb="1rem">
        {isPortfolio ? "Portfolio Average" : "Asset"} Decarbonisation Cost (
        {units})
      </Text>
      <Flex mb="0.2rem" flexDir="row" flexWrap="nowrap" justify="space-between">
        <Text fontSize="xs" color="gray.500">
          0
        </Text>
        <Text fontSize="xs" color="gray.500" textAlign="right">
          {closest10(breakpoints.low)}
        </Text>
      </Flex>

      <Flex
        justifyContent="space-between"
        flexDir="row"
        flexWrap="wrap"
        alignItems="center"
      >
        {Array.from(Array(numberOfBullets + 1).keys()).map((cat, i) => {
          if (
            (aboveValueIndex && valueAbove?.value && i === aboveValueIndex) ||
            (i === belowValueIndex && valueBelow)
          ) {
            return (
              <DecarbCostSliderTooltip
                key={cat}
                units={units}
                valueAbove={
                  aboveValueIndex && valueAbove?.value && i === aboveValueIndex
                    ? valueAbove
                    : undefined
                }
                valueBelow={
                  i === belowValueIndex && valueBelow ? valueBelow : undefined
                }
              >
                <Box
                  key={cat}
                  w="1rem"
                  h="1rem"
                  borderRadius="50%"
                  bg={getColor(i * (breakpoints.low / numberOfBullets))}
                  display="inline-block"
                  whiteSpace="nowrap"
                  _before={{ content: `""` }}
                />
              </DecarbCostSliderTooltip>
            );
          } else {
            return (
              <Box
                key={i}
                w="7px"
                h="7px"
                borderRadius="50%"
                bg={getColor(i * (breakpoints.low / numberOfBullets))}
                _before={setBeforePseudoElement(i)}
              />
            );
          }
        })}
      </Flex>
    </Box>
  );
};

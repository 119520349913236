import React from "react";
import { StandardLayout } from "src/common/components/templates/StandardLayout";
import { AppAdminSidebar } from "./components/AppAdmin/AppAdminSidebar";
import { Route, Routes } from "react-router-dom";
import GlobalUsers from "./components/AppAdmin/GlobalUsers";
import { Companies } from "./components/AppAdmin/Companies";
import { useCompany } from "src/common/components/organisms/CompanyProviderWrapper";
import { useAppSelector } from "src/redux/hooks";
import { selectAmISystemAdmin } from "src/redux/slices/auth/authInfo/selectors";
import { Recalculate } from "./components/AppAdmin/Recalculate";

export const AppAdminView: React.FC = () => {
  const { selectedCompanyId } = useCompany();
  const isSystemAdmin = useAppSelector(selectAmISystemAdmin);
  return (
    <StandardLayout
      sideBar={<AppAdminSidebar isSystemAdmin={isSystemAdmin} />}
      backUrl={selectedCompanyId ? "/companyView/" + selectedCompanyId : "/"}
      backUrlText={selectedCompanyId ? "Back to company" : "Home"}
    >
      <Routes>
        <Route path="companies" element={<Companies />} />
        <Route path="users" element={<GlobalUsers />} />
        <Route path="recalc" element={<Recalculate />} />
      </Routes>
    </StandardLayout>
  );
};

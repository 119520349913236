import { EntityState, createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { companyUsersAdapter } from "./adapter";
import { ICompanyPermission, ICrispUser } from "src/api/open-api";
import { selectSlice as selectPermissionsSlice } from "../permissions/selectors";
import { permissionsAdapter } from "../permissions/adapter";
import { IAppCompanyUser } from "src/redux/interfaces/IAppCompanyUser";
import { hardcodedEntityTypes } from "src/constants/accessEntityTypes";

const selectSlice = (state: RootState) => state.companyUsers;

const isCompanyAdminPermission =
  (userId: number) => (permission: ICompanyPermission) =>
    permission.entity_type_id === Number(hardcodedEntityTypes.company) &&
    permission.permission_type_name === "full" &&
    permission.user_id === userId;

export const selectCompanyUsers = createDraftSafeSelector(
  [selectSlice, selectPermissionsSlice],
  (
    companyUsersState: EntityState<ICrispUser>,
    companyPermissionsState: EntityState<ICompanyPermission>,
  ): IAppCompanyUser[] => {
    const users = companyUsersAdapter
      .getSelectors()
      .selectAll(companyUsersState);
    const permissions = permissionsAdapter
      .getSelectors()
      .selectAll(companyPermissionsState);

    return users.map((user) => ({
      ...user,
      isCompanyAdmin: permissions.some(isCompanyAdminPermission(user.user_id)),
    }));
  },
);

export const selectIsCompanyUsersLoading = createDraftSafeSelector(
  [selectSlice],
  (state): boolean => state.isLoading,
);

import React from "react";
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  UnorderedList,
  ListItem,
  Link,
} from "@chakra-ui/react";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const WRMInfoModal: React.FC<IProps> = (props) => {
  const { isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Link
            href="https://ec.europa.eu/info/sites/default/files/business_economy_euro/banking_and_finance/documents/210803-sustainable-finance-platform-report-technical-screening-criteria-taxonomy-annex_en.pdf"
            isExternal
          >
            <Text fontSize="1rem">Technical screening criteria Annex</Text>
          </Link>
        </ModalHeader>
        <ModalCloseButton size="lg" style={{ boxShadow: "none" }} />
        <ModalBody>
          <Text fontWeight="bold" fontSize="1rem" marginBottom={2}>
            Acquisition and ownership of buildings
          </Text>
          <Text fontWeight="bold" marginBottom={2}>
            Description of the activity
          </Text>
          <Text marginBottom={3}>
            Buying real estate and exercising ownership of that real estate. The
            economic activities in this category could be associated with NACE
            code L68 in accordance with the statistical classification of
            economic activities established by Regulation (EC) No 1893/2006
          </Text>
          <Text fontWeight="bold" marginBottom={3}>
            Substantial contribution to water resource management
          </Text>
          <Text>No criteria for Acquisition and ownership</Text>

          <Text fontWeight="bold" marginBottom={3}>
            Do no significant harm {"('DNSH')"}
          </Text>
          <UnorderedList marginBottom={3}>
            <ListItem>
              <Text fontWeight="bold">Climate change mitigation.</Text> N/A.
            </ListItem>
            <ListItem>
              <Text fontWeight="bold">Climate change adaptation.</Text> N/A.
            </ListItem>
            <ListItem>
              <Text fontWeight="bold">Pollution prevention and control.</Text>
              N/A.
            </ListItem>
            <ListItem>
              <Text fontWeight="bold">Transition to circular economy.</Text>
              N/A.
            </ListItem>
            <ListItem>
              <Text fontWeight="bold">
                Protection and restoration of biodiversity andecosystems.
              </Text>
              N/A.
            </ListItem>
          </UnorderedList>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default WRMInfoModal;

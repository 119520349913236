import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompanyApi, CompanyPayload, ICompany } from "src/api/open-api";
import { defaultApiConfiguration } from "src/api/configurations";
import { USE_LOCAL_MOCKS } from "src/config/mocks";
import { companiesMock } from "./__mocks__/companiesMock";

export const fetchCompanies = createAsyncThunk(
  "companies/fetch",
  async (_, { rejectWithValue }) => {
    if (USE_LOCAL_MOCKS) return companiesMock as ICompany[];
    const api = new CompanyApi(defaultApiConfiguration);
    try {
      const response = await api.getCompanies();
      return response.data;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

export const addCompany = createAsyncThunk(
  "companies/add",
  async (company: CompanyPayload, { rejectWithValue }) => {
    const api = new CompanyApi(defaultApiConfiguration);
    try {
      const response = await api.upsertCompany(company);
      return response.data;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

export const updateCompany = createAsyncThunk(
  "companies/update",
  async (company: ICompany, { rejectWithValue }) => {
    const api = new CompanyApi(defaultApiConfiguration);
    try {
      await api.updateCompany(company.company_id, company);
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
    return company;
  },
);

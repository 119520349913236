import { EntityState, createDraftSafeSelector } from "@reduxjs/toolkit";
import { userCompaniesAdapter } from "./adapter";
import { ICompany } from "src/api/open-api";
import { RootState } from "src/redux";

export const selectSlice = (state: RootState) => state.userCompanies;

export const selectAllUserCompanies = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<ICompany>) =>
    userCompaniesAdapter.getSelectors().selectAll(state),
);

export const selectCompaniesStateError = createDraftSafeSelector(
  [selectSlice],
  (state) => state.error,
);

export const selectCompaniesStateIsLoading = createDraftSafeSelector(
  [selectSlice],
  (state) => state.isLoading,
);

export const selectIsOnlyOneCompanyAvailable = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<ICompany>) =>
    userCompaniesAdapter.getSelectors().selectAll(state).length === 1,
);

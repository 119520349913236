export enum Performance {
  Low = 0,
  Medium = 1,
  High = 2,
}

export enum Risk {
  Low = 0,
  Medium = 1,
  High = 2,
}

export interface IThreshold {
  [Performance.Medium]: number; // Boundary from Low to Medium
  [Performance.High]: number; // Boundary from Medium to High
}

export interface IBenchmark {
  [key: number]: number;
}

export interface IThresholds {
  strandingYear: IThreshold;
  co2reduction: IThreshold;
  energyIntensity: IThreshold;
  netZeroCo2Readiness: IThreshold;
  shareOfRenewables: IThreshold;
}

export interface IBenchmarks {
  capex: IBenchmark;
}

export interface IUseTypeReference<T>
  extends Partial<Record<string, Partial<T>>> {
  default: T;
}

export interface ILibrary<T>
  extends Partial<Record<string, Partial<IUseTypeReference<Partial<T>>>>> {
  default: IUseTypeReference<T>;
}

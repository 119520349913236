import React from "react";
import {
  Table,
  Th,
  Td,
  Thead,
  Tr,
  Checkbox,
  Tbody,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { InfoTooltip } from "src/common/components/atoms/InfoTooltip";
import { IReduxChartTarget } from "src/redux/interfaces/IReduxChartTarget";
interface IProps {
  customPathways: IReduxChartTarget[];
  selectedIds: number[];
  toggleId: (id: number) => void;
  toggleAll: (isToggleOn: boolean) => void;
  onEditCustomPathway: (pathwayId?: number) => void;
  onDeleteCustomPathway: (pathwayId?: number) => void;
}

export const CustomPathwayTable: React.FC<IProps> = ({
  customPathways,
  selectedIds,
  toggleId,
  toggleAll,
  onEditCustomPathway,
  onDeleteCustomPathway,
}) => {
  return (
    <Table variant="simple" width="100%">
      <Thead position="sticky" top={0} backgroundColor={"white"} zIndex={100}>
        <Tr>
          <Th w="3%" alignItems="left">
            <Checkbox
              colorScheme="red"
              isChecked={selectedIds.length === customPathways?.length}
              onChange={(e) => toggleAll(e.target.checked)}
            />
          </Th>
          <Th>{`${
            customPathways?.length > selectedIds.length
              ? selectedIds.length
              : customPathways?.length
          } Pathways`}</Th>
          <Th w="120px">
            Stranding Year{" "}
            <InfoTooltip text="When asset GHG emissions are expected to exceed target GHG emissions" />
          </Th>
          <Th w="40px"></Th>
          <Th w="40px"></Th>
        </Tr>
      </Thead>
      <Tbody>
        {customPathways &&
          customPathways.map((pathway) => (
            <Tr
              key={pathway.lineId}
              _hover={{ bg: "white.400", cursor: "pointer" }}
            >
              <Td
                data-name="checkbox area"
                onClick={
                  (e) =>
                    e.stopPropagation() /* to prevent redirect to asset on checkbox click */
                }
              >
                <Checkbox
                  colorScheme="gray"
                  fill="red"
                  onChange={() => pathway.lineId && toggleId(pathway.lineId)}
                  isChecked={
                    (pathway.lineId && selectedIds.includes(pathway.lineId)) ||
                    false
                  }
                />
              </Td>
              <Td>{pathway.targetName}</Td>
              <Td>
                {pathway.strandingX >= 2050
                  ? "-"
                  : pathway.strandingX < 2020
                  ? "< 2020"
                  : Math.floor(pathway.strandingX)}
              </Td>
              <Td>
                <IconButton
                  variant="ghost"
                  icon={<EditIcon />}
                  aria-label="Edit custom pathway"
                  p="0"
                  w="14px"
                  h="14px"
                  onClick={() => onEditCustomPathway(pathway.lineId)}
                />
              </Td>
              <Td>
                <IconButton
                  variant="ghost"
                  icon={<DeleteIcon />}
                  aria-label="Delete custom pathway"
                  colorScheme="red"
                  p="0"
                  w="14px"
                  h="14px"
                  onClick={() => onDeleteCustomPathway(pathway.lineId)}
                />
              </Td>
            </Tr>
          ))}
        <Tr>
          <Td></Td>
          <Td>
            <Button
              size="sm"
              variant="ghost"
              ml="-9px"
              onClick={() => onEditCustomPathway()}
            >
              <AddIcon fill="grey.500" mr="12px" />
              Add new custom pathway
            </Button>
          </Td>
          <Td></Td>
          <Td></Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

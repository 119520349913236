import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { decimalToPercent, sum } from "src/common/utils/math";
import { IBusinessBuildingType } from "src/api/open-api";
import { unique } from "src/common/utils/aggregation";
import { DoughnutChart } from "src/common/components/atoms/Chart/DoughnutChart";
import { MeasureSystem } from "src/redux/interfaces/IAppPortfolio";
import { Units, getUnits } from "src/constants/measureUnits";
import { ArcChartsColorsSequence } from "src/common/components/atoms/Chart/config";

type PartialAsset = {
  businessBuildingTypes: IBusinessBuildingType[];
  area: number;
};
type TProps = {
  assets: PartialAsset[];
  measureSystem: MeasureSystem;
};

const createChartCategories = (
  assets: PartialAsset[],
): { label: string; value: number }[] => {
  const usageTypes: IBusinessBuildingType[] = assets
    .map((a) => a.businessBuildingTypes)
    .flat();
  const fullArea = sum(assets.map((it) => it.area));
  const sumOfAllTypes: number = sum(
    usageTypes.map((a) => a.business_building_type_percentage || 0).flat(),
  );
  const uniqueTypeIds: number[] = unique(
    usageTypes.map((it) => it.business_building_type_id),
  );
  const chartCategories = uniqueTypeIds
    .map((key) => {
      const percentageSum = sum(
        usageTypes
          .filter((t) => t.business_building_type_id === key)
          .map((t) => t.business_building_type_percentage || 0),
      );
      return {
        label: `${usageTypes.find((t) => t.business_building_type_id === key)
          ?.business_building_type_label}: ${decimalToPercent(
          percentageSum / sumOfAllTypes,
        )}%`,
        value: Math.round(fullArea * (percentageSum / sumOfAllTypes)) / 1000,
      };
    })
    .sort((a, b) => (a.value < b.value ? 1 : -1))
    .map((it) => {
      return { ...it };
    });

  if (chartCategories.length > 5) {
    const othersCount = chartCategories
      .slice(5, chartCategories.length)
      .map((it) => it.value)
      .reduce((a, b) => a + b, 0);
    const others = {
      label: "Others " + (othersCount * fullArea) / 100 + "%",
      percentageValue: (othersCount * fullArea) / 100,
      value: othersCount,
    };
    return chartCategories.slice(0, 5).concat(others);
  } else {
    return chartCategories;
  }
};

export const UsageSplit: React.FC<TProps> = ({ assets, measureSystem }) => {
  const cat = createChartCategories(assets);
  return (
    <>
      <Box width="50%" ml="auto" mr="auto" mb="10px">
        <DoughnutChart
          content={cat}
          hoverLabel={` [${getUnits(Units.area, measureSystem)}]: `}
        />
      </Box>
      {cat.map((c, i) => {
        return (
          <Flex key={c.label}>
            <Box
              alignSelf="center"
              w="15px"
              minWidth="15px"
              h="15px"
              bg={ArcChartsColorsSequence[i]}
            />
            <Text
              color="gray.500"
              marginLeft="1rem"
              mb="0.3rem"
              fontSize="0.8em"
            >
              {c.label}
            </Text>
          </Flex>
        );
      })}
    </>
  );
};

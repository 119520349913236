import React from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  VStack,
} from "@chakra-ui/react";
import { FormEvent, useState } from "react";
import { useAppDispatch } from "src/redux/hooks";
import { editUser } from "src/redux/thunks/admin";
import { useErrorState } from "src/redux/hooks/useErrorState";
import { ICrispUser } from "src/api/open-api";
import { FormInput } from "src/common/components/atoms/Input";

interface IProps {
  onSubmitCallback?: () => void;
  user: ICrispUser;
}

const formInitialState = {
  user_name: "",
  user_email: "",
  is_active: 1,
};

export const EditUserForm: React.FC<IProps> = ({ onSubmitCallback, user }) => {
  const { error, setError, clearError } = useErrorState();
  const dispatch = useAppDispatch();

  const [userForm, setUserForm] = useState(
    user ? { ...user } : { ...formInitialState },
  );

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (error) clearError();
    if (userForm) {
      dispatch(editUser({ ...user, ...userForm }))
        .then((res) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (res.error) throw res.error;
          setError(null);
          if (onSubmitCallback) onSubmitCallback();
        })
        .catch((err) => {
          setError(err);
        });
    }
  };

  const handleInputChange = (target: { name: string; value: string }) => {
    if (error) clearError();
    setUserForm({
      ...userForm,
      [target.name]: target.value,
    });
  };

  return (
    <>
      <Box>
        <form encType="multipart/form-data" onSubmit={handleSubmit}>
          <VStack spacing={"1.5rem"} textAlign="left">
            <FormInput
              label="Name"
              name="user_name"
              value={userForm.user_name}
              type="name"
              onBlur={handleInputChange}
              isRequired
            />
            <FormInput
              label="Email"
              name="user_email"
              value={userForm.user_email}
              type="email"
              onBlur={handleInputChange}
              isRequired
            />

            <Box mt="2rem">
              <Button
                type="submit"
                variant="outlinePrimary"
                placeSelf={{ base: "center" }}
              >
                Save
              </Button>
            </Box>
          </VStack>
        </form>
        {error && (
          <Alert status="error" mt="1rem">
            <AlertIcon />
            <AlertDescription>
              {error?.message || "Error saving user, please try again."}
            </AlertDescription>
          </Alert>
        )}
      </Box>
    </>
  );
};

import React, { useMemo, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Flex,
  Button,
  Stack,
  Icon,
  Checkbox,
  Alert as ChakraUiAlert,
  AlertDescription,
} from "@chakra-ui/react";
import { AttachmentIcon, CheckCircleIcon } from "@chakra-ui/icons";
import { Alert } from "../../atoms/Alert";
import { FaTimesCircle, FaExclamationCircle } from "react-icons/fa";
import { AxiosResponse } from "axios";
import { Drop } from "./Drop";
import { FileDownloadSection } from "./FileDownloadSection";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (file: File, id?: number) => Promise<{ errors?: string[] } | void>;
  onValidate: (
    file: File,
    id?: number,
    // eslint-disable-next-line
  ) => Promise<AxiosResponse<string> | { error: any } | { errors: string[] }>;
  getLatestFileUrl?: () => Promise<string | void>;
  title: string;
  templateLink?: string;
  isUpdate?: boolean;
  acceptedFileTypes?: { [key: string]: string[] };
  acceptedFileTypesDescription?: string;
  currentFileVersion?: string;
  companyName?: string;
}

const acceptedTypes = {
  "text/plain": [".xls", ".xlsx", ".xlsb", ".xlsm"],
  "application/vnd.ms-excel": [".xls", ".xlsx", ".xlsb", ".xlsm"],
};

// const ModalStates = {
// CLEAN: 'CLEAN',
//   NO_ERROR_NO_WARNING: 'NO_ERROR_NO_WARNING',
//   ERROR_AND_WARNING: 'ERROR_AND_WARNING',
//   WARNING_ONLY: 'WARNING_ONLY',
// };

export const FileUploadModaWithReports: React.FC<IProps> = ({
  isOpen,
  onClose,
  isUpdate,
  templateLink,
  onValidate,
  onUpload,
  getLatestFileUrl,
  acceptedFileTypes = acceptedTypes,
  acceptedFileTypesDescription = ".xls, .xlsx, .xlsb, .xlsm",
  title,
  currentFileVersion,
  companyName,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState<string[]>([]);
  const [modalState, setModalState] = useState("CLEAN"); // Initialize modal state
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const saveButtonDisabled = useMemo(
    () =>
      modalState === "ERROR_AND_WARNING" ||
      (modalState === "WARNING_ONLY" && !checkboxChecked) ||
      errorMsgs.length > 0,
    [modalState, errorMsgs, checkboxChecked],
  );

  const resetToClean = () => {
    setCheckboxChecked(false);
    setModalState("CLEAN");
    setErrorMsgs([]);
    setPdfUrl("");
    setContent(undefined);
  };

  const handleDownloadPDF = () => {
    // Handle the response
    const blob = new Blob([pdfUrl], { type: "application/pdf" });
    // Create a link element
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download =
      modalState === "ERROR_AND_WARNING"
        ? "Error_and_Warning_Report.pdf"
        : "Warning_Report.pdf"; // Set the desired filename here
    link.click();
  };
  const actionTitle = isUpdate ? "Update" : "Upload";

  const [content, setContent] = useState<File>();
  const handleDrop = async <T extends File>(file: T) => {
    setContent(file);
    setModalState("FILE_SELECTED");
  };

  const deleteUploadedFileAndClose = () => {
    resetToClean();
    onClose();
  };

  const handleUpload = async (excel: File) => {
    if (modalState === "FILE_SELECTED") {
      setLoading(true);
      setErrorMsgs([]);
      // Call onValidate function
      await onValidate(excel).then((response) => {
        const res = response;
        // @ts-expect-error  error type is always unknown
        if (res.error && res.error.errorCode === 422) {
          setModalState("ERROR_AND_WARNING");
          // @ts-expect-error  error type is always unknown
          setPdfUrl(res.error.errorData);
          setLoading(false);
          // @ts-expect-error  error type is always unknown
        } else if (res.error && res.error.errorCode === 400) {
          setModalState("WARNING_ONLY");
          // @ts-expect-error  error type is always unknown
          setPdfUrl(res.error.errorData);
          setLoading(false);
          // @ts-expect-error  error type is always unknown
        } else if (res.errors) {
          // @ts-expect-error  error type is always unknown
          setErrorMsgs(res.errors);
          setLoading(false);
        } else {
          setModalState("NO_ISSUE");
          setLoading(false);
        }
      });
    } else if (modalState === "NO_ISSUE") {
      saveUpload(excel);
    } else if (modalState === "WARNING_ONLY" && checkboxChecked === true) {
      saveUpload(excel);
    }
  };

  const saveUpload = async (excel: File) => {
    setErrorMsgs([]);
    setLoading(true);
    onUpload(excel).then((response) => {
      setLoading(false);
      if (response?.errors?.length) {
        setErrorMsgs(response.errors);
      }
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={deleteUploadedFileAndClose}
      size="lg"
      closeOnOverlayClick={!loading}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="normal" fontFamily="Times">
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <form
          encType="multipart/form-data"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody>
            {!isUpdate && companyName && (
              <Text mb="0.5em">
                Upload data for company <strong>{companyName}</strong>
              </Text>
            )}
            <Drop
              onDrop={handleDrop}
              onClean={resetToClean}
              currentFileVersion={currentFileVersion}
              acceptedFileTypesDescription={acceptedFileTypesDescription}
              acceptedFileTypes={acceptedFileTypes}
            />
            {modalState === "CLEAN" ? (
              <FileDownloadSection
                getLatestFileUrl={getLatestFileUrl}
                templateLink={templateLink}
                isUpdate={isUpdate}
              />
            ) : null}
            {pdfUrl &&
              (modalState === "ERROR_AND_WARNING" ||
                modalState === "WARNING_ONLY") && (
                <Box className="container" mt="1rem">
                  <Box
                    borderStyle="dashed"
                    borderRadius="0.25rem"
                    _hover={{
                      cursor: "pointer",
                      transition: "ease-in-out 0.3s",
                    }}
                    style={{ borderStyle: "dashed" }}
                    border="1px"
                    borderColor="gray.500"
                    padding="1rem"
                    title={`${actionTitle} file`}
                  >
                    <Flex justifyContent="left" alignItems="left" mb={4}>
                      {modalState === "ERROR_AND_WARNING" ? (
                        <>
                          <Icon
                            as={FaTimesCircle}
                            fill="red.500"
                            boxSize={4}
                            mr={2}
                          />
                          <Text
                            mt="0.10rem"
                            fontSize="xs"
                            fontWeight="bold"
                            color={"red.500"}
                          >
                            File Does not pass our validation please check the
                            error report and resolve mentioned errors.
                          </Text>
                        </>
                      ) : modalState === "WARNING_ONLY" ? (
                        <>
                          <Icon
                            as={FaExclamationCircle}
                            fill="blue.500"
                            boxSize={5}
                            mr={2}
                          />
                          <Text
                            mt="0.10rem"
                            fontSize="xs"
                            fontWeight="bold"
                            color="blue.500"
                          >
                            Warnings found in uploaded portfolio!
                          </Text>
                        </>
                      ) : null}
                    </Flex>
                    <Flex
                      mt={4}
                      dir="row"
                      alignItems="center"
                      borderColor="gray.200"
                      borderRadius="0.25rem"
                      borderWidth="1px"
                      paddingX={4}
                      paddingY={1}
                      justifyContent="space-between"
                    >
                      <Stack direction="row" alignItems="center" spacing={0}>
                        <Icon
                          as={AttachmentIcon}
                          fill="gray.800"
                          boxSize={6}
                          marginRight={4}
                          textAlign="center"
                        />
                        <Stack
                          direction="column"
                          alignItems="left"
                          spacing="0.2rem"
                        >
                          <Text
                            color="gray.800"
                            fontSize="small"
                            fontWeight="bold"
                            listStyleType="none"
                            onClick={handleDownloadPDF}
                          >
                            {modalState === "ERROR_AND_WARNING"
                              ? "Errors and Warnings Report"
                              : modalState === "WARNING_ONLY"
                              ? "Warning Report"
                              : null}
                          </Text>
                          <Text fontSize="sm">(PDF)</Text>
                        </Stack>
                      </Stack>
                    </Flex>
                  </Box>
                </Box>
              )}
            {modalState === "NO_ISSUE" && !errorMsgs.length && (
              <ChakraUiAlert
                status="success"
                mb="2px"
                borderRadius="8px"
                backgroundColor="green.50"
                mt="0.8em"
              >
                <CheckCircleIcon fill="green.700" mr="0.5em" />
                <AlertDescription
                  color="green.700"
                  fontSize="0.9em"
                  lineHeight="1.5"
                >
                  No issues found
                </AlertDescription>
              </ChakraUiAlert>
            )}
            {errorMsgs.length ? (
              <Box mt="1em">
                <Text color="red.500" fontSize="sm" fontWeight="bold">
                  Something went wrong. Please review issues and try again:
                </Text>
                {errorMsgs
                  .slice(0, errorMsgs.length <= 3 ? errorMsgs.length : 3)
                  .map((e, i) => (
                    <Alert message={e} key={i} isError />
                  ))}
                {errorMsgs.length > 3 && (
                  <Alert message={`${errorMsgs.length - 3} More...`} isError />
                )}
              </Box>
            ) : null}
            {/* Checkbox for Warnings */}
            {modalState === "WARNING_ONLY" && (
              <Box textAlign="left" mt="1rem">
                <Checkbox
                  variant="primary"
                  defaultChecked={false}
                  onChange={(event) => {
                    setCheckboxChecked(event.target.checked);
                  }}
                >
                  <Text color="red.500">Proceed with warnings!</Text>
                </Checkbox>
              </Box>
            )}
          </ModalBody>

          <ModalFooter justifyContent="space-around">
            {content && (
              <Button
                w="50%"
                type="submit"
                variant="outlinePrimary"
                isLoading={loading}
                onClick={() => content && handleUpload(content)}
                mb="15px"
                isDisabled={saveButtonDisabled}
              >
                {modalState === "FILE_SELECTED" ? "Validate" : "Save"}
              </Button>
            )}
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

import React from "react";
import { Box, Tr, Td, HStack } from "@chakra-ui/react";
import { riskToColor } from "src/common/utils/colors/colors";
import HoverTooltip from "../../atoms/HoverTooltip";
import { InfoTooltip } from "../../atoms/InfoTooltip";

const getTooltipInfoForRating = (rating: 0 | 1 | 2) => {
  switch (rating) {
    case 0:
      return "Overall unsatisfactory performance";
    case 1:
      return "Average performance";
    case 2:
      return "Overall good performance";
  }
};

type IProps = {
  name: string;
  tooltipInfo: string;
  value: number | string;
  rating?: 0 | 1 | 2;
};
export const PerformanceTableRow: React.FC<IProps> = ({
  name,
  tooltipInfo,
  value,
  rating,
}) => {
  if (!name) return null;

  return (
    <Tr>
      <Td>
        <HStack>
          <>{name}</>
          <InfoTooltip text={tooltipInfo} />
        </HStack>
      </Td>
      <Td whiteSpace="nowrap">{value}</Td>
      <Td>
        <HoverTooltip
          title="Performance Rating"
          text={getTooltipInfoForRating(rating as 0 | 1 | 2)}
        >
          <Box
            backgroundColor={riskToColor(
              rating === undefined ? undefined : 2 - rating,
            )}
            boxSize="1rem"
            borderRadius="50%"
          />
        </HoverTooltip>
      </Td>
    </Tr>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import { fetchRecalcLogs } from "../../../thunks/admin";
import { recalcAdapter } from "./adapter";
import {
  Ii18nError,
  apiErrorToI18nError,
} from "src/redux/interfaces/Ii18nError";

const slice = createSlice({
  name: "recalc",
  initialState: {
    ...recalcAdapter.getInitialState(),
    requestStatus: "initial",
    isLoading: false,
    error: null as null | Ii18nError,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRecalcLogs.pending, (state) => {
      recalcAdapter.removeAll(state);
      state.requestStatus = "pending";
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchRecalcLogs.rejected, (state, action) => {
      state.requestStatus = "rejected";
      state.isLoading = false;
      state.error = action.payload
        ? apiErrorToI18nError(action.payload)
        : { errorCode: "GENERIC" };
    });
    builder.addCase(fetchRecalcLogs.fulfilled, (state, { payload }) => {
      recalcAdapter.setMany(state, payload);
      state.requestStatus = "fulfilled";
      state.isLoading = false;
    });
  },
});

export default slice;

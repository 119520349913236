import { Box, Tabs, TabPanels, TabPanel, Text } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { StrandingChartWithSlider } from "src/common/components/molecules/ChartsWithSlider/StrandingChartWithSlider";
import { StrandingAssetList } from "src/common/components/organisms/AssetList/StrandingAssetList";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  selectFilteredAssets,
  selectAssetsStrandingYears,
  selectFilteredAndSelectedAssets,
  selectMaxReportingYear,
  selectIsAssetsDataLoading,
  selectGhgForSelectedAssets,
  selectEnergyForSelectedAssets,
} from "src/redux/slices/portfolios/assets/selectors";
import { selectSelectedAssetIds } from "src/redux/slices/uiState/assetsFilter/selectors";
import { toggleAssetId } from "src/redux/slices/uiState/assetsFilter/slice";
import { selectSelectedModelId } from "src/redux/slices/uiState/modelSelection/selectors";
import { setSelectedModelId } from "src/redux/slices/uiState/modelSelection/slice";
import { StrandedAssetsShareChart } from "../components/StrandedAssetsShareChart";
import { StandardContent } from "src/common/components/templates/StandardContent";
import { PerformanceTable } from "src/common/components/molecules/PerformanceTable";
import { PerformanceHighlights } from "src/common/components/molecules/PerformanceHighlights";
import { LoadComponentPlaceholder } from "src/common/components/atoms/Spinner";
import { selectOpenedPortfolio } from "src/redux/slices/portfolios/selectors";
import { Units, getUnits } from "src/constants/measureUnits";
import { currencyCodeToSymbol } from "src/common/utils/units";
import { getScaledStrandingChartValuesForGHG } from "src/common/utils/units/ghgChartScaling";
import { selectShouldShowCapex } from "src/redux/slices/featureConfig/selectors";
import { TabsHeader } from "src/common/components/atoms/TabsHeader";

export const StrandingTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const openedPortfolio = useAppSelector(selectOpenedPortfolio);
  const selectedAssetIds = useAppSelector(selectSelectedAssetIds);
  const filteredAssets = useAppSelector(selectFilteredAssets);
  const selectedAndFilteredAssets = useAppSelector(
    selectFilteredAndSelectedAssets,
  );
  const ghg = useAppSelector(selectGhgForSelectedAssets);
  const energy = useAppSelector(selectEnergyForSelectedAssets);
  const modelVerisonId = useAppSelector(selectSelectedModelId);
  const strandingYears = useAppSelector(selectAssetsStrandingYears);
  const maxReportingYear = useAppSelector(selectMaxReportingYear);
  const isLoading = useAppSelector(selectIsAssetsDataLoading);
  const shouldShowCapex = useAppSelector(selectShouldShowCapex);
  const [year, setYear] = useState(2031);
  const [tabIndex, setTabIndex] = useState(0);

  const ghgScaledValues = useMemo(
    () =>
      getScaledStrandingChartValuesForGHG(
        ghg.forecast,
        ghg.targets,
        [],
        openedPortfolio?.measureSystem,
      ),
    [ghg.forecast, ghg.targets, openedPortfolio],
  );

  if (isLoading) {
    return <LoadComponentPlaceholder />;
  }

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <Box>
      <StandardContent
        message={
          selectedAssetIds?.length < 1 ? "No assets were selected" : undefined
        }
        chartAreaContent={
          <>
            <Tabs
              colorScheme="red"
              size="sm"
              variant="unstyled"
              width="100%"
              index={tabIndex}
              onChange={handleTabsChange}
            >
              <TabsHeader
                ml="15px"
                tabTitles={[
                  "GHG Emissions Intensity",
                  "Energy Intensity",
                  "Share of Stranded Assets",
                ]}
              />

              <TabPanels>
                <TabPanel pl="0.5rem">
                  {tabIndex === 0 ? (
                    <StrandingChartWithSlider
                      units={ghgScaledValues.units}
                      year={year}
                      setYear={setYear}
                      forecast={ghgScaledValues.forecast}
                      targets={ghgScaledValues.targets}
                      customKey={selectedAssetIds?.length}
                    />
                  ) : null}
                </TabPanel>
                <TabPanel pl="0.5rem">
                  {tabIndex === 1 ? (
                    <StrandingChartWithSlider
                      units={getUnits(
                        Units.energyIntensityYearly,
                        openedPortfolio?.measureSystem,
                      )}
                      year={year}
                      setYear={setYear}
                      forecast={energy.forecast}
                      targets={energy.targets}
                      customKey={selectedAssetIds?.length}
                    />
                  ) : null}
                </TabPanel>
                <TabPanel pl="0.5rem">
                  <StrandedAssetsShareChart
                    year={year}
                    setYear={setYear}
                    forecast={energy.forecast}
                    assetsStrandingYears={strandingYears}
                    selectedModelVersionId={modelVerisonId}
                    setModelVersionId={(id) => dispatch(setSelectedModelId(id))}
                    targets={ghg.targets}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>

            {/* // TODO: maybe add line selection if we have time and make the page look fancier
            <Flex width="130px" direction="column">
              <Flex
                height="48px"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                {isDataComplete && (
                  <Text
                    mt="5px"
                    backgroundColor="red.50"
                    color="red.400"
                    fontSize="xs"
                    p="0"
                    display="inline-block"
                  >
                    Data is not complete
                  </Text>
                )}
              </Flex>
              54kgCO2e/m2 Forecast vaue for x year line select holding years
                </Flex>  */}
          </>
        }
      >
        <Box width="100%">
          {ghg.targets?.length > 0 ? (
            <>
              <PerformanceTable
                assets={selectedAndFilteredAssets}
                targets={ghg.targets}
                forecastGHG={ghg.forecast}
                forecastEnergy={energy.forecast}
                maxReportingYear={maxReportingYear}
                measureSystem={openedPortfolio?.measureSystem}
              />
              <PerformanceHighlights
                assets={selectedAndFilteredAssets}
                units={`${currencyCodeToSymbol(
                  openedPortfolio?.currency,
                )}/${getUnits(Units.area, openedPortfolio?.measureSystem)}`}
                areaUnits={getUnits(Units.area, openedPortfolio?.measureSystem)}
                shouldShowCapex={shouldShowCapex}
              />
            </>
          ) : (
            <Box
              color="gray.500"
              fontSize="0.8em"
              textAlign="center"
              mt="1.5rem"
            >
              <Text>No stranding data exists for selected asset(s)</Text>
            </Box>
          )}
        </Box>
      </StandardContent>

      <Box
        pt="10px"
        backgroundColor="white"
        width="100%"
        borderRadius="8px 8px 0 0"
      >
        <StrandingAssetList
          selectedAssetIds={selectedAssetIds}
          assets={filteredAssets}
          selectedModelVersionId={modelVerisonId}
          toggleAssetId={(id) => dispatch(toggleAssetId(id))}
        />
      </Box>
    </Box>
  );
};

import { Select as UISelect, Text } from "@chakra-ui/react";
import React from "react";

type TProps = {
  options: {
    isDisabled?: boolean;
    value: string;
    label: string;
    variant?: string;
  }[];
  onSelect: (value: string) => void;
  isDisabled?: boolean;
  placeholder: string;
  title?: string;
  selected?: string;
  variant?: string;
  size?: string;
};

export const Select: React.FC<TProps> = ({
  isDisabled,
  placeholder,
  onSelect,
  options,
  title,
  selected,
  variant,
  size,
}) => (
  <>
    {title ? (
      <Text pb="0.25rem" fontSize="xs" color="gray.400">
        {title}
      </Text>
    ) : null}
    <UISelect
      data-testid="Select"
      isDisabled={isDisabled}
      variant={variant}
      fontSize="small"
      placeholder={placeholder}
      size={size || "sm"}
      mb="0.8em"
      onChange={(e) => !isDisabled && onSelect(e.target.value)}
      data-cy={`Select-${title?.replace(" ", "-")}`}
      value={options.length === 1 ? options[0].value : selected}
      disabled={options.length < 2 || isDisabled}
    >
      {options.map((c) => (
        <option data-testid="options" key={`option_${c.value}`} value={c.value}>
          {c.label}
        </option>
      ))}
    </UISelect>
  </>
);

import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { IModelSelectionState } from "./slice";

export const selectSlice = (state: RootState) => state.selectedModel;

export const selectSelectedModelId = createDraftSafeSelector(
  [selectSlice],
  // TODO: make CRREM 1.5 (or any other) default
  (state: IModelSelectionState) => state.selectedModelVersionId || 0,
);

import { createSlice } from "@reduxjs/toolkit";
import { fetchAuthInfo } from "../../../thunks/auth";
import { userAccessAdapter } from "./adapter";
import {
  Ii18nError,
  apiErrorToI18nError,
} from "src/redux/interfaces/Ii18nError";

const slice = createSlice({
  name: "companies",
  initialState: {
    ...userAccessAdapter.getInitialState(),
    requestStatus: "initial",
    isLoading: false,
    error: null as null | Ii18nError,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAuthInfo.pending, (state) => {
      userAccessAdapter.removeAll(state);
      state.requestStatus = "pending";
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchAuthInfo.rejected, (state, action) => {
      state.requestStatus = "rejected";
      state.isLoading = false;
      state.error = action.payload
        ? apiErrorToI18nError(action.payload)
        : { errorCode: "GENERIC" };
    });
    builder.addCase(fetchAuthInfo.fulfilled, (state, { payload }) => {
      userAccessAdapter.setMany(state, payload.permissions);
      state.requestStatus = "fulfilled";
      state.isLoading = false;
    });
  },
});

export default slice;

import React from "react";
import {
  Box,
  Text,
  Flex,
  Button,
  useDisclosure,
  SimpleGrid,
  Heading,
} from "@chakra-ui/react";
import { ICompany } from "src/api/open-api";
import AddEntityModal from "../AddEntityModal";
import { AddCompanyForm } from "../AddCompanyForm";

interface IProps {
  company?: ICompany;
}

export const CompanyAdminSidebar: React.FC<IProps> = (props) => {
  const { company } = props;

  const openCompanyFormModal = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    addCompanyOnOpen();
  };

  const {
    isOpen: addCompanyModalOpen,
    onClose: addCompanyOnClose,
    onOpen: addCompanyOnOpen,
  } = useDisclosure();

  const onCompanyFormSubmit = () => {
    addCompanyOnClose();
  };

  const companyInfo = [
    {
      label: "Address",
      value: company?.company_address ?? " - ",
    },
    {
      label: "Country Code",
      value: company?.country_code ?? " - ",
    },
    {
      label: "Business Owner",
      value: company?.business_owner ?? " - ",
    },
    {
      label: "Client Relationship Manager",
      value: company?.client_relationship_manager ?? " - ",
    },
    {
      label: "Project Director",
      value: company?.project_director ?? " - ",
    },
    {
      label: "Project Manager",
      value: company?.project_manager ?? " - ",
    },
    {
      label: "Notes",
      value: company?.notes ?? " - ",
    },
  ];

  return (
    <Box fontSize="small">
      <Text mt="16px" as="h2" color="red.400" fontSize="xs">
        Admin
      </Text>
      <Heading as="h3" fontSize="xl" fontWeight="400" mb="1rem">
        {company ? company.company_name : "Company Details"}
      </Heading>
      <SimpleGrid
        mt="0.75rem"
        mb="1.5rem"
        spacing="1rem"
        columns={{ base: 1, xs: 2 }}
        minChildWidth={{ base: "100%", xs: "7.5rem" }}
        spacingX={4}
        spacingY={2}
      >
        <Button
          w={{ base: "100%" }}
          variant="outlineGray"
          placeSelf={{ base: "center", xs: "inherit" }}
          size="sm"
          onClick={(e) => openCompanyFormModal(e)}
        >
          Edit Company Details
        </Button>
      </SimpleGrid>

      <Text color="red.500" fontWeight="bold" my="0.5rem">
        Company Info.
      </Text>
      {companyInfo.map((data) => {
        return (
          <Flex flexWrap="wrap" key={data.label} my="0.5rem">
            <Text color="gray.400">{data.label}:&nbsp;</Text>
            <Text fontWeight="bold">{data.value}</Text>
          </Flex>
        );
      })}
      <AddEntityModal
        isOpen={addCompanyModalOpen}
        onClose={addCompanyOnClose}
        title={"Edit company details"}
      >
        <AddCompanyForm
          onSubmitCallback={onCompanyFormSubmit}
          company={company}
        />
      </AddEntityModal>
    </Box>
  );
};

import React from "react";
import { Thead, Tr, Th, Checkbox } from "@chakra-ui/react";
import { useAppDispatch } from "src/redux/hooks";
import { toggleAllAssetsIds } from "src/redux/slices/uiState/assetsFilter/slice";
interface IProps {
  filteredAssetsCnt: number;
  selectedAssetIds: number[];
  children: (React.ReactElement | null)[];
}

export const AssetsTableHeader: React.FC<IProps> = ({
  filteredAssetsCnt,
  selectedAssetIds,
  children,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Thead position="sticky" top={0} backgroundColor={"white"} zIndex={100}>
      <Tr>
        <Th w="3%" alignItems="left">
          <Checkbox
            colorScheme="red"
            isChecked={selectedAssetIds.length === filteredAssetsCnt}
            onChange={(e) => dispatch(toggleAllAssetsIds(e.target.checked))}
          />
        </Th>
        <Th>{`${
          filteredAssetsCnt > selectedAssetIds.length
            ? selectedAssetIds.length
            : filteredAssetsCnt
        } Assets`}</Th>
        <Th w="10%">CRREM Property Type</Th>
        <Th w="10%">Country</Th>
        {children}
      </Tr>
    </Thead>
  );
};

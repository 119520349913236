import React from "react";
import { Flex, Box } from "@chakra-ui/react";

interface ICategory {
  share: number;
  color: string;
}

interface IProps {
  categories: ICategory[];
}

const MultiColorBar: React.FC<IProps> = (props) => {
  const { categories } = props;
  const total = categories.map((c) => c.share).reduce((a, b) => a + b, 0);
  return (
    <Flex
      data-testid="MultiColorBar"
      overflow="hidden"
      w="100%"
      flexDir="row"
      borderRadius="0.5rem"
      h="0.5rem"
    >
      {categories.map((cat, i) => {
        return (
          <Box
            data-testid="Box"
            key={i}
            w={(cat.share / total) * 100 + "%"}
            h="100%"
            bg={cat.color}
          />
        );
      })}
    </Flex>
  );
};
export default MultiColorBar;

import React from "react";
import { FormEvent, useState } from "react";

import {
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Box,
  IconButton,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { ITimeSeriesPoint } from "src/api/open-api";
import { Input, NumberInput } from "src/common/components/atoms/Input";
import { IReduxChartTarget } from "src/redux/interfaces/IReduxChartTarget";
import { DeleteModal } from "src/pages/CRUDModals/components/DeleteModal";
import {
  deleteCustomPathway,
  upsertCustomPathway,
} from "src/redux/thunks/customPathways";
import { selectCustomPathwaysError } from "src/redux/slices/portfolios/assets/selectors";
import { clearCustomPathwaysError } from "src/redux/slices/portfolios/assets/slice";
import { ghgIntensityTypeId } from "src/constants/targets";
import { Alert } from "src/common/components/atoms/Alert";
import { useTranslation } from "react-i18next";
import { genErrorMessage } from "src/common/utils/errors";

interface IProps {
  assetId: number;
  customPathway?: IReduxChartTarget;
  isPending: boolean;
  units: string;
}
interface IState {
  [key: number]: number;
}

const composeCustomPathwayInitialState = (
  defaultValues?: ITimeSeriesPoint[],
): IState => {
  const mergedState: IState = {
    2020: 0,
    2030: 0,
    2040: 0,
    2050: 0,
  };
  if (defaultValues) {
    defaultValues.forEach(
      (point: ITimeSeriesPoint) =>
        point.value && (mergedState[point.year] = point.value),
    );
  }
  return mergedState;
};

export const CustomPathwayForm: React.FC<IProps> = ({
  customPathway,
  assetId,
  isPending,
  units,
}) => {
  const error = useAppSelector(selectCustomPathwaysError);
  const [state, setState] = useState<IState>(
    composeCustomPathwayInitialState(customPathway?.timeSeries),
  );
  const [name, setName] = useState(customPathway?.targetName);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    isOpen: deleteModalisOpen,
    onClose: deleteModalOnClose,
    onOpen: deleteModalOnOpen,
  } = useDisclosure();

  const onAddLine = (index: number) => {
    if (
      state[index + 5] === undefined &&
      index + 5 <= 2050 &&
      (index === 2020 || index === 2030 || index === 2040)
    ) {
      setState({ ...state, [index + 5]: 0 });
    } else {
      setState({
        ...state,
        ...(index + 1 <= 2050 && { [index + 1]: 0 }),
      });
    }
  };

  const onRemoveLine = (index: number) => {
    const newState = { ...state };
    delete newState[index];
    setState(newState);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (error) dispatch(clearCustomPathwaysError);
    const pathwayPoints = Object.keys(state).map((key) => ({
      year: Number(key),
      value: state[Number(key)],
    }));
    dispatch(
      upsertCustomPathway({
        assetId,
        customPathway: {
          custom_pathway_id: customPathway?.lineId || undefined,
          intensity_type_id: ghgIntensityTypeId,
          intensity_type_name: "CO2",
          custom_pathway_name: name || "",
          time_series_points: pathwayPoints,
        },
      }),
    );
  };

  const handleTableChange = (val: number, year: number) => {
    if (error) dispatch(clearCustomPathwaysError);
    setState({ ...state, [year]: val });
  };
  const maxYear = Math.max(...Object.keys(state).map((key) => Number(key)));

  const handleDelete = async () => {
    if (customPathway && customPathway.lineId) {
      dispatch(
        deleteCustomPathway({
          assetId: assetId,
          customPathwayId: customPathway.lineId,
        }),
      );
      deleteModalOnClose();
    }
  };

  return (
    <Box>
      <form
        encType="multipart/form-data"
        onSubmit={(e) => {
          handleSubmit(e);
          e.preventDefault();
        }}
      >
        <Input
          title="Pathway name"
          placeholder="Please enter custom pathway name"
          onChange={(e) => setName(e.target.value)}
          defaultValue={customPathway?.targetName}
          variant="outlineMuted"
        />
        <Table variant="simple" mt="1em">
          <Thead textColor="gray.700">
            <Tr>
              <Td>Year</Td>
              <Td>
                <Text display="inline" fontWeight="bold">
                  GHG Emissions Intensity
                </Text>{" "}
                <Text display={{ base: "block", xl: "inline" }}>({units})</Text>
              </Td>
              <Td></Td>
            </Tr>
          </Thead>

          <Tbody>
            {Object.keys(state)
              .map((key) => Number(key))
              .map((key) => (
                <Tr key={key} _hover={{ bg: "white.400" }}>
                  <Td>{key}</Td>
                  <Td position="relative">
                    <NumberInput
                      defaultValue={state[key]}
                      onChange={(val) => handleTableChange(val, key)}
                    />
                    {key < maxYear && state[key + 1] === undefined && (
                      <IconButton
                        colorScheme="red"
                        borderRadius="50%"
                        minWidth="23px"
                        minHeight="23px"
                        icon={<AddIcon fill="white" />}
                        aria-label="Add year"
                        w="23px"
                        h="23px"
                        position="absolute"
                        left="-25px"
                        zIndex="1"
                        bottom="-13px"
                        onClick={() => onAddLine(key)}
                        tabIndex={-1}
                        paddingInline="7px"
                      />
                    )}
                  </Td>
                  <Td>
                    {key !== 2020 &&
                      key !== 2030 &&
                      key !== 2040 &&
                      key !== 2050 && (
                        <IconButton
                          colorScheme="gray"
                          borderRadius="50%"
                          minWidth="23px"
                          minHeight="23px"
                          icon={<MinusIcon />}
                          aria-label="Remove year"
                          w="23px"
                          h="23px"
                          zIndex="1"
                          onClick={() => onRemoveLine(key)}
                          tabIndex={-1}
                          paddingInline="7px"
                        >
                          Remove
                        </IconButton>
                      )}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
        {error ? (
          <Alert message={genErrorMessage(error, t, "")} isError mt="1em" />
        ) : null}
        <Flex width="100%" mt="2rem" direction="row" justifyContent="center">
          <Button
            type="submit"
            variant="outlinePrimary"
            marginRight="5px"
            disabled={isPending}
          >
            Save
          </Button>
          {customPathway && (
            <>
              <Button
                variant="outlineGrey"
                placeSelf={{ base: "center" }}
                onClick={deleteModalOnOpen}
                type="button"
              >
                Delete Pathway
              </Button>
            </>
          )}
        </Flex>
      </form>
      <DeleteModal
        title="Delete custom pathway"
        objectDescription="this custom pathway"
        isOpen={deleteModalisOpen}
        onClose={deleteModalOnClose}
        onDelete={handleDelete}
        isLoading={false}
      />
    </Box>
  );
};

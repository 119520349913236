import React from "react";
import {
  Box,
  Text,
  Icon,
  Button,
  Flex,
  Grid,
  GridItem,
  useDisclosure,
} from "@chakra-ui/react";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import TaxonomyInfoModal from "src/pages/PortfolioView/components/Taxonomy/TaxonomyInfoModal";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { decimalToPercent } from "src/common/utils/math";

interface IProps {
  assets: IAppAsset[];
  icon: React.FC;
  label: string;
  category: TaxonomyCategory;
}

type TaxonomyCategory = "ccm" | "cca" | "tce" | "wrm" | "ppc" | "bde";

const data: {
  [key: string]: {
    substantialContribution: string;
    doNotSignificantlyHarm?: string[];
  };
} = {
  ccm: {
    substantialContribution: "EPC Class A or top 15% of the market",
    doNotSignificantlyHarm: [
      "Risk assessment",
      "Implement adaptation measures in 5 years",
    ],
  },
  cca: {
    substantialContribution:
      "Risk assessment & Implemented physical and non-physical solutions",
    doNotSignificantlyHarm: [
      "Min EPC class C",
      "Building not dedicated to fossil fuels (extraction, storage, transport or manufacture)",
    ],
  },
  tce: {
    substantialContribution: "TBC",
  },
  wrm: {
    substantialContribution: "TBC",
  },
  ppc: {
    substantialContribution: "TBC",
  },
  bde: {
    substantialContribution:
      "Biodiversity strategy or management plan for the site",
    doNotSignificantlyHarm: [
      "Risk assessment",
      "Implement adaptation measures in 5 years",
    ],
  },
};
const TaxonomyCard: React.FC<IProps> = (props) => {
  const { assets, icon, label, category } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const ref = data[category];
  const substantial = ref.substantialContribution;

  const getPercentageSubstantialContributionCompliance = (
    assets: IAppAsset[],
    category: TaxonomyCategory,
  ) => {
    const relevant = assets.filter(
      (a) => a.taxonomy?.substantial_contribution_type === category,
    );
    const compliant = relevant.filter(
      (a) => a.taxonomy?.substantial_contribution_compliance,
    );
    return !relevant.length
      ? 0
      : decimalToPercent(compliant.length / relevant.length);
  };

  const relevant = assets
    .map(
      (a) =>
        a.taxonomy?.doNoSignificantHarm[
          category as unknown as "cca" | "ccm" | "bde"
        ],
    )
    .filter((c) => c?.length && (c[0] !== undefined || c[1] !== undefined));
  const harmCompliance = [
    relevant.filter((c) => c && c[0] !== false).length,
    relevant.filter((c) => c && c[1] !== false).length,
  ];

  return (
    <>
      <Box bg="white.500" borderRadius="0.5rem" p={{ base: 1, xl: 4 }} h="100%">
        <Grid w="100%" gap={3}>
          {/* Header */}
          <GridItem pb={2}>
            <Flex
              w="100%"
              direction={{ base: "column", sm: "row" }}
              justify="space-between"
              align="center"
            >
              <Box
                border="2px solid transparent"
                borderRadius="100%"
                padding="2px"
                style={{ aspectRatio: "1 / 1" }}
              >
                <Icon as={icon} fill="gray.700" boxSize="40px" />
              </Box>
              <Button
                leftIcon={<InfoOutlineIcon color="gray.500" />}
                color="gray.500"
                size="sm"
                variant="ghost"
                alignSelf="center"
                _hover={{ bg: "white.400", cursor: "pointer" }}
                fontSize="small"
                onClick={onOpen}
              >
                Info
              </Button>
            </Flex>
          </GridItem>
          {/* Label */}
          <GridItem pb={2}>
            <Text
              color="gray.800"
              fontSize={{ base: ".8rem", xl: "1rem" }}
              fontWeight="bold"
            >
              {label}
            </Text>
          </GridItem>
          {/* Substantial Contribution */}
          {substantial && (
            <GridItem pb={2} color="#D10008">
              <Grid>
                <GridItem mb={1}>
                  <Text fontSize="xs" fontWeight="bold">
                    Substantial Contribution
                  </Text>
                </GridItem>
                <GridItem>
                  <Grid templateColumns="repeat(10, 1fr)">
                    <GridItem colSpan={3}>
                      <Text fontSize="xs" fontWeight="bold">
                        {`${Math.round(
                          getPercentageSubstantialContributionCompliance(
                            assets,
                            category,
                          ),
                        )} %`}
                      </Text>
                    </GridItem>
                    <GridItem colSpan={7}>
                      <Text fontSize="xs">{substantial}</Text>
                    </GridItem>
                  </Grid>
                </GridItem>
              </Grid>
            </GridItem>
          )}
          {data[category].doNotSignificantlyHarm && (
            <GridItem pb={2} color="gray.800">
              <Grid>
                <GridItem>
                  <Text fontSize="xs" fontWeight="bold">
                    Do no significant harm
                  </Text>
                </GridItem>
                {data[category].doNotSignificantlyHarm?.map((item, index) => (
                  <GridItem key={`doNotSignificantlyHarm${index}`} mb={0.5}>
                    <Grid templateColumns="repeat(10, 1fr)">
                      <GridItem colSpan={3}>
                        <Text fontSize="small" fontWeight="bold">
                          {`${decimalToPercent(
                            (harmCompliance[index] || 0) / (assets.length || 1),
                          )}%`}
                        </Text>
                      </GridItem>
                      <GridItem colSpan={7}>
                        <Text fontSize="small">{item}</Text>
                      </GridItem>
                    </Grid>
                  </GridItem>
                ))}
              </Grid>
            </GridItem>
          )}
        </Grid>
      </Box>
      <TaxonomyInfoModal
        isOpen={isOpen}
        onClose={onClose}
        category={category}
      />
    </>
  );
};
export default TaxonomyCard;

import React from "react";
import { Box } from "@chakra-ui/react";

export const NabersRating: React.FC<{
  rating: number;
}> = ({ rating }) => {
  return (
    <Box
      data-testid="NabersRating"
      className="star-ratings"
      m="0"
      p="0"
      width="fit-content"
    >
      <div
        data-testid="fill-ratings"
        className="fill-ratings"
        style={{ width: (rating * 100) / 6 + "%" }}
      >
        <span>★★★★★★</span>
      </div>
      <div data-testid="empty-ratings" className="empty-ratings">
        <span>★★★★★★</span>
      </div>
    </Box>
  );
};

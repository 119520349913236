import { IBenchmark } from "../components/molecules/PerformanceTable/references";
import { Performance } from "../components/molecules/PerformanceTable/references";

export const getCapexPerformance = (
  unitCost: number,
  benchmark: IBenchmark,
): Performance => {
  if (unitCost > benchmark[Performance.Medium]) {
    return Performance.Low;
  } else if (unitCost > benchmark[Performance.High]) {
    return Performance.Medium;
  } else {
    return Performance.High;
  }
};

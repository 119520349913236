import { EntityState, createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { allUsersAdapter } from "./adapter";
import { ICrispUser } from "src/api/open-api";

const selectSlice = (state: RootState) => state.allUsers;

export const selectIsAppUsersPending = createDraftSafeSelector(
  [selectSlice],
  (state) => state.requestStatus === "pending",
);

export const selectAllCrispUsers = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<ICrispUser>) =>
    allUsersAdapter.getSelectors().selectAll(state),
);

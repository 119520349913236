import React from "react";
import { Table, Th, Td, Box } from "@chakra-ui/react";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { AssetsTableHeader } from "./components/AssetsTableHeader";
import { AssetsTableBody } from "./components/AssetsTableBody";
import { SustainabilityScoreBar } from "./components/SustainabilityScoreBar";
import {
  LeedBadge,
  WellBadge,
  DgnbBadge,
} from "src/common/components/atoms/CertificationBadge";

interface IProps {
  assets: IAppAsset[];
  selectedAssetIds: number[];
}

const certificationLabels = {
  leed: ["certified", "silver", "gold", "platinum"],
  well: ["silver", "gold", "platinum"],
  dgnb: ["bronze", "silver", "gold", "platinum"],
};

export const SustainabilityList: React.FC<IProps> = (props) => {
  const { assets, selectedAssetIds } = props;

  return (
    <Box overflowY="auto" maxHeight="500px">
      <Table variant="simple" width="100%" backgroundColor="white">
        <AssetsTableHeader
          selectedAssetIds={selectedAssetIds}
          filteredAssetsCnt={assets.length}
        >
          <Th>LEED</Th>
          <Th>WELL</Th>
          <Th>DGNB</Th>
        </AssetsTableHeader>
        <AssetsTableBody
          assets={assets}
          selectedAssetIds={selectedAssetIds}
          tabName="sustainability"
          renderCustomTds={(asset) => {
            // Check if certifications are defined and not undefined
            const leedCertification = asset.certifications?.leed || "";
            const wellCertification = asset.certifications?.well || "";
            const dgnbCertification = asset.certifications?.dgnb || "";

            // Find the index of the certification label within the mappings
            const leedIdx = certificationLabels.leed.indexOf(leedCertification);
            const wellIdx = certificationLabels.well.indexOf(wellCertification);
            const dgnbIdx = certificationLabels.dgnb.indexOf(dgnbCertification);

            return (
              <>
                <Td>
                  <SustainabilityScoreBar
                    badges={[
                      <LeedBadge key="0" size="sm" level="certified" />,
                      <LeedBadge key="1" size="sm" level="silver" />,
                      <LeedBadge key="2" size="sm" level="gold" />,
                      <LeedBadge key="3" size="sm" level="platinum" />,
                    ]}
                    value={
                      leedIdx >= 0 && leedIdx <= 3
                        ? (leedIdx as 0 | 1 | 2 | 3)
                        : undefined
                    }
                  />
                </Td>
                <Td>
                  <SustainabilityScoreBar
                    badges={[
                      <WellBadge key="0" size="sm" level="silver" />,
                      <WellBadge key="1" size="sm" level="gold" />,
                      <WellBadge key="2" size="sm" level="platinum" />,
                    ]}
                    value={
                      wellIdx >= 0 && wellIdx <= 3
                        ? (wellIdx as 0 | 1 | 2)
                        : undefined
                    }
                  />
                </Td>
                <Td>
                  <SustainabilityScoreBar
                    badges={[
                      <DgnbBadge key="0" size="sm" level="bronze" />,
                      <DgnbBadge key="1" size="sm" level="silver" />,
                      <DgnbBadge key="2" size="sm" level="gold" />,
                      <DgnbBadge key="3" size="sm" level="platinum" />,
                    ]}
                    value={
                      dgnbIdx >= 0 && dgnbIdx <= 3
                        ? (dgnbIdx as 0 | 1 | 2 | 3)
                        : undefined
                    }
                    isSquare
                  />
                </Td>
              </>
            );
          }}
        />
      </Table>
    </Box>
  );
};

export default SustainabilityList;

export const sum = (arr: number[]) => arr.reduce((a, b) => a + b, 0);
export const avg = (arr: number[]) => sum(arr) / arr.length;

// rounding
export const closest10 = (number: number) => Math.round(number / 10) * 10;
export const closest50 = (number: number) => Math.round(number / 50) * 50;
const closest500 = (number: number) => Math.round(number / 500) * 500;
const closest1k = (number: number) => Math.round(number / 1000) * 1000;
const closest10k = (number: number) => Math.round(number / 10000) * 10000;
const closest50k = (number: number) => Math.round(number / 50000) * 50000;
const closest500k = (number: number) => Math.round(number / 500000) * 500000;

export const roundMonetary = (value: number) => {
  if (!value) return 0;
  const abs = Math.abs(value);
  if (abs > 100000000) {
    return closest500k(value);
  }
  if (abs > 1000000) {
    return closest50k(value);
  }
  if (abs > 100000) {
    return closest10k(value);
  }
  if (abs > 10000) {
    return closest1k(value);
  }
  if (abs > 1000) {
    return closest500(value);
  }
  return value;
};

const roundToTwoDecimals = (x: number) => {
  return Math.round(x * 100) / 100;
};
export const roundTo = (number?: number, decimalPlaces?: number) => {
  if (!decimalPlaces || decimalPlaces === 2)
    return roundToTwoDecimals(number || 0);
  const multiplier = Math.pow(10, decimalPlaces || 2);
  return Math.round((number || 0) * multiplier) / multiplier;
};

export const decimalToPercent = (number?: number) =>
  Math.round((number || 0) * 100);

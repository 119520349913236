import React, { useState, useEffect } from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { ITimeSeriesPoint } from "src/api/open-api";
import { ChartSliderTwoTargets } from "src/common/components/atoms/Slider/SliderTwoTargets";
import { EmissionsChart } from "src/common/components/atoms/Chart/EmissionsChart";
import { Alert } from "src/common/components/atoms/Alert";
import { IReduxChartTarget } from "src/redux/interfaces/IReduxChartTarget";
import { SliderWithHoldingYears } from "src/common/components/atoms/Slider/SliderWithHoldingYear";
import { globalMinYear } from "src/constants";
import {
  maxEmissionsTargetLabel,
  minEmissionsTargetlabel,
} from "src/constants/targetLabels";

interface IProps {
  title?: string;
  units: string;
  year: number;
  setYear: (year: number) => void;
  forecast: ITimeSeriesPoint[];
  targets: IReduxChartTarget[];
  customKey?: number;
}

export const StrandingChartWithSlider: React.FC<IProps> = ({
  forecast,
  targets,
  year,
  setYear,
  title,
  units,
  customKey,
}) => {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    // This is a workaround to make chart component to rerender on customKey value change if there was an error.
    // Added this because chartjs2 library isn't perfect and I'm paranoid.
    setIsError(false);
  }, [customKey]);
  const isDataFull = targets?.length && forecast?.length;

  if (!isDataFull) {
    return (
      <Flex
        fontSize="small"
        flexDir="column"
        marginRight="0.5rem"
        align="center"
      >
        {title && (
          <Text display="inline" fontWeight="bold" textAlign="center" mb="10px">
            {title}{" "}
          </Text>
        )}
        <Alert
          message="There is no target data for selected asset(s)"
          isError={false}
        />
      </Flex>
    );
  }

  const value = forecast.find((a) => a.year === year)?.value || NaN;

  const allYears = forecast.map((e) => e.year);
  const minYear = Math.max(Math.min(...allYears), globalMinYear);

  const targetLowerEmissions = targets.find(
    (t) => t.targetName === minEmissionsTargetlabel,
  );
  const targetHigherEmissions = targets.find(
    (t) => t.targetName === maxEmissionsTargetLabel,
  );

  const anyTarget = targets && targets[0];

  const handleChartIssue = () => {
    setIsError(true);
  };
  return (
    <Flex
      fontSize="small"
      flexDir="column"
      marginRight="0.5rem"
      justifyContent="space-between"
      align="left"
    >
      <Box>
        <Box ml="0.5rem" textColor="gray.500">
          {title && (
            <Text display="inline" fontWeight="bold">
              {title}{" "}
            </Text>
          )}
          <Text display={{ base: "block", xl: "inline" }}>
            ({units}, {minYear}-2050)
          </Text>
        </Box>
        <Box overflow="hidden" w="100%" pb="1.1rem">
          {!isNaN(value) && !isError ? (
            <EmissionsChart
              year={year}
              targets={targets}
              forecast={forecast}
              criticalIssueCallback={handleChartIssue}
            />
          ) : (
            <Box my="1.5rem">
              <Alert
                message={
                  isError
                    ? "Something went wrong, try reloading"
                    : "No value in the forecast data for selected year"
                }
              />
            </Box>
          )}
          <Box w="100%" mt="1rem" pl={9} pr={4}>
            {targetLowerEmissions && targetHigherEmissions ? (
              <ChartSliderTwoTargets
                year={year}
                setYear={setYear}
                minYear={minYear}
                targetLowerEmissions={targetLowerEmissions}
                targetHigherEmissions={targetHigherEmissions}
              />
            ) : anyTarget ? (
              <SliderWithHoldingYears
                year={year}
                setYear={setYear}
                minYear={minYear}
                normalizedStrandingYear={anyTarget.strandingX}
                holdingYears={anyTarget.strandingX >= 2050 ? 0 : 10}
              />
            ) : (
              <Alert message="Target pathways data is not complete" />
            )}
          </Box>
        </Box>
      </Box>
      <Flex justifyContent="space-between" w="100%" alignItems="center">
        <Box p={4}>
          <Text fontWeight="bold">Forecast Value ({year} year)</Text>
          <Box>
            <Text
              color="red.500"
              display="inline"
              fontWeight="bold"
              fontSize="xl"
            >
              {value.toFixed(1)}
            </Text>
            <Text
              color="red.500"
              display="inline"
              fontWeight="bold"
              fontSize={{ lg: "md", xl: "lg" }}
            >
              &nbsp;{units}
            </Text>
          </Box>
        </Box>
        <Box marginLeft="1rem">
          {targets.map((line) => (
            <Flex key={line.targetName}>
              <Box alignSelf="center" w="1.5rem" h="2px" bg={line.lineColor} />
              <Text color="gray.500" marginLeft="1rem" mb="0.3rem">
                {line.targetName}
              </Text>
            </Flex>
          ))}
          <Flex>
            <Box alignSelf="center" w="1.5rem" h="2px" bg="gray.500" />
            <Text color="gray.500" marginLeft="1rem" mb="0.3rem">
              Forecast
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

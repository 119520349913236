import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
  variants: {
    simple: {
      tr: { textColor: "grey.800", h: "2rem" },
      th: {
        fontFamily: "body",
        textTransform: "none",
        letterSpacing: "none",
        borderColor: "gray.100",
      },
      td: {
        borderBottom: "1px",
        borderColor: "gray.100",
      },
    },
  },
  sizes: {
    xs: {
      th: {
        px: "4",
        py: "1",
        lineHeight: "5",
        fontSize: "small",
      },
      td: {
        px: "4",
        py: "1.5",
        fontSize: "small",
        lineHeight: "5",
      },
      caption: {
        px: "4",
        py: "2",
        fontSize: "xs",
      },
    },
    sm: {
      th: {
        px: "2",
        py: "1",
        lineHeight: "5",
        fontSize: "small",
      },
      td: {
        px: "2",
        py: "1",
        fontSize: "small",
        lineHeight: "5",
      },
    },
  },
  defaultProps: {
    variant: "simple",
    size: "xs",
  },
};

export default componentOverride;

import { Image, Flex, Text } from "@chakra-ui/react";
import React from "react";
import ArupLogo from "src/assets/img/arup_red.svg";
import { HeaderNavMenu } from "./HeaderNavMenu";
import {
  signOut as signOutAction,
  authReset,
  authSetError,
} from "src/redux/slices/auth/authInfo/slice";
import { signOut } from "src/common/utils/auth/cognitoAuth";
import { errorReporter } from "src/common/utils/errorReporter/errorReporter";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  selectAuthData,
  selectCanAccessAppAdminLevel,
} from "src/redux/slices/auth/authInfo/selectors";
import { selectAmICompanyAdmin } from "src/redux/slices/auth/userAccess/selectors";
import { Link } from "react-router-dom";

export const AppHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const { userName, isAppAdmin, isSystemAdmin } =
    useAppSelector(selectAuthData);
  const isCompanyAdmin = useAppSelector(selectAmICompanyAdmin);
  const canEditCompanies = useAppSelector(selectCanAccessAppAdminLevel);
  const shouldSeeAdminMenu = isAppAdmin || isSystemAdmin || isCompanyAdmin;
  const handleSignOut = () => {
    dispatch(signOutAction());
    signOut()
      .then(() => {
        dispatch(authReset());
      })
      .catch((error: Error) => {
        errorReporter({
          stack: JSON.stringify(error),
          message: "Signout request error",
          activeUrl: window?.location?.pathname,
          context: "AppHeader signOutAction",
        });
        dispatch(authSetError(error));
      });
  };

  return (
    <Flex
      width="100%"
      height="52px"
      padding={{ base: "1rem", sm: "0.875rem 2.3rem" }}
      backgroundColor="white"
      justifyContent="space-between"
      alignItems="center"
      boxShadow="base"
    >
      <Flex textAlign={{ base: "center", md: "left" }} direction="row">
        <Text
          fontFamily="Times New Roman"
          color="red.400"
          fontSize="heading2"
          marginRight="0.5em"
        >
          <Link to="/">CRISP</Link>
        </Text>
        <Text
          alignSelf="flex-end"
          fontFamily="Times New Roman"
          color="gray.600"
          fontSize={{ base: "0.8em", md: "1em" }}
          display={{ base: "none", sm: "block" }}
        >
          <Link to="/">Carbon Risk Impact Screening of Portfolios</Link>
        </Text>
      </Flex>
      <Flex direction="row" justifyContent="center" alignItems="center">
        <HeaderNavMenu
          shouldSeeAdminMenu={shouldSeeAdminMenu}
          onSignOut={handleSignOut}
          userName={userName}
          canEditCompanies={canEditCompanies}
        />
        <Image
          src={ArupLogo}
          alt="Arup logo"
          w="57px"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          commit_details="@@GIT_COMMIT_DATA@@"
        />
      </Flex>
    </Flex>
  );
};

import { ITimeSeriesPoint } from "src/api/open-api";
import { globalMaxYear, globalMinYear } from "src/constants";
import { cutTimeSeries } from "./cutTimeSeries";
import { roundTo } from "../math";

// This is an unsafe function, it expects timeseries to be processed by normalizeTimeSeries
// (which typically happens in thunk tranform fucntion right after API calls, for performance reasons)
export const sumOfPathways = (
  a: ITimeSeriesPoint[] | undefined,
  b: ITimeSeriesPoint[] | undefined,
): ITimeSeriesPoint[] => {
  if (!a?.length || !b?.length)
    return (a?.length && a) || (b?.length && b) || [];

  const [normA, normB] = cutTimeSeries(a, b);

  const result: ITimeSeriesPoint[] = [];
  normA.forEach((point, index) => {
    result.push({
      year: point.year,
      value: point.value + ((normB[index] && normB[index].value) || 0),
    });
  });
  return result;
};

// Params: forecast, target = lines to intersect
// intersection - pre-calculated not normalized intersection point, x & y might be NaN if lines to not intersect
// result: strandingX = stranding year or min year or max year, strandingY = stranding value. Might be undefined if lines do not intersect.
const _normalizeStrandingPoint = (
  forecast: ITimeSeriesPoint[],
  target: ITimeSeriesPoint[],
  intersection: { x: number; y: number },
): { strandingX: number; strandingY?: number } => {
  return {
    strandingX: !isNaN(intersection.x)
      ? roundTo(intersection.x, 2)
      : forecast?.length &&
        target?.length &&
        forecast[0].value > target[0].value
      ? globalMinYear - 1
      : globalMaxYear + 1,
    strandingY: !isNaN(intersection.y) ? roundTo(intersection.y, 2) : undefined,
  };
};

// This is an unsafe function, it expects timeseries to be processed by normalizeTimeSeries
// (which typically happens in thunk tranform fucntion right after API calls, for performance reasons)
// IMPORTANT NOTICE: our interest is point when line goes above target.
export const intersectTimeSeries = (
  line: ITimeSeriesPoint[],
  target: ITimeSeriesPoint[],
): { strandingX: number; strandingY?: number } => {
  if (!line?.length || !target?.length)
    return _normalizeStrandingPoint(line, target, {
      x: NaN,
      y: NaN,
    });
  // Make arrays same length
  const [cts1, cts2] = cutTimeSeries(line, target);

  const smaller = cts1;
  const larger = cts2;

  // Find index at which the smaller list value is greater than the larger list item value
  const id = smaller.findIndex(
    (smaller, idx) => smaller.value >= (larger[idx] && larger[idx].value),
  );

  const x1 = smaller[id]?.year;
  const y1 = smaller[id]?.value;
  const x2 = smaller[id - 1]?.year;
  const y2 = smaller[id - 1]?.value;
  const x3 = larger[id]?.year;
  const y3 = larger[id]?.value;
  const x4 = larger[id - 1]?.year;
  const y4 = larger[id - 1]?.value;

  const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

  const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;

  const rawStrandingPoint = { x: x1 + ua * (x2 - x1), y: y1 + ua * (y2 - y1) };

  return _normalizeStrandingPoint(line, target, rawStrandingPoint);
};

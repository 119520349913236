import React from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";

export const BooleanIcon: React.FC<{ isTrue?: boolean }> = ({ isTrue }) => {
  return isTrue ? (
    <CheckCircleIcon
      w={4}
      h={4}
      fill="green.400"
      data-testid="CheckCircleIcon"
    />
  ) : (
    <>-</>
  );
};

import React from "react";
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  OrderedList,
  UnorderedList,
  ListItem,
  Link,
} from "@chakra-ui/react";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const CCAInfoModal: React.FC<IProps> = (props) => {
  const { isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Link
            href="https://ec.europa.eu/finance/docs/level-2-measures/taxonomy-regulation-delegated-act-2021-2800-annex-2_en.pdf"
            isExternal
          >
            <Text fontSize="1rem">Technical screening criteria Annex 2</Text>
          </Link>
        </ModalHeader>
        <ModalCloseButton size="lg" style={{ boxShadow: "none" }} />
        <ModalBody>
          <Text fontWeight="bold" fontSize="1rem" marginBottom={2}>
            7.7. Acquisition and ownership of buildings
          </Text>
          <Text fontWeight="bold" marginBottom={2}>
            Description of the activity
          </Text>
          <Text marginBottom={3}>
            Buying real estate and exercising ownership of that real estate. The
            economic activities in this category could be associated with NACE
            code L68 in accordance with the statistical classification of
            economic activities established by Regulation (EC) No 1893/2006
          </Text>
          <Text fontWeight="bold" marginBottom={3}>
            Substantial contribution to climate change adaptation
          </Text>
          <OrderedList marginBottom={3}>
            <ListItem marginBottom={2}>
              The economic activity has implemented physical and non-physical
              solutions (‘adaptation solutions’) that substantially reduce the
              most important physical climate risks that are material to that
              activity.
            </ListItem>
            <ListItem marginBottom={2}>
              The physical climate risks that are material to the activity have
              been identified from those listed in Appendix A to this Annex by
              performing a robust climate risk and vulnerability assessment with
              the following steps.
              <OrderedList styleType="lower-latin">
                <ListItem>
                  screening of the activity to identify which physical climate
                  risks from the list in Appendix A to this Annex may affect the
                  performance of the economic activity during its expected
                  lifetime;
                </ListItem>
                <ListItem>
                  where the activity is assessed to be at risk from one or more
                  of the physical climate risks listed in Appendix A to this
                  Annex, a climate risk and vulnerability assessment to assess
                  the materiality of the physical climate risks on the economic
                  activity;
                </ListItem>
                <ListItem>
                  an assessment of adaptation solutions that can reduce the
                  identified physical climate risk.
                </ListItem>
              </OrderedList>
              The climate risk and vulnerability assessment is proportionate to
              the scale of the activity and its expected lifespan, such that:
              <OrderedList styleType="lower-latin">
                <ListItem>
                  for activities with an expected lifespan of less than 10
                  years, the assessment is performed, at least by using climate
                  projections at the smallest appropriate scale;
                </ListItem>
                <ListItem>
                  for all other activities, the assessment is performed using
                  the highest available resolution, state-of-the-art climate
                  projections across the existing range of future scenarios612
                  consistent with the expected lifetime of the activity,
                  including, at least, 10 to 30 year climate projections
                  scenarios for major investments.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem marginBottom={2}>
              . The climate projections and assessment of impacts are based on
              best practice and available guidance and take into account the
              state-of-the-art science for vulnerability and risk analysis and
              related methodologies in line with the most recent
              Intergovernmental Panel on Climate Change reports613, scientific
              peer-reviewed publications and open source614 or paying models
            </ListItem>
            <ListItem>The adaptation solutions implemented:</ListItem>
            <OrderedList styleType="lower-latin">
              <ListItem>
                do not adversely affect the adaptation efforts or the level of
                resilience to physical climate risks of other people, of nature,
                of cultural heritage, of assets and of other economic
                activities;
              </ListItem>
              <ListItem>
                favour nature-based solutions615 or rely on blue or green
                infrastructure616 to the extent possible;
              </ListItem>
              <ListItem>
                are consistent with local, sectoral, regional or national
                adaptation plans and strategies
              </ListItem>
              <ListItem>
                are monitored and measured against pre-defined indicators and
                remedial action is considered where those indicators are not
                met; (e) where the solution implemented is physical and consists
                in an activity for which technical screening criteria have been
                specified in this Annex, the solution complies with the do no
                significant harm technical screening criteria for that activity.
              </ListItem>
            </OrderedList>
          </OrderedList>
          <Text fontWeight="bold" marginBottom={3}>
            Do no significant harm {"('DNSH')"}
          </Text>
          <UnorderedList marginBottom={3}>
            <ListItem>
              <Text fontWeight="bold">Climate change mitigation.</Text>
              The building is not dedicated to extraction, storage, transport or
              manufacture of fossil fuels. For buildings built before 31
              December 2020, the building has at least an Energy Performance
              Certificate (EPC) class C. As an alternative, the building is
              within the top 30% of the national or regional building stock
              expressed as operational Primary Energy Demand (PED) and
              demonstrated by adequate evidence, which at least compares the
              performance of the relevant asset to the performance of the
              national or regional stock built before 31 December 2020 and at
              least distinguishes between residential and non-residential
              buildings. For buildings built after 31 December 2020, the Primary
              Energy Demand (PED)617 defining the energy performance of the
              building resulting from the construction does not exceed the
              threshold set for the nearly zero-energy building (NZEB)
              requirements in national regulation implementing Directive
              2010/31/EU. The energy performance is certified using an as built
              Energy Performance Certificate (EPC).
            </ListItem>
            <ListItem>
              <Text fontWeight="bold">
                Sustainable use and protection of water and marine resources.
              </Text>
              N/A
            </ListItem>
            <ListItem>
              <Text fontWeight="bold">Transition to a circular economy.</Text>
              N/A
            </ListItem>
            <ListItem>
              <Text fontWeight="bold">Pollution prevention and control.</Text>
              N/A
            </ListItem>
            <ListItem>
              <Text fontWeight="bold">
                Protection and restoration of biodiversity and ecosystems.
              </Text>
              N/A
            </ListItem>
          </UnorderedList>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default CCAInfoModal;

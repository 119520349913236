import React, { useState, useMemo } from "react";
import {
  VStack,
  Heading,
  Text,
  Box,
  Select,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Button,
  Tbody,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { Option } from "chakra-ui-simple-autocomplete";

import { AccessAutocomplete } from "./AccessAutocomplete";
import { EntitySelect } from "./EntitySelect";
import { IAppCompanyUser } from "src/redux/interfaces/IAppCompanyUser";
import { hardcodedEntityTypes } from "src/constants/accessEntityTypes";
import { selectAllCompanyPermissions } from "src/redux/slices/admin/permissions/selectors";
import { hardcodedPermissionTypes } from "src/constants/accessRolesAndPermissions";
import { AddUserPermissionsRequestInner, ICompany } from "src/api/open-api";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  addCompanyPermissions,
  deleteCompanyPermissions,
  fetchCompanyPermissions,
} from "src/redux/thunks/admin";

interface IProps {
  company?: ICompany;
  users: IAppCompanyUser[];
}

const mapUserToItem = (user: IAppCompanyUser): Option => ({
  value: String(user.user_id),
  label: user.user_name,
});

const availablePermissionTypes = [
  {
    value: hardcodedPermissionTypes.full,
    label: "Full",
  },
  {
    value: hardcodedPermissionTypes.write,
    label: "Write",
  },
  {
    value: hardcodedPermissionTypes.read,
    label: "Read",
  },
];

export const AccessSettings: React.FC<IProps> = ({ company, users }) => {
  const [selectedUsers, setSelectedUsers] = useState<Option[]>([]);
  const [selectedEntities, setSelectedEntities] = useState<Option[]>([]);
  const [selectedEntityType, setSelectedEntityType] = useState<string>();
  const [selectedPermissionType, setSelectedPermissionType] =
    useState<number>();
  const dispatch = useAppDispatch();
  const companyPermissions = useAppSelector(selectAllCompanyPermissions);

  const fullUserList = useMemo(() => users.map(mapUserToItem), [users]);

  const handleSelectedUsers = (selectedItems: Option[]) => {
    setSelectedUsers(selectedItems);
  };

  const handleSelectedEntities = (type: string, selectedItems: Option[]) => {
    setSelectedEntityType(type);
    setSelectedEntities(selectedItems);
  };

  const handleSelectedPermissionType = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedPermissionType(Number(e?.target?.value));
  };

  const isAddPermissionEnabled = useMemo(() => {
    return (
      (selectedEntities.length ||
        selectedEntityType === hardcodedEntityTypes.company) &&
      selectedUsers.length &&
      selectedPermissionType
    );
  }, [
    selectedEntities,
    selectedUsers,
    selectedPermissionType,
    selectedEntityType,
  ]);

  const addPermission = () => {
    const permissionsToAdd = selectedUsers
      .filter((p) => !!p.value)
      .reduce<AddUserPermissionsRequestInner[]>((acc, current) => {
        if (selectedEntityType === hardcodedEntityTypes.company) {
          return [
            ...acc,
            {
              user_id: Number(current.value),
              entity_type_id: Number(selectedEntityType),
              permission_type_id: Number(selectedPermissionType),
              entity_id: Number(company?.company_id),
            },
          ];
        }
        return [
          ...acc,
          ...selectedEntities.map((entity) => ({
            user_id: Number(current.value),
            entity_type_id: Number(selectedEntityType),
            permission_type_id: Number(selectedPermissionType),
            entity_id: Number(entity.value),
          })),
        ];
      }, []);
    dispatch(addCompanyPermissions(permissionsToAdd));

    if (company) {
      dispatch(fetchCompanyPermissions(company.company_id));
    }
  };

  const deletePermission = (id: number) => {
    if (company) {
      dispatch(deleteCompanyPermissions([id]));
    }
  };

  return (
    <>
      <Heading
        as="h3"
        fontSize="heading1"
        fontWeight="normal"
        color="red.500"
        mt="1rem"
      >
        Add permission
      </Heading>
      <VStack
        mt="0.5rem"
        mb="2rem"
        padding="1rem"
        backgroundColor="gray.50"
        borderRadius="8px"
        align="stretch"
      >
        <Grid templateColumns="repeat(12, 1fr)" gap="1.5rem">
          <GridItem colSpan={{ base: 12, md: 6, lg: 4 }}>
            <Text>User</Text>
            <AccessAutocomplete
              items={fullUserList}
              onSelectCallback={handleSelectedUsers}
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, md: 6, lg: 4 }}>
            <EntitySelect handleSelectedEntities={handleSelectedEntities} />
          </GridItem>
          <GridItem colSpan={{ base: 12, md: 6, lg: 4 }}>
            <Text mb="0.5rem">Permission level</Text>
            <Select
              fontSize="small"
              placeholder="Please select permission type"
              size="sm"
              width="100%"
              variant="outlineMuted"
              onChange={handleSelectedPermissionType}
            >
              {availablePermissionTypes.map((permission) => (
                <option key={permission.value} value={permission.value}>
                  {permission.label}
                </option>
              ))}
            </Select>
          </GridItem>
        </Grid>

        <Box textAlign="center">
          <Button
            mt="1rem"
            variant="solidPrimary"
            isDisabled={!isAddPermissionEnabled}
            onClick={addPermission}
          >
            Add
          </Button>
        </Box>
      </VStack>

      <Heading
        as="h3"
        fontSize="heading1"
        fontWeight="normal"
        mt="2rem"
        mb="1rem"
        color="red.500"
      >
        All permissions
      </Heading>
      <Table variant="simple" width="100%">
        <Thead>
          <Tr>
            <Th>User</Th>
            <Th>Entity type</Th>
            <Th>Entity</Th>
            <Th>Permission level</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {companyPermissions.map((permission) => (
            <Tr
              key={permission.company_level_permission_id}
              _hover={{ bg: "white.400" }}
            >
              <Td>{permission.user_name || permission.user_email || ""}</Td>
              <Td>{permission.entity_type_name}</Td>
              <Td>
                {permission.entity_type_name === "company"
                  ? permission.company_name
                  : permission.portfolio_name || ""}
              </Td>
              <Td textTransform="capitalize">
                {permission.permission_type_name}
              </Td>
              <Td w="2rem">
                <Button
                  w={{ base: "100%" }}
                  variant="outlineGrey"
                  placeSelf={{ base: "center", xs: "inherit" }}
                  mb="0.25rem"
                  onClick={() =>
                    deletePermission(permission.company_level_permission_id)
                  }
                >
                  Delete
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

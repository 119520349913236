import React from "react";
import { Button, Text, VStack } from "@chakra-ui/react";
import { ICrispUser } from "src/api/open-api";

interface IProps {
  user?: ICrispUser;
  activateCallback: (user: ICrispUser) => void;
}

export const ActivateUserAlert = (props: IProps) => {
  const { user, activateCallback } = props;

  if (!user) return <></>;

  return (
    <VStack spacing="2rem" justify="center">
      <Text display="inline">
        Are you sure you want to reactivate <b>{user?.user_name}</b>?
      </Text>
      <Button
        justifyContent="space-around"
        w="50%"
        variant="outlinePrimary"
        onClick={() => activateCallback(user)}
      >
        Activate
      </Button>
    </VStack>
  );
};

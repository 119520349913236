export const baseFieldHoverStyles = {
  backgroundColor: "gray.0",
  borderColor: "gray.400",
};

export const baseFieldFocusStyles = {
  backgroundColor: "gray.0",
  borderColor: "gray.300",
  outline: "none",
  boxShadow: "none",
};

export const baseStyle = {
  field: {
    textColor: "gray.900",
    fontFamily: "Arial",
    fontSize: "14px",
    lineHeight: "15px",
    letterSpacing: "0.4px",
    padding: "8px",
  },
  icon: {
    color: "gray.800",
    width: "16px",
    height: "16px",
  },
  element: {
    color: "gray.800",
  },
};

import React from "react";
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  UnorderedList,
  ListItem,
  Link,
  OrderedList,
  Divider,
  Image,
} from "@chakra-ui/react";
import Figure1 from "src/assets/img/TaxonomyAnnexAFigure1.png";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const BDEInfoModal: React.FC<IProps> = (props) => {
  const { isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Link
            href="https://ec.europa.eu/info/sites/default/files/business_economy_euro/banking_and_finance/documents/210803-sustainable-finance-platform-report-technical-screening-criteria-taxonomy-annex_en.pdf"
            isExternal
          >
            <Text fontSize="1rem">Technical screening criteria Annex 5.3</Text>
          </Link>
        </ModalHeader>
        <ModalCloseButton size="lg" style={{ boxShadow: "none" }} />
        <ModalBody>
          <Text fontWeight="bold" fontSize="1rem" marginBottom={2}>
            Acquisition and ownership of buildings
          </Text>
          <Text fontWeight="bold" marginBottom={2}>
            Description of the activity
          </Text>
          <Text marginBottom={3}>
            Buying real estate and exercising ownership of that real estate. The
            economic activities in this category could be associated with NACE
            code L68 in accordance with the statistical classification of
            economic activities established by Regulation (EC) No 1893/2006
          </Text>
          <Text fontWeight="bold" marginBottom={3}>
            Substantial contribution to biodiversity
          </Text>

          <Text>
            A substantial contribution to biodiversity is considered to have
            been made when criteria <b>A</b>, <b>B</b> and <b>C</b> are met:
          </Text>
          <OrderedList styleType="upper-latin">
            <ListItem>
              A biodiversity strategy or biodiversity management plan for the
              site has been produced by a suitably qualified ecologist that
              respects the mitigation hierarchy 413 and addresses, as a minimum:
              <OrderedList>
                <ListItem>
                  Measures taken to protect any species found on the site that
                  are classified by the European and IUCN Red Lists414 as
                  Vulnerable, Endangered or critically endangered, including,
                  where appropriate; protective barriers and/or signage around
                  habitats, guidance for site users and contractors etc. and any
                  other measures deemed necessary by the suitably qualified
                  ecologist.
                </ListItem>
                <ListItem>
                  A plan for ongoing maintenance of green and biodiversity
                  infrastructure included in the development.
                </ListItem>
                <ListItem>
                  Consideration for how the development contributes to the aims
                  and objectives of relevant local, national, regional and
                  international strategies for biodiversity and green
                  infrastructure, including connecting the site to urban green
                  infrastructure networks or corridors, where these exist. And
                  all green infrastructure features have been designed and
                  installed in line with appropriate best practice guidance
                  (examples are listed in footnotes 443 and 445 below)
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              <OrderedList>
                <ListItem>
                  At least 60% of the external horizontal surface area
                  (excluding surface area that is required for renewable energy
                  sources in order to comply with mandatory local requirements),
                  is dedicated to natural habitat or biotopes (eg green
                  roofs415)
                </ListItem>
                <ListItem>
                  At least 80% of all exposed horizonal surfaces on the site
                  (including roofs416) are permeable to water (including open
                  water surfaces, eg ponds etc.).
                </ListItem>
                <ListItem>
                  Provision has been made of additional biodiversity
                  infrastructure such as artificial, building integrated or
                  free-standing nesting boxes for bats and birds and insect
                  habitats (‘insect hotels’). As a minimum, one of each such
                  feature deemed appropriate for the location by the suitably
                  qualified ecologist must be provided per residential unit417
                  or per 100m2 of site for nonresidential development.
                  Compliance may also be demonstrated through the application of
                  a locally applicable Green Space Factor (GSF) 418 method and
                  the appropriate locally defined thresholds for the type of
                  development, provided these are not lower in overall ambition
                  than the above thresholds. Where not already included in the
                  local Green Space Factor (GSF) method, provision must also be
                  made of additional biodiversity infrastructure such as
                  artificial, building-integrated nesting boxes for bats and
                  birds and free-standing or building-integrated insect habitats
                  (‘insect hotels’) as per the requirement above.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              At the point of acquisition, and every two years thereafter, the
              compliance with the biodiversity management plan or equivalent
              instrument and the continued compliance with the thresholds set
              out in criteria B above are controlled by either the relevant
              national competent authorities or by an independent third-party
              certifier, at the request of national authorities or the asset
              owner. The independent third-party certifier may not have any
              conflict of interest with the owner or the funder, and may not be
              involved in the development or operation of the activity. In order
              to reduce costs, audits may be performed together with any green
              building certification scheme or other audit.
            </ListItem>
          </OrderedList>

          <Text fontWeight="bold" marginBottom={3}>
            Do no significant harm {"('DNSH')"}
          </Text>
          <UnorderedList marginBottom={3}>
            <ListItem>
              <Text fontWeight="bold">Climate change mitigation.</Text> N/A.
            </ListItem>
            <ListItem>
              <Text fontWeight="bold">Climate change adaptation.</Text>
              The activity complies with the criteria set out in
              <Text as="span" color="red">
                {" Appendix A "}
              </Text>
              to this Annex.
            </ListItem>
            <ListItem>
              <Text fontWeight="bold">
                Sustainable use and protection of water and marine resources.
              </Text>
              N/A.
            </ListItem>
            <ListItem>
              <Text fontWeight="bold">Transition to circular economy.</Text>
              N/A.
            </ListItem>
            <ListItem>
              <Text fontWeight="bold">Pollution prevention and control.</Text>
              N/A.
            </ListItem>
          </UnorderedList>
          <Text>
            <Text fontWeight="bold" marginBottom={3}>
              Appendix A
            </Text>
            <Text fontWeight="bold" marginBottom={3}>
              GENERIC CRITERIA FOR DNSH TO CLIMATE CHANGE ADAPTATION
            </Text>
            <Text fontWeight="bold" marginBottom={3}>
              I.Criteria
            </Text>
            <Text>
              The physical climate risks that are material to the activity have
              been identified from those listed in the table in Section II of
              this Appendix by performing a robust climate risk and
              vulnerability assessment with the following steps:
            </Text>
            <OrderedList styleType="lower-latin">
              <ListItem>
                screening of the activity to identify which physical climate
                risks from the list in Section II of this Appendix may affect
                the performance of the economic activity during its expected
                lifetime;
              </ListItem>
              <ListItem>
                where the activity is assessed to be at risk from one or more of
                the physical climate risks listed in Section II of this
                Appendix, a climate risk and vulnerability assessment to assess
                the materiality of the physical climate risks on the economic
                activity;
              </ListItem>
              <ListItem>
                an assessment of adaptation solutions that can reduce the
                identified physical climate risk.
              </ListItem>
            </OrderedList>
            <Text>
              The climate risk and vulnerability assessment is proportionate to
              the scale of the activity and its expected lifespan, such that:
            </Text>
            <OrderedList styleType="lower-alpha">
              <ListItem>
                for activities with an expected lifespan of less than 10 years,
                the assessment is performed, at least by using climate
                projections at the smallest appropriate scale;
              </ListItem>
              <ListItem>
                for all other activities, the assessment is performed using the
                highest available resolution, state-of-the-art climate
                projections across the existing range of future scenarios1
                consistent with the expected lifetime of the activity,
                including, at least, 10 to 30 year climate projections scenarios
                for major investments.
              </ListItem>
            </OrderedList>
            <Text>
              The climate projections and assessment of impacts are based on
              best practice and available guidance and take into account the
              state-of-the-art science for vulnerability and risk analysis and
              related methodologies in line with the most recent
              Intergovernmental Panel on Climate Change reports², scientific
              peer-reviewed publications, and open source³ or paying models.
            </Text>
            <Text>
              For existing activities and new activities using existing physical
              assets, the economic operator implements physical and non-physical
              solutions (‘adaptation solutions’), over a period of time of up to
              five years, that reduce the most important identified physical
              climate risks that are material to that activity. An adaptation
              plan for the implementation of those solutions is drawn up
              accordingly.
            </Text>
            <Text>
              For new activities and existing activities using newly-built
              physical assets, the economic operator integrates the adaptation
              solutions that reduce the most important identified physical
              climate risks that are material to that activity at the time of
              design and construction and has implemented them before the start
              of operations.
            </Text>
            <Text>
              The adaptation solutions implemented do not adversely affect the
              adaptation efforts or the level of resilience to physical climate
              risks of other people, of nature, of cultural heritage, of assets
              and of other economic activities; are consistent with local,
              sectoral, regional or national adaptation strategies and plans;
              and consider the use of nature-based solutions4 or rely on blue or
              green infrastructure5 to the extent possible.
            </Text>
            <Text fontWeight="bold" my={3}>
              II.Classification of climate-related hazards
            </Text>
            <Image src={Figure1} />
            <Divider />
            <OrderedList styleType="none">
              <ListItem>
                <sup>1</sup>Future scenarios include Intergovernmental Panel on
                Climate Change representative concentration pathways RCP2.6,
                RCP4.5, RCP6.0 and RCP8.5.
              </ListItem>
              <ListItem>
                <sup>2</sup>Assessments Reports on Climate Change: Impacts,
                Adaptation and Vulnerability, published periodically by the
                Intergovernmental Panel on Climate Change (IPCC), the United
                Nations body for assessing the science related to climate change
                produces reports.
              </ListItem>
              <ListItem>
                <sup>3</sup>Such as Copernicus services managed by the European
                Commission.
              </ListItem>
              <ListItem>
                <sup>4</sup>Nature-based solutions are defined as ‘solutions
                that are inspired and supported by nature, which are
                cost-effective, simultaneously provide environmental, social and
                economic benefits and help build resilience. Such solutions
                bring more, and more diverse, nature and natural features and
                processes into cities, landscapes and seascapes, through locally
                adapted, resource-efficient and systemic interventions’.
                Therefore, nature-based solutions benefit biodiversity and
                support the delivery of a range of ecosystem services. (version
                of [adoption date]: Research and innovation ).
              </ListItem>
              <ListItem>
                <sup>5</sup>See Communication from the Commission to the
                European Parliament, the Council, the European Economic and
                Social Committee and the Committee of the Regions: Green
                Infrastructure (GI) — Enhancing Europe’s Natural Capital
                (COM/2013/0249 final).
              </ListItem>
              <ListItem>
                <sup>6</sup>The list of climate-related hazards in this table is
                non-exhaustive, and constitutes only an indicative list of most
                widespread hazards that are to be taken into account as a
                minimum in the climate risk and vulnerability assessment.
              </ListItem>
            </OrderedList>
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default BDEInfoModal;

import React from "react";
import { Progress, Text, Flex } from "@chakra-ui/react";

interface IProps {
  value: number;
  showLabel?: boolean;
  height?: string;
  color?: string;
}

export const ProgressBar: React.FC<IProps> = (props) => {
  const { value, showLabel, height } = props;
  const color = props.color || "black";
  const colorScheme = color.split(".")[0];
  return (
    <Flex
      data-testid="ProgressBar"
      height={height || ".7rem"}
      width="100%"
      alignItems="center"
      mt="3px"
    >
      <Flex
        flex={1}
        alignSelf="center"
        h="100%"
        margin="auto"
        alignItems="center"
      >
        <Progress
          data-testid="Progress"
          value={value}
          colorScheme={colorScheme}
          w="100%"
          h="100%"
          borderRadius="lg"
        />
      </Flex>

      {showLabel ? (
        <Text
          align="center"
          fontWeight="bold"
          marginLeft="1rem"
          minWidth="2.5rem"
          color={color}
        >
          {value + "%"}
        </Text>
      ) : null}
    </Flex>
  );
};

import { read } from "xlsx";
import parseAssets from "./parseAssets";
import { parsePortfolio } from "./parsePortfolio";

export const parsePortfolioExcel = async (file: File, companyId?: number) => {
  if (companyId === undefined) throw new Error("Company is not selected");

  // Parse excel data
  const filedata = await file.arrayBuffer();

  const workbook = read(filedata, { type: "array" });
  const portfolio = parsePortfolio(workbook.Sheets["Portfolio"], companyId);
  const assets = await parseAssets(workbook.Sheets["Assets"]);
  return {
    assets,
    portfolio,
  };
};

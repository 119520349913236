import { sum } from "src/common/utils/math";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";

export const getConsumptionShares = (
  assetsEnergiesConsumption: IAppAsset["energiesConsumption"][],
) => {
  const gasPower = sum(assetsEnergiesConsumption.map((it) => it.gas));
  const oilPower = sum(assetsEnergiesConsumption.map((it) => it.oil));
  const othersPower = sum(
    assetsEnergiesConsumption.map((it) => it.otherTypeValue || 0),
  );
  const electricity = sum(
    assetsEnergiesConsumption.map((it) => it.electricity),
  );
  const districtHeatPower = sum(
    assetsEnergiesConsumption.map((it) => it.heating),
  );
  const districtCoolPower = sum(
    assetsEnergiesConsumption.map((it) => it.cooling),
  );

  const renewableElectricityConsumed = sum(
    assetsEnergiesConsumption.map(
      (it) => it.onSiteRenewable?.electricity?.consumed || 0,
    ),
  );
  const renewableNonElectricityConsumed = sum(
    assetsEnergiesConsumption.map(
      (it) => it.onSiteRenewable?.others?.consumed || 0,
    ),
  );
  const renewableElectricityExported = sum(
    assetsEnergiesConsumption.map(
      (it) => it.onSiteRenewable?.electricity?.exported || 0,
    ),
  );
  const renewableNonElectricityExported = sum(
    assetsEnergiesConsumption.map(
      (it) => it.onSiteRenewable?.others?.exported || 0,
    ),
  );

  const consumedRenewables =
    renewableElectricityConsumed + renewableNonElectricityConsumed;
  const exportedRenewables =
    renewableElectricityExported + renewableNonElectricityExported;
  const fossilFuels = gasPower + oilPower + othersPower;
  const district = districtHeatPower + districtCoolPower;
  const totalEnergy =
    consumedRenewables +
    exportedRenewables +
    electricity +
    fossilFuels +
    district;

  return {
    consumedRenewables: consumedRenewables,
    exportedRenewables: exportedRenewables,
    electricity: electricity,
    fossilFuels: fossilFuels,
    district: district,
    totalEnergy: totalEnergy,
  };
};

import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/auth/authInfo/slice";
import userAccessSlice from "./slices/auth/userAccess/slice";
import portfoliosFilterSlice from "./slices/uiState/portfoliosFilter/slice";
import assetsFilterSlice from "./slices/uiState/assetsFilter/slice";
import selectedModelSlice from "./slices/uiState/modelSelection/slice";
import portfoliosSlice from "./slices/portfolios/slice";
import assetsSlice from "./slices/portfolios/assets/slice";
import recalcSlice from "./slices/admin/recalc/slice";
import countriesSlice from "./slices/countries/slice";
import companiesSlice from "./slices/admin/companies/slice";
import permissionsSlice from "./slices/admin/permissions/slice";
import usersAdminRolesSlice from "./slices/admin/usersAdminRoles/slice";
import allUsersSlice from "./slices/admin/usersAll/slice";
import companyUsersSlice from "./slices/admin/usersCompany/slice";
import holdingYearsSlice from "./slices/uiState/holdingYears/slice";
import modelSelectionSlice from "./slices/uiState/modelSelection/slice";
import customPathwaysSelectionSlice from "./slices/uiState/customPathwaysSelection/slice";
import scenariosSlice from "./slices/portfolios/scenarios/slice";
import archetypesSlice from "./slices/portfolios/archetypes/slice";
import versionSlice from "./slices/version/slice";
import userCompaniesSlice from "./slices/companies/slice";
import featureConfigSlice from "./slices/featureConfig/slice";

export const store = configureStore({
  reducer: {
    allUsers: allUsersSlice.reducer,
    assets: assetsSlice.reducer,
    assetsFilter: assetsFilterSlice.reducer,
    authUserData: authSlice.reducer,
    companies: companiesSlice.reducer,
    userCompanies: userCompaniesSlice.reducer,
    companyUsers: companyUsersSlice.reducer,
    countries: countriesSlice.reducer,
    holdingYears: holdingYearsSlice.reducer,
    modelSelection: modelSelectionSlice.reducer,
    permisisons: permissionsSlice.reducer,
    portfolios: portfoliosSlice.reducer,
    portfoliosFilter: portfoliosFilterSlice.reducer,
    recalc: recalcSlice.reducer,
    scenarios: scenariosSlice.reducer,
    selectedModel: selectedModelSlice.reducer,
    userAccess: userAccessSlice.reducer,
    usersAdminRoles: usersAdminRolesSlice.reducer,
    archetypes: archetypesSlice.reducer,
    version: versionSlice.reducer,
    featureConfig: featureConfigSlice.reducer,
    customPathwaysSelection: customPathwaysSelectionSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {user: UserState}
export type AppDispatch = typeof store.dispatch;

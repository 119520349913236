import { EntityState, createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { companiesAdapter } from "./adapter";
import { ICompany } from "src/api/open-api";

export const selectSlice = (state: RootState) => state.companies;

export const selectAllCompanies = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<ICompany>) =>
    companiesAdapter.getSelectors().selectAll(state),
);

export const selectCompaniesStateError = createDraftSafeSelector(
  [selectSlice],
  (state) => state.error,
);

export const selectCompaniesStateIsLoading = createDraftSafeSelector(
  [selectSlice],
  (state) => state.isLoading,
);

import React, { useState } from "react";
import { Text, Select } from "@chakra-ui/react";
import { Option } from "chakra-ui-simple-autocomplete";
import { AccessAutocomplete } from "./AccessAutocomplete";
import { hardcodedEntityTypes } from "src/constants/accessEntityTypes";
import { useAppSelector } from "src/redux/hooks";
import { selectFilteredPortfolios } from "src/redux/slices/portfolios/selectors";
import { useParams } from "react-router-dom";

interface IProps {
  handleSelectedEntities: (type: string, entityId: Option[]) => void;
}

const availableEntityTypes = [
  {
    label: "Company",
    value: hardcodedEntityTypes.company,
  },
  {
    label: "Portfolio",
    value: hardcodedEntityTypes.portfolio,
  },
];

export const EntitySelect: React.FC<IProps> = ({ handleSelectedEntities }) => {
  const [selectedType, setSelectedType] = useState<{
    id?: string;
    label?: string;
  }>();
  const { companyId } = useParams<{ companyId: string }>();
  const portfolios = useAppSelector(selectFilteredPortfolios);
  const filteredPortfolios = companyId
    ? portfolios.filter(
        (portfolio) => portfolio.companyId === parseInt(companyId),
      )
    : portfolios;
  const [entities, setEntities] = useState<Option[]>([]);

  const handleSelectedEntityType = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    handleSelectedEntities(e?.target?.value, []);
    if (e?.target?.value) {
      const target = {
        id: e.target.value,
        label: availableEntityTypes.find((t) => t.value === e.target.value)
          ?.label,
      };

      setSelectedType(target);
      if (e.target.value === hardcodedEntityTypes.portfolio) {
        setEntities(
          filteredPortfolios.map((p) => ({
            value: String(p.portfolioId),
            label: p.portfolioName,
          })),
        );
      } else {
        setEntities([]);
      }
    }
  };

  const renderAutocomplete = (type?: string) => {
    if (!type || type === hardcodedEntityTypes.company) {
      return <></>;
    }
    return (
      <AccessAutocomplete
        key={type}
        items={entities}
        onSelectCallback={(selectedItems) =>
          type && handleSelectedEntities(type, selectedItems)
        }
      />
    );
  };

  return (
    <>
      <Text mb="0.5rem">Entity type</Text>
      <Select
        fontSize="small"
        placeholder="Please select entity type"
        size="sm"
        width="100%"
        variant="outlineMuted"
        onChange={handleSelectedEntityType}
        defaultValue={selectedType?.id}
      >
        {availableEntityTypes.map((type) => (
          <option key={type.value} value={type.value}>
            {type.label}
          </option>
        ))}
      </Select>
      {selectedType?.label && renderAutocomplete(selectedType?.id)}
    </>
  );
};

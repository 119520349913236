import React, { useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import CompaniesTable from "./CompaniesTable";
import AddEntityModal from "../AddEntityModal";
import { Pagination } from "src/common/components/molecules/Pagination";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { fetchCompanies } from "src/redux/thunks/companies";
import {
  selectAllCompanies,
  selectCompaniesStateError,
  selectCompaniesStateIsLoading,
} from "src/redux/slices/admin/companies/selectors";
import { AddCompanyForm } from "../AddCompanyForm";
import { selectCanAddCompany } from "src/redux/slices/auth/authInfo/selectors";
import { Alert } from "src/common/components/atoms/Alert";

export const Companies: React.FC = () => {
  const dispatch = useAppDispatch();
  const companies = useAppSelector(selectAllCompanies);
  const reduxError = useAppSelector(selectCompaniesStateError);
  const isLoading = useAppSelector(selectCompaniesStateIsLoading);
  const canEditCompany = useAppSelector(selectCanAddCompany);

  const {
    isOpen: addCompanyModalOpen,
    onClose: addCompanyOnClose,
    onOpen: addCompanyOnOpen,
  } = useDisclosure();

  useEffect(() => {
    dispatch(fetchCompanies()).catch(() => {
      // TODO: nice handling, low-priority
    });
  }, [dispatch]);

  const openCompanyFormModal = () => {
    addCompanyOnOpen();
  };

  const onCompanyFormSubmit = () => {
    addCompanyOnClose();
  };

  return (
    <Box backgroundColor="white" padding="40px" borderRadius="8px">
      <HStack mt="2rem" mb="2rem">
        <Box display="flex" flexDirection="column" flex={1}>
          <Text color="red.500" fontWeight="bold">
            Admin
          </Text>
          <Heading as="h1" fontSize="lg" fontWeight="semi-bold">
            Companies
          </Heading>
        </Box>
        {canEditCompany && (
          <Button
            variant="outlinePrimary"
            placeSelf={{ base: "center" }}
            onClick={openCompanyFormModal}
            disabled={!!reduxError}
          >
            Add Company
          </Button>
        )}
      </HStack>
      <AddEntityModal
        isOpen={addCompanyModalOpen}
        onClose={addCompanyOnClose}
        title={"Add new company"}
      >
        <AddCompanyForm onSubmitCallback={onCompanyFormSubmit} />
      </AddEntityModal>

      {isLoading ? (
        <Flex h="100%" w="100%" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      ) : reduxError ? (
        <Alert isError message="Error loading companies." />
      ) : (
        companies.length > 0 && (
          <Pagination allItems={companies}>
            <CompaniesTable />
          </Pagination>
        )
      )}
    </Box>
  );
};

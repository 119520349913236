import { createAsyncThunk } from "@reduxjs/toolkit";
import { ArchetypesApi } from "src/api/open-api";
import { defaultApiConfiguration } from "src/api/configurations";
import { USE_LOCAL_MOCKS } from "src/config/mocks";
import { mockedArchetype } from "./__mocks__/mockArchetype";
import { AxiosError } from "axios";
import { transformArchetypeToScenario } from "./utils";

const useArcMocks = false;
export const fetchArchetypeByAssetId = createAsyncThunk(
  "archetypeByAsset/fetch",
  async (assetId: number, { rejectWithValue }) => {
    if (USE_LOCAL_MOCKS || useArcMocks) {
      return transformArchetypeToScenario(assetId, mockedArchetype);
    }
    const api = new ArchetypesApi(defaultApiConfiguration);

    let response;
    try {
      response = await api.getArchetypesByAssetId(assetId);
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
    const data = response?.data;

    try {
      return transformArchetypeToScenario(assetId, data);
    } catch (error) {
      return rejectWithValue({ errorCode: "UI_DATA_IS_BROCKEN" });
    }
  },
);

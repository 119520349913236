import React from "react";
import {
  Box,
  AccordionItem as AccordionItemUI,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

type IProps = {
  name: string;
  children: React.ReactElement | React.ReactElement[] | null;
};

export const AccordionItem: React.FC<IProps> = ({ name, children }) => {
  return (
    <AccordionItemUI fontSize="0.8em">
      <h2>
        <AccordionButton
          _focus={{ boxShadow: "0 0 0 2px rgba(255, 0, 0, 0.4)" }}
        >
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontSize="0.9em"
            color="red"
            fontWeight="bold"
          >
            {name}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4} pl="0" pr="0">
        {children}
      </AccordionPanel>
    </AccordionItemUI>
  );
};

import React from "react";
import { Image } from "@chakra-ui/react";

import DGNB from "src/assets/img/DGNB_00.jpg";
import DGNBBr from "src/assets/img/DGNB_01.jpg";
import DGNBSi from "src/assets/img/DGNB_02.jpg";
import DGNBGo from "src/assets/img/DGNB_03.jpg";
import DGNBPl from "src/assets/img/DGNB_04.jpg";

type TWellBadgeTypes = "bronze" | "silver" | "gold" | "platinum";
interface IProps {
  size?: "sm" | "md";
  level?: TWellBadgeTypes;
}

const _getImageByName = (name?: TWellBadgeTypes) => {
  switch (name) {
    case "bronze":
      return DGNBBr;
    case "silver":
      return DGNBSi;
    case "gold":
      return DGNBGo;
    case "platinum":
      return DGNBPl;
    default:
      return DGNB;
  }
};

export const DgnbBadge: React.FC<IProps> = ({ size, level }) => {
  const img = _getImageByName(level);

  return (
    <Image
      data-testid="Badge"
      src={img}
      maxW={
        size === "sm"
          ? { base: "1.2rem", lg: "1.7rem" }
          : { base: "3rem", lg: "4rem" }
      }
      style={{ aspectRatio: `1 / 1` }}
    />
  );
};

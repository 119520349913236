import { Checkbox, CheckboxGroup, Text } from "@chakra-ui/react";
import React from "react";

type TProps = {
  options: {
    value: string;
    label: string;
    isSelected?: boolean;
  }[];
  onSelect: (values: string[]) => void;
  title: string;
};
export const PortfolioFilterCheckboxGroup: React.FC<TProps> = ({
  onSelect,
  options,
  title,
}) => {
  if (options.length === 0) return null;

  return (
    <>
      <Text pb="0.25rem" mt="0.8em" fontSize="xs" color="gray.400">
        {title}
      </Text>
      <CheckboxGroup
        colorScheme="gray"
        defaultValue={options
          .filter((option) => option.isSelected)
          .map((option) => option.value)}
      >
        {options.map((use) => (
          <Checkbox
            spacing="0.75rem"
            w="100%"
            key={use.value}
            value={use.value}
            onChange={(e) => {
              const newValues = options
                .map((option) => ({
                  ...option,
                  isSelected:
                    option.value === e.target.value
                      ? !option.isSelected
                      : option.isSelected,
                }))
                .filter((option) => option.isSelected)
                .map((option) => option.value);
              onSelect(newValues);
            }}
          >
            <Text fontSize="sm" color="gray.700">
              {use.label}
            </Text>
          </Checkbox>
        ))}
      </CheckboxGroup>
    </>
  );
};

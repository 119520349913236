import { IAsset } from "src/api/open-api";
import { IRenewableEnergy } from "src/redux/interfaces/IAppAsset";

export const transformRenewableOnLoad = (
  asset: IAsset,
): IRenewableEnergy | undefined => {
  const electricity =
    !!asset.renewable_electricity_consumed ||
    !!asset.renewable_electricity_exported
      ? {
          consumed: asset.renewable_electricity_consumed || 0,
          exported: asset.renewable_electricity_exported || 0,
        }
      : null;
  const other =
    !!asset.renewable_other_consumed || !!asset.renewable_other_exported
      ? {
          consumed: asset.renewable_other_consumed || 0,
          exported: asset.renewable_other_exported || 0,
        }
      : null;
  if (!electricity && !other) return undefined;
  const renewables: IRenewableEnergy = {};
  if (electricity) renewables.electricity = electricity;
  if (other) renewables.others = other;
  return renewables;
};

import { roundMonetary } from "src/common/utils/math";

export const genTableDisplayValue = (
  value: number,
  units?: string,
  maxFractionDigits?: number,
) =>
  !value
    ? "-"
    : Number(value.toFixed(maxFractionDigits || 1)) +
      (units ? " " + units : "");

export const getTableMonetaryDisplayValue = (
  value: number,
  maxFractionDigits?: number,
  minFractionDigits?: number,
) =>
  !value
    ? 0
    : roundMonetary(value).toLocaleString(undefined, {
        maximumFractionDigits: maxFractionDigits || 0,
        minimumFractionDigits: minFractionDigits || 0,
      });

export const formatMonetaryValue = (
  value: number,
  maxFractionDigits?: number,
  minFractionDigits?: number,
) =>
  value?.toLocaleString(undefined, {
    maximumFractionDigits: maxFractionDigits || 0,
    minimumFractionDigits: minFractionDigits || 0,
  });

import { createSlice } from "@reduxjs/toolkit";
import { permissionsAdapter } from "./adapter";
import {
  addCompanyPermissions,
  deleteCompanyPermissions,
  fetchCompanyPermissions,
  fetchCompanyPermissionsByUserId,
} from "src/redux/thunks/admin";
import {
  Ii18nError,
  apiErrorToI18nError,
} from "src/redux/interfaces/Ii18nError";

// this slice is for company level permissions only, not admin-level things
const slice = createSlice({
  name: "companyPermissions",
  initialState: {
    ...permissionsAdapter.getInitialState(),
    requestStatus: "initial",
    isLoading: false,
    error: null as null | Ii18nError,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyPermissions.pending, (state) => {
      permissionsAdapter.removeAll(state);
      state.requestStatus = "pending";
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchCompanyPermissions.rejected, (state, action) => {
      state.requestStatus = "rejected";
      state.isLoading = false;
      state.error = action.payload
        ? apiErrorToI18nError(action.payload)
        : { errorCode: "GENERIC" };
    });
    builder.addCase(fetchCompanyPermissions.fulfilled, (state, { payload }) => {
      permissionsAdapter.setMany(state, payload);
      state.requestStatus = "fulfilled";
      state.isLoading = false;
    });
    builder.addCase(
      fetchCompanyPermissionsByUserId.fulfilled,
      (state, { payload }) => {
        permissionsAdapter.upsertMany(
          state,
          payload.filter((p) => !!p),
        );
        state.requestStatus = "fulfilled";
        state.isLoading = false;
        state.error = null;
      },
    );
    builder.addCase(addCompanyPermissions.fulfilled, (state, { payload }) => {
      permissionsAdapter.upsertMany(state, payload);
    });
    builder.addCase(
      deleteCompanyPermissions.fulfilled,
      (state, { payload }) => {
        permissionsAdapter.removeMany(state, payload);
      },
    );
  },
});

export default slice;

import { Button } from "@chakra-ui/button";
import { Grid, GridItem, Flex, Box } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/react";
import React from "react";
import { Select } from "src/common/components/atoms/Select";
import { updateFilter } from "src/redux/slices/uiState/portfoliosFilter/slice";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { selectSelectedCountry } from "src/redux/slices/uiState/portfoliosFilter/selectors";

export const Stylebook = () => {
  const dispatch = useAppDispatch();
  const selectedCountry = useAppSelector(selectSelectedCountry);
  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  return (
    <Box bg="white" p="25px">
      <Grid templateRows="repeat(2, 1fr)" gap={4}>
        <GridItem>
          <Text textAlign="center">Buttons</Text>
          <Button variant="solidPrimary">solidPrimary</Button>
          <Button variant="solidPrimary" isDisabled={true}>
            isDisabled
          </Button>
          <Button variant="outlinePrimary">outlinePrimary</Button>
          <Button variant="outlineGray">outlineGray</Button>
          <Button variant="outlineGray" isDisabled={true}>
            isDisabled
          </Button>
          <Button variant="noBackground">noBackground</Button>
          <Button variant="assetTab">assetTab</Button>
          <Button variant="assetTabSelected">assetTabSelected</Button>
        </GridItem>
        <GridItem>
          <Text textAlign="center">Menus</Text>
          <Flex direction="column">
            <Select
              variant="outline"
              title="Options"
              placeholder="Outline"
              options={options}
              onSelect={(value) =>
                dispatch(
                  updateFilter({
                    filterType: "selectedCountries",
                    filterValues: value ? [value] : [],
                  }),
                )
              }
              selected={selectedCountry}
            />
            <Select
              isDisabled={true}
              variant="outline"
              title="Options"
              placeholder="Outline isDisabled"
              options={options}
              onSelect={(value) =>
                dispatch(
                  updateFilter({
                    filterType: "selectedCountries",
                    filterValues: value ? [value] : [],
                  }),
                )
              }
              selected={selectedCountry}
            />
            <Select
              variant="solid"
              title="Options"
              placeholder="Solid"
              options={options}
              onSelect={(value) =>
                dispatch(
                  updateFilter({
                    filterType: "selectedCountries",
                    filterValues: value ? [value] : [],
                  }),
                )
              }
              selected={selectedCountry}
            />
            <Select
              isDisabled={true}
              variant="solid"
              title="Options"
              placeholder="Solid isDisabled"
              options={options}
              onSelect={(value) =>
                dispatch(
                  updateFilter({
                    filterType: "selectedCountries",
                    filterValues: value ? [value] : [],
                  }),
                )
              }
              selected={selectedCountry}
            />
            <Select
              variant="flushed"
              title="Options"
              placeholder="Flushed"
              options={options}
              onSelect={(value) =>
                dispatch(
                  updateFilter({
                    filterType: "selectedCountries",
                    filterValues: value ? [value] : [],
                  }),
                )
              }
              selected={selectedCountry}
            />
            <Select
              isDisabled={true}
              variant="flushed"
              title="Options"
              placeholder="Flushed isDisabled"
              options={options}
              onSelect={(value) =>
                dispatch(
                  updateFilter({
                    filterType: "selectedCountries",
                    filterValues: value ? [value] : [],
                  }),
                )
              }
              selected={selectedCountry}
            />
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

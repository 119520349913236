import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { selectGHGTargetsForOpenedAsset } from "src/redux/slices/portfolios/assets/openedAssetSelectors";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { SelectableChartTab } from "../../components/ChartTabs/SelectableChartTab";
import { LoadComponentPlaceholder } from "src/common/components/atoms/Spinner";
import { findArchetypeErrorForSelectedAsset } from "src/redux/slices/portfolios/archetypes/selectors";
import { selectOpenedPortfolio } from "src/redux/slices/portfolios/selectors";
import { getScaledStrandingChartValuesForGHG } from "src/common/utils/units/ghgChartScaling";
import { Alert } from "src/common/components/atoms/Alert";
import AddEntityModal from "src/pages/AdminView/components/AddEntityModal";
import { CustomPathwayForm } from "./CustomPathwayForm";
import {
  resetCustomPathwayEditStatusInProgress,
  setCustomPathwayEditStatusInProgress,
} from "src/redux/slices/portfolios/assets/slice";
import { selectCustomPathwaysEditStatus } from "src/redux/slices/portfolios/assets/selectors";
import { CustomPathwayTable } from "./CustomPathwayTable";
import { IReduxChartTarget } from "src/redux/interfaces/IReduxChartTarget";
import {
  toggleAll,
  toggleId,
} from "src/redux/slices/uiState/customPathwaysSelection/slice";
import { selectCustomPathwaysSelectedIds } from "src/redux/slices/uiState/customPathwaysSelection/selectors";
import { Units, getNonScaledUnits } from "src/constants/measureUnits";
import { deleteCustomPathway } from "src/redux/thunks/customPathways";

type TProps = {
  asset: IAppAsset;
};
export const CustomPathwaysTab: React.FC<TProps> = ({ asset }) => {
  const [editPathwayId, setEditPathwayId] = useState<number | null>(null);
  const archetypeError = useAppSelector(findArchetypeErrorForSelectedAsset);
  const editStatus = useAppSelector(selectCustomPathwaysEditStatus);
  const targetsGHG = useAppSelector(selectGHGTargetsForOpenedAsset);
  const openedPortfolio = useAppSelector(selectOpenedPortfolio);
  const selectedIds = useAppSelector(selectCustomPathwaysSelectedIds);

  const dispatch = useAppDispatch();
  const isAddModalOpen = editStatus === "userinput" || editStatus === "pending";

  const ghgScaledValues = useMemo(
    () =>
      getScaledStrandingChartValuesForGHG(
        asset.ghgResults.emissionsForecast || [],
        targetsGHG,
        [],
        openedPortfolio?.measureSystem,
        asset.ghgResults.customPathways || [],
      ),
    [
      asset.ghgResults.emissionsForecast,
      targetsGHG,
      openedPortfolio,
      asset.ghgResults.customPathways,
    ],
  );

  if (!asset) {
    return <LoadComponentPlaceholder />;
  }

  if (
    editPathwayId &&
    !asset.ghgResults.customPathways?.find((p) => p.lineId === editPathwayId)
  ) {
    setEditPathwayId(null);
  }

  const handleEditPathway = (pathwayId?: number) => {
    setEditPathwayId(pathwayId || null);
    dispatch(setCustomPathwayEditStatusInProgress());
  };

  const handleDeleteCuatomPathway = (pathwayId?: number) => {
    if (pathwayId) {
      dispatch(
        deleteCustomPathway({
          assetId: asset.assetId,
          customPathwayId: pathwayId,
        }),
      );
    }
  };

  const errorMessage = archetypeError ? "Cannot display custom pathways" : "";
  const message = "";

  return (
    <>
      <Flex
        width="100%"
        direction="column"
        backgroundColor="white"
        p="25px"
        mr="15px"
        minHeight="460px"
        flexGrow="1"
        borderRadius="8px"
      >
        {message || errorMessage ? (
          <Box
            color="gray.500"
            fontSize="0.8em"
            textAlign="center"
            mt="1.5rem"
            width="100%"
          >
            {message ? (
              <Text>{message}</Text>
            ) : (
              <Alert message={errorMessage || ""} isError />
            )}
          </Box>
        ) : (
          <>
            <Text color="red.600" ml="5px">
              GHG Emissions Intensity
            </Text>
            <SelectableChartTab
              forecast={ghgScaledValues.forecast}
              targets={[
                ...ghgScaledValues.targets,
                ...(selectedIds
                  .map(
                    (id) =>
                      ghgScaledValues.customPathways.find(
                        (p) => p.lineId === id,
                      ) || null,
                  )
                  .filter((p) => !!p) as IReduxChartTarget[]),
              ]}
              scenarios={[]}
              errorMessage={errorMessage}
              units={ghgScaledValues.units}
              disableSelection
            />
          </>
        )}

        <AddEntityModal
          isOpen={isAddModalOpen}
          onClose={() => dispatch(resetCustomPathwayEditStatusInProgress())}
          title={
            editPathwayId ? "Edit Custom Pathway" : "Create New Custom Pathway"
          }
        >
          <CustomPathwayForm
            assetId={asset.assetId}
            isPending={editStatus === "pending"}
            customPathway={
              editPathwayId
                ? asset.ghgResults.customPathways?.find(
                    (p) => p.lineId === editPathwayId,
                  )
                : undefined
            }
            units={getNonScaledUnits(
              Units.ghgIntensityYearly,
              openedPortfolio?.measureSystem,
            )}
          />
        </AddEntityModal>
      </Flex>

      <Box
        pt="10px"
        backgroundColor="white"
        width="100%"
        borderRadius="8px 8px 0 0"
        mt="1em"
      >
        <CustomPathwayTable
          selectedIds={selectedIds}
          customPathways={ghgScaledValues.customPathways || []}
          toggleId={(id) => dispatch(toggleId(id))}
          toggleAll={(check) => dispatch(toggleAll(check))}
          onEditCustomPathway={handleEditPathway}
          onDeleteCustomPathway={handleDeleteCuatomPathway}
        />
      </Box>
    </>
  );
};

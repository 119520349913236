import React from "react";
import { Box, SimpleGrid, Text, Divider } from "@chakra-ui/react";
import {
  LeedBadge,
  WellBadge,
  DgnbBadge,
} from "src/common/components/atoms/CertificationBadge";

import {
  IAppAsset,
  IAppAssetCertifications,
} from "src/redux/interfaces/IAppAsset";

import AssetBadgeItem from "../components/AssetBadgeItem";

interface IProps {
  asset: IAppAsset;
}

const CertificationBadges: React.FC<IProps> = (props) => {
  const { asset } = props;

  const isCertified = (cert: keyof IAppAssetCertifications, type: string) => {
    return asset.certifications && asset.certifications[cert] === type;
  };

  return (
    <Box w="100%" p="1.5rem" bg="white" borderRadius="8px">
      <Divider />
      {/* Leed Certification */}
      <SimpleGrid columns={{ base: 1, md: 1, lg: 6 }} gap={2} py="0.8rem">
        <AssetBadgeItem name={"Leed"}>
          <LeedBadge />
        </AssetBadgeItem>
        <Text fontSize={{ base: "xxs", lg: "xs" }} ml={2}>
          Rating system with a focus on environmental criteria
        </Text>
        <AssetBadgeItem
          name={"Leed Certified"}
          isGhost={!isCertified("leed", "certified")}
        >
          <LeedBadge level="certified" />
        </AssetBadgeItem>
        <AssetBadgeItem
          name={"Leed Silver"}
          isGhost={!isCertified("leed", "silver")}
        >
          <LeedBadge level="silver" />
        </AssetBadgeItem>
        <AssetBadgeItem
          name={"Leed Gold"}
          isGhost={!isCertified("leed", "gold")}
        >
          <LeedBadge level="gold" />
        </AssetBadgeItem>
        <AssetBadgeItem
          name={"Leed Platinum"}
          isGhost={!isCertified("leed", "platinum")}
        >
          <LeedBadge level="platinum" />
        </AssetBadgeItem>
      </SimpleGrid>
      <Divider />

      {/* Well Certification */}
      <SimpleGrid columns={{ base: 1, md: 1, lg: 6 }} gap={2} py="0.8rem">
        <AssetBadgeItem name={"Well"}>
          <WellBadge />
        </AssetBadgeItem>
        <Text fontSize={{ base: "xxs", lg: "xs" }} ml={2}>
          Certification for advancing health and well-being
        </Text>
        <AssetBadgeItem
          name={"Well Silver"}
          isGhost={!isCertified("well", "silver")}
        >
          <WellBadge level="silver" />
        </AssetBadgeItem>
        <AssetBadgeItem
          name={"Well Gold"}
          isGhost={!isCertified("well", "gold")}
        >
          <WellBadge level="gold" />
        </AssetBadgeItem>
        <AssetBadgeItem
          name={"Well Platinum"}
          isGhost={!isCertified("well", "platinum")}
        >
          <WellBadge level="platinum" />
        </AssetBadgeItem>
      </SimpleGrid>

      <Divider />
      {/* DGNB Certification */}
      <SimpleGrid columns={{ base: 1, md: 1, lg: 6 }} gap={2} py="0.8rem">
        <AssetBadgeItem name={"DGNB"}>
          <DgnbBadge />
        </AssetBadgeItem>
        <Text fontSize={{ base: "xxs", lg: "xs" }} ml={2}>
          Standard on environmental, economic, and social performance
        </Text>
        <AssetBadgeItem
          name={"DGNB Bronze"}
          isGhost={!isCertified("dgnb", "bronze")}
        >
          <DgnbBadge level="bronze" />
        </AssetBadgeItem>
        <AssetBadgeItem
          name={"DGNB Silver"}
          isGhost={!isCertified("dgnb", "silver")}
        >
          <DgnbBadge level="silver" />
        </AssetBadgeItem>
        <AssetBadgeItem
          name={"DGNB Gold"}
          isGhost={!isCertified("dgnb", "gold")}
        >
          <DgnbBadge level="gold" />
        </AssetBadgeItem>
        <AssetBadgeItem
          name={"DGNB Platinum"}
          isGhost={!isCertified("dgnb", "platinum")}
        >
          <DgnbBadge level="platinum" />
        </AssetBadgeItem>
      </SimpleGrid>
      <Divider />
    </Box>
  );
};

export default CertificationBadges;

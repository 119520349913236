import { createAsyncThunk } from "@reduxjs/toolkit";
import { VersionApi } from "src/api/open-api";
import { defaultApiConfiguration } from "src/api/configurations";

export const fetchDatabaseVersion = createAsyncThunk(
  "databaseVersion/fetch",
  async (_, { rejectWithValue }) => {
    const api = new VersionApi(defaultApiConfiguration);
    try {
      const response = await api.getDatabaseVersion();
      return response.data;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

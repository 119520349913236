const safeColor = "green.500";
const warningColor = "yellow.400";
const dangerColor = "red.400";

export const getColorForGivenYear = (
  year: number,
  strandingYear: number,
  holdingYear = 0,
) => {
  if (year >= strandingYear) return dangerColor;
  else if (year >= strandingYear - holdingYear) return warningColor;
  else return safeColor;
};

export const getMultiColorBarCategories = (
  strandingLowerEmissions: number,
  strandingHigherEmissions: number,
  minYear: number,
  maxYear = 2050,
) => {
  return [
    { share: strandingLowerEmissions - minYear, color: safeColor },
    {
      share: strandingHigherEmissions - strandingLowerEmissions,
      color: warningColor,
    },
    {
      share: maxYear - strandingHigherEmissions,
      color: dangerColor,
    },
  ];
};

/*
// Return [<stranding year for lower emissions target>, <stranding year for higher emissions target>]
export const getNormalizedStrandingYears = (
  targetLowerEmissions: TStrandingProps,
  targetHigherEmissions: TStrandingProps,
  forecast: ITimeSeriesPoint[],
  minYear: number,
  maxYear: number,
): [number, number] => {
  if (
    isNaNOrNull(targetLowerEmissions.strandingYear) &&
    isNaNOrNull(targetHigherEmissions.strandingYear)
  ) {
    // Case where line does not intersect targets
    if (forecast[0].value > targetHigherEmissions.timeSeries[0].value) {
      return [minYear - 1, minYear - 1];
    } else if (forecast[0].value > targetLowerEmissions.timeSeries[0].value) {
      return [minYear - 1, maxYear + 1];
    } else {
      return [maxYear + 1, maxYear + 1];
    }
  } else if (isNaNOrNull(strandingYear15)) {
    return [minYear - 1, strandingYear20];
  } else if (isNaNOrNull(strandingYear20)) {
    return [strandingYear15, maxYear + 1];
  } else {
    return [strandingYear15, strandingYear20];
  }
};
*/

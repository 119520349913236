import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  calcInterventionPackageTotals,
  getInterventionField,
  metricTypes,
} from "../utils/getInterventionValues";
import { MeasureSystem } from "src/redux/interfaces/IAppPortfolio";
import { Units, getUnits } from "src/constants/measureUnits";
import { AccordionItem } from "src/common/components/atoms/AccordionItem";
import { IAppIntervention } from "src/redux/interfaces/IAppIntervention";

type IProps = {
  name: string;
  interventions: IAppIntervention[];
  assetArea: number;
  selectedType: metricTypes;
  measureSystem?: MeasureSystem;
  isDetailed?: boolean;
};

const headersMap = {
  [metricTypes.Absolute]: {
    carbon: (measureSystem?: MeasureSystem) =>
      `Change ${getUnits(Units.ghgYearly, measureSystem).replace(
        "{weight}",
        getUnits(Units.weight, measureSystem),
      )}`,
    energy: (measureSystem?: MeasureSystem) =>
      `Change ${getUnits(Units.energyYearly, measureSystem)}`,
  },
  [metricTypes.Relative]: {
    carbon: () => "Change %",
    energy: () => "Change %",
  },
  [metricTypes.Normalized]: {
    carbon: (measureSystem?: MeasureSystem) =>
      `Change ${getUnits(Units.ghgIntensityYearly, measureSystem).replace(
        "{weight}",
        getUnits(Units.weight, measureSystem),
      )}`,
    energy: (measureSystem?: MeasureSystem) =>
      `Change ${getUnits(Units.energyIntensityYearly, measureSystem)}`,
  },
};

export const PackageInfo: React.FC<IProps> = ({
  name,
  interventions,
  measureSystem,
  selectedType = metricTypes.Absolute,
  isDetailed,
  assetArea,
}) => {
  const { carbonTotal, energyTotal } = calcInterventionPackageTotals(
    interventions,
    selectedType,
    assetArea,
    selectedType === metricTypes.Relative ? 0 : 1,
  );

  const minFractionDigitsCarbon = selectedType === metricTypes.Relative ? 2 : 0;
  const maxFractionDigitsCarbon = selectedType === metricTypes.Relative ? 2 : 0;
  const minFractionDigitsEnergy = selectedType === metricTypes.Relative ? 2 : 0;
  const maxFractionDigitsEnergy = selectedType === metricTypes.Relative ? 2 : 0;

  return (
    <>
      <AccordionItem name={`Package ${name}`}>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th lineHeight="1.2">
                {isDetailed ? (
                  "Measure Description"
                ) : (
                  <>
                    Year <Text fontSize="0.7em">Impl.</Text>
                  </>
                )}
              </Th>
              <Th lineHeight="1.2" textAlign="right">
                Carbon{" "}
                <Text fontSize="0.7em">
                  {headersMap[selectedType].carbon(measureSystem)}
                </Text>
              </Th>
              <Th lineHeight="1.2" textAlign="right">
                Energy{" "}
                <Text fontSize="0.7em">
                  {headersMap[selectedType].energy(measureSystem)}
                </Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {interventions.map((intervention) => (
              <Tr key={intervention.interventionCode}>
                <Td>
                  <Tooltip label={intervention.interventionDescription}>
                    {intervention.interventionCode}
                  </Tooltip>
                </Td>
                <Td>
                  {isDetailed
                    ? intervention.interventionDescription
                    : intervention.impementationYear}
                </Td>
                <Td isNumeric>
                  {getInterventionField(
                    intervention,
                    selectedType,
                    "carbon",
                    assetArea,
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: minFractionDigitsCarbon,
                    maximumFractionDigits: maxFractionDigitsCarbon,
                  })}
                </Td>
                <Td isNumeric>
                  {getInterventionField(
                    intervention,
                    selectedType,
                    "energy",
                    assetArea,
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: minFractionDigitsEnergy,
                    maximumFractionDigits: maxFractionDigitsEnergy,
                  })}
                </Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th></Th>
              <Th color="black">Total</Th>
              <Th color="black" isNumeric>
                {carbonTotal.toLocaleString(undefined, {
                  minimumFractionDigits: minFractionDigitsCarbon,
                  maximumFractionDigits: maxFractionDigitsCarbon,
                })}
              </Th>
              <Th color="black" isNumeric>
                {energyTotal.toLocaleString(undefined, {
                  minimumFractionDigits: minFractionDigitsEnergy,
                  maximumFractionDigits: maxFractionDigitsEnergy,
                })}
              </Th>
            </Tr>
          </Tfoot>
        </Table>
      </AccordionItem>
    </>
  );
};

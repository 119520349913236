import React, { useEffect } from "react";
import { StandardLayout } from "src/common/components/templates/StandardLayout";
import { CompanyAdminSidebar } from "./components/CompanyAdmin/CompanyAdminSidebar";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  selectAllCompanies,
  selectCompaniesStateIsLoading,
} from "src/redux/slices/admin/companies/selectors";
import { selectCanAccessAppAdminLevel } from "src/redux/slices/auth/authInfo/selectors";
import { CompanyContent } from "./components/CompanyAdmin/CompanyContent";
import {
  selectCompanyUsers,
  selectIsCompanyUsersLoading,
} from "src/redux/slices/admin/usersCompany/selectors";
import { Flex, Spinner } from "@chakra-ui/react";
import { Alert } from "src/common/components/atoms/Alert";
import {
  fetchCompanyPermissions,
  fetchCompanyUsers,
} from "src/redux/thunks/admin";
import { fetchCompanies } from "src/redux/thunks/companies";
import { fetchPortfolios } from "src/redux/thunks/portfolios";
import { selectFullFeatureConfig } from "src/redux/slices/featureConfig/selectors";
import { fetchConfigByCompanyId } from "src/redux/thunks/featureConfig";
import { useCompany } from "src/common/components/organisms/CompanyProviderWrapper";

type TProps = {
  companyId?: number | null;
};
export const CompanyAdminView: React.FC<TProps> = ({ companyId }) => {
  const dispatch = useAppDispatch();
  const companies = useAppSelector(selectAllCompanies);
  const companyUsers = useAppSelector(selectCompanyUsers);
  const isLoadingUsers = useAppSelector(selectIsCompanyUsersLoading);
  const isLoadingCompanies = useAppSelector(selectCompaniesStateIsLoading);
  const isAppAdmin = useAppSelector(selectCanAccessAppAdminLevel);
  const featureConfig = useAppSelector(selectFullFeatureConfig);
  const { selectedCompanyId } = useCompany();
  const { companyId: paramsCompanyId } = useParams<{
    companyId: string;
  }>();
  const theId =
    paramsCompanyId === undefined ? companyId : parseInt(paramsCompanyId);
  const company = companies.find((c) => c.company_id === theId);
  const isLoading = isLoadingUsers || isLoadingCompanies;

  useEffect(() => {
    if (theId !== undefined && theId !== null) {
      dispatch(fetchCompanyUsers(theId));
      dispatch(fetchCompanyPermissions(theId));
      dispatch(fetchPortfolios());
      dispatch(fetchConfigByCompanyId(theId));
    }
  }, [dispatch, theId]);

  useEffect(() => {
    if (!isLoadingCompanies && companies.length === 0) {
      dispatch(fetchCompanies());
    }
  }, [dispatch, isLoadingCompanies, companies.length]);

  if (isLoading) {
    return (
      <Flex h="100%" w="100%" justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    );
  }

  if (!company) {
    return (
      <Flex h="100%" w="100%" justifyContent="center" alignItems="center">
        <Alert message={`No company with id=${theId} found`} />
      </Flex>
    );
  }

  return (
    <StandardLayout
      sideBar={<CompanyAdminSidebar company={company} />}
      backUrl={
        isAppAdmin
          ? "/admin/companies"
          : selectedCompanyId
          ? "/companyView/" + selectedCompanyId
          : "/"
      }
      backUrlText={
        isAppAdmin
          ? "Companies list"
          : selectedCompanyId
          ? "Back to company"
          : "Home"
      }
    >
      <CompanyContent
        company={company}
        users={companyUsers}
        isAppAdmin={isAppAdmin}
        featureConfig={featureConfig}
      />
    </StandardLayout>
  );
};

import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { getEPCColor } from "src/common/utils/colors/epcColors";

interface IProps {
  rating?: "A" | "B" | "C" | "D" | "E" | "F" | "G";
  size?: number;
}

export const EPCLabel: React.FC<IProps> = (props) => {
  const { rating, size } = props;

  return (
    <Flex
      data-testid="EPCLabel"
      flexDir="row"
      justifyContent="center"
      alignItems="center"
      w={size ? `${size}rem` : "1.5rem"}
      h={size ? `${size}rem` : "1.5rem"}
      backgroundColor={rating ? getEPCColor(rating) : "gray.50"}
      borderRadius="5px"
    >
      <Text color="white" fontSize={size ? `${0.785 * size}rem` : "1rem"}>
        {rating}
      </Text>
    </Flex>
  );
};

import { ChartDataset, ScatterDataPoint } from "chart.js";
import { ITimeSeriesPoint } from "src/api/open-api";

type ILineProps = {
  name: string;
  timeSeries: ITimeSeriesPoint[];
  color: string;
};

type IBarProps = {
  label: string;
  timeSeries: ITimeSeriesPoint[];
  color: string;
};

export const getCapexChartLine = ({
  name,
  timeSeries,
  color,
}: ILineProps): ChartDataset<"line", ScatterDataPoint[]> => ({
  type: "line",
  label: name,
  data: timeSeries.map((point) => ({ y: point.value, x: point.year })),
  borderColor: color,
  hoverBorderColor: "#485654",
  borderWidth: 2,
  fill: false,
  pointRadius: 0,
  pointHoverRadius: 0,
  pointHitRadius: 6,
  yAxisID: "OPEX",
  borderDash: [6, 2],
});

export const getCapexCharBars = ({
  label,
  timeSeries,
  color,
}: IBarProps): ChartDataset<"bar", ScatterDataPoint[]> => {
  return {
    type: "bar",
    label,
    data: timeSeries.map((point) => ({ y: point.value, x: point.year })),
    backgroundColor: color,
    borderColor: color,
    barThickness: 4,
    yAxisID: "CAPEX",
  };
};

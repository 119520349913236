import { IIntervention, IScenario } from "src/api/open-api";
import { IAppIntervention } from "src/redux/interfaces/IAppIntervention";

export const groupInterventionsByPackage = (scenario: IScenario) => {
  const packages = new Map();
  scenario.interventions?.forEach((interv) =>
    packages.set(interv.package_code, [
      ...(packages.get(interv.package_code) || []),
      interv,
    ]),
  );
  return {
    scenario_name: scenario.scenario_name,
    packages: Array.from(packages).map((entry) => ({
      package_code: entry[0] as string,
      interventions: (entry[1] as IIntervention[])?.map((intervention) => ({
        impementationYear: intervention.implementation_year,
        interventionCode: intervention.intervention_code,
        interventionDescription: intervention.intervention_description,
        ghgAbsoluteChange: intervention.ghg_emission_change,
        ghgRelativeChange: intervention.relative_ghg_value,
        euiAbsoluteChange: intervention.energy_emission_change,
        euiRelativeChange: intervention.relative_energy_value,
        capexAbsoluteTotal: intervention.capex_cost,
        capexAbsoluteEol: intervention.capex_eol_cost,
        capexAbsoluteDecarb: intervention.capex_decarb_cost,
        opexAbsolute: intervention.opex_revenue_cost,
        marginalAbatementCost: intervention.marginal_abatement_cost,
      })) as IAppIntervention[],
    })),
    pathways: scenario.pathways,
    cost: scenario.cost,
  };
};

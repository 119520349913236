import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// usage in the app: t('error.MISSING_ARGUMENT', { x: 'portfolioName' });
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    resources: {
      en: {
        translation: {
          error: {
            MISSING_ARGUMENT: " Parameter {{x}} is missing.",
            INVALID_ARGUMENT:
              "Request field {{x}} is {{y}}, expected one of [{{z}}].",
            OUT_OF_RANGE: "Parameter x is out of range [{{y}}, {{z}}]",
            INVALID_COMPANY:
              "The specified company does not exist or the user does not have access.",
            INVALID_PORTFOLIO:
              "The specified portfolio does not exist or the user does not have access.",
            UNAUTHORIZED: "User is not authorized",
            ACCESS_TOKEN_MISSING: "Access token missing from header",
            INVALID_ACCESS_TOKEN: "Invalid Access Token",
            PERMISSION_DENIED: "Permission '{{x}}' denied on resource '{{y}}'.",
            NOT_FOUND: "Resource '{{x}}' not found.",
            ALREADY_EXISTS: "{{x}} already exists.",
            ERR_NETWORK: "Network connection issues.",
            ERR_BAD_REQUEST:
              "App is requesting wrong URL, please contact support.",
            GENERIC: "Something went wrong",
            UI_DATA_IS_BROCKEN:
              "Data received from server has unexpected format. Cannot perform necessary calculations.",
          },
        },
      },
    },
  });

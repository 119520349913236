import React from "react";
import { Icon } from "@chakra-ui/react";
import { ReactComponent as InfoIcon } from "src/assets/icons/info-filled.svg";
import HoverTooltip from "../HoverTooltip";

export const InfoTooltip: React.FC<{
  title?: string;
  text: string;
}> = (props) => {
  const { title, text } = props;
  return (
    <HoverTooltip title={title} text={text}>
      <Icon
        data-testid="InfoIcon"
        as={InfoIcon}
        boxSize="16px"
        fill="gray.300"
      />
    </HoverTooltip>
  );
};

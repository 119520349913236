import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { CustomPieChart } from "src/common/components/molecules/CustomPieChart/index";
import { getCapexReferences } from "src/common/components/molecules/PerformanceTable/getReference";
import { Performance } from "src/common/components/molecules/PerformanceTable/references";
import { getCapexPerformance } from "src/common/utils/getCapexPerformance";
import { getPerformanceColor } from "src/common/utils/colors/colors";
import { decimalToPercent } from "src/common/utils/math";

export const PortfolioDecarbCosts: React.FC<{
  assets: IAppAsset[];
}> = ({ assets }) => {
  const countPerCategory = {
    lowPerforming: 0,
    mediumPerforming: 0,
    highPerforming: 0,
  };

  assets.forEach((asset) => {
    if (asset.capex) {
      const breakpoints = getCapexReferences({
        buildingType: asset.primaryBusinessBuildingType,
        locationName: asset.location.businessLocationName,
      });

      const performance = getCapexPerformance(
        asset.capex?.capex_cost_decarb_per_meter || 0,
        breakpoints,
      );

      if (performance === Performance.High) {
        countPerCategory.highPerforming += 1;
      } else if (performance === Performance.Medium) {
        countPerCategory.mediumPerforming += 1;
      } else if (performance === Performance.Low) {
        countPerCategory.lowPerforming += 1;
      }
    }
  });
  const total =
    countPerCategory.lowPerforming +
    countPerCategory.mediumPerforming +
    countPerCategory.highPerforming;
  const lowPc = decimalToPercent(countPerCategory.lowPerforming / total);
  const medPc = decimalToPercent(countPerCategory.mediumPerforming / total);
  const highPc = decimalToPercent(countPerCategory.highPerforming / total);
  const chartCategories = [
    {
      color: getPerformanceColor(0),
      value: lowPc,
      focused: true,
      label: `High Cost ${lowPc}%`,
    },
    {
      color: getPerformanceColor(1),
      value: medPc,
      focused: false,
      label: `Medium Cost ${medPc}%`,
    },
    {
      color: getPerformanceColor(2),
      value: highPc,
      focused: false,
      label: `Low Cost ${highPc}%`,
    },
  ];

  return (
    <>
      <Box>
        <Text
          fontFamily="body"
          fontSize="small"
          fontWeight="bold"
          color="gray.600"
        >
          Portfolio Share of Decarbonisation Costs
        </Text>
      </Box>
      <Flex direction="row" justifyContent="space-evenly" p="0.5rem">
        <Box maxWidth="55%" p="1rem">
          <CustomPieChart
            categories={chartCategories}
            customLabel={`${assets.length}`}
            units={"Assets"}
          />
        </Box>
        <Flex mt="4" direction="column" justifyContent="center">
          {chartCategories.map((category, index) => (
            <Flex direction="row" key={index} mb="2">
              <Box
                w="4"
                h="4"
                borderRadius="full"
                bgColor={category.color}
                mr="2"
              />
              <Text fontSize="sm">{category.label}</Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </>
  );
};

import React from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  selectAllCountries,
  selectFundTypes,
  selectRiskLevels,
  selectSelectedCountry,
  selectBuildingTypes,
} from "src/redux/slices/uiState/portfoliosFilter/selectors";
import { updateFilter } from "src/redux/slices/uiState/portfoliosFilter/slice";
import { Select } from "src/common/components/atoms/Select";
import { PortfolioFilterCheckboxGroup } from "./PortfoliosFilterCheckboxGroup";
import { useModalState } from "src/common/utils/useModalState";
import PortfolioExcelModal from "src/pages/CRUDModals/components/CreateUpdateModal";
import {
  selectCanAccessAppAdminLevel,
  selectCompanyEditPermission,
} from "src/redux/slices/auth/authInfo/selectors";
import { useCompany } from "src/common/components/organisms/CompanyProviderWrapper";
import { selectAllUserCompanies } from "src/redux/slices/companies/selectors";

export const CompanySidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isOpen, setOpen, setClose } = useModalState();
  const { selectedCompanyId } = useCompany();
  const companyId = selectedCompanyId ? parseInt(selectedCompanyId) : undefined;
  const allCountries = useAppSelector(selectAllCountries);
  const selectedCountry = useAppSelector(selectSelectedCountry);
  const buildingTypes = useAppSelector(selectBuildingTypes);
  const riskLevels = useAppSelector(selectRiskLevels);
  const fundTypes = useAppSelector(selectFundTypes);
  const companyEditPermissions = useAppSelector(selectCompanyEditPermission);
  const companies = useAppSelector(selectAllUserCompanies);
  const adminPermission = useAppSelector(selectCanAccessAppAdminLevel);

  const canIAddPortfolio =
    companyEditPermissions?.length &&
    companyEditPermissions.find(
      (permission) => permission.entity_id === companyId,
    );

  return (
    <>
      <Text mt="16px" as="h2" color="red.400" fontSize="xs">
        Company
      </Text>
      <Text mt="0" as="h3">
        {companies?.find((c) => c.company_id === companyId)?.company_name}
      </Text>
      {canIAddPortfolio || adminPermission ? (
        <Flex direction="row" mt="0.5em">
          <Button
            variant="solidPrimary"
            colorScheme="red"
            width="100%"
            onClick={setOpen}
          >
            Add Portfolio
          </Button>
        </Flex>
      ) : null}

      <Text mt="18px" as="h2" color="red.400" fontSize="xs">
        Filters
      </Text>
      <Select
        variant="flushed"
        title="Country"
        placeholder="All Countries"
        options={allCountries.filter((c) =>
          c.companyIds.find((id) => String(id) === String(selectedCompanyId)),
        )}
        onSelect={(value) =>
          dispatch(
            updateFilter({
              filterType: "selectedCountries",
              filterValues: value ? [value] : [],
            }),
          )
        }
        selected={selectedCountry}
      />
      <PortfolioFilterCheckboxGroup
        title="CRREM Property Type"
        options={buildingTypes.filter((c) =>
          c.companyIds.find((id) => String(id) === String(selectedCompanyId)),
        )}
        onSelect={(values) => {
          dispatch(
            updateFilter({
              filterType: "selectedBuildingTypes",
              filterValues: values,
            }),
          );
        }}
      />
      <PortfolioFilterCheckboxGroup
        title="Stranding Risk"
        options={riskLevels.filter((c) =>
          c.companyIds.find((id) => String(id) === String(selectedCompanyId)),
        )}
        onSelect={(values) => {
          dispatch(
            updateFilter({
              filterType: "selectedRiskLevels",
              filterValues: values,
            }),
          );
        }}
      />
      {fundTypes?.length > 1 ? (
        <PortfolioFilterCheckboxGroup
          title="Fund Type"
          options={fundTypes.filter((c) =>
            c.companyIds.find((id) => String(id) === String(selectedCompanyId)),
          )}
          onSelect={(values) => {
            dispatch(
              updateFilter({
                filterType: "selectedFundTypes",
                filterValues: values,
              }),
            );
          }}
        />
      ) : null}

      <PortfolioExcelModal isOpen={isOpen} onClose={setClose} />
    </>
  );
};

import { IBusinessBuildingType } from "src/api/open-api";

export const getPrimaryBuildingType = (
  buildingTypes: IBusinessBuildingType[],
): string => {
  const sorted = [...buildingTypes].sort(
    (a, b) =>
      (b.business_building_type_percentage || 0) -
      (a.business_building_type_percentage || 0),
  );
  return (sorted && sorted[0]?.business_building_type_label) || "";
};

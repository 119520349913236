import React, { useMemo } from "react";
import { Box } from "@chakra-ui/react";
import MultiColorBar from "./MultiColorBar";
import { SliderWithLine } from "./SliderWithLine";
import { ITimeSeriesPoint } from "src/api/open-api";
import {
  getColorForGivenYear,
  getMultiColorBarCategories,
} from "./sliderHelpers";

interface IProps {
  year: number;
  setYear: (year: number) => void;
  minYear: number;
  maxYear?: number;
  targetLowerEmissions: {
    timeSeries: ITimeSeriesPoint[];
    strandingX?: number;
  };
  targetHigherEmissions: {
    timeSeries: ITimeSeriesPoint[];
    strandingX?: number;
  };
}

export const ChartSliderTwoTargets: React.FC<IProps> = ({
  year,
  setYear,
  minYear,
  maxYear = 2050,
  targetLowerEmissions,
  targetHigherEmissions,
}) => {
  // memorise to not recalculate on every "year" parameter change
  const multiColorBarCategories = useMemo(
    () =>
      getMultiColorBarCategories(
        targetLowerEmissions.strandingX || 0,
        targetHigherEmissions.strandingX || 0,
        minYear,
        maxYear,
      ),
    [targetLowerEmissions, targetHigherEmissions, minYear, maxYear],
  );

  if (!targetHigherEmissions?.strandingX || !targetLowerEmissions?.strandingX)
    return null;

  const color =
    getColorForGivenYear(
      year,
      targetHigherEmissions.strandingX,
      targetHigherEmissions.strandingX - targetLowerEmissions.strandingX,
    ) || "red.500";

  return (
    <Box>
      <Box mb="-1rem">
        <MultiColorBar categories={multiColorBarCategories} />
      </Box>

      <SliderWithLine
        currentValue={year}
        min={minYear}
        max={maxYear || 2050}
        onChange={setYear}
        pointColor={color}
      />
    </Box>
  );
};

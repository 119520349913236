import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import { Box } from "@chakra-ui/react";
import { Feature, GeoJsonProperties, Point } from "geojson";
import { theme } from "src/config/theme";
import config from "src/api";
interface IProps {
  markers: Feature<Point, GeoJsonProperties>[];
}

export const Map: React.FC<IProps> = (props) => {
  const { markers } = props;
  const mapContainer = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<mapboxgl.Map>();

  const initializeMap = (node: HTMLElement) => {
    const m = new mapboxgl.Map({
      container: node,
      accessToken: config.MAPGL_ACCESS_TOCKEN,
      style: "mapbox://styles/dhub/ckvtbd7q20luz14paghmwq4s1",
      attributionControl: false,
    });
    m.on("load", function () {
      m.addSource("markers", {
        type: "geojson",
        data: { type: "FeatureCollection", features: markers },
      });

      m.addLayer({
        id: "markers",
        type: "circle",
        source: "markers",
        paint: {
          "circle-color": theme.colors.gray[500],
          "circle-radius": 6,
        },
      });

      if (!markers.length) {
        return;
      }

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      m.on("mouseenter", "markers", (e) => {
        m.getCanvas().style.cursor = "pointer";
        if (
          e.features &&
          e.features[0].geometry.type === "Point" &&
          e.features[0].properties
        ) {
          const name = e.features[0].properties.name;
          const description = e.features[0].properties.description;
          popup
            .setLngLat(e.lngLat)
            .setHTML(`<strong>${name}</strong><p>${description}</p>`)
            .addTo(m);
        }
      });

      m.on("mouseleave", "markers", () => {
        m.getCanvas().style.cursor = "";
        popup.remove();
      });
    });
    return m;
  };

  const adjustBounds = (m: mapboxgl.Map) => {
    const bounds = new mapboxgl.LngLatBounds();
    const points = markers.filter(
      (m) => m.geometry.type === "Point",
    ) as Feature<Point, GeoJsonProperties>[];

    for (const p of points) {
      bounds.extend([p.geometry.coordinates[0], p.geometry.coordinates[1]]);
    }

    // set bounds according to assets
    m.fitBounds(bounds, {
      padding: 50,
      maxZoom: 14.15,
      duration: 2000,
    });
  };

  const refreshSource = (m: mapboxgl.Map) => {
    const source = m.getSource("markers") as unknown as mapboxgl.GeoJSONSource;
    if (source)
      source.setData({ type: "FeatureCollection", features: markers });
  };

  useEffect(() => {
    const node = mapContainer.current;
    if (typeof window === "undefined" || node === null) return;
    if (node) {
      setMap(initializeMap(node));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (map) {
      refreshSource(map);
      adjustBounds(map);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, JSON.stringify(markers)]);

  return <Box ref={mapContainer} h="11rem" />;
};

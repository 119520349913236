import React from "react";
import { Text, Flex, HStack, Box } from "@chakra-ui/react";

interface IProps {
  value?: 0 | 1 | 2;
  labels?: string[];
  colors?: string[];
  height?: string;
  spacing?: string;
  aspectRatio?: string;
  borderRadius?: string;
}

export const ScoreBar: React.FC<IProps> = (props) => {
  const { value, labels, height, aspectRatio, borderRadius, spacing } = props;

  const colors = props.colors || ["green.400", "yellow.400", "red.400"];
  const color = value !== undefined && colors[value];

  return (
    <Flex
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
      data-testid="ScoreBar"
    >
      <HStack
        spacing={spacing || "0.5rem"}
        w="100%"
        justifyContent={spacing === undefined ? "space-between" : "left"}
      >
        {colors.map((_, idx) => (
          <Box
            key={`scoreBox${idx}`}
            bg={value === idx ? color || "gray.50" : "gray.100"}
            borderRadius={borderRadius || "lg"}
            height={!aspectRatio ? height || "0.7rem" : "none"}
            flex="1"
            minH="0.5rem"
            aspectRatio={aspectRatio}
            data-testid="ScoreBar_Box"
          />
        ))}
      </HStack>

      {labels && (
        <Text
          color={color || "gray.100"}
          marginLeft={spacing ? `calc(${spacing} * 2)` : "1rem"}
          whiteSpace="nowrap"
          minWidth="2.5rem"
        >
          {(value !== undefined && labels[value]) || "No data"}
        </Text>
      )}
    </Flex>
  );
};

import React from "react";
import {
  Button,
  GridItem,
  Divider,
  Heading,
  Text,
  Flex,
  Box,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { theme } from "src/config/theme";
import { ProgressBar } from "src/common/components/atoms/ProgressBar";
import { ScoreBar } from "src/common/components/atoms/ScoreBar";
import { getFundColor } from "src/common/utils/colors/colors";
import { PortfolioCardTerm } from "./PortfolioCardTerm";
import { PortfolioCardGraphics } from "./PortfolioCardGraphics";
import { useModalState } from "src/common/utils/useModalState";
import { DeleteModal } from "src/pages/CRUDModals/components/DeleteModal";
import {
  selectDeletePortfolioError,
  selectDeletePortfolioPending,
} from "src/redux/slices/portfolios/selectors";
import { deletePortfolio } from "src/redux/thunks/portfolios";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  selectCanAccessAppAdminLevel,
  selectPortfolioEditPermission,
} from "src/redux/slices/auth/authInfo/selectors";
import { useParams } from "react-router-dom";

enum FundType {
  "Art 6" = "Art 6",
  "Art 8" = "Art 8",
  "Art 9" = "Art 9",
}
type TRiskLevel = 1 | 2 | 0;
const fundTypes = [FundType["Art 6"], FundType["Art 8"], FundType["Art 9"]];
interface IProps {
  portfolioId: number;
  portfolioName: string;
  countries: string[];
  uses: string[];
  riskLevel?: number;
  assetsCnt: number;
  area: string;
  value: string;
  taxonomy?: number;
  certifications: number;
  dataMaturity?: number;
  fundType?: string;
  cardConfig: { [key: string]: boolean };
  firstTab: string;
}
const renderStringArrayAsEnum = (arr: string[]) =>
  arr.length > 3 ? `Multiple (${arr.length})` : arr.join(", ");

export const PortfolioCard: React.FC<IProps> = ({
  portfolioId,
  portfolioName,
  countries,
  uses,
  riskLevel,
  assetsCnt,
  area,
  value,
  taxonomy,
  certifications,
  dataMaturity,
  fundType,
  cardConfig,
  firstTab,
}) => {
  const {
    isOpen: isDeleteOpen,
    setOpen: setDeleteOpen,
    setClose: setDeleteClose,
  } = useModalState();
  const dispatch = useAppDispatch();
  const { companyId } = useParams<{ companyId: string }>();
  const error = useAppSelector(selectDeletePortfolioError);
  const adminPermission = useAppSelector(selectCanAccessAppAdminLevel);
  const portfolioEditPermissions = useAppSelector(
    selectPortfolioEditPermission,
  );
  const canIDeletePortfolio =
    portfolioEditPermissions?.length &&
    portfolioEditPermissions.find(
      (permission) =>
        (permission.entity_type_name === "company" &&
          permission.entity_id === companyId) ||
        (permission.entity_type_name === "portfolio" &&
          permission.entity_id === portfolioId),
    );
  const isDeleteInProgress = useAppSelector(selectDeletePortfolioPending);
  const handleDelete = async () => {
    if (isDeleteInProgress || !portfolioId) return;
    await dispatch(deletePortfolio(portfolioId));
  };
  return (
    <GridItem bg="white" borderRadius="0.5rem" p={{ base: 7 }} maxWidth="400px">
      <Link
        to={{
          pathname: `/${portfolioId}/${firstTab}`,
        }}
      >
        <Heading
          color="gray.800"
          fontSize="1.75rem"
          fontFamily={theme.fonts.heading}
          fontWeight="regular"
        >
          {portfolioName}
        </Heading>
        <Divider my={2} />
        <Flex width="100%">
          <Box width="48%">
            <PortfolioCardTerm
              title="Countries"
              value={renderStringArrayAsEnum(countries)}
            />
            <PortfolioCardTerm
              title="No. of Assets"
              value={String(assetsCnt)}
            />
            <PortfolioCardTerm title="Area" value={area} />
            {cardConfig.portfolio_card_value ? (
              <PortfolioCardTerm title="Value" value={value} />
            ) : null}
            <PortfolioCardTerm
              title="CRREM Property Type"
              value={renderStringArrayAsEnum(uses)}
            />
          </Box>

          <Box width="48%">
            <PortfolioCardGraphics title="Stranding Risk">
              <ScoreBar
                value={riskLevel as TRiskLevel}
                labels={["Low", "Med.", "High"]}
              />
            </PortfolioCardGraphics>
            {cardConfig.portfolio_card_fund_type ? (
              <PortfolioCardGraphics title="Fund Type">
                {fundType !== undefined ? (
                  <ScoreBar
                    value={
                      fundTypes.findIndex((x) => x === fundType) as TRiskLevel
                    }
                    labels={fundTypes.map((ft) => FundType[ft])}
                    colors={fundTypes.map((ft) => getFundColor(ft))}
                  />
                ) : (
                  <Text fontSize="0.8em" fontWeight="bold" color="gray.400">
                    Not provided
                  </Text>
                )}
              </PortfolioCardGraphics>
            ) : null}
            {cardConfig.portfolio_card_taxonomy ? (
              <PortfolioCardGraphics title="Taxonomy">
                <ProgressBar
                  value={Math.round(taxonomy || 0)}
                  showLabel={true}
                  color="gray.500"
                />
              </PortfolioCardGraphics>
            ) : null}
            {cardConfig.portfolio_card_certifications ? (
              <PortfolioCardGraphics title="Certifications">
                <ProgressBar
                  value={Math.round(certifications || 0)}
                  showLabel={true}
                  color="green.500"
                />
              </PortfolioCardGraphics>
            ) : null}
            {cardConfig.portfolio_card_data_maturity &&
            dataMaturity !== undefined ? (
              <PortfolioCardGraphics title="Data Maturity">
                <ProgressBar
                  value={Math.round(dataMaturity)}
                  showLabel={true}
                  color="gray.400"
                />
              </PortfolioCardGraphics>
            ) : null}
          </Box>
        </Flex>
      </Link>
      {adminPermission || canIDeletePortfolio ? (
        <Button
          size="sm"
          variant="outlineGray"
          width="100%"
          onClick={setDeleteOpen}
        >
          Delete Portfolio
        </Button>
      ) : null}
      <DeleteModal
        title="Delete Portfolio"
        objectDescription={portfolioName}
        isOpen={isDeleteOpen}
        onClose={setDeleteClose}
        onDelete={handleDelete}
        error={error}
        isLoading={isDeleteInProgress}
      />
    </GridItem>
  );
};

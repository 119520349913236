import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import {
  IPortfolioFilters,
  TPortfolioFilter,
} from "src/redux/interfaces/IPortfolioFilter";

export const selectSlice = (state: RootState) => state.portfoliosFilter;
const getListWithSelectedState = (
  allOptions: TPortfolioFilter[],
  selectedOptions: string[],
) =>
  allOptions.map((option) => ({
    ...option,
    value: option.value,
    isSelected: selectedOptions.includes(option.value),
  }));

export const selectAllCountries = createDraftSafeSelector(
  [selectSlice],
  (state: IPortfolioFilters) => state.countries,
);

export const selectSelectedCountry = createDraftSafeSelector(
  [selectSlice],
  (state: IPortfolioFilters) => state.selectedCountries[0],
);

export const selectBuildingTypes = createDraftSafeSelector(
  [selectSlice],
  (state: IPortfolioFilters) =>
    getListWithSelectedState(state.buildingTypes, state.selectedBuildingTypes),
);
export const selectRiskLevels = createDraftSafeSelector(
  [selectSlice],
  (state: IPortfolioFilters) =>
    getListWithSelectedState(state.riskLevels, state.selectedRiskLevels),
);
export const selectFundTypes = createDraftSafeSelector(
  [selectSlice],
  (state: IPortfolioFilters) =>
    getListWithSelectedState(state.fundTypes, state.selectedFundTypes),
);

import { MeasureSystem } from "src/redux/interfaces/IAppPortfolio";
import { currencyCodeToSymbol } from "./units";
import { Units, getUnits } from "src/constants/measureUnits";
import { ITimeSeriesPoint } from "src/api/open-api";

export const formatMoney = (value?: number, isoCurrecyCode?: string) => {
  if (!value) return "0";
  return Math.abs(value) > 1e6
    ? (value / 1e6).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      }) +
        " Mio " +
        currencyCodeToSymbol(isoCurrecyCode)
    : value.toLocaleString(undefined, {
        maximumFractionDigits: 1,
      }) +
        " " +
        currencyCodeToSymbol(isoCurrecyCode);
};

const scaleTox1000 = (value: number) => Math.round(value / 1000);
const scaleTod2000 = (value: number) => Math.round(value * 2000 * 100) / 100;
const scaleTod20 = (value: number) => Math.round(value * 20 * 10) / 10;
const roundTo1 = (value: number) => Math.round(value * 10) / 10;

const getUnitsAndMultiplier = (
  unitsText: string,
  value = 2,
  measureSystem?: MeasureSystem,
) => {
  if (!value || !unitsText?.includes("{weight}")) return null; // if no value or units do not contain weight it cannot be scalable

  if (value > 20000)
    return {
      scaleFn: scaleTox1000,
      units: unitsText.replace(
        "{weight}",
        getUnits(Units.weightx1000, measureSystem),
      ),
    };
  if (Math.abs(value) > 100)
    return {
      scaleFn: Math.round,
      units: unitsText.replace(
        "{weight}",
        getUnits(Units.weight, measureSystem),
      ),
    };
  if (Math.abs(value) > 1)
    return {
      scaleFn: roundTo1,
      units: unitsText.replace(
        "{weight}",
        getUnits(Units.weight, measureSystem),
      ),
    };
  if (measureSystem === MeasureSystem.us) {
    if (Math.abs(value) < 0.02) {
      // short ton to pounds
      return {
        scaleFn: scaleTod2000,
        units: unitsText.replace(
          "{weight}",
          getUnits(Units.weightd2000, measureSystem),
        ),
      };
    }
    // esle: ton to cwt
    return {
      scaleFn: scaleTod20,
      units: unitsText.replace(
        "{weight}",
        getUnits(Units.weightd20, measureSystem),
      ),
    };
  }
  return {
    scaleFn: (value: number) => value,
    units: unitsText.replace("{weight}", getUnits(Units.weight, measureSystem)),
  };
};

export const formatWeight = (
  unitsText: string,
  value?: number,
  measureSystem?: MeasureSystem,
) => {
  if (!value) return "No emissions";
  const transformParams = getUnitsAndMultiplier(
    unitsText,
    value,
    measureSystem,
  );
  if (!transformParams)
    return (
      value.toFixed(1) +
      " " +
      unitsText.replace("{weight}", getUnits(Units.weight, measureSystem))
    );

  return `${transformParams.scaleFn(value).toFixed(1)} ${
    transformParams.units
  }`;
};

export const formatTimeSeriesWeight = (
  unitsText: string,
  timeSeries: ITimeSeriesPoint[],
  refValue?: number, // need this to scale corectly if more than one line is on the chart
  measureSystem?: MeasureSystem,
) => {
  const transformParams = getUnitsAndMultiplier(
    unitsText,
    refValue,
    measureSystem,
  );
  if (!transformParams)
    return {
      timeSeries,
      units: unitsText,
    };
  return {
    timeSeries: timeSeries.map((p) => ({
      year: p.year,
      value: transformParams.scaleFn(p.value),
    })),
    units: transformParams.units,
  };
};

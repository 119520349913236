import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import { CostTable } from "./CostTable";
import { ITimeSeriesPoint } from "src/api/open-api";
import { ICostScenarioResults } from "src/redux/slices/portfolios/scenarios/selectors";
import CostChart, {
  scenarioColor,
} from "src/common/components/molecules/CostChart";

type IProps = {
  statusQuoOpexLine: ITimeSeriesPoint[] | null;
  assetTotalCapex: number;
  assetTotalOpex: number;
  scenarios?: ICostScenarioResults[];
  isoCurrencyCode?: string;
};

const CostTab: React.FC<IProps> = ({
  statusQuoOpexLine,
  assetTotalCapex,
  assetTotalOpex,
  scenarios,
  isoCurrencyCode,
}) => {
  const [selectedScenarioName, setScenario] = useState(
    (scenarios && scenarios[0]?.scenarioName) || "",
  );

  if (!scenarios?.length)
    return (
      <Box color="gray.500" fontSize="0.8em" textAlign="center" mt="3rem">
        There is no data to display yet. <br />
        Please make sure you uploaded scenarios
      </Box>
    );

  const scenario = scenarios.find(
    (s) => s.scenarioName === selectedScenarioName,
  );
  if (!scenario) {
    setScenario(scenarios[0].scenarioName);
  }

  return (
    <Box>
      {!!scenario && (
        <CostChart
          forecastOpEx={statusQuoOpexLine || []}
          scenarioOpEx={{
            timeSeries: scenario.opexLine || [],
            targetName: scenario.scenarioName,
            lineColor: scenarioColor,
          }}
          scenarioCapexEol={scenario.capexEolLine}
          scenarioDecarbCapex={scenario.capexDecarbLine}
          isoCurrecyCode={isoCurrencyCode}
        />
      )}

      <CostTable
        isoCurrencyCode={isoCurrencyCode}
        baseline={{ opexTotal: assetTotalOpex, capexTotal: assetTotalCapex }}
        scenarios={scenarios}
        selectedScenarioName={selectedScenarioName}
        onSelectScenario={setScenario}
      />
    </Box>
  );
};
export default CostTab;

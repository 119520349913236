import { IAsset } from "src/api/open-api";
import { IAppAssetCertifications } from "src/redux/interfaces/IAppAsset";

export const transformCertificationsOnLoad = (
  asset: IAsset,
): IAppAssetCertifications => {
  return {
    leed:
      asset.certifications?.find((c) => c.asset_certification_type_id === 1)
        ?.certification_result || undefined,
    well:
      asset.certifications?.find((c) => c.asset_certification_type_id === 2)
        ?.certification_result || undefined,
    dgnb:
      asset.certifications?.find((c) => c.asset_certification_type_id === 3)
        ?.certification_result || undefined,
  } as IAppAssetCertifications;
};

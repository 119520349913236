import { IExcelPortfolio } from "src/api/typeguards/templates/excelTypes/IExcelPortfolio";
import { read } from "xlsx";
import { utils } from "xlsx";

export const checkVersion = async (file: File, currentVersion: string) => {
  // Parse excel data
  const filedata = await file.arrayBuffer();

  const workbook = read(filedata, { type: "array" });
  const cover = workbook.Sheets["Portfolio"];
  if (!cover) return false;
  const dataJson: IExcelPortfolio[] = utils.sheet_to_json(cover, {
    range: 6,
  });
  const excelPortoflioLine = dataJson.filter((p) => "NAME" in p)[0];
  return excelPortoflioLine?.FILE_VERS === currentVersion;
};

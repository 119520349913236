/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Flex, useColorModeValue } from "@chakra-ui/react";
import { Scatter } from "react-chartjs-2";
import type { ChartOptions } from "chart.js";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { epcColorsModel } from "src/common/utils/colors/epcColors";

interface IProps {
  assets: IAppAsset[];
}

const DeviationBubbleChart: React.FC<IProps> = (props) => {
  const { assets } = props;
  const bubbleColor = useColorModeValue("#C2C8C8", "#666");

  const ratingPerformance = assets.map((a) => {
    return {
      epcRating: a.epc.rating,
      epcPerformance: a.epc.actualPerformance,
    };
  });

  const getBackdropColor = (ctx: any) => {
    return (
      epcColorsModel.find((epc) => epc.label === ctx.tick.label)?.color ??
      "#6B7675"
    );
  };

  const getRadius = (freq: number, minRadius: number, maxRadius: number) => {
    const radius =
      (freq / maxFreq) * maxRadius > minRadius
        ? (freq / maxFreq) * maxRadius
        : minRadius;
    return radius;
  };

  const ratPerfKeys = [...new Set(ratingPerformance)];

  const values = ratingPerformance.map((key) => ({
    x: key.epcRating,
    y: key.epcPerformance,
    freq: ratingPerformance.filter(
      (pair) =>
        pair.epcRating === key.epcRating &&
        pair.epcPerformance === key.epcPerformance,
    ).length,
  }));

  const freqs = values.map((it) => it.freq);
  const maxFreq = Math.max(...freqs);

  const bubbles = ratPerfKeys.map((key: any) => ({
    label: `Assets: ${
      ratingPerformance.filter(
        (pair) =>
          pair.epcRating === key.epcRating &&
          pair.epcPerformance === key.epcPerformance,
      ).length
    }`,
    fill: true,
    backgroundColor: bubbleColor,
    pointBorderColor: bubbleColor,
    pointBackgroundColor: bubbleColor,
    pointHoverBackgroundColor: "#95979A",
    pointRadius: getRadius(
      ratingPerformance.filter(
        (pair) =>
          pair.epcRating === key.epcRating &&
          pair.epcPerformance === key.epcPerformance,
      ).length,
      2,
      10,
    ),
    pointHoverRadius:
      getRadius(
        ratingPerformance.filter(
          (pair) =>
            pair.epcRating === key.epcRating &&
            pair.epcPerformance === key.epcPerformance,
        ).length,
        2,
        10,
      ) * 1.05,
    data: [
      {
        x: epcColorsModel.find((epc) => epc.label === key.epcRating)?.value,
        y: epcColorsModel.find((epc) => epc.label === key.epcPerformance)
          ?.value,
      },
    ],
  }));

  const dataLine = [
    { x: -1, y: -1 },
    { x: 6, y: 6 },
  ];

  const line = {
    fill: false,
    showLine: true,
    borderWidth: 1,
    pointRadius: 0,
    pointHoverRadius: 0,
    pointHitRadius: 0,
    data: dataLine,
  };
  // const data: ChartData<'scatter'> = { // investigate why this doesn't work?
  const data = {
    labels: ["Scatter"],
    datasets: [...bubbles, line],
  };

  const options: ChartOptions<"scatter"> = {
    maintainAspectRatio: true,
    animation: {
      duration: 0,
    },
    responsive: true,
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#6B7675",
        displayColors: false,
        filter: (tooltipItem: any) => tooltipItem?.dataIndex === 0,
        callbacks: {
          label: (context: any) => context?.dataset?.label || "",
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          padding: 5,
          stepSize: 1,
          maxRotation: 0,
          minRotation: 0,
          backdropColor: getBackdropColor,
          backdropPadding: {
            top: 1,
            left: 3,
            bottom: 1,
            right: 3,
          },
          color: "white",
          showLabelBackdrop: true,
          display: true,
          callback: function (value: any) {
            if (value >= 0 && value <= 9)
              return epcColorsModel.find((epc) => epc.value === value)?.label;
          },
          font: {
            size: 12,
            family: "Consolas",
          },
        },
        title: {
          display: true,
          text: "Theoretical Energy Label Obtained",
          color: "#8F9796",
          font: {
            size: 12,
            family: "Arial",
          },
        },
        min: -1,
        max: 6,
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          padding: 5,
          stepSize: 1,
          maxRotation: 0,
          minRotation: 0,
          backdropColor: getBackdropColor,
          backdropPadding: {
            top: 1,
            left: 3,
            bottom: 1,
            right: 3,
          },
          color: "white",
          showLabelBackdrop: true,
          display: true,
          callback: function (value: any) {
            if (value >= 0 && value <= 9)
              return epcColorsModel.find((epc) => epc.value === value)?.label;
          },
          font: {
            size: 12,
            family: "Consolas",
          },
        },
        min: -1,
        max: 6,
        title: {
          display: true,
          text: "Building Real Performance",
          color: "#8F9796",
          font: {
            size: 12,
            family: "Arial",
          },
        },
      },
    },
  };
  return (
    <Flex width="100%" position="relative">
      <Scatter data={data} options={options} />
    </Flex>
  );
};
export default DeviationBubbleChart;

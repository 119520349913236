import { Flex, Button } from "@chakra-ui/react";
import React from "react";
import { metricTypes } from "../utils/getInterventionValues";

const metricTypesArray = [
  metricTypes.Absolute,
  metricTypes.Relative,
  metricTypes.Normalized,
];

export const MetricTypeSelect: React.FC<{
  selectedType: metricTypes;
  onSelect: (type: metricTypes) => void;
}> = ({ selectedType, onSelect }) => {
  return (
    <Flex justifyContent="space-between" mx={1}>
      {metricTypesArray.map((t) => (
        <Button
          key={t}
          size="sm"
          variant={selectedType === t ? "outlinePrimary" : "outlineGray"}
          placeSelf={{ base: "center", xs: "inherit" }}
          onClick={() => onSelect(t)}
          ml="0.5em"
        >
          {t}
        </Button>
      ))}
    </Flex>
  );
};

import React, { useEffect, useState } from "react";
import { Box, Flex, Heading } from "@chakra-ui/react";
import { Alert } from "src/common/components/atoms/Alert";
import { Select } from "../../common/components/atoms/Select";
import { useAppSelector } from "src/redux/hooks";
import { selectAllUserCompanies } from "src/redux/slices/companies/selectors";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../../common/components/organisms/CompanyProviderWrapper";
import { AppHeader } from "src/common/components/organisms/AppHeader";

export const CompanySelection: React.FC = () => {
  const { setSelectedCompanyId } = useCompany();
  const companies = useAppSelector(selectAllUserCompanies);
  const [companyId, setCompanyId] = useState<string | undefined>();
  const navigate = useNavigate();
  const handleCompanySelect = (value: string) => {
    setCompanyId(value);
    setSelectedCompanyId(value);
    navigate(`/companyView/${value}`, { state: { companyStatus: false } });
  };

  useEffect(() => {
    if (companies && companies.length === 1) {
      setSelectedCompanyId(companies[0].company_id.toString());
      // If only one company, navigate directly to company view
      navigate(`/companyView/${companies[0].company_id}`, {
        state: { companyStatus: true },
      });
    }
  }, [companies, navigate, setSelectedCompanyId]);

  if (companies.length === 1) {
    return null; // If no companies or only one company, don't render the selection component
  }
  if (companies.length === 0) {
    return (
      <Flex direction="column" minHeight="100vh" width="100%">
        <AppHeader />
        <Flex
          justifyContent="center"
          alignItems="center"
          height="80vh"
          width="100%"
        >
          <Box width="40%">
            <Alert message="Please contact admin to access the application!" />
          </Box>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex direction="column" minHeight="100vh" width="100%">
      <AppHeader />
      <Flex
        justifyContent="center"
        alignItems="center"
        height="80vh"
        width="100%"
      >
        <Box
          p={4}
          borderRadius="md"
          width="80%"
          height="40%"
          maxWidth="500px"
          maxHeight="500px"
        >
          <Heading
            as="h2"
            fontSize="lg"
            mb={4}
            color="red"
            fontWeight="normal"
            fontFamily="Times"
          >
            Select Company
          </Heading>
          <Select
            options={
              companies?.map((c) => ({
                value: String(c.company_id),
                label: c.company_name,
              })) || []
            }
            size="md"
            placeholder="Not selected"
            selected={companyId}
            variant="outlineMuted"
            onSelect={handleCompanySelect}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Button,
} from "@chakra-ui/react";
import { Alert } from "../../atoms/Alert";
import { Select } from "../../atoms/Select";
import { ICompany } from "src/api/open-api";
import { Drop } from "./Drop";
import { FileDownloadSection } from "./FileDownloadSection";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (file: File, id?: number) => Promise<{ errors?: string[] } | void>;
  getLatestFileUrl?: () => Promise<string | void>;
  title: string;
  templateLink?: string;
  isUpdate?: boolean;
  acceptedFileTypes?: { [key: string]: string[] };
  acceptedFileTypesDescription?: string;
  companies?: ICompany[];
}

const acceptedTypes = {
  "text/plain": [".xls", ".xlsx", ".xlsb", ".xlsm"],
  "application/vnd.ms-excel": [".xls", ".xlsx", ".xlsb", ".xlsm"],
};

const FileUploadModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  isUpdate,
  templateLink,
  onUpload,
  getLatestFileUrl,
  acceptedFileTypes = acceptedTypes,
  acceptedFileTypesDescription = ".xls, .xlsx, .xlsb, .xlsm",
  companies,
  title,
}) => {
  const [loading, setLoading] = useState(false);
  const [companyId, setCompanyId] = useState<string>();
  const [errorMsgs, setErrorMsgs] = useState<string[]>([]);
  const [modalState, setModalState] = useState("CLEAN"); // Initialize modal state

  const [content, setContent] = useState<File>();
  const handleDrop = <T extends File>(file: T) => {
    setContent(file);
    setModalState("FILE_SELECTED");
  };

  const deleteUploadedFileAndClose = () => {
    onClose();
    setContent(undefined);
    setErrorMsgs([]);
  };

  const handleUpload = async (excel: File) => {
    setErrorMsgs([]);
    setLoading(true);
    const resCompanyId =
      companies?.length === 1 ? companies[0].company_id : companyId;
    onUpload(
      excel,
      resCompanyId !== undefined ? Number(resCompanyId) : undefined,
    ).then((response) => {
      setLoading(false);
      if (response?.errors?.length) {
        setErrorMsgs(response.errors);
      }
    });
  };

  const shouldSelectcompany = Boolean(
    !isUpdate && companies?.length && companies.length > 1,
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={deleteUploadedFileAndClose}
      size="lg"
      closeOnOverlayClick={!loading}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="normal" fontFamily="Times">
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <form
          encType="multipart/form-data"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody>
            {!isUpdate && companies?.length === 1 && (
              <Text mb="0.5em">
                Upload data for company{" "}
                <strong>{companies[0].company_name}</strong>
              </Text>
            )}
            {shouldSelectcompany && (
              <Select
                title="Company"
                options={
                  companies?.map((c) => ({
                    value: String(c.company_id),
                    label: c.company_name,
                  })) || []
                }
                placeholder="Select company"
                selected={companyId}
                onSelect={setCompanyId}
              />
            )}
            <Drop
              onDrop={handleDrop}
              onClean={() => setModalState("CLEAN")}
              acceptedFileTypesDescription={acceptedFileTypesDescription}
              acceptedFileTypes={acceptedFileTypes}
            />
            {modalState === "CLEAN" ? (
              <FileDownloadSection
                getLatestFileUrl={getLatestFileUrl}
                templateLink={templateLink}
                isUpdate={isUpdate}
              />
            ) : null}
            {errorMsgs.length ? (
              <Box mt="1em">
                <Text color="red.500" fontSize="sm" fontWeight="bold">
                  We identified{" "}
                  {errorMsgs.length === 1 ? "an issue" : "several issues"} in
                  the submited file.
                </Text>
                <Text
                  mb="0.5em"
                  color="red.500"
                  fontSize="sm"
                  fontWeight="bold"
                >
                  Please review and try again:
                </Text>
                {errorMsgs
                  .slice(0, errorMsgs.length <= 3 ? errorMsgs.length : 3)
                  .map((e, i) => (
                    <Alert message={e} key={i} isError />
                  ))}
                {errorMsgs.length > 3 && (
                  <Alert message={`${errorMsgs.length - 3} More...`} isError />
                )}
              </Box>
            ) : null}
          </ModalBody>

          <ModalFooter justifyContent="space-around">
            {content && (
              <Button
                w="50%"
                type="submit"
                variant="outlinePrimary"
                isLoading={loading}
                onClick={() => content && handleUpload(content)}
                mb="15px"
                isDisabled={shouldSelectcompany && companyId === undefined}
              >
                Save
              </Button>
            )}
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
export default FileUploadModal;

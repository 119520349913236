import { unique } from "src/common/utils/aggregation";
import { IAppPortfolio } from "src/redux/interfaces/IAppPortfolio";

export const buildFilter = (
  portfolios: IAppPortfolio[],
  getValue: (portfolio: IAppPortfolio) => string[],
) => {
  const uniqueValues = unique(portfolios.map(getValue).flat(1));
  return uniqueValues.map((value) => ({
    label: value,
    value: value,
    companyIds: portfolios
      .filter((p) => getValue(p).find((str) => str === value))
      .map((p) => p.summary.companyId),
  }));
};

export const buildFilterFromDefault = <T>(
  defaultValues: { label: string; value: T }[],
  portfolios: IAppPortfolio[],
  getValue: (portfolio: IAppPortfolio) => T,
) => {
  return defaultValues
    .filter((option) =>
      portfolios.some((portfolio) => getValue(portfolio) === option.value),
    )
    .map((option) => ({
      ...option,
      value: String(option.value),
      companyIds: portfolios
        .filter((p) => getValue(p) === option.value)
        .map((p) => p.summary.companyId),
    }));
};

import {
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";

import { ExternalLinkIcon, SettingsIcon, EmailIcon } from "@chakra-ui/icons";
import { ReactComponent as InfoIcon } from "src/assets/icons/info-icon.svg";
import { UserBlock } from "./UserBlock";
import { VersionBlock } from "./VersionBlock";
import { appVersion } from "src/config/version";

type TProps = {
  shouldSeeAdminMenu: boolean;
  canEditCompanies?: boolean;
  userName?: string;
  onSignOut: () => void;
  shouldShowDocumentation?: boolean;
};
export const HeaderNavMenu: React.FC<TProps> = ({
  shouldSeeAdminMenu,
  canEditCompanies,
  userName,
  onSignOut,
  shouldShowDocumentation = false,
}) => {
  if (!userName) return null;
  return (
    <Menu>
      <MenuButton paddingRight="1.5em">
        <UserBlock name={userName} />
      </MenuButton>
      <MenuList marginTop="0.2em">
        <MenuItem
          size="md"
          icon={<EmailIcon />}
          as={Link}
          href="mailto:crisp.support@arup.com"
          variant="outlineGrey"
        >
          Support
        </MenuItem>
        {shouldShowDocumentation && (
          <MenuItem
            icon={<Icon as={InfoIcon} boxSize="12px" />}
            as={Link}
            href="https://docs.crisp.arup.com"
            variant="outlineGrey"
          >
            Documentation
          </MenuItem>
        )}
        {shouldSeeAdminMenu && (
          <MenuItem
            icon={<SettingsIcon />}
            as={ReactRouterLink}
            to={canEditCompanies ? "/admin/users" : "/admin"}
          >
            Admin
          </MenuItem>
        )}
        <MenuItem
          onClick={onSignOut}
          as={Link}
          icon={<ExternalLinkIcon />}
          variant="outlineGrey"
        >
          Sign out
        </MenuItem>
        <VersionBlock appVersion={appVersion} />
      </MenuList>
    </Menu>
  );
};

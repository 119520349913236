import React, { useMemo } from "react";
import { Feature, GeoJsonProperties, Point } from "geojson";
import {
  Button,
  Flex,
  Tabs,
  Text,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Icon,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ListMagnifyIcon } from "src/assets/icons/ListMagnifyingGlass.svg";
import { ReactComponent as ShapesIcon } from "src/assets/icons/Shapes.svg";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { useModalState } from "src/common/utils/useModalState";
import { Map } from "src/common/components/atoms/Map";
import {
  selectAllAssets,
  selectFilteredAndSelectedAssets,
  selectFilteredAssets,
} from "src/redux/slices/portfolios/assets/selectors";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { PortfolioStatTable } from "./PortfolioStatTable";
import { AssetFilters } from "./AssetFilters";
import { UsageSplit } from "./UsageSplit";
import PortfolioExcelModal from "src/pages/CRUDModals/components/CreateUpdateModal";
import { DeleteModal } from "src/pages/CRUDModals/components/DeleteModal";
import { selectCanIEditOpenedPortfolio } from "src/redux/slices/auth/authInfo/selectors";
import { selectCanIGenerateReport } from "src/redux/slices/auth/authInfo/selectors";
import { MeasureSystem } from "src/redux/interfaces/IAppPortfolio";
import {
  selectShouldShowPortfolioReportButton,
  selectShouldShowValue,
} from "src/redux/slices/featureConfig/selectors";
import {
  selectDeletePortfolioError,
  selectDeletePortfolioPending,
} from "src/redux/slices/portfolios/selectors";
import {
  deletePortfolio,
  getPortfolioReport,
} from "src/redux/thunks/portfolios";
import { useCompany } from "src/common/components/organisms/CompanyProviderWrapper";

const getMarkers = (assets: IAppAsset[]): Feature<Point, GeoJsonProperties>[] =>
  assets
    .flatMap((a) => (a.location.gpsCoordinates?.length ? [a] : []))
    .map((a) => ({
      type: "Feature",
      geometry: { type: "Point", coordinates: a.location.gpsCoordinates },
      properties: {
        name: a.assetLabel,
        description: a.location.businessLocationName,
      },
    }));

type TProps = {
  portfolioId: number;
  portfolioName: string;
  isoCurrencyCode: string;
  measureSystem: MeasureSystem;
  latestUpdate?: Date;
};
export const PortfolioSidebar: React.FC<TProps> = ({
  portfolioId,
  portfolioName,
  latestUpdate,
  isoCurrencyCode,
  measureSystem,
}) => {
  const {
    isOpen: isEditOpen,
    setOpen: setEditOpen,
    setClose: setEditClose,
  } = useModalState();
  const {
    isOpen: isDeleteOpen,
    setOpen: setDeleteOpen,
    setClose: setDeleteClose,
  } = useModalState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const company = useCompany();
  const allAssets = useAppSelector(selectAllAssets);
  const filteredAssets = useAppSelector(selectFilteredAssets);
  const filteredAndSelectedAssets = useAppSelector(
    selectFilteredAndSelectedAssets,
  );
  const error = useAppSelector(selectDeletePortfolioError);
  const canIEditThisPortfolio = useAppSelector(selectCanIEditOpenedPortfolio);
  const canIGenerateReport = useAppSelector(selectCanIGenerateReport);
  const shouldShowValue = useAppSelector(selectShouldShowValue);
  const shouldShowButton = useAppSelector(
    selectShouldShowPortfolioReportButton,
  );
  const isDeleteInProgress = useAppSelector(selectDeletePortfolioPending);
  const markers = useMemo(() => getMarkers(filteredAssets), [filteredAssets]);

  const hanldleDelete = async () => {
    if (isDeleteInProgress || !portfolioId) return;
    await dispatch(deletePortfolio(portfolioId));
    navigate(`/companyView/${company.selectedCompanyId}`);
  };

  const getPortfolioReportURL = async () => {
    await dispatch(getPortfolioReport(portfolioId));
  };
  return (
    <>
      <Text mt="16px" as="h2" color="red.400" fontSize="xs">
        Portfolio
      </Text>
      <Text mt="0" as="h3">
        {portfolioName}
      </Text>
      {canIEditThisPortfolio ? (
        <Flex direction="row" mt="0.7em">
          <Button
            size="sm"
            variant="outlineGray"
            width="100%"
            marginRight="1rem"
            onClick={setEditOpen}
          >
            Edit Portfolio
          </Button>
          <Button
            size="sm"
            variant="outlineGray"
            width="100%"
            onClick={setDeleteOpen}
          >
            Delete Portfolio
          </Button>
        </Flex>
      ) : null}

      {latestUpdate && (
        <Text
          mt="10px"
          mb="10px"
          backgroundColor="red.50"
          color="red.400"
          fontSize="xs"
          padding="2px 5px"
          display="inline-block"
        >
          Last Dataset Update: {latestUpdate.toLocaleDateString()}
        </Text>
      )}
      {markers.length > 0 && <Map markers={markers} />}
      <PortfolioStatTable
        selectedAssets={filteredAndSelectedAssets}
        allAssets={allAssets}
        isoCurrecyCode={isoCurrencyCode}
        measureSystem={measureSystem}
        shouldHideValue={!shouldShowValue}
      />
      {canIGenerateReport && shouldShowButton ? (
        <Flex direction="row" mt="0.7em">
          <Button
            size="sm"
            variant="outlineGray"
            width="100%"
            onClick={getPortfolioReportURL}
          >
            Generate Report
          </Button>
        </Flex>
      ) : null}
      <Tabs w="100%" height="100%" variant="ghost" marginTop={5}>
        <Flex alignItems="center" flexWrap="wrap">
          <TabList flex={2} lineHeight=".7rem">
            <Tab
              marginLeft={0}
              display="flex"
              justifyContent="center"
              fontSize="sm"
              alignItems="center"
              p="3px 10px"
              _selected={{
                color: "red.500",
                backgroundColor: "red.50",
                fill: "red.500",
              }}
              borderRadius="1rem"
            >
              <Icon as={ListMagnifyIcon} boxSize="18px" mr="5px" />
              <Text>Filters</Text>
            </Tab>
            <Tab
              marginLeft="0.3em"
              display="flex"
              justifyContent="center"
              fontSize="sm"
              alignItems="center"
              p="3px 10px"
              _selected={{
                color: "red.500",
                backgroundColor: "red.50",
                fill: "red.500",
              }}
              borderRadius="1rem"
            >
              <Icon as={ShapesIcon} boxSize="18px" mr="5px" />
              <Text>Usage Split</Text>
            </Tab>
          </TabList>
        </Flex>
        <TabPanels>
          <TabPanel m="0" px={0}>
            <AssetFilters />
          </TabPanel>
          <TabPanel m="0" px={0}>
            <UsageSplit assets={allAssets} measureSystem={measureSystem} />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <PortfolioExcelModal
        isOpen={isEditOpen}
        onClose={setEditClose}
        portfolioId={portfolioId}
      />
      <DeleteModal
        title="Delete Portfolio"
        objectDescription={portfolioName}
        isOpen={isDeleteOpen}
        onClose={setDeleteClose}
        onDelete={hanldleDelete}
        error={error}
        isLoading={isDeleteInProgress}
      />
    </>
  );
};

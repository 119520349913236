import React, { createContext, useContext, useState, ReactNode } from "react";
import { useAppDispatch } from "src/redux/hooks";
import { fetchConfigByCompanyId } from "src/redux/thunks/featureConfig";

interface CompanyContextType {
  selectedCompanyId: string | undefined;
  setSelectedCompanyId: (companyId: string) => void;
}

const CompanyContext = createContext<CompanyContextType>({
  selectedCompanyId: undefined,
  setSelectedCompanyId: () => {},
});

interface CompanyProviderProps {
  children: ReactNode;
}

export const CompanyProvider: React.FC<CompanyProviderProps> = ({
  children,
}) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    string | undefined
  >();
  const dispatch = useAppDispatch();

  const handleCompanySelection = (id: string) => {
    if (id !== selectedCompanyId) {
      dispatch(fetchConfigByCompanyId(Number(id)));
      setSelectedCompanyId(id);
    }
  };

  return (
    <CompanyContext.Provider
      value={{
        selectedCompanyId,
        setSelectedCompanyId: handleCompanySelection,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = () => useContext(CompanyContext);

// eslint-disable-next-line
export const debounce = (func: Function, timeout = 300) => {
  let timer: NodeJS.Timeout;
  // eslint-disable-next-line
  // @ts-ignore
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

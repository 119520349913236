import { createSlice } from "@reduxjs/toolkit";

import { scenariosAdapter } from "./adapter";
import {
  fetchScenarios,
  setSelectedScenarios,
} from "src/redux/thunks/scenarios";
import { fetchArchetypeByAssetId } from "src/redux/thunks/archetypes";
import {
  Ii18nError,
  apiErrorToI18nError,
} from "src/redux/interfaces/Ii18nError";

const slice = createSlice({
  name: "customPathways",
  initialState: {
    ...scenariosAdapter.getInitialState(),
    requestStatus: "initial",
    selectedScenarios: [] as string[],
    error: null as null | Ii18nError,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchScenarios.pending, (state) => {
      state.requestStatus = "pending";
      state.error = null;
      scenariosAdapter.removeAll(state);
    });
    builder.addCase(fetchScenarios.rejected, (state, action) => {
      state.requestStatus = "rejected";
      state.error = action.payload
        ? apiErrorToI18nError(action.payload)
        : { errorCode: "GENERIC" };
    });
    builder.addCase(fetchScenarios.fulfilled, (state, { payload }) => {
      scenariosAdapter.setMany(state, payload);
      const isArchetypeIn = !!state.selectedScenarios.find(
        (s) => s === "Archetype",
      );
      const scenariosToSelect = payload.map(
        (scenario) => scenario.scenario_name,
      );
      if (isArchetypeIn) scenariosToSelect.push("Archetype");
      state.selectedScenarios = scenariosToSelect;
      state.requestStatus = "fulfilled";
    });
    builder.addCase(fetchArchetypeByAssetId.fulfilled, (state, { payload }) => {
      if (payload?.scenario_name && payload?.packages?.length) {
        state.selectedScenarios = [
          ...state.selectedScenarios,
          payload?.scenario_name,
        ];
      }
    });
    builder.addCase(setSelectedScenarios, (state, { payload }) => {
      state.selectedScenarios = payload;
    });
  },
});

export default slice;

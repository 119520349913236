import React from "react";
import { Box } from "@chakra-ui/react";
import { SustainabilityList } from "src/common/components/organisms/AssetList/SustainabilityList";
import CertificationBadges from "../components/CertificationBadges";
import { selectSelectedAssetIds } from "src/redux/slices/uiState/assetsFilter/selectors";
import { useAppSelector } from "src/redux/hooks";
import {
  selectFilteredAndSelectedAssets,
  selectFilteredAssets,
  selectIsAssetsDataLoading,
} from "src/redux/slices/portfolios/assets/selectors";
import { LoadComponentPlaceholder } from "src/common/components/atoms/Spinner";

export const SustainabilityTab: React.FC = () => {
  const selectedAssetIds = useAppSelector(selectSelectedAssetIds);
  const filteredAssets = useAppSelector(selectFilteredAssets);
  const selectedAssets = useAppSelector(selectFilteredAndSelectedAssets);
  const isLoading = useAppSelector(selectIsAssetsDataLoading);

  if (isLoading) {
    return <LoadComponentPlaceholder />;
  }

  return (
    <Box maxWidth="984px">
      <Box
        w="100%"
        minW="20rem"
        overflowX="scroll"
        p="15px"
        backgroundColor="white"
        borderRadius="8px"
        mb="1em"
      >
        <CertificationBadges assets={selectedAssets} />
      </Box>

      <Box
        pt="10px"
        backgroundColor="white"
        width="100%"
        borderRadius="8px 8px 0 0"
      >
        <SustainabilityList
          assets={filteredAssets}
          selectedAssetIds={selectedAssetIds}
        />
      </Box>
    </Box>
  );
};

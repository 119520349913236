import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  fetchAllUsers,
  deleteUser,
  activateUser,
} from "src/redux/thunks/admin";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  selectAllCrispUsers,
  selectIsAppUsersPending,
} from "src/redux/slices/admin/usersAll/selectors";
import { Pagination } from "src/common/components/molecules/Pagination";
import AddEntityModal from "../../components/AddEntityModal";
import UsersTable from "../UsersTable";
import { CreateUserForm } from "./CreateUserForm";
import { selectAllCompanies } from "src/redux/slices/admin/companies/selectors";
import { fetchCompanies } from "src/redux/thunks/companies";
import { Input } from "src/common/components/atoms/Input";

const GlobalUsers: React.FC = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const companies = useAppSelector(selectAllCompanies);
  const allUsers = useAppSelector(selectAllCrispUsers);
  const isLoading = useAppSelector(selectIsAppUsersPending);
  const [filterUsers, setFilterUsers] = useState("");

  const isError = false;

  const toastMessage = (title: string, description: string) => {
    toast({
      title: title,
      description: description,
      status: "error",
      isClosable: true,
      position: "top",
    });
  };

  const {
    isOpen: isCreateUserModalOpen,
    onClose: closeCreateUserModal,
    onOpen: openCreateUserModal,
  } = useDisclosure();

  useEffect(() => {
    dispatch(fetchAllUsers())
      .unwrap()
      .catch((err) => {
        // TODO: nice handling, low-priority
      });
    dispatch(fetchCompanies())
      .unwrap()
      .catch(() => {
        // TODO: nice handling, low-priority
      });
  }, [dispatch]);

  const handleDeleteUser = async (id: number) => {
    return dispatch(deleteUser(id))
      .then(() => {
        dispatch(fetchAllUsers()).then(() => {
          dispatch(fetchCompanies());
        });
      })
      .catch(() => {
        toastMessage(
          "Delete user error",
          `Cannot delete user, please try again.`,
        );
      });
  };

  const handleEditUser = () => {
    dispatch(fetchAllUsers());
  };

  const handleActivateUser = async (userId: number) => {
    return dispatch(activateUser(userId))
      .then(() => {
        dispatch(fetchAllUsers()).then(() => {
          dispatch(fetchCompanies());
        });
      })
      .catch(() => {
        toastMessage(
          "Company loading error",
          `Cannot download company list, please try again.`,
        );
      });
  };

  const addUserCallback = () => {
    closeCreateUserModal();
    dispatch(fetchAllUsers());
  };

  const filteredUsers = !filterUsers
    ? allUsers
    : allUsers.filter((u) => u.user_name?.toLowerCase()?.includes(filterUsers));

  return (
    <Box backgroundColor="white" padding="40px" borderRadius="8px">
      <HStack mt="1rem" mb="2rem">
        <Box display="flex" flexDirection="column" flex={1}>
          <Text color="red.500" fontWeight="bold">
            Admin
          </Text>
          <Heading as="h1" fontSize="lg" fontWeight="semi-bold">
            App Users
          </Heading>
        </Box>
        <HStack>
          <Input
            placeholder="Filter by user name"
            backgroundColor="white"
            variant="outlineMuted"
            onChange={(v) =>
              setFilterUsers(v.target.value?.toLowerCase() || "")
            }
          />
          <Button
            size="sm"
            ml="1rem"
            variant="outlinePrimary"
            placeSelf={{ base: "center" }}
            onClick={openCreateUserModal}
            disabled={isError}
          >
            Add User
          </Button>
        </HStack>
      </HStack>
      <AddEntityModal
        isOpen={isCreateUserModalOpen}
        onClose={closeCreateUserModal}
        title={"Add new user to CRISP system"}
      >
        <CreateUserForm
          onSubmitCallback={addUserCallback}
          companies={companies}
        />
      </AddEntityModal>

      {isLoading ? (
        <Flex h="100%" w="100%" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      ) : isError ? (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>Error loading companies.</AlertDescription>
        </Alert>
      ) : (
        allUsers.length > 0 && (
          <Pagination allItems={filteredUsers}>
            <UsersTable
              editActionCallback={handleEditUser}
              deleteActionCallback={handleDeleteUser}
              activateActionCallback={handleActivateUser}
            />
          </Pagination>
        )
      )}
    </Box>
  );
};

export default GlobalUsers;

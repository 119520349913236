import { createAsyncThunk } from "@reduxjs/toolkit";
import { defaultApiConfiguration } from "src/api/configurations";
import { LocationApi, ICountry } from "src/api/open-api";
import { USE_LOCAL_MOCKS } from "src/config/mocks";

export const fetchCountries = createAsyncThunk<ICountry[]>(
  "countries/fetch",
  async (_, { rejectWithValue }) => {
    if (USE_LOCAL_MOCKS) {
      return [
        { country_iso_code: "IE", country_label: "Ireland" },
        { country_iso_code: "DE", country_label: "Germany" },
        { country_iso_code: "IT", country_label: "Italy" },
        { country_iso_code: "UK", country_label: "Britain" },
      ];
    }
    const api = new LocationApi(defaultApiConfiguration);
    try {
      const response = await api.countriesGet();
      return response.data;
    } catch (_error) {
      // @ts-expect-error error type is always unknown
      const error: AxiosError = _error;
      return rejectWithValue(
        error?.response?.data || { errorCode: error?.code },
      );
    }
  },
);

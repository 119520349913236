import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { AppHeader } from "../organisms/AppHeader";
import { Link as RouterLink } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { ReactComponent as ArrowsIcon } from "src/assets/icons/caret-double-right.svg";
import { ArrowBackIcon } from "@chakra-ui/icons";

type TProps = {
  children: React.ReactNode;
  sideBar: React.ReactElement;
  backUrl?: string;
  backUrlText?: string;
};

export const StandardLayout: React.FC<TProps> = ({
  children,
  sideBar,
  backUrl,
  backUrlText,
}) => {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <Flex w="100%" direction="column" minHeight="100vh">
      <AppHeader />
      <Flex
        direction="row"
        margin="1.5em"
        maxWidth="1350px"
        width="98%"
        ml="auto"
        mr="auto"
      >
        <Box
          width="300px"
          position={{ base: "absolute", xl: "static" }}
          top={{ base: "58px", xl: "0" }}
          left="0"
          ml={{ base: isExpanded ? "0" : "-280px", xl: "0" }}
          zIndex="1000"
          transition="margin 0.5s ease"
          backgroundColor={{ base: "white", xl: "transparent" }}
          boxShadow={{ base: "base", xl: "none" }}
          pt={{ base: "1em", xl: "0" }}
          borderRadius="8px"
          minH={{ base: "80vh", xl: "30vh" }}
        >
          {backUrl && backUrlText && (
            <RouterLink to={backUrl}>
              <Button
                as="span"
                colorScheme="red"
                variant="outlinePrimary"
                marginBottom="0.7rem"
                marginLeft={{ base: "30px", xl: "0" }}
                width={{ base: isExpanded ? "90%" : "20px", xl: "100%" }}
                position="relative"
                right={{ base: isExpanded ? "1em" : "-250px", xl: "auto" }}
                paddingX={{ base: "10px !important", xl: "2em" }}
                backgroundColor={{ base: "white", xl: "transparent" }}
              >
                <ArrowBackIcon fill="red.500" />
                <Text
                  as="span"
                  display={{
                    base: isExpanded ? "inline" : "none",
                    xl: "inline",
                  }}
                >
                  {backUrlText}
                </Text>
              </Button>
            </RouterLink>
          )}
          <Button
            position="absolute"
            onClick={() => setExpanded(!isExpanded)}
            right="-20px"
            top="40vh"
            zIndex="90"
            display={{ base: "block", xl: "none" }}
            variant="collapseButton"
          >
            <Icon
              as={ArrowsIcon}
              boxSize="14px"
              transform={isExpanded ? "rotate(180deg)" : ""}
              transition="transform 0.5 ease"
            />
          </Button>
          <Box
            backgroundColor="white"
            p="1.5em"
            borderRadius="8px"
            boxSizing="border-box"
            minHeight="50vh"
          >
            {sideBar}
          </Box>
        </Box>
        <Box
          marginLeft={{ base: "4rem", xl: "1rem" }}
          flexGrow="1"
          maxWidth="1020px"
        >
          {children}
        </Box>
      </Flex>
    </Flex>
  );
};

import { ITimeSeriesPoint } from "src/api/open-api";
import { findMaxYearIndex, findMinYearIndex } from "./normalizeTimeSeries";

const _cutArray = (a: ITimeSeriesPoint[], minYear: number, maxYear: number) => {
  const minIndex = findMinYearIndex(a, minYear);
  const maxIndex = findMaxYearIndex(a, maxYear);
  return a.slice(minIndex, maxIndex + 1);
};

// it expects timeSeries to be sorted before using it.
// typically TimeSeries are sorted in thunks, if data is coming from the backend.
// @return [processedA, processedB], there processedA and processedB are new TimeSeries with matching start and end year
export const cutTimeSeries = (
  a: ITimeSeriesPoint[],
  b: ITimeSeriesPoint[],
): [processedA: ITimeSeriesPoint[], processedB: ITimeSeriesPoint[]] => {
  const commonStartYear = Math.max(a[0].year, b[0].year);
  const commonEndYear = Math.min(a[a.length - 1].year, b[b.length - 1].year);
  return [
    _cutArray(a, commonStartYear, commonEndYear),
    _cutArray(b, commonStartYear, commonEndYear),
  ];
};

import { createSlice } from "@reduxjs/toolkit";

import { featureConfigAdapter } from "./adapter";
import {
  Ii18nError,
  apiErrorToI18nError,
} from "src/redux/interfaces/Ii18nError";
import {
  fetchConfigByCompanyId,
  deleteFeatureConfig,
  addFeatureConfigByCompanyId,
} from "src/redux/thunks/featureConfig";

const slice = createSlice({
  name: "featureConfig",
  initialState: {
    ...featureConfigAdapter.getInitialState(),
    requestStatus: "initial",
    error: null as null | Ii18nError,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConfigByCompanyId.pending, (state) => {
      state.requestStatus = "pending";
      state.error = null;
      featureConfigAdapter.removeAll(state);
    });
    builder.addCase(fetchConfigByCompanyId.rejected, (state, action) => {
      state.requestStatus = "rejected";
      state.error = action.payload
        ? apiErrorToI18nError(action.payload)
        : { errorCode: "GENERIC" };
    });
    builder.addCase(fetchConfigByCompanyId.fulfilled, (state, { payload }) => {
      featureConfigAdapter.setMany(state, payload);
      state.requestStatus = "fulfilled";
    });
    builder.addCase(deleteFeatureConfig.fulfilled, (state, { payload }) => {
      featureConfigAdapter.removeOne(state, payload);
    });
    builder.addCase(
      addFeatureConfigByCompanyId.fulfilled,
      (state, { payload }) => {
        featureConfigAdapter.upsertOne(state, payload);
      },
    );
  },
});

export default slice;

import React from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  VStack,
  Select,
} from "@chakra-ui/react";
import { ChangeEvent, FormEvent, useState } from "react";
import { useAppDispatch } from "src/redux/hooks";
import { addUser } from "src/redux/thunks/admin";
import { useErrorState } from "src/redux/hooks/useErrorState";
import { FormInput } from "src/common/components/atoms/Input";
import { ICompany, ICrispUser } from "src/api/open-api";
import { generateCompanyLevelPermissions } from "./helpers";
import { useTranslation } from "react-i18next";
import { genErrorMessage } from "src/common/utils/errors";

interface IProps {
  onSubmitCallback?: () => void;
  user?: ICrispUser;
  companies?: ICompany[];
  isCompanyLevel?: boolean;
}

const formInitialState = {
  user_name: "",
  user_email: "",
  is_active: 1,
};

const usersRoles = [
  {
    role_id: 1,
    role_name: "System Admin",
  },
  {
    role_id: 2,
    role_name: "App Admin",
  },
  {
    role_id: 3,
    role_name: "Company Admin",
  },
  {
    role_id: 4,
    role_name: "User",
  },
];

export const CreateUserForm: React.FC<IProps> = (props: IProps) => {
  const { onSubmitCallback, user, companies, isCompanyLevel } = props;
  const { error, setError, clearError } = useErrorState();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [userForm, setUserForm] = useState(
    user ? { ...user } : { ...formInitialState },
  );
  const [userRoleId, setUserRoleId] = useState<number>(0);
  const [companyId, setCompanyId] = useState<number>();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (error) clearError();
    if (userForm) {
      const adminRole =
        userRoleId === 1 || userRoleId === 2 ? userRoleId : undefined;
      const companyPermissions =
        userRoleId >= 3 && companyId !== undefined
          ? generateCompanyLevelPermissions(userRoleId, companyId)
          : undefined;

      // TOOD: permissions (admin roles and company role as company admin)
      dispatch(addUser({ user: userForm, adminRole, companyPermissions }))
        .then((res) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (res.error) {
            const error = genErrorMessage(
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ...res.payload,
                parameters: { x: `User ${userForm.user_email}` },
              },
              t,
            );
            if (error) {
              throw error;
            }
          }
          setError(null);
          if (onSubmitCallback) onSubmitCallback();
        })
        .catch((err) => {
          setError(err);
        });
    }
  };

  const handleInputChange = (target: { name: string; value: string }) => {
    if (error) clearError();
    setUserForm({
      ...userForm,
      [target.name]: target.value,
    });
  };

  const handleRoleChange = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
  ) => {
    setUserRoleId(Number(event.target.value));
  };

  const handleCompanyChange = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
  ) => {
    setCompanyId(Number(event.target.value));
  };

  return (
    <>
      <Box>
        <form encType="multipart/form-data" onSubmit={handleSubmit}>
          <VStack spacing={"1.5rem"} textAlign="left">
            <FormInput
              label="Name"
              name="user_name"
              value={userForm.user_name}
              type="name"
              onBlur={handleInputChange}
              isRequired
            />
            <FormInput
              label="Email"
              name="user_email"
              value={userForm.user_email}
              type="email"
              onBlur={handleInputChange}
              isRequired
            />
            {!isCompanyLevel && !user && (
              <>
                <FormControl isRequired>
                  <FormLabel>Role</FormLabel>
                  <Select
                    name="role_id"
                    placeholder="select role"
                    width="100%"
                    onChange={handleRoleChange}
                  >
                    {usersRoles?.map((role) => (
                      <option key={role.role_id} value={role.role_id}>
                        {role.role_name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                {(userRoleId === 3 || userRoleId === 4) && (
                  <FormControl>
                    <FormLabel>Company</FormLabel>
                    <Select
                      name="company_id"
                      placeholder="select company"
                      width="100%"
                      onChange={handleCompanyChange}
                    >
                      {companies &&
                        companies.map((c) => (
                          <option key={c.company_id} value={c.company_id}>
                            {c.company_name}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </>
            )}

            <Box mt="2rem">
              <Button
                type="submit"
                variant="outlinePrimary"
                placeSelf={{ base: "center" }}
              >
                Save
              </Button>
            </Box>
          </VStack>
        </form>
        {error && (
          <Alert status="error" mt="1rem">
            <AlertIcon />
            <AlertDescription>
              {error.toString() || "Error saving user, please try again."}
            </AlertDescription>
          </Alert>
        )}
      </Box>
    </>
  );
};

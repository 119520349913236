import { theme } from "src/config/theme";

type ILineProps = {
  name: string;
  hideLine?: boolean;
  timeSeries: { year: number; value: number }[];
  color: string;
  width?: number;
};

export const getChartLine = (
  { name, hideLine, timeSeries, color, width }: ILineProps,
  isSelected?: boolean,
) => ({
  type: "line",
  label: name,
  data: timeSeries.map((p) => ({ x: p.year, y: p.value })),
  showLine: !hideLine,
  borderColor: color,
  hoverBorderColor: "#485654",
  borderWidth: width ? width : isSelected ? 3 : 1.5,
  pointHoverRadius: 0,
  pointHitRadius: 6,
  tension: 0,
  pointRadius: 0,
  animate: false,
});

type IPointProps = {
  x: number;
  y: number;
  color?: string;
  radius?: number;
  label: string;
};
export const getChartPoint = ({ x, y, color, radius, label }: IPointProps) => ({
  label,
  data: [
    {
      x,
      y,
    },
  ],
  pointBorderColor: color ? color : theme.colors.gray["500"],
  pointBackgroundColor: color ? color : theme.colors.gray["500"],
  pointBorderWidth: 1,
  pointHoverRadius: radius ? radius + 1 : 5,
  pointRadius: radius ? radius : 4,
  pointHitRadius: 4,
});

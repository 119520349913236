import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StandardLayout } from "src/common/components/templates/StandardLayout";
import { CompanySidebar } from "./components/CompanySidebar";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  selectFetchPortfolioError,
  selectFilteredPortfolios,
} from "src/redux/slices/portfolios/selectors";
import { fetchCompanies } from "src/redux/thunks/companies";
import { Alert } from "src/common/components/atoms/Alert";
import { genErrorMessage } from "src/common/utils/errors";
import {
  selectPortfolioCardSetup,
  selectTabList,
} from "src/redux/slices/featureConfig/selectors";
import { useParams } from "react-router-dom";
import { Pagination } from "src/common/components/molecules/Pagination";
import { useCompany } from "src/common/components/organisms/CompanyProviderWrapper";
import { selectIsOnlyOneCompanyAvailable } from "src/redux/slices/companies/selectors";
import { PortfolioCardPage } from "./components/PortfolioCardPage";

export const CompanyView: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const company = useCompany();
  const portfolios = useAppSelector(selectFilteredPortfolios);
  const filteredPortfolios = companyId
    ? portfolios.filter(
        (portfolio) => portfolio.companyId === parseInt(companyId),
      )
    : portfolios;
  const portfolioError = useAppSelector(selectFetchPortfolioError);
  const isOnlyOneCompanyAvailable = useAppSelector(
    selectIsOnlyOneCompanyAvailable,
  );
  const cardConfig = useAppSelector(selectPortfolioCardSetup);
  const tabs = useAppSelector(selectTabList);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (companyId && companyId !== company.selectedCompanyId) {
      company.setSelectedCompanyId(companyId);
    }
  }, [companyId, company]);

  return (
    <StandardLayout
      sideBar={<CompanySidebar />}
      backUrl={!isOnlyOneCompanyAvailable ? `/` : undefined}
      backUrlText={!isOnlyOneCompanyAvailable ? "Company Selection" : undefined}
    >
      {portfolioError && (
        <Alert
          isError
          message={genErrorMessage(
            portfolioError,
            t,
            "Cannot display portfolios. ",
          )}
        />
      )}
      {filteredPortfolios.length === 0 && (
        <Alert message="There is no portfolio available for this company" />
      )}
      {filteredPortfolios && (
        <Pagination allItems={filteredPortfolios} itemPerPage={6}>
          <PortfolioCardPage
            cardConfig={cardConfig}
            firstTab={tabs[0]?.to || "stranding"}
          />
        </Pagination>
      )}
    </StandardLayout>
  );
};

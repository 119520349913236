import React from "react";
import { Box, Tooltip } from "@chakra-ui/react";

interface IProps {
  units: string;
  valueAbove?: {
    label: string;
    value: number;
  };
  valueBelow?: {
    label: string;
    value: number;
  };
  children: React.ReactNode;
}

export const DecarbCostSliderTooltip: React.FC<IProps> = ({
  valueAbove,
  valueBelow,
  children,
  units,
}) => {
  if (valueAbove && valueBelow) {
    return (
      <Box position="relative" w="1rem" h="1rem">
        <Tooltip
          isOpen
          label={`${
            valueAbove.label
          } ${valueAbove.value.toLocaleString()} ${units}`}
          bg="gray.50"
          color="gray.800"
          fontWeight="bold"
          fontSize="small"
          placement="top"
          hasArrow
        >
          <Box w="1em" h="1em" position="absolute" top="0" bottom="0"></Box>
        </Tooltip>

        <Tooltip
          isOpen
          label={`${
            valueBelow.label
          } ${valueBelow.value.toLocaleString()} ${units}`}
          bg="gray.50"
          color="gray.800"
          fontWeight="bold"
          fontSize="small"
          placement="bottom"
          hasArrow
        >
          {children}
        </Tooltip>
      </Box>
    );
  }

  if (valueAbove) {
    return (
      <Tooltip
        isOpen
        label={`${
          valueAbove.label
        } ${valueAbove.value.toLocaleString()} ${units}`}
        bg="gray.50"
        color="gray.800"
        fontWeight="bold"
        fontSize="small"
        placement="top"
        hasArrow
        border="1px solid gray.900"
      >
        {children}
      </Tooltip>
    );
  }
  if (valueBelow) {
    return (
      <Tooltip
        isOpen
        label={`${
          valueBelow.label
        } ${valueBelow.value.toLocaleString()} ${units}`}
        bg="gray.50"
        color="gray.800"
        fontWeight="bold"
        fontSize="small"
        placement="bottom"
        hasArrow
      >
        {children}
      </Tooltip>
    );
  }
  return children;
};

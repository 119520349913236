import React from "react";
import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import { EPCLabel } from "src/common/components/atoms/EPCLabel";
import RatingPerformanceLabel from "./RatingPerformanceLabel";

interface IProps {
  epcRating: string;
  epcPerformance: string;
  expectedIntensity?: number;
  actualIntensity: number;
  units: string;
}

const RatingAgainstActualPerformance: React.FC<IProps> = (props) => {
  const {
    epcRating,
    epcPerformance,
    expectedIntensity,
    actualIntensity,
    units,
  } = props;

  const labels: ["A", "B", "C", "D", "E", "F", "G"] = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
  ];

  return (
    <Box width="100%" mb={4}>
      <Text fontSize="small" fontWeight="700" mb={3}>
        Asset&apos;s EPC Rating Against Actual Performance
      </Text>
      {/* Rating */}
      <Grid
        templateColumns="repeat(7, 1fr)"
        templateRows="repeat(1, 1fr)"
        gap={0}
        width="100%"
      >
        {labels.map((label, index) => {
          if (epcRating === epcPerformance && label === epcRating) {
            return (
              <RatingPerformanceLabel
                text={[
                  `EPC rating`,
                  `${Math.round(actualIntensity)?.toLocaleString()} ${units}`,
                ]}
                rating={label}
                key={`rating-performance-${index}`}
              />
            );
          } else if (label === epcRating) {
            return (
              <RatingPerformanceLabel
                text={[
                  `EPC rating`,
                  expectedIntensity && expectedIntensity > 0
                    ? `${Math.round(expectedIntensity)} ${units}`
                    : "",
                ]}
                rating={label}
                key={`rating-performance-expected--${index}`}
              />
            );
          } else if (label === epcPerformance) {
            return (
              <RatingPerformanceLabel
                text={[
                  `Actual perf.` /*, `${Math.round(actualIntensity)} ${units}` - more info on why it is commented out: CRISP-1054*/,
                ]}
                rating={label}
                key={`rating-performance-actual-${index}`}
              />
            );
          } else {
            /* an empty Box is rendered */
            return (
              <GridItem
                rowSpan={1}
                colSpan={1}
                key={`rating-performance-${index}`}
                alignSelf="center"
                justifySelf="center"
              >
                <Box />
              </GridItem>
            );
          }
        })}
      </Grid>
      {/* Line */}
      <Box border="1px" borderColor="gray.400" bg="gray.400" mb={2} />
      {/* Labels */}
      <Grid templateColumns="repeat(7, 1fr)" gap={0} width="100%">
        {labels.map((label, index) => (
          <GridItem
            rowSpan={1}
            colSpan={1}
            key={`label-${index}`}
            alignSelf="center"
            justifySelf="center"
          >
            <EPCLabel rating={label} />
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};
export default RatingAgainstActualPerformance;

import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Box, Flex } from "@chakra-ui/react";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { InfoTooltip } from "src/common/components/atoms/InfoTooltip";
import { currencyCodeToSymbol } from "src/common/utils/units";
import { sum } from "src/common/utils/math";

interface IProps {
  assets: IAppAsset[];
  isoCurrencyCode?: string;
}

const CostAnalysisTable: React.FC<IProps> = ({ assets, isoCurrencyCode }) => {
  const capexStatusQuoEOL = (
    sum(assets.map((a) => a.capex?.capex_cost_oel || 0)) / 1e6
  ).toFixed(1);

  const capexDecarbEOL = capexStatusQuoEOL; // currently we calculate it the same way

  const capexDecarbTotal = (
    sum(assets.map((a) => a.capex?.capex_cost_decarb || 0)) / 1e6
  ).toFixed(1);

  const opexStatusQuoEnergy = (
    sum(assets.map((a) => a.opex.opex_status_quo_energy || 0)) / 1e6
  ).toFixed(1);

  const opexDecarbEnergy = (
    sum(assets.map((a) => a.opex.opex_pathway_energy || 0)) / 1e6
  ).toFixed(1);

  const opexStatusQuoCarbon = (
    sum(assets.map((a) => a.opex.opex_status_quo_carbon || 0)) / 1e6
  ).toFixed(1);

  const opexDecarbCarbon = (
    sum(assets.map((a) => a.opex.opex_pathway_carbon || 0)) / 1e6
  ).toFixed(1);

  const capexStatusQuoDiff = (0).toFixed(1); // it's always zero

  const statusQuoSum =
    Number(capexStatusQuoEOL) +
    Number(capexStatusQuoDiff) +
    Number(opexStatusQuoEnergy) +
    Number(opexStatusQuoCarbon);

  const decarbSum =
    Number(capexDecarbEOL) +
    Number(capexDecarbTotal) +
    Number(opexDecarbEnergy) +
    Number(opexDecarbCarbon);

  const costingItems = [
    {
      name: "CAPEX - Business as Usual Refurb. Costs",
      statusQuo: capexStatusQuoEOL,
      decarbPathway: capexDecarbEOL,
      delta: (Number(capexDecarbEOL) - Number(capexStatusQuoEOL) + 0).toFixed(
        1,
      ),
      InfoTooltip:
        "Total investment cost related to business-as-usual necessary replacements accumulated up to 2050 (cost that would have been incurred anyway).",
    },
    {
      name: "CAPEX - Additional Decarb. Costs",
      statusQuo: capexStatusQuoDiff,
      decarbPathway: capexDecarbTotal,
      delta: (
        Number(capexDecarbTotal) -
        Number(capexStatusQuoDiff) +
        0
      ).toFixed(1),
      InfoTooltip:
        'Total additional investment cost required to achieve GHG targets accumulated up to 2050 ("true" cost of decarbonisation).',
    },
    {
      name: "OPEX - Energy Costs",
      statusQuo: opexStatusQuoEnergy,
      decarbPathway: opexDecarbEnergy,
      delta: (
        Number(opexDecarbEnergy) -
        Number(opexStatusQuoEnergy) +
        0
      ).toFixed(1),
      InfoTooltip: "Accumulated energy costs up to 2050.",
    },
    {
      name: "Total Cost to 2050",
      statusQuo: statusQuoSum.toFixed(1),
      decarbPathway: decarbSum.toFixed(1),
      delta: (decarbSum - statusQuoSum).toFixed(1),
    },
  ];

  return (
    <Box fontSize="small" mb="2rem" w="100%">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th fontSize="small" whiteSpace="nowrap" textColor="gray.700">
              Cost Analysis to 2050 ({currencyCodeToSymbol(isoCurrencyCode)}{" "}
              Millions)
            </Th>
            <Th fontSize="small" color="gray.500" isNumeric>
              <Flex
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <InfoTooltip text="Accumulated CAPEX and OPEX costs up to 2050 for 'do nothing' scenario." />{" "}
                BAU
              </Flex>
            </Th>
            <Th fontSize="small" color="gray.500" isNumeric>
              <Flex
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <InfoTooltip text="Accumulated CAPEX and reduced OPEX costs up to 2050 for Decarb. Pathway" />
                Decarb. Pathway
              </Flex>
            </Th>
            <Th fontSize="small" color="gray.500" isNumeric>
              <Flex
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <InfoTooltip text='Difference between the "Decarbonisation" and the "Status Quo" scenarios. (Negative delta = savings)' />
                Delta
              </Flex>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {costingItems.map((item, i) => {
            return (
              <Tr key={i}>
                <Td fontSize="small">
                  {item.name}{" "}
                  {item.InfoTooltip && <InfoTooltip text={item.InfoTooltip} />}
                </Td>
                <Td fontSize="small" isNumeric>
                  {item.statusQuo}
                </Td>
                <Td fontSize="small" isNumeric>
                  {item.decarbPathway}
                </Td>
                <Td fontSize="small" isNumeric>
                  {item.delta}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};
export default CostAnalysisTable;

import { Box, Flex, Image, Progress } from "@chakra-ui/react";
import React from "react";

import ArupLogo from "../../../../assets/img/arup_red.svg";

export const LogoProgress: React.FC = () => {
  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      <Image src={ArupLogo} alt="Arup logo" w="7rem" mb="1rem" />
      <Box width="100%">
        <Progress size="xs" isIndeterminate colorScheme="gray" />
      </Box>
    </Flex>
  );
};

import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ArcChartsColorsSequence } from "../config";
import { globalMaxYear } from "src/constants";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, BarElement);

type TProps = {
  content: {
    value: number;
    year: number;
  }[];
  hoverLabel?: string;
};

const defaultOptions = {
  plugins: {
    legend: { display: false },
  },
  scales: {
    x: {
      max: globalMaxYear,
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 10,
        minRotation: 0,
        maxRotation: 0,
      },
    },
    y: {
      ticks: {
        callback: function (value: string | number) {
          return value + "%";
        },
      },
    },
  },
};

export const BarChart: React.FC<TProps> = ({ content, hoverLabel }) => {
  if (!content.length) return null;
  const data = {
    labels: content?.map((c) => c.year),
    datasets: [
      {
        label: hoverLabel ? hoverLabel : " ",
        data: content?.map((c) => c.value),
        backgroundColor: ArcChartsColorsSequence[0],
        borderWidth: 0,
      },
    ],
  };
  return <Bar data={data} options={defaultOptions} />;
};

import { useState } from "react";

export const useModalState = () => {
  const [isOpen, setIsOpen] = useState(false);
  return {
    isOpen,
    setOpen: () => setIsOpen(true),
    setClose: () => setIsOpen(false),
  };
};

export interface Ii18nError {
  errorCode: string;
  parameters?: {
    x?: string;
    y?: string;
    z?: string;
  };
}

export const apiErrorToI18nError = (error: unknown): Ii18nError => {
  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    errorCode: error?.errorCode,
    parameters: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      x: error?.sourceX,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      y: error?.sourceY,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      z: error?.sourceZ,
    },
  };
};

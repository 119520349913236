import { theme } from "src/config/theme";

export const getEPCColor = (rating: string) => {
  switch (rating) {
    case "A":
      return "energyRating.a";
    case "B":
      return "energyRating.b";
    case "C":
      return "energyRating.c";
    case "D":
      return "energyRating.d";
    case "E":
      return "energyRating.e";
    case "F":
      return "energyRating.f";
    case "G":
      return "energyRating.g";
    default:
      return "grey.50";
  }
};

export const epcColorsModel = ["A", "B", "C", "D", "E", "F", "G"].map(
  (rating, index) => ({
    label: rating,
    value: index,
    color:
      theme.colors.energyRating[rating.toLowerCase()] ||
      theme.colors["grey.50"],
  }),
);

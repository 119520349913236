import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import {
  AuthenticationProvider,
  AxiosInterceptor,
} from "@arup-group/cognito-authenticator";

import { store } from "./redux";
import { theme } from "./config/theme";
import config from "./api";
import "./config/reset.css";
import "mapbox-gl/dist/mapbox-gl.css";
import App from "./App";

import "./config/i18n";

const container = document.getElementById("app");

// div with id="app" always exist
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <AuthenticationProvider
      region={config.AWS_REGION}
      userPoolId={config.COGNITO_POOL_ID}
      userPoolWebClientId={config.COGNITO_CLIENT_ID}
      domain={config.COGNITO_DOMAIN}
      azureADProviderId={config.COGNITO_AZURE_PROVIDER_ID}
      allowSignUp={false}
    >
      <AxiosInterceptor
        requestMatcher={(r) => !!r.url?.startsWith(config.API)}
      />
      <ChakraProvider theme={theme}>
        <Provider store={store}>
          <CSSReset />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </ChakraProvider>
    </AuthenticationProvider>
  </React.StrictMode>,
);

import { EntityState, createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { permissionsAdapter } from "./adapter";
import { IAppRole } from "src/redux/interfaces/IAppRole";

export const selectSlice = (state: RootState) => state.usersAdminRoles;

export const selectUsersAdminPermissions = createDraftSafeSelector(
  [selectSlice],
  (state: EntityState<IAppRole>) =>
    permissionsAdapter.getSelectors().selectAll(state),
);

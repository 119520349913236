import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { ICompany } from "src/api/open-api";
import { selectAllCountries } from "src/redux/slices/countries/selectors";
import { useErrorState } from "src/redux/hooks/useErrorState";
import { addCompany } from "src/redux/thunks/companies";
import { Alert } from "src/common/components/atoms/Alert";
import { FormInput } from "src/common/components/atoms/Input";

interface IProps {
  onSubmitCallback?: () => void;
  company?: ICompany;
}

export const AddCompanyForm: React.FC<IProps> = (props: IProps) => {
  const { onSubmitCallback, company } = props;

  const dispatch = useAppDispatch();
  const countries = useAppSelector(selectAllCountries);

  const { error, setError, clearError } = useErrorState();

  const [companyFormDetails, setCompanyFormDetails] = useState({
    company_name: company?.company_name || "",
    company_address: company?.company_address || "",
    country_code: company?.country_code || "",
    business_owner: company?.business_owner || "",
    project_director: company?.project_director || "",
    client_relationship_manager: company?.client_relationship_manager || "",
    notes: company?.notes || "",
    project_manager: company?.project_manager || "",
  });

  const handleRawChange = (
    event: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>,
  ) => {
    if (error) clearError();
    handleSanitizedChange({
      name: event.target.name,
      value: event.target.value,
    });
  };

  const handleSanitizedChange = (target: { name: string; value: string }) => {
    if (error) clearError();
    setCompanyFormDetails({
      ...companyFormDetails,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(
      addCompany(
        company?.company_id
          ? { ...company, ...companyFormDetails }
          : companyFormDetails,
      ),
    )
      .unwrap()
      .then(() => {
        if (onSubmitCallback) onSubmitCallback();
      })
      .catch((err) => {
        setError(err.message || "Error creating company");
      });
  };

  return (
    <>
      <Box>
        <form
          encType="multipart/form-data"
          onSubmit={(e) => {
            handleSubmit(e);
            e.preventDefault();
          }}
        >
          <VStack spacing={"1.5rem"} textAlign="left">
            <FormInput
              isRequired
              label="Company Name"
              name="company_name"
              value={companyFormDetails.company_name}
              type="name"
              onBlur={handleSanitizedChange}
            />
            <FormInput
              label="Company Address"
              name="company_address"
              value={companyFormDetails.company_address}
              type="address"
              onBlur={handleSanitizedChange}
              maxLength={255}
            />
            <FormControl isRequired>
              <FormLabel>Country</FormLabel>
              <Select
                name="country_code"
                fontSize="small"
                placeholder="Please select country"
                width="100%"
                onChange={handleRawChange}
                value={companyFormDetails?.country_code}
              >
                {countries.map((country) => (
                  <option
                    key={country.country_iso_code}
                    value={country.country_iso_code}
                  >
                    {country.country_label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormInput
              label="Business Owner"
              name="business_owner"
              value={companyFormDetails.business_owner}
              type="name"
              onBlur={handleSanitizedChange}
            />
            <FormInput
              label="Client Relationship Manager"
              name="client_relationship_manager"
              value={companyFormDetails.client_relationship_manager}
              type="name"
              onBlur={handleSanitizedChange}
            />
            <FormInput
              label="Project Director"
              name="project_director"
              value={companyFormDetails.project_director}
              type="name"
              onBlur={handleSanitizedChange}
            />
            <FormInput
              label="Project Manager"
              name="project_manager"
              value={companyFormDetails.project_manager}
              type="name"
              onBlur={handleSanitizedChange}
            />
            <FormControl>
              <FormLabel>Notes</FormLabel>
              <Textarea
                name="notes"
                value={companyFormDetails.notes}
                onChange={handleRawChange}
                maxLength={200}
              />
            </FormControl>

            <Box mt="1rem">
              <Button
                type="submit"
                variant="outlinePrimary"
                placeSelf={{ base: "center" }}
              >
                Save
              </Button>
            </Box>
          </VStack>
        </form>
        {error && (
          <Alert isError message={error?.message || String(error)} mt="1rem" />
        )}
      </Box>
    </>
  );
};

import React, { useState, useEffect } from "react";
import { Box, Text, Accordion, Flex, Select } from "@chakra-ui/react";
import { MeasureSystem } from "src/redux/interfaces/IAppPortfolio";
import { useAppSelector } from "src/redux/hooks";
import { selectSelectedScenarios } from "src/redux/slices/portfolios/scenarios/selectors";
import { metricTypes } from "../utils/getInterventionValues";
import { MetricTypeSelect } from "./MetricTypeSelection";
import { PackageInfo } from "./PackageInfo";

const InterventionsTab: React.FC<{
  assetArea: number;
  measureSystem?: MeasureSystem;
}> = ({ assetArea, measureSystem }) => {
  const [metric, setMetric] = useState(metricTypes.Absolute);
  const scenarios = useAppSelector(selectSelectedScenarios);
  const [selectedScenario, setSelectedScenario] = useState(
    scenarios[0]?.scenario_name,
  );

  useEffect(() => {
    setSelectedScenario(scenarios[0]?.scenario_name);
  }, [scenarios]);

  const handleSelectedScenario = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedScenario(e.target.value);
  };

  const scenarioToShow = scenarios.find(
    (s) => s.scenario_name === selectedScenario,
  );

  if (!scenarios?.length) {
    return (
      <Box color="gray.500" fontSize="0.8em" textAlign="center" mt="3rem">
        There is no data to display yet. <br />
        Please make sure you uploaded scenarios
      </Box>
    );
  }

  return (
    <Box>
      <Flex
        mt="-16px"
        py="1.3em"
        justifyContent="space-between"
        alignItems="baseline"
        wrap="wrap"
      >
        <Flex alignItems="baseline" mr="1em" mb="0.5rem">
          <Text fontWeight="bold" fontSize="sm">
            Scenario:
          </Text>
          <Select
            fontSize="0.8em"
            size="sm"
            minWidth="11em"
            ml="1em"
            variant="solid"
            onChange={handleSelectedScenario}
            defaultValue={selectedScenario}
            isDisabled={scenarios?.length < 2}
          >
            {scenarios.map((scenario) => (
              <option
                key={scenario.scenario_name}
                value={scenario.scenario_name}
              >
                {scenario.scenario_name}
              </option>
            ))}
          </Select>
        </Flex>
        <Box mb="0.5rem">
          <MetricTypeSelect selectedType={metric} onSelect={setMetric} />
        </Box>
      </Flex>
      {scenarioToShow && (
        <Accordion defaultIndex={[0]} allowMultiple size="sm">
          {scenarioToShow.packages?.map((p) => (
            <PackageInfo
              selectedType={metric}
              key={p.package_code}
              name={p.package_code}
              interventions={p.interventions}
              assetArea={assetArea}
              isDetailed
              measureSystem={measureSystem}
            />
          ))}
        </Accordion>
      )}
    </Box>
  );
};
export default InterventionsTab;

import { createSlice } from "@reduxjs/toolkit";

import { fetchDatabaseVersion } from "src/redux/thunks/version";

import {
  Ii18nError,
  apiErrorToI18nError,
} from "src/redux/interfaces/Ii18nError";

const slice = createSlice({
  name: "countries",
  initialState: {
    version: undefined as undefined | string,
    requestStatus: "initial",
    error: null as null | Ii18nError,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDatabaseVersion.pending, (state) => {
      state.requestStatus = "pending";
      state.error = null;
      state.version = undefined;
    });
    builder.addCase(fetchDatabaseVersion.rejected, (state, action) => {
      state.requestStatus = "rejected";
      state.error = action.payload
        ? apiErrorToI18nError(action.payload)
        : { errorCode: "GENERIC" };
    });
    builder.addCase(fetchDatabaseVersion.fulfilled, (state, { payload }) => {
      state.version = payload;
      state.requestStatus = "fulfilled";
    });
  },
});

export default slice;

import { menuAnatomy } from "@chakra-ui/anatomy";
import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
} from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  list: {
    // this will style the MenuList component
    py: "5px",
    borderRadius: 0,
    border: "none",

    boxShadow: "base",
    bg: "white",
    top: "2px",
  },
  item: {
    // this will style the MenuItem and MenuItemOption components
    color: "gray.600",
    _hover: {
      bg: "gray.50",
    },
    _focus: {
      bg: "gray",
    },
    fontSize: "sm",
  },
});

const componentOverride: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle,
});

export default componentOverride;

import React, { useEffect } from "react";
import {
  Stack,
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { selectSelectedAssetIds } from "src/redux/slices/uiState/assetsFilter/selectors";
import {
  selectAllAssets,
  selectFilteredAssets,
} from "src/redux/slices/portfolios/assets/selectors";
import { selectOpenedPortfolio } from "src/redux/slices/portfolios/selectors";
import { PerformanceTable } from "src/common/components/molecules/PerformanceTable";
import { IAppAsset } from "src/redux/interfaces/IAppAsset";
import { CapexPerformance } from "./CapexPerformance";
import AssetDecarbonisationCosts from "./AssetDecarbonisationCosts";
import { selectAllPortfolios } from "src/redux/slices/portfolios/selectors";
import { useLocation } from "react-router-dom";
import { StandardContent } from "src/common/components/templates/StandardContent";
import { fetchPortfolios } from "src/redux/thunks/portfolios";
import { DecarbonisationCostSlider } from "src/common/components/molecules/DecarbCostSlider";
import CostAnalysisTable from "./CostAnalysisTable";
import { currencyCodeToSymbol } from "src/common/utils/units";
import { Units, getUnits } from "src/constants/measureUnits";
import { selectGHGTargetsForOpenedAsset } from "src/redux/slices/portfolios/assets/openedAssetSelectors";

type TProps = {
  asset: IAppAsset;
};
export const EconomicAssetTab: React.FC<TProps> = ({ asset }) => {
  const dispatch = useAppDispatch();
  const openedPortfolio = useAppSelector(selectOpenedPortfolio);
  const selectedAssetIds = useAppSelector(selectSelectedAssetIds);
  const filteredAssets = useAppSelector(selectFilteredAssets);
  const allAssetsInPortfolio = useAppSelector(selectAllAssets);
  const targetsGHG = useAppSelector(selectGHGTargetsForOpenedAsset);
  const portfolios = useAppSelector(selectAllPortfolios);
  const location = useLocation();

  useEffect(() => {
    if (!openedPortfolio) {
      dispatch(fetchPortfolios());
    }
  }, [dispatch, openedPortfolio, asset?.portfolioId]);

  const portfolioId = Number(location.pathname.split("/")[1]);
  const portfolio = portfolios.find((p) => p.portfolioId === portfolioId);

  const showAlert = !filteredAssets?.length || !selectedAssetIds?.length;
  const moneyPerAreaUnit = `${currencyCodeToSymbol(
    portfolio?.currency,
  )}/${getUnits(Units.area, portfolio?.measureSystem)}`;

  return (
    <Stack>
      <StandardContent
        chartAreaContent={
          <>
            {showAlert ? (
              <Alert status="warning">
                <AlertIcon />
                <AlertDescription>
                  {selectedAssetIds.length === 0
                    ? "Please select at least one Asset."
                    : "No results"}
                </AlertDescription>
              </Alert>
            ) : (
              <Box p="1rem" w="100%">
                <CostAnalysisTable
                  assets={[asset]}
                  isoCurrencyCode={openedPortfolio?.currency}
                />
                <CapexPerformance
                  assets={[asset]}
                  units={moneyPerAreaUnit}
                  areaUnits={getUnits(
                    Units.area,
                    openedPortfolio?.measureSystem,
                  )}
                />
                <DecarbonisationCostSlider
                  asset={asset}
                  assets={allAssetsInPortfolio}
                  units={moneyPerAreaUnit}
                />
              </Box>
            )}
          </>
        }
      >
        <Box width="100%" px="0.5rem">
          <AssetDecarbonisationCosts asset={asset} />
          <PerformanceTable
            assets={[asset]}
            targets={targetsGHG}
            forecastGHG={asset.ghgResults.emissionsForecast || []}
            forecastEnergy={asset.energyResults.emissionsForecast || []}
            maxReportingYear={asset.reportingYear}
            measureSystem={openedPortfolio?.measureSystem}
          />
        </Box>
      </StandardContent>
    </Stack>
  );
};
export default EconomicAssetTab;
